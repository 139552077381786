import * as types from '../constants/ActionTypes'
import { fromJS } from 'immutable'

const initialState = fromJS({
  allUsersSubscriptions: [],
  allUserRoles: [],
  allCities: [],
})

export default (state = initialState, action) => {
  let newState = state
  switch (action.type) {
    case types.GET_ADMIN_VIEW_INFO:
      newState = newState.set(
        'allUsersSubscriptions',
        fromJS(action.payload.GetAllUsersSubscriptions)
      )
      newState = newState.set('allCities', fromJS(action.payload.AllCities))
      return newState
    case types.UPDATE_SUBSCRIPTION:
      const index = newState
        .get('allUsersSubscriptions')
        .findLastIndex(
          (x) => x.getIn(['subscription', 'id']) === action.payload.id
        )
      newState = newState.setIn(
        ['allUsersSubscriptions', index, 'subscription', 'city_id'],
        action.payload.city_id
      )
      return newState
    case types.GET_ALL_USER_ROLES:
      newState = newState.set(
        'allUserRoles',
        fromJS(action.payload.AllUserRoles)
      )
      return newState
    case types.LOGOUT_USER:
      if (action.payload.LogoutUser.success) return initialState
      return state
    default:
      return state
  }
}
