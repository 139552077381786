import './Icon.scss'
import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

const Icon = ({ className, name, ...props }) => (
  <i {...props} className={cx('icon-' + name, className, 'icon-style')} />
)

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
}

export default Icon
