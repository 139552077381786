import img1 from '../../assets/introDialog/introImg1.png'
import img2 from '../../assets/introDialog/introImg2.png'
import img3 from '../../assets/introDialog/introImg3.png'
import './IntroDialog.scss'
import Dialog from '@material-ui/core/Dialog'
import Grid from '@material-ui/core/Grid'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { CSSTransition } from 'react-transition-group'

export const IntroDialog = ({ open, onClose }) => {
  const [step, setStep] = React.useState(0)

  function renderStepContents() {
    return (
      <React.Fragment>
        {step === 0 && (
          <CSSTransition
            mountOnEnter
            appear
            in={true}
            timeout={0}
            classNames="fadeT"
            unmountOnExit
          >
            <div>
              <div className="subHeader">
                <FormattedMessage id="intro.thisIsUs" />
              </div>
              <div className="header">
                <FormattedMessage id={'intro.dimHeader'} />
              </div>
              <div className="body">
                <FormattedMessage id={'intro.dimBody'} />
              </div>
              <img src={img1} alt="" className="smallImg" />
              <div className="footer">
                <span onClick={() => setStep((step + 4) % 3)}>
                  {' '}
                  <FormattedMessage id={'intro.next'} />{' '}
                </span>
              </div>
            </div>
          </CSSTransition>
        )}
        {step === 1 && (
          <CSSTransition
            mountOnEnter
            appear
            in={true}
            timeout={0}
            classNames="fadeT"
            unmountOnExit
          >
            <div>
              <div className="subHeader">
                <FormattedMessage id="intro.thisIsUs" />
              </div>
              <div className="header">
                <FormattedMessage id={'intro.metHeader'} />
              </div>
              <div className="body">
                <FormattedMessage id={'intro.metBody'} />
              </div>
              <img src={img2} alt="" />
              <div className="footer">
                <span className="back" onClick={() => setStep((step + 2) % 3)}>
                  {' '}
                  <FormattedMessage id={'intro.prev'} />{' '}
                </span>
                <span onClick={() => setStep((step + 4) % 3)}>
                  {' '}
                  <FormattedMessage id={'intro.next'} />{' '}
                </span>
              </div>
            </div>
          </CSSTransition>
        )}
        {step === 2 && (
          <CSSTransition
            mountOnEnter
            appear
            in={true}
            timeout={0}
            classNames="fadeT"
            unmountOnExit
          >
            <div>
              <div className="subHeader">
                <FormattedMessage id="intro.thisIsUs" />
              </div>
              <div className="header">
                <FormattedMessage id={'intro.trendHeader'} />
              </div>
              <div className="body">
                <FormattedMessage id={'intro.trendBody'} />
              </div>
              <img src={img3} alt="" />
              <div className="footer">
                <span className="back" onClick={() => setStep((step + 2) % 3)}>
                  {' '}
                  <FormattedMessage id={'intro.prev'} />{' '}
                </span>
                <span onClick={() => onClose()}>
                  {' '}
                  <FormattedMessage id={'intro.finish'} />{' '}
                </span>
              </div>
            </div>
          </CSSTransition>
        )}
      </React.Fragment>
    )
  }

  return (
    <Dialog onClose={onClose} open={open} classes={{ paper: 'IntroDialog' }}>
      <div className="InnerIntroDialog">
        <Grid container spacing={8}>
          {renderStepContents()}
        </Grid>
      </div>
    </Dialog>
  )
}

export default IntroDialog
