import { Icon, InfoDonut } from '..'
import { closeIndDialog, setDrawerinfo } from '../../actions/ModalActions'
import { gAnalytics, indicatorsLookup } from '../../helpers/analytics'
import './IndicatorDialog.scss'
import Dialog from '@material-ui/core/Dialog'
import Grid from '@material-ui/core/Grid'
import ArrowBack from '@material-ui/icons/ArrowBack'
import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

const IndicatorDialog = ({
  open,
  onClose,
  intl,
  modalStore,
  pageStore,
  actions,
}) => {
  const [affectedConsiderations, setAffectedConsiderations] = React.useState([])

  function updateAffected(id) {
    const arr = []
    pageStore.getIn(['allInfo', 'LatestCityIndicatorWeights']).forEach((w) => {
      if (w.get('i') === id) {
        arr.push({ name: w.get('c'), weight: w.get('w') })
      }
    })
    setAffectedConsiderations(arr)
  }

  React.useEffect(() => {
    //Send the event to google analytics
    gAnalytics('event', [
      'Indicator',
      'open',
      indicatorsLookup[modalStore.get('dialogIndicator')],
    ])

    //Update modal state to new 'affected considerations'
    updateAffected(modalStore.get('dialogIndicator'))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalStore.get('dialogIndicator')])

  function renderAffectedConsiderations() {
    return affectedConsiderations.map((c, i) => {
      return (
        <Grid
          item
          md={6}
          sm={12}
          xs={12}
          key={i}
          className="impacted-considerations-wrapper"
        >
          <div
            className="grade impacted-consideration-item"
            onClick={() => handleOpenDrawer(undefined, c.name)}
          >
            <InfoDonut
              actual={parseFloat(c.weight)}
              size={75}
              textSize={18}
              displayText={parseFloat(c.weight).toFixed(1) + '%'}
              actualColor={'#9EC73D'}
            />
            <div className="gradeDescription">
              <div className="gradeDescTitle">
                <FormattedMessage id={'considerations.' + c.name} />
              </div>
            </div>
          </div>
        </Grid>
      )
    })
  }

  function handleOpenDrawer(grade, consideration) {
    actions.setDrawerinfo({ consideration, grade })
    actions.closeIndDialog()
  }

  const grade = modalStore.get('dialogIndicatorGrade')
  const id = modalStore.get('dialogIndicator')
  const name = intl.formatMessage({ id: `indicators.name.${id}` })
  const description = intl.formatMessage({
    id: `indicators.description.${id}`,
  })
  return (
    <Dialog
      onClose={onClose}
      open={open}
      classes={{ paper: 'IndicatorDialog' }}
    >
      <div className="closeBtn">
        <ArrowBack onClick={onClose} />
      </div>
      <div className="InnerIndicatorDialog">
        <Grid
          item
          xs={12}
          classes={{ item: 'itemRow' }}
          className="action-btns"
        ></Grid>
        <div className="subHeader">
          <span className="icon">
            {' '}
            <Icon name="indicator-icon" />{' '}
          </span>{' '}
          <FormattedMessage id="indicators.indicator" />
        </div>
        <div className="header">
          {/* changed to passing an indicator name on click, this affects */}
          {/* internationalization going down the road */}
          {/* <FormattedMessage id={'indicators.name.' + id} /> */}
          {name}
        </div>
        <div
          className="grade bottomMargin"
          style={{ cursor: 'auto', background: 'inherit' }}
        >
          <InfoDonut actual={grade} actualColor="#9EC73D" />
          <div className="gradeDescription">
            <div className="gradeDescTitle">
              <FormattedMessage id="indicators.grade" />
            </div>
            <div>
              <FormattedMessage id="indicators.gradeDescription" />
            </div>
          </div>
        </div>
        <div className="description">{description}</div>
        <p className="title">
          <FormattedMessage id="indicators.impactedConsiderations" />
        </p>
        <Grid
          container
          spacing={24}
          className="impacted-considerations-container"
        >
          {renderAffectedConsiderations()}
        </Grid>
      </div>
    </Dialog>
  )
}

function mapStateToProps(state) {
  return {
    modalStore: state.ModalStore,
    pageStore: state.PageStore,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        closeIndDialog,
        setDrawerinfo,
      },
      dispatch
    ),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(IndicatorDialog))
