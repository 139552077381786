import theme from '../../theme'
import { MuiThemeProvider } from '@material-ui/core/styles'
import React, { Fragment } from 'react'

const ThemeProvider = ({ children }) => (
  <MuiThemeProvider theme={theme}>
    <Fragment>{children}</Fragment>
  </MuiThemeProvider>
)

export default ThemeProvider
