import {
  forgotPassword,
  resetPassword,
  validateToken,
} from '../../actions/UserActions'
import citiIQ_background from '../../assets/citiIQ_login_image.jpg'
import logo from '../../assets/citiiq-login-logo.png'
import LanguageSelector from '../../components/PageWrapper/LanguageSelector'
import { gAnalytics } from '../../helpers/analytics'
import './ForgotPassword.scss'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import React from 'react'
import Helmet from 'react-helmet'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'

const ForgotPassword = ({ history, actions, intl }) => {
  const [email, setEmail] = React.useState('')
  const [emailFocus, setEmailFocus] = React.useState(false)
  const [emailSent, setEmailSent] = React.useState(false)
  const [emailError, setEmailError] = React.useState('')

  function forgotPassword() {
    actions.forgotPassword(email).then((response) => {
      if (response.success) {
        gAnalytics('event', ['Forgot Email', 'sent', email])
        setEmailSent(true)
      } else {
        setEmailError(response.error)
      }
    })
  }

  function handleLoginButtonClick() {
    gAnalytics('pageview', ['/login'])
    history.push('/')
  }

  function handleInputChange(e) {
    setEmail(e.target.value)
  }

  function handleFocus(e) {
    setEmailFocus(true)
  }

  function handleBlur(e) {
    setEmailFocus(false)
  }

  if (emailSent) {
    return (
      <div
        className="forgotPass"
        style={{ backgroundImage: citiIQ_background }}
      >
        <Helmet>
          <title>
            CitiIQ - {intl.formatMessage({ id: 'login.forgotMessage' })}
          </title>
        </Helmet>
        <img src={citiIQ_background} className="forgotPassBackground" alt="" />
        <div className="forgotPassDiv">
          <img className="siteLogo" src={logo} alt="" />
          <div className="registerDiv">
            <span>
              <FormattedMessage id="login.goToLoginText" />
            </span>
            <Button
              onClick={() => handleLoginButtonClick()}
              color="primary"
              classes={{ textPrimary: 'registerButton' }}
            >
              <FormattedMessage id="login.login" />
            </Button>
          </div>
          <React.Fragment>
            <p className="forgotPassHeader bottomPadded">
              <FormattedMessage id="forgotpass.sentEmail" />
            </p>
            <Grid container spacing={16}>
              <Grid item xs={10} style={{ margin: '0 auto', color: '#eee' }}>
                <FormattedMessage id="forgotpass.emailMessage" />
              </Grid>
            </Grid>
            <Grid container spacing={16}>
              <Grid
                item
                xs={6}
                style={{ margin: '0 auto', color: '#eee', fontSize: '20px' }}
              >
                <h4>{email}</h4>
              </Grid>
            </Grid>
          </React.Fragment>
        </div>
      </div>
    )
  }

  return (
    <div className="forgotPass" style={{ backgroundImage: citiIQ_background }}>
      <Helmet>
        <title>
          CitiIQ - {intl.formatMessage({ id: 'login.forgotMessage' })}
        </title>
      </Helmet>
      <img src={citiIQ_background} className="forgotPassBackground" alt="" />
      <div className="forgotPassDiv">
        <img className="siteLogo" src={logo} alt="" />
        <div className="registerDiv">
          <span>
            <FormattedMessage id="login.goToLoginText" />
          </span>
          <Button
            onClick={() => history.push('/')}
            color="primary"
            classes={{ textPrimary: 'registerButton' }}
          >
            <FormattedMessage id="login.login" />
          </Button>
        </div>
        <React.Fragment>
          <p className="forgotPassHeader bottomPadded">
            <FormattedMessage id="login.forgotMessage" />
          </p>
          {emailError && (
            <p className="noEmail bottomPadded">
              <FormattedMessage id="forgotpass.noEmail" />
            </p>
          )}
          <div className="forgotPassGridWrapper">
            <Grid container spacing={16}>
              <Grid item xs={12}>
                <input
                  className="forgotPassInput"
                  value={email}
                  name="email"
                  onFocus={(e) => handleFocus(e)}
                  onChange={(e) => handleInputChange(e)}
                  onBlur={(e) => handleBlur(e)}
                  placeholder={
                    !emailFocus
                      ? intl.formatMessage({
                          id: 'input.emailPlaceholder',
                        })
                      : ''
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  onClick={() => forgotPassword()}
                  color="primary"
                  classes={{ textPrimary: 'forgotPassButton' }}
                >
                  <FormattedMessage id="login.sendResetEmail" />
                </Button>
              </Grid>
            </Grid>
          </div>
        </React.Fragment>
      </div>
      <div className="languageSelectorDiv">
        <div className="selector">
          <LanguageSelector />
        </div>
      </div>
    </div>
  )
}

function mapStateToProps(state) {
  return {
    userStore: state.UserStore,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        forgotPassword,
        resetPassword,
        validateToken,
      },
      dispatch
    ),
  }
}

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(ForgotPassword))
)
