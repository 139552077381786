/* eslint-disable react-hooks/exhaustive-deps */
import { renderParagraphWithLinebreaks } from '../../helpers/string'
import useLanguage from '../../state/hooks/useLanguage'
import { renderMethodologyImage } from './Images'
import './Methodology.scss'
import { CircularProgress } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import ListItem from '@material-ui/core/ListItem'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import ArrowBack from '@material-ui/icons/ArrowBack'
import PropTypes from 'prop-types'
import React from 'react'
import Helmet from 'react-helmet'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Link } from 'react-router-dom'

const Methodology = ({ intl }) => {
  const [loading, setLoading] = React.useState(true)
  const { language } = useLanguage()

  React.useEffect(() => {
    setLoading(false)
  }, [])
  const sessionHashes = [
    '#citiiq-hierarchy-and-algorithm',
    '#weighting-the-data',
    '#data-quality-rating',
    '#sdg-compatibility-and-tracker',
    '#indicators',
  ]
  const sessionRefs = [
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
    React.useRef(null),
  ]
  function scrollTo(id) {
    if (sessionRefs[id].current) {
      sessionRefs[id].current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    }
  }
  const handleAnchorClick = (e, sessionId) => {
    e.preventDefault()
    scrollTo(sessionId)
  }

  React.useEffect(() => {
    switch (window.location.href) {
      case '#citiiq-hierarchy-and-algorithm':
        scrollTo(0)
        break
      case '#weighting-the-data':
        scrollTo(1)
        break
      case '#data-quality-rating':
        scrollTo(2)
        break
      case '#sdg-compatibility-and-tracker':
        scrollTo(3)
        break
      case '#indicators':
        scrollTo(4)
        break
      default:
        break
    }
  }, [
    sessionRefs[0],
    sessionRefs[1],
    sessionRefs[2],
    sessionRefs[3],
    sessionRefs[4],
  ])

  function renderMenuList() {
    return (
      <MenuList>
        <ListItem className="anchor-text">
          <FormattedMessage
            id="methodology.contents.title"
            description="text"
          />
        </ListItem>
        {sessionHashes.map((hash, idx) => {
          const title = intl.formatMessage({ id: `methodology.title.${idx}` })
          return (
            <MenuItem
              onClick={(e) => handleAnchorClick(e, idx)}
              className="anchor-title"
              component="a"
              href={hash}
            >
              <FormattedMessage id={title} description="context-header" />
            </MenuItem>
          )
        })}
      </MenuList>
    )
  }

  function renderCitiiqHierarchy() {
    return (
      <>
        {renderParagraphWithLinebreaks({
          intl: intl,
          paragraphClassName: 'content-text',
          messageId: `methodology.contents.0.1`,
        })}
        {renderMethodologyImage({
          locale: language.locale,
          imageId: 'methodology.image.0.1',
        })}
        {renderParagraphWithLinebreaks({
          intl: intl,
          paragraphClassName: 'content-text',
          messageId: `methodology.contents.0.2`,
        })}
        {renderMethodologyImage({
          locale: language.locale,
          imageId: 'methodology.image.0.2',
        })}

        {renderParagraphWithLinebreaks({
          intl: intl,
          paragraphClassName: 'content-text',
          messageId: `methodology.contents.0.3`,
        })}
        {renderMethodologyImage({
          locale: language.locale,
          imageId: 'methodology.image.0.3',
        })}

        {renderParagraphWithLinebreaks({
          intl: intl,
          paragraphClassName: 'content-text',
          messageId: `methodology.contents.0.4`,
        })}
        {renderMethodologyImage({
          locale: language.locale,
          imageId: 'methodology.image.0.4',
        })}

        {renderParagraphWithLinebreaks({
          intl: intl,
          paragraphClassName: 'content-text',
          messageId: `methodology.contents.0.5`,
        })}
        {renderMethodologyImage({
          locale: language.locale,
          imageId: 'methodology.image.0.5',
        })}

        {renderParagraphWithLinebreaks({
          intl: intl,
          paragraphClassName: 'content-text',
          messageId: `methodology.contents.0.6`,
        })}
        {renderMethodologyImage({
          locale: language.locale,
          imageId: 'methodology.image.0.6',
        })}

        {renderParagraphWithLinebreaks({
          intl: intl,
          paragraphClassName: 'content-text',
          messageId: `methodology.contents.0.7`,
        })}
        {renderMethodologyImage({
          locale: language.locale,
          imageId: 'methodology.image.0.7',
        })}
      </>
    )
  }
  function renderWeightingData() {
    return (
      <>
        {renderParagraphWithLinebreaks({
          intl: intl,
          paragraphClassName: 'content-text',
          messageId: `methodology.contents.1.1`,
        })}
        {renderMethodologyImage({
          locale: language.locale,
          imageId: 'methodology.image.1.1',
        })}

        {renderParagraphWithLinebreaks({
          intl: intl,
          paragraphClassName: 'content-text',
          messageId: `methodology.contents.1.2`,
        })}
        <Grid container>
          <Grid item>
            {renderMethodologyImage({
              locale: language.locale,
              imageId: 'methodology.image.1.2.1',
            })}
          </Grid>
          <Grid item>
            {renderMethodologyImage({
              locale: language.locale,
              imageId: 'methodology.image.1.2.2',
            })}
          </Grid>
        </Grid>
        {renderParagraphWithLinebreaks({
          intl: intl,
          paragraphClassName: 'content-text',
          messageId: `methodology.contents.1.3`,
        })}
        <Grid container>
          <Grid item>
            {renderMethodologyImage({
              locale: language.locale,
              imageId: 'methodology.image.1.3.1',
            })}
          </Grid>
          <Grid item>
            {renderMethodologyImage({
              locale: language.locale,
              imageId: 'methodology.image.1.3.2',
            })}
          </Grid>
        </Grid>
        {renderParagraphWithLinebreaks({
          intl: intl,
          paragraphClassName: 'content-text',
          messageId: `methodology.contents.1.4`,
        })}
        <Grid container>
          <Grid item>
            {renderMethodologyImage({
              locale: language.locale,
              imageId: 'methodology.image.1.4.1',
            })}
          </Grid>
          <Grid item>
            {renderMethodologyImage({
              locale: language.locale,
              imageId: 'methodology.image.1.4.2',
            })}
          </Grid>
        </Grid>
        {renderParagraphWithLinebreaks({
          intl: intl,
          paragraphClassName: 'content-text',
          messageId: `methodology.contents.1.5`,
        })}
        <Grid container>
          <Grid item>
            {renderMethodologyImage({
              locale: language.locale,
              imageId: 'methodology.image.1.5.1',
            })}
          </Grid>
          <Grid item>
            {renderMethodologyImage({
              locale: language.locale,
              imageId: 'methodology.image.1.5.2',
            })}
          </Grid>
        </Grid>
        {renderParagraphWithLinebreaks({
          intl: intl,
          paragraphClassName: 'content-text',
          messageId: `methodology.contents.1.6`,
        })}
        {renderMethodologyImage({
          locale: language.locale,
          imageId: 'methodology.image.1.6',
        })}
        {renderParagraphWithLinebreaks({
          intl: intl,
          paragraphClassName: 'content-text',
          messageId: `methodology.contents.1.7`,
        })}
        {renderMethodologyImage({
          locale: language.locale,
          imageId: 'methodology.image.1.7',
        })}
        {renderParagraphWithLinebreaks({
          intl: intl,
          paragraphClassName: 'content-text',
          messageId: `methodology.contents.1.8`,
        })}
        {renderMethodologyImage({
          locale: language.locale,
          imageId: 'methodology.image.1.8',
        })}
        {renderParagraphWithLinebreaks({
          intl: intl,
          paragraphClassName: 'content-text',
          messageId: `methodology.contents.1.9`,
        })}
      </>
    )
  }

  function renderDataQuality() {
    return (
      <>
        {renderParagraphWithLinebreaks({
          intl: intl,
          paragraphClassName: 'content-text',
          messageId: `methodology.contents.2.1`,
        })}
        {renderMethodologyImage({
          locale: language.locale,
          imageId: 'methodology.image.2.1',
        })}
        {renderParagraphWithLinebreaks({
          intl: intl,
          paragraphClassName: 'content-text',
          messageId: `methodology.contents.2.2`,
        })}
        <ol>
          <li>
            {renderParagraphWithLinebreaks({
              intl: intl,
              paragraphClassName: 'content-text',
              messageId: `methodology.contents.2.2.1`,
            })}
            <ol style={{ listStyleType: 'lower-alpha' }}>
              <li>
                {renderParagraphWithLinebreaks({
                  intl: intl,
                  paragraphClassName: 'content-text',
                  messageId: `methodology.contents.2.2.1.a`,
                })}
              </li>
              <li>
                {renderParagraphWithLinebreaks({
                  intl: intl,
                  paragraphClassName: 'content-text',
                  messageId: `methodology.contents.2.2.1.b`,
                })}
              </li>
              <li>
                {renderParagraphWithLinebreaks({
                  intl: intl,
                  paragraphClassName: 'content-text',
                  messageId: `methodology.contents.2.2.1.c`,
                })}
              </li>
            </ol>
          </li>
          <li>
            {renderParagraphWithLinebreaks({
              intl: intl,
              paragraphClassName: 'content-text',
              messageId: `methodology.contents.2.2.2`,
            })}
          </li>
          <li>
            {renderParagraphWithLinebreaks({
              intl: intl,
              paragraphClassName: 'content-text',
              messageId: `methodology.contents.2.2.3`,
            })}
          </li>
        </ol>
        {renderMethodologyImage({
          locale: language.locale,
          imageId: 'methodology.image.2.2',
        })}
        {renderParagraphWithLinebreaks({
          intl: intl,
          paragraphClassName: 'content-text',
          messageId: `methodology.contents.2.3`,
        })}
      </>
    )
  }
  function renderSdgCompatibility() {
    return (
      <>
        {renderParagraphWithLinebreaks({
          intl: intl,
          messageId: 'methodology.contents.3.1',
        })}
        {renderMethodologyImage({
          locale: language.locale,
          imageId: 'methodology.image.3.1',
        })}
      </>
    )
  }

  function renderIndicators() {
    const getIndicatorName = (idx) => {
      const key = `indicators.name.${idx}`
      const name = intl.formatMessage({ id: key })
      return name === key ? null : name
    }
    // indicator id's are scattered, so we need to loop through all possible id's
    const allIndicators = [...Array(200)]
      .map((u, idx) => getIndicatorName(idx))
      .filter((indicator) => indicator !== null)
      .sort((a, b) => a.localeCompare(b))

    return (
      <>
        {renderParagraphWithLinebreaks({
          intl: intl,
          paragraphClassName: 'content-text',
          messageId: `methodology.contents.4.1`,
        })}
        <ul className="content-text source-list ">
          {allIndicators.map((name) => (
            <li>{name}</li>
          ))}
        </ul>
      </>
    )
  }
  function renderContentForSession(hash) {
    switch (hash) {
      case '#citiiq-hierarchy-and-algorithm':
        return renderCitiiqHierarchy()
      case '#weighting-the-data':
        return renderWeightingData()
      case '#data-quality-rating':
        return renderDataQuality()
      case '#sdg-compatibility-and-tracker':
        return renderSdgCompatibility()
      case '#indicators':
        return renderIndicators()
      default:
        return <></>
    }
  }

  return (
    <div className="Methodology">
      <Helmet>
        <title>
          CitiIQ - {intl.formatMessage({ id: 'methodology.header' })}
        </title>
      </Helmet>
      <Link to="/dashboard" className="navigation">
        <h1>
          <ArrowBack className="nav-icon" />
          <FormattedMessage id="methodology.navigation" description="nav" />
        </h1>
      </Link>

      {loading ? (
        <CircularProgress />
      ) : (
        <Grid
          container
          spacing={24}
          direction="column"
          className="contents-container"
        >
          <Grid container item spacing={0}>
            <Grid item xs={12} sm={10} md={8}>
              <Grid item xs={12} className="contentsAnchor">
                {renderMenuList()}
              </Grid>
              {sessionHashes.map((hash, idx) => {
                return (
                  <>
                    {idx !== 0 && <br />}
                    {idx !== 0 && <br />}
                    {idx !== 0 && <hr />}
                    <div
                      className="anchor-item"
                      id={hash.replace('#', '')}
                      ref={sessionRefs[idx]}
                    />
                    <Grid item xs={12} className="content-item">
                      <h2 className="content-header">
                        <FormattedMessage
                          id={`methodology.title.${idx}`}
                          description="context-header"
                        />
                      </h2>
                      {renderContentForSession(hash)}
                    </Grid>
                  </>
                )
              })}
            </Grid>
          </Grid>
        </Grid>
      )}
    </div>
  )
}

Methodology.propTypes = {
  intl: PropTypes.object,
}

export default injectIntl(Methodology)
