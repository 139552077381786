import * as types from '../constants/ActionTypes'

//Opens the consideration drawer (rendered in pageWrapper)
//data should look like "data={consideration: 'c_1', grade: 85}"
export const setDrawerinfo = (data) => (dispatch) => {
  dispatch({ type: types.SET_DRAWER, payload: data })
}

export const closeDrawer = () => (dispatch) => {
  dispatch({ type: types.CLOSE_DRAWER })
}

//Opens the indicator Dialog (rendered in pageWrapper)
//data should look like "data={indicator: 'i_1', grade: 85, weight: 24.56}"
export const setIndDialoginfo = (data) => (dispatch) => {
  dispatch({ type: types.SET_INDICATOR_D, payload: data })
}

//Closes indicator dialog (rendered in pageWrapper)
export const closeIndDialog = () => (dispatch) => {
  dispatch({ type: types.CLOSE_INDICATOR_D })
}

export const closeForceLogoutDialog = () => (dispatch) => {
  dispatch({ type: types.CLOSE_FORCE_LOGOUT_DIALOG })
}
