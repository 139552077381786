import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Doughnut } from 'react-chartjs-2'

// render donut
const donutDatasetRow = {
  data: [],
  backgroundColor: [],
  hoverBackgroundColor: [],
  borderWidth: [],
}
const donutOptions = {
  legend: {
    display: false,
  },
  tooltips: {
    enabled: false,
  },
  cutoutPercentage: 80,
  circumference: 2 * Math.PI,
  animation: {
    duration: 0,
  },
  elements: {
    arc: {
      borderWidth: 0,
    },
  },
}

export function renderDonut({
  data,
  colors,
  label = null,
  iconLabel = null,
  donutSize = 60,
  fontSize = 20,
}) {
  const donutData = {
    datasets: [
      {
        ...donutDatasetRow,
        data: data,
        backgroundColor: colors,
        hoverBackgroundColor: colors,
      },
    ],
  }

  return (
    <div
      style={{
        position: 'relative',
        width: `${donutSize}px`,
        height: `${donutSize}px`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '10px auto',
      }}
    >
      <Doughnut
        data={donutData}
        height={donutSize}
        width={donutSize}
        options={donutOptions}
      />
      {label !== null && (
        <span
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%,-50%)',
            fontSize: `${fontSize}px`,
          }}
        >
          {label}
        </span>
      )}
      {iconLabel !== null && (
        <span
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%,-50%)',
          }}
        >
          <FontAwesomeIcon
            icon={iconLabel}
            style={{
              fontSize: '15px',
            }}
          />
        </span>
      )}
    </div>
  )
}

// render simple bar
export function renderSimpleBar(color, value) {
  return (
    <div style={{ marginRight: '10px' }}>
      <div
        style={{
          position: 'relative',
          height: '10px',
          width: '100px',
          backgroundColor: '#444',
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            width: `${value}%`,
            backgroundColor: color,
          }}
        ></div>
      </div>
    </div>
  )
}

//sort cities by dimension
export function sort(dimension, cities) {
  let list = Object.keys(cities)
  function compare(a, b, idx) {
    if (!cities[a] && !cities[b]) return 0
    if (!cities[a]) return 1
    if (!cities[b]) return -1
    const scoreA = cities[a].dimensions[idx].value
    const scoreB = cities[b].dimensions[idx].value
    if (scoreA > scoreB) return -1
    if (scoreA < scoreB) return 1
    if (scoreA === scoreB) return 0
  }
  switch (dimension) {
    case 1:
      return list.sort((a, b) => compare(a, b, 0))
    case 2:
      return list.sort((a, b) => compare(a, b, 1))
    case 3:
      return list.sort((a, b) => compare(a, b, 2))
    case 4:
      return list.sort((a, b) => compare(a, b, 3))
    case 5:
      return list.sort((a, b) => compare(a, b, 4))
    default:
      return list
  }
}
