import { toggleMultiCityView } from '../../actions/PageActions'
import { updateFilteredCities } from '../../actions/UserActions'
import { updateCurrentCityId } from '../../actions/UserActions'
import {
  ConsiderationChart,
  CitiIQCard,
  DimensionDashboard,
  Icon as CustomIcon,
  LoadingScreen,
} from '../../components'
import Rating from '../../components/Rating/rating'
import SimpleDialog from '../../components/SimpleDialog'
import { renderColor } from '../../constants/Constants'
import { getDimensionWeightsForOverallDonut } from '../../helpers/donut'
import {
  getLocalizedCityName,
  getLocalizedCountryName,
} from '../../helpers/lang'
import useLanguage from '../../state/hooks/useLanguage'
import '../Dashboard/Dashboard.scss'
import QuarterSelector from '../Dashboard/QuarterSelector'
import Autocomplete from './Autocomplete'
import './DashboardMultiCity.scss'
import DimensionData from './DimensionData'
import { renderDonut } from './Helpers'
import Table from './Table'
import FullWidthTabs from './Tabs'
import { Button, Grid, Select, MenuItem } from '@material-ui/core'
import Chip from '@material-ui/core/Chip'
import CircularProgress from '@material-ui/core/CircularProgress'
import React from 'react'
import Helmet from 'react-helmet'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { CSSTransition } from 'react-transition-group'
import { bindActionCreators } from 'redux'

const DashboardMultiCity = ({ pageStore, userStore, actions, intl }) => {
  const [dimension, setDimension] = React.useState(0) // 0 | 1 | 2 | 3 | 4 | 5,
  const [cityFilterDialogOpen, setCityFilterDialogOpen] = React.useState(false)
  const [infoModalOpen, setInfoModalOpen] = React.useState(false) // TODO cleanup - maybe not in use in multi-city dashboard, only single-city dashboard
  const [currentInfoSlide, setCurrentInfoSlide] = React.useState(0) // TODO cleanup - maybe not in use in multi-city dashboard, only single-city dashboard,
  const [formattedCityCountryMap, setFormattedCityCountryMap] =
    React.useState(null)
  const [formattedFilteredCityCountryMap, setFormattedFilteredCityCountryMap] =
    React.useState(null)
  const { language } = useLanguage()

  React.useEffect(() => {
    setFormattedCityCountryMap(pageStore.get('cityCountryMap').toJS())
    setFormattedFilteredCityCountryMap(formatFilteredCityCountryMap())

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    pageStore.get('multiCityInfos').size,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    userStore.getIn(['selectedQuarter']),
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ])
  React.useEffect(() => {
    setFormattedCityCountryMap(pageStore.get('cityCountryMap').toJS())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [(pageStore.get('cityCountryMap').toJS().arr ?? []).size])

  React.useEffect(() => {
    setFormattedFilteredCityCountryMap(formatFilteredCityCountryMap())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userStore.getIn(['filteredCities'])])

  function getFilteredCityInformation() {
    const filteredCitiesList = formatFilteredCityCountryMap().arr || []
    const cities =
      pageStore.get('multiCityInfos') && pageStore.get('multiCityInfos').toJS()

    const filterLength = (filteredCitiesList || []).length
    if (!Boolean(filterLength)) {
      return cities
    }
    if (!cities) return
    let filteredCityInformation = {}
    filteredCitiesList?.forEach((city) => {
      if (cities && cities[city.id]) {
        filteredCityInformation[city.id] = cities[city.id]
      }
    })
    return filteredCityInformation
  }

  function formatFilteredCityCountryMap() {
    const list = userStore.getIn(['filteredCities'])
    let obj = {}
    ;(list || []).forEach((city) => {
      obj[city.id] = city
    })
    return {
      obj: obj,
      arr: list,
    }
  }

  function openModalWithOverride() {
    setInfoModalOpen(true)
    setCurrentInfoSlide(5)
  }

  function renderInfoDonuts() {
    if (!isOverallDataLoaded()) return null

    return (
      <DimensionDashboard
        onDimensionClick={(d_id) => handleDimensionClick(d_id)}
        infoModalOpen={infoModalOpen}
        openInfoModal={() => setInfoModalOpen(true)}
        closeInfoModal={() => {
          setInfoModalOpen(false)
        }}
        defaultSlide={5}
        setCurrentSlide={(slideNum) => setCurrentInfoSlide(slideNum)(slideNum)}
        cityIqCardProps={{ height: '300px' }}
        multiCityDimensionScores={sortMultiCityIqs()}
        formattedCityCountryMap={(getCitiesMap() || { obj: {} }).obj}
      />
    )
  }

  function isOverallDataLoaded() {
    const data = sortMultiCityIqs()
    if (!data) return false
    if (!data[0]) return false
    return Boolean(data && data[0].city.id)
  }

  function sortMultiCityIqs() {
    const cities = getFilteredCityInformation()
    if (!cities) return null
    const data = {
      0: {
        city: {
          id: undefined,
          name: undefined,
        },
        iq: 0,
      },
      1: {
        city: {
          id: undefined,
          name: undefined,
        },
        iq: 0,
      },
      2: {
        city: {
          id: undefined,
          name: undefined,
        },
        iq: 0,
      },
      3: {
        city: {
          id: undefined,
          name: undefined,
        },
        iq: 0,
      },
      4: {
        city: {
          id: undefined,
          name: undefined,
        },
        iq: 0,
      },
      5: {
        city: {
          id: undefined,
          name: undefined,
        },
        iq: 0,
      },
    }
    const cityCountryMap = pageStore.get('cityCountryMap').toJS()
    if (!cityCountryMap) return null

    let sorted = {
      ...data,
    }

    Object.values(cities).forEach((city) => {
      if (city && Object.values(cityCountryMap).length > 0) {
        const currentCity = cityCountryMap.obj[city.city_id]
        const cityName = currentCity.name
        const { iq, dimensions } = city

        sorted = {
          0:
            iq > sorted[0].iq
              ? {
                  iq: iq,
                  city: {
                    id: parseInt(city.city_id),
                    name: cityName,
                    iq_quality_star: currentCity.iq_quality_star,
                  },
                }
              : sorted[0],
          1:
            dimensions[0].value > sorted[1].iq
              ? {
                  city: {
                    id: parseInt(city.city_id),
                    name: cityName,
                  },
                  iq: dimensions[0].value,
                }
              : sorted[1],
          2:
            dimensions[1].value > sorted[2].iq
              ? {
                  city: {
                    id: parseInt(city.city_id),
                    name: cityName,
                  },
                  iq: dimensions[1].value,
                }
              : sorted[2],
          3:
            dimensions[2].value > sorted[3].iq
              ? {
                  city: {
                    id: parseInt(city.city_id),
                    name: cityName,
                  },
                  iq: dimensions[2].value,
                }
              : sorted[3],
          4:
            dimensions[3].value > sorted[4].iq
              ? {
                  city: {
                    id: parseInt(city.city_id),
                    name: cityName,
                  },
                  iq: dimensions[3].value,
                }
              : sorted[4],
          5:
            dimensions[4].value > sorted[5].iq
              ? {
                  city: {
                    id: parseInt(city.city_id),
                    name: cityName,
                  },
                  iq: dimensions[4].value,
                }
              : sorted[5],
        }
      }
    })
    return sorted
  }

  function getHighestForDimension(dimensionId) {
    const data = sortMultiCityIqs()
    return data[dimensionId]
  }

  function renderOverallScore(isMobile) {
    const size = isMobile ? 60 : 90

    if (!isOverallDataLoaded()) {
      return (
        <div className="overallScore">
          <div className="dimScoresHeader">
            <p className="topScoreTitle">
              {intl.formatMessage({
                id: 'dashboard.multi.highestCityScore',
              })}
            </p>
            <CustomIcon
              name="city-profile"
              className="overall-info"
              onClick={() => openModalWithOverride()}
            />
          </div>

          <CircularProgress
            style={{ color: '#b1ff44' }}
            classes={{ root: 'spinner' }}
          />
        </div>
      )
    }

    const { iq, city } = getHighestForDimension(0)

    const citiesObj =
      formattedCityCountryMap &&
      formattedCityCountryMap.obj &&
      formattedCityCountryMap.obj

    if (!citiesObj) return
    if (!citiesObj[city.id]) return

    const cityName = getLocalizedCityName(citiesObj[city.id], language)
    const countryName = getLocalizedCountryName(citiesObj[city.id], language)
    const rating = city.iq_quality_star
    const multiCityInfos = pageStore.get('multiCityInfos').toJS() ?? {}
    return (
      <div className="overallScore">
        <p className="topScoreTitle">
          {intl.formatMessage({
            id: 'dashboard.multi.highestCityScore',
          })}
        </p>
        <CSSTransition
          mountOnEnter
          appear
          in={true}
          timeout={0}
          classNames="fadeT"
          unmountOnExit
        >
          <span
            className="redirect-city-scores"
            style={{ cursor: 'auto' }}
            onClick={() => {
              // updateCurrentCityId(city.id)
              // const page = 'dashboard'
              // actions.setCurrentPage(page)
              // history.push('/' + page)
            }}
          >
            {renderDonut({
              data: getDimensionWeightsForOverallDonut(multiCityInfos, city.id),
              colors: [1, 2, 3, 4, 5]
                .map((d) => renderColor(d).main)
                .concat(['#444']),
              label: Math.round(iq),
              donutSize: size,
              fontSize: size / 3,
            })}
          </span>
        </CSSTransition>
        {rating && (
          <div style={{ margin: ' 2px 0 -12px' }}>
            <Rating rating={rating} starWidth="15.95px" starSpacing="1.85px" />
          </div>
        )}
        <p className="topScoreCity">{cityName}</p>
        <p className="topScoreCountry">{countryName}</p>
      </div>
    )
  }

  function handleDimensionClick(d_id) {
    switchDimension(d_id)
  }

  function switchDimension(d) {
    setDimension(d)
  }

  function renderOverallContent() {
    const isMobile = window.innerWidth <= 768
    return (
      <Grid>
        <h3>
          <FormattedMessage id="dashboard.multi.topCities" />
        </h3>
        <Grid container spacing={16} justify="flex-start">
          <CitiIQCard className="overallDonutsCard">
            {renderOverallScore(isMobile)}
            {renderInfoDonuts()}
          </CitiIQCard>
        </Grid>
        <h3>
          <FormattedMessage id="dashboard.multi.multiCityScores" />
        </h3>
        {renderTable()}
      </Grid>
    )
  }

  function renderDimensionContent() {
    return (
      <div>
        <h3>
          <FormattedMessage id="dashboard.dimension" />
          {`: `}
          <FormattedMessage id={`dimensions.${dimension}`} />
        </h3>
        <CitiIQCard height="auto">{renderDimensionCharts()}</CitiIQCard>
        <DimensionData
          actions={actions}
          pageStore={pageStore}
          getCitiesMap={getCitiesMap}
          selectedDimension={dimension}
        />
      </div>
    )
  }

  function renderTable() {
    const cities =
      pageStore.get('multiCityInfos') && pageStore.get('multiCityInfos').toJS()

    const citiesMap = getCitiesMap()
    if (!cities || !citiesMap || !citiesMap.arr || citiesMap.arr.length === 0) {
      return null
    }
    return (
      <Table
        dimension={dimension}
        dimensionView={'number'}
        cities={cities}
        formattedCityCountryMap={citiesMap}
        updateCurrentCityId={actions.updateCurrentCityId}
        toggleMultiCityView={actions.toggleMultiCityView}
      />
    )
  }
  function renderDimensionCharts() {
    // creating seperate instances of charts this way
    // we can make sure it is rerendered
    // whenever the dimension changes

    const cities = pageStore.get('multiCityInfos').toJS()

    return [1, 2, 3, 4, 5].map((key) => {
      return key === dimension ? (
        <Grid key={key} container className="dashboard-contents">
          <Grid item xs={12}>
            <ConsiderationChart
              dimension={key}
              cities={cities}
              cityCountryMap={(getCitiesMap() || { obj: {} }).obj}
              filteredCitiesList={(getCitiesMap() || { arr: [] }).arr}
            />
          </Grid>
        </Grid>
      ) : null
    })
  }

  function renderDimensionButtons() {
    return (
      <Grid container className="dashboard-contents">
        <Grid item xs={12} className="dimension-buttons">
          {[1, 2, 3, 4, 5].map((d) => (
            <Button
              key={d}
              disabled={d === dimension}
              className={`dimension-buttons__button ${
                d === dimension ? 'active' : ''
              }`}
              onClick={(e) => switchDimension(d)}
            >
              <div
                className="dimension-buttons__button__dot"
                style={{
                  backgroundColor: renderColor(d).main,
                }}
              />
              <FormattedMessage id={`dimensions.${d}`} />
            </Button>
          ))}
          <Select
            className="dimension-select"
            value={dimension}
            inputProps={{ className: 'dimension-select__input' }}
            variant="filled"
            onChange={(event) => {
              switchDimension(event.target.value)
            }}
          >
            {[1, 2, 3, 4, 5].map((d) => (
              <MenuItem
                key={d}
                disabled={d === dimension}
                value={d}
                className={`dimension-buttons__button ${
                  d === dimension ? 'active' : ''
                }`}
              >
                <div
                  className="dimension-buttons__button__dot"
                  style={{
                    backgroundColor: renderColor(d).main,
                  }}
                />
                <FormattedMessage id={`dimensions.${d}`} />
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>
    )
  }

  function getCitiesMap() {
    if (
      !formattedFilteredCityCountryMap ||
      formattedFilteredCityCountryMap.arr.length !== 0
    ) {
      return formattedFilteredCityCountryMap
    }
    return formattedCityCountryMap.length === 1 &&
      formattedCityCountryMap[0] === undefined
      ? undefined
      : formattedCityCountryMap
  }

  function renderHeader() {
    const filterLength =
      formattedFilteredCityCountryMap &&
      formattedFilteredCityCountryMap.arr &&
      formattedFilteredCityCountryMap.arr.length
    const showFilter = Boolean(filterLength)
    const header = intl.formatMessage({ id: 'dashboard.multi.header' })
    return (
      <div className="dashboardHeader">
        <div className="dashboardTitle">
          <h1>{header}</h1>
          {showFilter ? (
            <Chip
              className="multiCityFilterChip"
              label={
                filterLength === 1
                  ? intl.formatMessage({ id: 'cities.filter.oneCityViewing' })
                  : intl.formatMessage(
                      { id: 'cities.filter.multipleCitiesViewing' },
                      { count: filterLength }
                    )
              }
              onDelete={() => {
                const userId = userStore.getIn(['userInfo', 'id'])
                actions.updateFilteredCities([], userId)
              }}
            />
          ) : (
            <Chip
              disabled
              className="multiCityFilterChip disabled"
              label={intl.formatMessage({
                id: 'cities.filter.viewingAll',
              })}
            />
          )}
        </div>
        <div className="actions">
          {formattedCityCountryMap?.arr ? (
            <SimpleDialog
              className="actions__button"
              disabled={!formattedCityCountryMap?.arr}
              buttonText={
                <FormattedMessage
                  id={
                    'cities.filter.buttonDesktop'
                    // isTablet || isMobile
                    //   ? 'cities.filter.buttonMobile'
                    //   : 'cities.filter.buttonDesktop'
                  }
                />
              }
              open={cityFilterDialogOpen}
              onOpenDialog={() => setCityFilterDialogOpen(true)}
              onCloseDialog={() => setCityFilterDialogOpen(false)}
              children={
                <Autocomplete
                  userId={userStore.getIn(['userInfo', 'id'])}
                  selectedCities={
                    (formattedFilteredCityCountryMap || { arr: [] }).arr
                  }
                  allCities={(formattedCityCountryMap || { arr: [] }).arr}
                  onCloseDialog={() => setCityFilterDialogOpen(false)}
                />
              }
            />
          ) : null}

          <QuarterSelector isMultiCity={true} selectedCityId={null} />
        </div>
      </div>
    )
  }

  const header = intl.formatMessage({ id: 'dashboard.multi.header' })
  return (
    <div className="Dashboard">
      <Helmet>
        <title>CitiIQ - {header}</title>
      </Helmet>
      {renderHeader()}

      {pageStore.getIn(['isLoadingMultiCityInfo']) ? (
        <>
          <LoadingScreen />
        </>
      ) : (
        <Grid item xs={12}>
          <CitiIQCard height="auto">
            <FullWidthTabs
              dimension={dimension}
              switchDimension={switchDimension}
              buttons={renderDimensionButtons()}
            />
          </CitiIQCard>
          {dimension === 0 ? renderOverallContent() : renderDimensionContent()}
        </Grid>
      )}
    </div>
  )
}

function mapStateToProps(state) {
  return {
    userStore: state.UserStore,
    pageStore: state.PageStore,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        updateFilteredCities,
        updateCurrentCityId,
        toggleMultiCityView,
      },
      dispatch
    ),
  }
}

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(DashboardMultiCity)
)
