export const primaryGreen = '#9EC73D'
export const td_cell_width = '160px'
export const Theme = {
  overrides: {
    MUIDataTable: {
      responsiveScroll: {
        overflowX: 'none',
        height: 'auto',
        maxHeight: 'unset !important',
      },
      tableRoot: {
        overflow: 'hidden',
      },
    },
    MuiPaper: {
      root: {
        backgroundColor: '#31353D',
        color: '#FFF',
      },
    },
    MuiInputLabel: {
      '&$focused': {
        color: `${primaryGreen} !important`,
      },
    },
    MuiButton: {
      textPrimary: {
        color: primaryGreen,
      },
    },
    MuiTable: {},
    MuiToolbar: {
      regular: {
        width: '100% !important',
        margin: 0,
      },
    },
    MUIDataTableToolbar: {
      root: {},
      actions: {
        textAlign: 'right !important',
        display: 'flex',
        justifyContent: 'flex-end',
        margin: '0',
        width: '100%',
        marginTop: '-10px',
        marginRight: '-20px',
      },
    },
    MUIDataTableBodyRow: {},
    MUIDataTableHeadCell: {
      root: {
        textAlign: 'center',
        backgroundColor: '#31353D !important',
        color: '#FFF !important',
        minWidth: '80px',
        '&:nth-child(odd)': {
          backgroundColor: '#31353D !important',
        },
        sortLabelRoot: {
          margin: 0,
          opacity: 0.1,
        },
      },
      toolButton: {
        cursor: 'pointer',
        display: 'flex',
        outline: 'none',
        position: 'absolute',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        top: '0',
        left: '50%',
        transform: 'translateX(-50%)',
      },
      sortAction: {
        display: 'block',
        margin: '0 auto',
        padding: '0 10px',
      },
      data: {
        display: 'inline-block',
        textAlign: 'inherit',
        width: 'inherit',
        lineHeight: 1.75,
      },
      sortActive: {
        color: '#FFF !important',
        fontWeight: 'bold',
      },
      sortLabelRoot: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '15px auto',
      },
    },
    MuiTableSortLabel: {
      active: {
        color: '#FFF',
      },
    },
    MuiIconButton: {
      root: {
        color: '#FFF',
      },
    },
    MUIDataTableSearch: {
      searchIcon: {
        color: '#FFF',
      },
    },
    MUIDataTableViewCol: {
      title: {
        color: '#FFF',
      },
      label: {
        color: '#FFF',
      },
    },
    MuiSvgIcon: {
      root: {
        fill: '#FFF',
      },
    },
    MuiInput: {
      root: {
        color: '#FFF',
      },
      underline: {
        '&::before': {
          borderBottom: '1px solid rgba(255,255,255,.7)',
        },
        '&::after': {
          borderBottom: `2px solid ${primaryGreen}`,
        },
      },
    },
    MUIDataTableFilter: {
      root: {
        backgroundColor: 'inherit',
      },
      resetLink: {
        fontWeight: 'bold',
      },
      title: {
        color: '#fff',
      },
    },
    MuiFormLabel: {
      root: {
        color: '#fff',
      },
    },
    MuiMenuItem: {
      root: {
        color: '#fff',
      },
    },
    MuiTableRow: {
      root: {
        '&:nth-child(odd)': {
          backgroundColor: 'rgba(0, 0, 0, 0.108214) !important',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.15) !important',
          },
        },
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: '0px',
        padding: '4px 15px',
        minWidth: td_cell_width,
      },
      head: {
        minWidth: td_cell_width,
        textAlign: 'left',
        position: 'relative',
      },
    },
    MUIDataTableCell: {
      root: {},
    },
    MUIDataTableBodyCell: {
      root: {
        color: 'inherit !important',
        textAlign: 'center',
      },
    },
    MuiTableFooter: {},
    MuiTablePagination: {
      input: {
        color: 'white',
      },
    },
    MuiTypography: {
      caption: {
        color: '#fff',
      },
    },
  },
}
