import {
  createMilestone,
  updateMilestone,
} from '../../actions/MilestoneActions'
import { getDimensionsComparison } from '../../actions/PageActions'
import useLanguage from '../../state/hooks/useLanguage'
import './MilestoneDialog.scss'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import { DatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers'
import MomentUtils from 'material-ui-pickers/utils/moment-utils'
import moment from 'moment'
import 'moment/locale/es'
import 'moment/locale/fr'
import 'moment/locale/pt'
import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

const MilestoneDialog = ({
  isEditing,
  title,
  milestone_id,
  actions,
  userStore,
  onClose,
  open,
  intl,
}) => {
  const [milestoneTitle, setMilestoneTitle] = React.useState(title)
  const [milestoneId, setMilestoneId] = React.useState(milestone_id)
  const [date, setDate] = React.useState(new Date())
  const [titleError, setTitleError] = React.useState(false)
  const [dateError, setDateError] = React.useState(false)
  const { language } = useLanguage()

  React.useEffect(() => {
    moment.locale(language.locale)
  }, [language])

  React.useEffect(() => {
    setMilestoneTitle(title)
    setMilestoneId(milestone_id)
    setDate(new Date())
  }, [title, milestone_id])

  function handleInputChange(e) {
    if (e.target?.name === 'title') {
      setMilestoneTitle(e.target.value)
    } else {
      setDate(e.format('YYYY-MM-DD'))
    }
  }

  function handleUpdate(e) {
    e.preventDefault()
    resetErrorState()

    if (handleValidation()) {
      actions
        .updateMilestone(milestoneId, milestoneTitle, date)
        .then((response) => {
          const selectedCityId = userStore.getIn(['selectedCityId'])
          const user_id = userStore.getIn(['userInfo', 'id'])
          actions.getDimensionsComparison(selectedCityId, user_id, true)
        })
      resetState()
      onClose()
    }
  }

  function handleAdd(e) {
    e.preventDefault()
    resetErrorState()
    if (handleValidation()) {
      const selectedCityId = userStore.getIn(['selectedCityId'])
      const user_id = userStore.getIn(['userInfo', 'id'])
      actions
        .createMilestone(milestoneTitle, date, user_id, selectedCityId)
        .then((response) => {
          actions.getDimensionsComparison(selectedCityId, user_id, true)
        })
      resetState()
      onClose()
    }
  }

  function resetState() {
    setMilestoneTitle('')
    setDate(new Date())
    setMilestoneId(0)
    setTitleError(false)
    setDateError(false)
  }

  function resetErrorState() {
    setDateError(false)
    setTitleError(false)
  }

  function handleValidation() {
    let formValid = true

    if (!milestoneTitle) {
      formValid = false
      setTitleError(true)
    }

    if (!date) {
      formValid = false
      setDateError(true)
    }

    return formValid
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={{ paper: 'MilestoneDialog' }}
    >
      <div className="header">
        <FormattedMessage id="milestoneDialog.header" />
      </div>
      <Grid container spacing={8}>
        <Grid item sm={7} xs={7} classes={{ item: 'inputHeader' }}>
          <FormattedMessage id="milestoneDialog.title" />
        </Grid>
        <Grid item sm={5} xs={5} classes={{ item: 'inputHeader' }}>
          <FormattedMessage id="milestoneDialog.date" />
        </Grid>
        <Grid item sm={7} xs={7}>
          <TextField
            error={titleError}
            required
            onChange={(e) => handleInputChange(e)}
            name="title"
            classes={{ root: 'input' }}
            value={milestoneTitle}
          />
        </Grid>
        <Grid item sm={5} xs={5} classes={{ item: 'hideOverflow' }}>
          <MuiPickersUtilsProvider
            utils={MomentUtils}
            locale={language.locale}
            moment={moment}
          >
            <DatePicker
              value={date}
              error={dateError}
              name="date"
              cancelLabel={intl.formatMessage({ id: 'milestoneDialog.cancel' })}
              okLabel={intl.formatMessage({ id: 'milestoneDialog.save' })}
              onChange={(e) => handleInputChange(e)}
              format="DD/MM/YYYY"
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12}>
          <Button classes={{ root: 'cancel' }} onClick={onClose}>
            <FormattedMessage id="milestoneDialog.cancel" />
          </Button>
          <Button
            classes={{ root: 'save' }}
            onClick={isEditing ? (e) => handleUpdate(e) : (e) => handleAdd(e)}
          >
            <FormattedMessage id="milestoneDialog.save" />
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  )
}

function mapStateToProps(state) {
  return {
    userStore: state.UserStore,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        createMilestone,
        updateMilestone,
        getDimensionsComparison,
      },
      dispatch
    ),
  }
}

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(MilestoneDialog)
)
