import { registerUser, checkDuplicateEmail } from '../../actions/UserActions'
import citiIQ_background from '../../assets/citiIQ_login_image.jpg'
import logo from '../../assets/citiiq-login-logo.png'
import { gAnalytics } from '../../helpers/analytics'
import './AddUserForm.scss'
import {
  Button,
  Grid,
  Dialog,
  FormControl,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@material-ui/core'
import React from 'react'
import Helmet from 'react-helmet'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'

const AddUserForm = ({
  actions,
  history,
  isAdminAdd,
  handleUserAdded,
  inline,
  intl,
}) => {
  const [values, setValues] = React.useState({
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
    occupation: '',
    company: '',
    address1: '',
    address2: '',
    city: '',
    region: '',
    country: '',
    postal: '',
    role: 1,
  })
  const [focus, setFocus] = React.useState({
    email: false,
    firstName: false,
    lastName: false,
    company: false,
    address1: false,
    address2: false,
    occupation: false,
    city: false,
    region: false,
    country: false,
    postal: false,
    phone: false,
    role: false,
  })

  const [registerStage, setRegisterStage] = React.useState(1)
  const [errorMessage, setErrorMessage] = React.useState([])
  const [attemptSubmit, setAttemptSubmit] = React.useState(false)
  const [badSubmit, setBadSubmit] = React.useState(false)
  const [showRoleInputs, setShowRoleInputs] = React.useState(false)

  React.useEffect(() => {
    var _badSubmit = false
    if (attemptSubmit && errorMessage.length === 0) {
      registerUser()
    }
    if (attemptSubmit && errorMessage.length !== 0) {
      gAnalytics('event', [
        'Admin add user',
        'badInput',
        registerStage.toString(),
      ])
      _badSubmit = true
    }
    if (attemptSubmit) {
      setAttemptSubmit(false)
      setBadSubmit(_badSubmit)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attemptSubmit, errorMessage.length])

  function checkForEnter(e) {
    if (e.keyCode === 13) {
      if (registerStage === 1) {
        handleStageChange(2)
      } else {
        validateFields('submit')
      }
    }
  }

  function addError(str, errorMessage) {
    const index = errorMessage.findIndex((e) => e === str)
    if (index < 0) {
      return errorMessage.concat(str)
    } else {
      return errorMessage
    }
  }

  function rmError(str, errorMessage) {
    const returnMessage = errorMessage
    const index = errorMessage.findIndex((e) => e === str)
    if (index >= 0) {
      returnMessage.splice(index, 1)
    }
    return returnMessage
  }

  function renderRegistrationErrors(errorArr) {
    let count = 0

    //Combine all "____ cannot be blank" messages
    let returnArr = errorArr.filter((err) => {
      let isBlank = [
        'err_3',
        'err_4',
        'err_7',
        'err_8',
        'err_9',
        'err_10',
        'err_11',
        'err_12',
        'err_13',
        'err_14',
      ].includes(err)
      if (isBlank) {
        count++
      }
      return !isBlank
    })
    if (count) {
      returnArr.push('noBlank')
    }

    return returnArr.map((e, i) => {
      if (e === 'noBlank') {
        // for admin not every field is required
        return (
          <p className="errorMessage" key={i}>
            Please fill in required fields
          </p>
        )
      }
      return (
        <p className="errorMessage" key={i}>
          <FormattedMessage id={'register.' + e} />
        </p>
      )
    })
  }

  function checkCondition(test, str, errorMessage) {
    if (test) {
      return addError(str, errorMessage)
    } else {
      return rmError(str, errorMessage)
    }
  }

  function validateFields(checking, adding) {
    let _errorMessage = [...errorMessage]
    const startLength = _errorMessage.length
    function checkEmail(emailInput) {
      var regex = new RegExp(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
      if (regex.test(emailInput)) return true
      else return false
    }

    //Check Email
    if (checking === 'submit' || checking === 2) {
      _errorMessage = checkCondition(!values.email, 'err_4', _errorMessage)
      _errorMessage = checkCondition(
        !checkEmail(values.email) && values.email,
        'err_5',
        _errorMessage
      )
      actions.checkDuplicateEmail(values.email).then((response) => {
        _errorMessage = checkCondition(
          response.data.CheckDuplicateEmail,
          'err_6',
          _errorMessage
        )
      })
    }

    //No fields can be left blank
    if (checking === 'submit' || checking === 2) {
      _errorMessage = checkCondition(!values.firstName, 'err_7', _errorMessage)
    }
    if (checking === 'submit' || checking === 2) {
      _errorMessage = checkCondition(!values.lastName, 'err_8', _errorMessage)
    }
    if (checking === 'submit') {
      _errorMessage = checkCondition(!values.company, 'err_11', _errorMessage)
    }
    if (checking === 'submit' && !_errorMessage.length) {
      setAttemptSubmit(true)
    }
    if (checking === 'submit' && _errorMessage.length) {
      gAnalytics('event', [
        'Admin add user',
        'badInput',
        registerStage.toString(),
      ])
    }
    setErrorMessage(_errorMessage)
    return !(_errorMessage.length > startLength)
  }

  function handleInputChange(e) {
    const relations = {
      postal: ['err_3'],
      email: ['err_4', 'err_5', 'err_6'],
      firstName: ['err_7'],
      lastName: ['err_8'],
      occupation: ['err_9'],
      phone: ['err_10'],
      company: ['err_11'],
      city: ['err_12'],
      region: ['err_13'],
      country: ['err_14'],
    }
    if (relations[e.target.name]) {
      var _errorMessage = errorMessage
      relations[e.target.name].forEach((e) => {
        _errorMessage = rmError(e, _errorMessage)
      })
    }
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    })
  }

  function handleFocus(e) {
    setFocus({
      ...focus,
      [e.target.name]: true,
    })
  }

  function handleBlur(e) {
    setFocus({
      ...focus,
      [e.target.name]: false,
    })
  }

  function handleStageChange(stage) {
    if (validateFields(stage)) {
      if (stage === 2) {
        actions.checkDuplicateEmail(values.email).then((response) => {
          let hasFirstScreenError = false
          errorMessage.forEach((error) => {
            if (
              [
                'err_1',
                'err_2',
                'err_4',
                'err_5',
                'err_7',
                'err_8',
                'err_9',
                'err_10',
              ].includes(error)
            ) {
              hasFirstScreenError = true
            }
          })
          const _errorMessage = checkCondition(
            response.data.CheckDuplicateEmail,
            'err_6',
            errorMessage
          )
          setErrorMessage(_errorMessage)
          if (!response.data.CheckDuplicateEmail && !hasFirstScreenError) {
            setRegisterStage(stage)
          } else {
            gAnalytics('event', [
              'Admin add user',
              'badInput',
              registerStage.toString(),
            ])
          }
        })
      } else {
        setRegisterStage(stage)
      }
    } else {
      gAnalytics('event', [
        'Admin add user',
        'badInput',
        registerStage.toString(),
      ])
    }
  }

  function handleLoginButtonClick() {
    gAnalytics('pageview', ['/login'])
    history.push('/')
  }

  const registerUser = async () => {
    const registerByAdmin = isAdminAdd || false
    actions
      .registerUser(
        values.company,
        values.address1 || '',
        values.address2 || '',
        values.city || '',
        values.region || '',
        values.country || '',
        values.postal || '',
        values.firstName,
        values.lastName,
        '',
        values.email,
        values.phone || '',
        values.occupation || '',
        registerByAdmin,
        values.role
      )
      .then((res) => {
        if (res.data.registerUser.success === true) {
          gAnalytics('event', [
            'Admin add user',
            'Admin add user',
            values.email,
          ])
          setRegisterStage(3)
          isAdminAdd && handleUserAdded()
        }
      })
  }

  function renderErrorDialog() {
    return (
      <Dialog open={badSubmit} onClose={() => setBadSubmit(false)}>
        <div className="errorDialog">
          <FormattedMessage id="register.fixErrors" />
          <div className="errorDiv">
            {renderRegistrationErrors(errorMessage)}
          </div>
        </div>
      </Dialog>
    )
  }

  function renderFormBody() {
    return (
      <div className="registerGridWrapper">
        {!!errorMessage.length && (
          <div className="errorDiv">
            {renderRegistrationErrors(errorMessage)}
          </div>
        )}
        {registerStage === 1 && (
          <Grid container spacing={16}>
            <Grid item xs={6}>
              <FormattedMessage id="input.firstNamePlaceholder">
                {(placeholder) => (
                  <input
                    className={
                      'registerInput' +
                      (errorMessage.includes('err_7') ? ' errorInput' : '')
                    }
                    value={values.firstName}
                    name="firstName"
                    onFocus={(e) => handleFocus(e)}
                    onChange={(e) => handleInputChange(e)}
                    onBlur={(e) => handleBlur(e)}
                    placeholder={
                      !focus.firstName
                        ? `${intl.formatMessage({
                            id: 'input.firstNamePlaceholder',
                          })} *`
                        : ''
                    }
                  />
                )}
              </FormattedMessage>
            </Grid>
            <Grid item xs={6}>
              <FormattedMessage id="input.lastNamePlaceholder">
                {(placeholder) => (
                  <input
                    className={
                      'registerInput' +
                      (errorMessage.includes('err_8') ? ' errorInput' : '')
                    }
                    value={values.lastName}
                    name="lastName"
                    onFocus={(e) => handleFocus(e)}
                    onChange={(e) => handleInputChange(e)}
                    onBlur={(e) => handleBlur(e)}
                    placeholder={!focus.lastName ? `${placeholder} *` : ''}
                  />
                )}
              </FormattedMessage>
            </Grid>
            <Grid item xs={12}>
              <FormattedMessage id="input.emailPlaceholder">
                {(placeholder) => (
                  <input
                    className={
                      'registerInput' +
                      (errorMessage.includes('err_4') ||
                      errorMessage.includes('err_5') ||
                      errorMessage.includes('err_6')
                        ? ' errorInput'
                        : '')
                    }
                    value={values.email}
                    name="email"
                    onFocus={(e) => handleFocus(e)}
                    onChange={(e) => handleInputChange(e)}
                    onBlur={(e) => handleBlur(e)}
                    placeholder={!focus.email ? `${placeholder} *` : ''}
                  />
                )}
              </FormattedMessage>
            </Grid>
            <Grid item xs={12}>
              <FormattedMessage id="input.occupationPlaceholder">
                {(placeholder) => (
                  <input
                    className={
                      'registerInput' +
                      (errorMessage.includes('err_9') ? ' errorInput' : '')
                    }
                    value={values.occupation}
                    name="occupation"
                    onFocus={(e) => handleFocus(e)}
                    onChange={(e) => handleInputChange(e)}
                    onBlur={(e) => handleBlur(e)}
                    placeholder={!focus.occupation ? 'Title' : ''}
                  />
                )}
              </FormattedMessage>
            </Grid>
            <Grid item xs={12}>
              <FormattedMessage id="input.phonePlaceholder">
                {(placeholder) => (
                  <input
                    className={
                      'registerInput' +
                      (errorMessage.includes('err_10') ? ' errorInput' : '')
                    }
                    value={values.phone}
                    name="phone"
                    onFocus={(e) => handleFocus(e)}
                    onChange={(e) => handleInputChange(e)}
                    onBlur={(e) => handleBlur(e)}
                    placeholder={!focus.phone ? placeholder : ''}
                  />
                )}
              </FormattedMessage>
            </Grid>
            <Grid item xs={12}>
              <Button
                color="primary"
                classes={{ textPrimary: 'registerButton' }}
                onClick={() => handleStageChange(2)}
              >
                <FormattedMessage id="login.next" />
              </Button>
            </Grid>
          </Grid>
        )}
        {registerStage === 2 && (
          <Grid container spacing={16}>
            <Grid item xs={12}>
              <FormattedMessage id="input.companyPlaceholder">
                {(placeholder) => (
                  <input
                    className={
                      'registerInput' +
                      (errorMessage.includes('err_11') ? ' errorInput' : '')
                    }
                    value={values.company}
                    name="company"
                    onFocus={(e) => handleFocus(e)}
                    onChange={(e) => handleInputChange(e)}
                    onBlur={(e) => handleBlur(e)}
                    placeholder={!focus.company ? `${placeholder} *` : ''}
                  />
                )}
              </FormattedMessage>
            </Grid>
            <Grid item xs={12}>
              <FormattedMessage id="input.address1Placeholder">
                {(placeholder) => (
                  <input
                    className={'registerInput'}
                    value={values.address1}
                    name="address1"
                    onFocus={(e) => handleFocus(e)}
                    onChange={(e) => handleInputChange(e)}
                    onBlur={(e) => handleBlur(e)}
                    placeholder={!focus.address1 ? placeholder : ''}
                  />
                )}
              </FormattedMessage>
            </Grid>
            <Grid item xs={12}>
              <FormattedMessage id="input.address2Placeholder">
                {(placeholder) => (
                  <input
                    className={'registerInput'}
                    value={values.address2}
                    name="address2"
                    onFocus={(e) => handleFocus(e)}
                    onChange={(e) => handleInputChange(e)}
                    onBlur={(e) => handleBlur(e)}
                    placeholder={!focus.address2 ? placeholder : ''}
                  />
                )}
              </FormattedMessage>
            </Grid>
            <Grid item xs={6}>
              <FormattedMessage id="input.cityPlaceholder">
                {(placeholder) => (
                  <input
                    className={
                      'registerInput' +
                      (errorMessage.includes('err_12') ? ' errorInput' : '')
                    }
                    value={values.city}
                    name="city"
                    onFocus={(e) => handleFocus(e)}
                    onChange={(e) => handleInputChange(e)}
                    onBlur={(e) => handleBlur(e)}
                    placeholder={!focus.city ? placeholder : ''}
                  />
                )}
              </FormattedMessage>
            </Grid>
            <Grid item xs={6}>
              <FormattedMessage id="input.countryPlaceholder">
                {(placeholder) => (
                  <input
                    className={
                      'registerInput' +
                      (errorMessage.includes('err_14') ? ' errorInput' : '')
                    }
                    value={values.country}
                    name="country"
                    onFocus={(e) => handleFocus(e)}
                    onChange={(e) => handleInputChange(e)}
                    onBlur={(e) => handleBlur(e)}
                    placeholder={!focus.country ? placeholder : ''}
                  />
                )}
              </FormattedMessage>
            </Grid>
            <Grid item xs={6}>
              <FormattedMessage id="input.regionPlaceholder">
                {(placeholder) => (
                  <input
                    className={
                      'registerInput' +
                      (errorMessage.includes('err_13') ? ' errorInput' : '')
                    }
                    value={values.region}
                    name="region"
                    onFocus={(e) => handleFocus(e)}
                    onChange={(e) => handleInputChange(e)}
                    onBlur={(e) => handleBlur(e)}
                    placeholder={!focus.region ? placeholder : ''}
                  />
                )}
              </FormattedMessage>
            </Grid>
            <Grid item xs={6}>
              <FormattedMessage id="input.postalPlaceholder">
                {(placeholder) => (
                  <input
                    className={
                      'registerInput' +
                      (errorMessage.includes('err_3') ? ' errorInput' : '')
                    }
                    value={values.postal}
                    name="postal"
                    onFocus={(e) => handleFocus(e)}
                    onChange={(e) => handleInputChange(e)}
                    onBlur={(e) => handleBlur(e)}
                    placeholder={!focus.postal ? placeholder : ''}
                  />
                )}
              </FormattedMessage>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                component="fieldset"
                className="advancedOptionsContainer"
              >
                <FormLabel
                  component="legend"
                  className="advancedOptionsLabel"
                  onClick={() => setShowRoleInputs(!showRoleInputs)}
                >
                  {`Advanced Options ${showRoleInputs ? '-' : '+'}`}
                </FormLabel>
                <RadioGroup
                  aria-label="role"
                  name="role1"
                  value={values.role}
                  style={{
                    display: showRoleInputs ? 'block' : 'none',
                  }}
                  onChange={(event) =>
                    setValues({ ...values, role: parseInt(event.target.value) })
                  }
                >
                  <FormControlLabel
                    value={2}
                    control={<Radio />}
                    label="Admin"
                  />
                  <FormControlLabel
                    value={1}
                    control={<Radio />}
                    label="User"
                  />
                  <FormControlLabel
                    value={3}
                    control={<Radio />}
                    label="Private User"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <Button
                color="primary"
                classes={{ textPrimary: 'backButton' }}
                onClick={() => handleStageChange(1)}
              >
                {' '}
                <FormattedMessage id="login.back" />{' '}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                color="primary"
                classes={{ textPrimary: 'registerButton' }}
                onClick={() => validateFields('submit')}
              >
                {' '}
                <FormattedMessage id="login.submit" />{' '}
              </Button>
            </Grid>
          </Grid>
        )}
        {registerStage === 3 && !isAdminAdd && (
          <Grid container spacing={16}>
            <Grid item xs={6} style={{ margin: '0 auto' }}>
              <Button
                color="primary"
                classes={{ textPrimary: 'registerButton' }}
                onClick={() => history.push('/')}
              >
                {' '}
                <FormattedMessage id="login.login" />{' '}
              </Button>
            </Grid>
          </Grid>
        )}
      </div>
    )
  }
  function renderPage() {
    return (
      <div className="register" style={{ backgroundImage: citiIQ_background }}>
        <Helmet>
          <title>Register - CitiIQ</title>
        </Helmet>
        <img
          src={citiIQ_background}
          className="registerBackground"
          alt="CitiIQ Background"
        />
        {renderErrorDialog()}
        <div className="registerDiv" onKeyDown={(e) => checkForEnter(e)}>
          <Link to="/dashboard">
            <img className="siteLogo" src={logo} alt="" />
          </Link>
          <div className="loginDiv">
            <span>
              <FormattedMessage id="login.goToLoginText" />
            </span>
            <Button
              onClick={() => handleLoginButtonClick()}
              color="primary"
              classes={{ textPrimary: 'loginButton' }}
            >
              <FormattedMessage id="login.login" />
            </Button>
          </div>
          <React.Fragment>
            <p className="registerHeader">
              <FormattedMessage
                id={`register.header${registerStage}`}
                description="A Greeting message"
              />
            </p>
            <p className="registerSubHeader">
              <FormattedMessage
                id={
                  registerStage !== 3
                    ? 'register.registerIntro'
                    : 'register.awaitApproval'
                }
              />
            </p>
            {renderFormBody()}
          </React.Fragment>
        </div>
      </div>
    )
  }
  function renderInlineForm() {
    return (
      <div className="register register--inline">
        {renderErrorDialog()}
        <div className="registerDiv">{renderFormBody()}</div>
      </div>
    )
  }

  if (inline) {
    return renderInlineForm()
  } else {
    return renderPage()
  }
}

function mapStateToProps(state) {
  return {
    userStore: state.UserStore,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        registerUser,
        checkDuplicateEmail,
      },
      dispatch
    ),
  }
}

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(AddUserForm)
)
