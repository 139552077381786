/* eslint-disable react-hooks/exhaustive-deps */
import {
  getAllUsersSubscriptions,
  getAllUserRoles,
  deleteSubscription,
  createSubscription,
} from '../../actions/AdminActions'
import { CitiIQCard } from '../../components'
import { getLocalizedSdgLabel } from '../../helpers/lang'
import useLanguage from '../../state/hooks/useLanguage'
import './SDG.scss'
import { CardContent, CircularProgress } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import ArrowBack from '@material-ui/icons/ArrowBack'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import AnnotationsFactory from 'highcharts/modules/annotations'
import React from 'react'
import Helmet from 'react-helmet'
import { FormattedMessage } from 'react-intl'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'
import { bindActionCreators } from 'redux'

AnnotationsFactory(Highcharts)

const BAR_COLORS = [
  '#19486A',
  '#00689D',
  '#56C02B',
  '#0A97D9',
  '#3F7E44',
  '#BF8B2E',
  '#FD9D24',
  '#DD1367',
  '#FD6925',
  '#A21942',
  '#FCC30B',
  '#26BDE2',
  '#FF3A21',
  '#C5192D',
  '#4C9F38',
  '#DDA83A',
  '#E5243B',
].reverse()

const CHART_OPTIONS = {
  chart: {
    type: 'bar',
    backgroundColor: 'transparent',
    style: {
      fontFamily: 'inherit',
    },
    height: 800,
    spacingRight: 30,
  },
  title: {
    text: '',
    align: 'left',
  },
  xAxis: {
    className: 'SDG_xAxis',
    labels: {
      useHTML: true,
      style: {
        color: 'white',
        padding: '10px',
        fontSize: '14px',
      },
    },
    // overflow: 'allow',
    lineWidth: 0,
    maxPadding: 1,
    title: {
      text: null,
    },
  },
  yAxis: {
    gridLineColor: 'rgba(196, 196, 196, 0.3)',
    plotLines: [{ color: 'rgba(196, 196, 196, 0.3) ', width: 0.5, value: 0 }],
    title: {
      text: '',
      align: 'high',
    },
    labels: {
      overflow: 'justify',
      align: 'right',
      style: {
        color: 'white',
        fontSize: '12px',
        padding: '20px',
      },
    },
  },
  plotOptions: {
    bar: {
      colorByPoint: true,
      borderWidth: 0,
      dataLabels: {
        enabled: false,
      },
    },
    series: {
      events: {
        legendItemClick(e) {
          e.preventDefault()
          let chart = this.chart

          chart.series.forEach((s) => {
            if (s !== this && s.visible) {
              s.hide()
            } else {
              s.show()
            }
          })
        },
      },
    },
  },
  tooltip: {
    visible: true,
    backgroundColor: 'rgba(0,0,0,0)',
    shadow: false,
    borderWidth: 0,
    useHTML: true,
    formatter: function () {
      return (
        `<div 
      style="display:flex;pointer-events:auto;font-size:11px;background-color:${this.color}; color:white; padding: 10px 15px; border-radius: 6px; font-weight: bold">` +
        `<div>` +
        `<div>${this.x}</div>` +
        `<div>${this.point.series.legendItem.element.innerHTML}</div>` +
        `</div>` +
        `<div style="font-size:20px; padding-left: 10px" >${this.y}% </div>` +
        `</div>`
      )
    },
  },
  legend: {
    className: 'SDG_legend',
    align: 'left',
    verticalAlign: 'top',
    floating: false,
    backgroundColor: 'transparent',
    borderWidth: 0,
    useHTML: true,
    itemStyle: {
      color: 'white',
      opacity: 0.7,
      fontSize: '16px',
    },
    itemHoverStyle: {
      color: 'white',
      opacity: 1,
    },
  },
  credits: {
    enabled: false,
  },
}

const SustainabilityDevelopmentGoals = ({
  cityStore,
  userStore,
  pageStore,
  intl,
}) => {
  const [mobile, setMobile] = React.useState(window.innerWidth <= 500)
  const [images, setImages] = React.useState([])
  const [dChart, setDChart] = React.useState(null)
  const [mChart, setMChart] = React.useState(null)
  const [loading, setLoading] = React.useState(false)
  const { language } = useLanguage()
  const [pageNotFound, setPageNotFound] = React.useState(false)
  const handleWindowSizeChange = () => {
    setMobile(window.innerWidth <= 500)
  }

  React.useEffect(() => {
    let imgs = []
    for (let i = 1; i <= 17; i++) {
      switch (language.locale) {
        case 'fr':
          imgs.push(
            <img
              className="sdg_image"
              src={`/sdg/fr/F-WEB-Goal-${i < 10 ? `0${i}` : i}.png`}
              alt="sdg"
              width="118.5px"
            />
          )
          break
        case 'en':
        default:
          imgs.push(
            <img
              className="sdg_image"
              src={`/sdg/en/E_WEB_${i < 10 ? `0${i}` : i}.png`}
              alt="sdg"
              width="118.5px"
            />
          )
          break
      }
    }
    setImages(imgs)
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [language.locale])

  React.useEffect(() => {
    if (pageStore.get('isMultiCity')) {
      setPageNotFound(true)
      return
    } else {
      setDChart(null)
      setMChart(null)
      setLoading(true)
    }
  }, [
    language.locale,
    pageStore.get('isMultiCity'),
    userStore.getIn(['selectedCityId']),
  ])

  React.useEffect(() => {
    if (!!dChart && !!mChart) {
      setLoading(false)
    }
  }, [dChart, mChart])

  React.useEffect(() => {
    const SDGData = cityStore.get('sdg')?.toJS() ?? {}
    const { previousQuarter, previousYear } = SDGData
    if (!previousQuarter || !previousYear) {
      // setPageNotFound(true)
      // setLoading(true)
      return
    }

    const xAxisCategories = previousQuarter?.changePercent.map(
      (p) => `${intl.formatMessage({ id: `sdg.item.${p['id']}` })} [${p['id']}]`
    )

    const chartSeries = [
      {
        name: getLocalizedSdgLabel(previousQuarter, language.locale),
        color: 'rgba(246, 74, 74, 1)',
        data: (previousQuarter?.changePercent ?? []).map((p) => {
          return p['value']
        }),
        minPointLength: 1,
      },
      {
        name: getLocalizedSdgLabel(previousYear, language.locale),
        color: 'rgba(128, 140, 255, 1)',
        data: (previousYear?.changePercent ?? []).map((p) => {
          return p['value']
        }),
        minPointLength: 1,
      },
    ]
    const options = {
      ...CHART_OPTIONS,
      xAxis: {
        ...CHART_OPTIONS.xAxis,
        categories: xAxisCategories,
      },
      yAxis: {
        ...CHART_OPTIONS.yAxis,
      },
      colors: BAR_COLORS,
      series: chartSeries,
    }

    setDChart(<HighchartsReact highcharts={Highcharts} options={options} />)

    const arr = [
      <div>
        <div className="sdg_mobile_chart_legend">
          {previousQuarter?.graphTitle}
        </div>
        <div className="sdg_mobile_chart_legend">
          {previousYear?.graphTitle}
        </div>
      </div>,
    ]
    let graphMinYValue = 0
    let graphMaxYValue = 0

    chartSeries[0].data.concat(chartSeries[1].data).forEach((i) => {
      if (i < graphMinYValue) graphMinYValue = i
      if (i > graphMaxYValue) graphMaxYValue = i
    })

    for (let i = 0; i < 17; i++) {
      const mOptions = {
        ...options,
        xAxis: {
          categories: [''],
        },
        yAxis: {
          ...options.yAxis,
          min: graphMinYValue,
          max: graphMaxYValue,
        },
        colors: [BAR_COLORS[i]],
        series: chartSeries.map((series) => {
          return { ...series, data: [series.data[i]] }
        }),
        chart: {
          ...options.chart,
          height: 80,
          spacingRight: null,
        },
        legend: {
          enabled: false,
        },
        tooltip: {
          enabled: false,
        },
        annotations: [
          {
            labels: [
              {
                point: {
                  y: 0,
                  yAxis: 0,
                },
                y: 15,
                backgroundColor: 'rgba(246, 74, 74, 1)',
                borderWidth: 0,
                shape: 'circle',
                text: ' ',
              },
              {
                point: {
                  y: 0,
                  yAxis: 0,
                },
                y: 35,
                borderWidth: 0,
                backgroundColor: 'rgba(128, 140, 255, 1)',
                shape: 'circle',
                text: ' ',
              },
            ],
            labelOptions: {
              useHTML: true,
              y: graphMinYValue,
            },
          },
        ],
      }
      arr.push(
        <label className="sdg_mobile_chart_label">{xAxisCategories[i]}</label>
      )
      arr.push(<HighchartsReact highcharts={Highcharts} options={mOptions} />)
    }
    setMChart(<div>{arr}</div>)
    // }
  }, [
    language.locale,
    cityStore.get('sdg'),
    cityStore.get('area'),
    cityStore.get('population'),
  ])

  if (pageNotFound) return <Redirect to="/" />
  return (
    <div className="SDGPage">
      <Helmet>
        <title>CitiIQ - {intl.formatMessage({ id: 'sdg.navigation' })}</title>
      </Helmet>
      <Link to="/dashboard" className="navigation">
        <h1>
          <ArrowBack className="nav-icon" />
          <FormattedMessage id="sdg.navigation" description="nav" />
        </h1>
      </Link>
      <Grid
        container
        style={mobile ? {} : { paddingLeft: '24px', paddingRight: '24px' }}
      >
        <Grid item xs={12}>
          <h2 style={{ fontWeight: 400 }}>
            <FormattedMessage id="sdg.chart-title" description="nav" />
          </h2>
        </Grid>
        <Grid item style={{ paddingBottom: '24px' }} xs={12}>
          <CitiIQCard height="auto">
            {loading ? (
              <CardContent
                style={{
                  padding: 0,
                  paddingRight: '30px',
                  overflow: 'visible',
                  width: '100%',
                  minHeight: '800px',
                }}
              >
                <CircularProgress />
              </CardContent>
            ) : (
              <CardContent
                style={{
                  padding: 0,
                  paddingRight: '30px',
                  overflow: 'visible',
                  width: '100%',
                }}
              >
                {mobile ? mChart : dChart}
              </CardContent>
            )}
          </CitiIQCard>
        </Grid>
        <Grid
          item
          style={{
            display: 'flex',
            flexWrap: 'wrap',
          }}
          className="sdg_images_container"
          xs={12}
        >
          {images}
        </Grid>
      </Grid>
    </div>
  )
}

function mapStateToProps(state) {
  return {
    cityStore: state.CityStore,
    userStore: state.UserStore,
    pageStore: state.PageStore,
    adminStore: state.AdminStore,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        getAllUsersSubscriptions,
        getAllUserRoles,
        deleteSubscription,
        createSubscription,
      },
      dispatch
    ),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(SustainabilityDevelopmentGoals))
