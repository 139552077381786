import { CitiIQCard, HexProgress } from '../../components'
import './HexScorecard.scss'
import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'

export const HexScorecard = ({
  cardHeight = 100,
  hexHeight = 70,
  mainColor = '#31af83',
  score,
  id,
  onClick,
  ...props
}) => {
  const isMobile = window.innerWidth < 570
  const scale = isMobile ? 0.77 : 1
  return (
    <CitiIQCard
      height={cardHeight * scale + 'px'}
      classes={{ root: 'HexScorecardWrapper' }}
    >
      <div
        className="HexScorecard"
        onClick={onClick ? () => onClick() : () => false}
        style={{ cursor: onClick ? 'pointer' : 'inherit' }}
      >
        <HexProgress
          percent={score || null}
          height={hexHeight * scale}
          {...props}
          mainColor={mainColor}
          id={'HexProgress' + id}
        />
        <span className="score" style={{ color: mainColor }}>
          {' '}
          {score || 0}{' '}
        </span>
        <span className="considerationName">
          <FormattedMessage
            id={'considerations.' + id}
            description="hex-text"
          />
        </span>
      </div>
    </CitiIQCard>
  )
}

HexScorecard.propTypes = {
  cardHeight: PropTypes.number,
  hexHeight: PropTypes.number,
  mainColor: PropTypes.string,
  secondaryColor: PropTypes.string,
  backgroundColor: PropTypes.string,
}
