import ThemeProvider from './components/ThemeProvider/ThemeProvider'
import './index.scss'
import App from './pages/App/App'
import rootReducer from './reducers/rootReducer'
import { unregister } from './registerServiceWorker'
import CssBaseline from '@material-ui/core/CssBaseline'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import 'mapbox-gl/dist/mapbox-gl.css'
import React from 'react'
import ReactDOM from 'react-dom'
import Helmet from 'react-helmet'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'

if (process.env.NODE_ENV === 'development') {
  // Code that should only run in development (local)
  console.log('Running in local environment. Disabling sentry...')
} else {
  Sentry.init({
    dsn: 'https://a23a7a74716e4640a7bfd5008d71ece6@o445902.ingest.sentry.io/6571347',
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  })
}
const store = createStore(
  rootReducer,
  {},
  compose(
    applyMiddleware(thunk),
    window.__REDUX_DEVTOOLS_EXTENSION__
      ? window.__REDUX_DEVTOOLS_EXTENSION__()
      : (f) => f
  )
)

if (module.hot) {
  module.hot.accept()
}

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider>
      <Helmet>
        <meta name="version" content={process.env.REACT_APP_VERSION} />
      </Helmet>
      <CssBaseline />
      <App />
    </ThemeProvider>
  </Provider>,
  document.getElementById('root')
)

unregister()
