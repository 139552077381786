/**
 * App Theme
 */
import { StyledScrollbar } from './helpers/scrollbar'
import grey from '@material-ui/core/colors/grey'
import { createMuiTheme } from '@material-ui/core/styles'
import { color } from 'highcharts'

const darkItemColor = '#31353D'
const darkBgColor = '#282828'

const theme = createMuiTheme({
  overrides: {
    MuiSwitch: {
      checked: {
        color: '#fff !important',
        '& + $bar ': {
          backgroundColor: '#9EC73E !important',
          opacity: '0.85 !important',
        },
      },
    },
    MuiPopover: {
      paper: {
        // CityMenu scrollbar
        ...StyledScrollbar,
      },
    },
    MuiPickersModal: {
      dialogAction: {
        '&:last-child': {
          marginRight: 0,
          color: 'rgb(88, 129, 196)',
        },
      },
    },
    MuiTab: {
      root: {
        backgroundColor: '#31353D',
        color: '#fff',
        fontWeight: 'bold',
        textTransform: 'capitalize',
        '&:hover': {
          backgroundColor: 'rgba(255, 255, 255, 0.1)',
        },
      },
      selected: {
        backgroundColor: '#22252C',
        color: '#fff',
        fontWeight: 'bold',
        textTransform: 'capitalize',
      },
    },
    MuiListItem: {
      selected: {
        backgroundColor: `${darkBgColor} !important`,
      },
    },
  },
  palette: {
    type: 'dark',
    types: {
      dark: {
        background: {
          paper: darkItemColor,
          default: darkBgColor,
          appBar: darkItemColor,
          contentFrame: darkBgColor,
          chip: darkItemColor,
          avatar: darkItemColor,
          tabs: darkItemColor,
        },
      },
    },
    primary: {
      light: grey[400],
      main: grey[700],
      dark: grey[900],
      contrastText: '#fff',
    },
    secondary: {
      light: grey[700],
      main: grey[700],
      dark: grey[700],
      contrastText: '#fff',
    },
    background: {
      paper: darkItemColor,
      default: darkBgColor,
      appBar: darkItemColor,
      contentFrame: darkBgColor,
      chip: darkItemColor,
      avatar: darkItemColor,
      tabs: darkItemColor,
    },
  },
  status: {
    danger: 'orange',
  },
  typography: {
    button: {
      fontWeight: 400,
      textAlign: 'capitalize',
    },
  },
})

export default theme
