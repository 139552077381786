import en_0_7 from '../../assets/methodology/0.7.jpg'
import es_0_7 from '../../assets/methodology/0.7.jpg'
import fr_0_7 from '../../assets/methodology/0.7.jpg'
import pt_0_7 from '../../assets/methodology/0.7.jpg'
import en_2_1 from '../../assets/methodology/2.1.png'
import es_2_1 from '../../assets/methodology/2.1.png'
import fr_2_1 from '../../assets/methodology/2.1.png'
import pt_2_1 from '../../assets/methodology/2.1.png'
import en_3_1 from '../../assets/methodology/3.1.png'
import es_3_1 from '../../assets/methodology/3.1.png'
import fr_3_1 from '../../assets/methodology/3.1.png'
import pt_3_1 from '../../assets/methodology/3.1.png'
import en_0_1 from '../../assets/methodology/en/0.1.png'
import en_0_2 from '../../assets/methodology/en/0.2.png'
import en_0_3 from '../../assets/methodology/en/0.3.png'
import en_0_4 from '../../assets/methodology/en/0.4.png'
import en_0_5 from '../../assets/methodology/en/0.5.png'
import en_0_6 from '../../assets/methodology/en/0.6.png'
import en_1_1 from '../../assets/methodology/en/1.1.png'
import en_1_21 from '../../assets/methodology/en/1.2.1.png'
import en_1_22 from '../../assets/methodology/en/1.2.2.png'
import en_1_31 from '../../assets/methodology/en/1.3.1.png'
import en_1_32 from '../../assets/methodology/en/1.3.2.png'
import en_1_41 from '../../assets/methodology/en/1.4.1.png'
import en_1_42 from '../../assets/methodology/en/1.4.2.png'
import en_1_51 from '../../assets/methodology/en/1.5.1.png'
import en_1_52 from '../../assets/methodology/en/1.5.2.png'
import en_1_6 from '../../assets/methodology/en/1.6.png'
import en_1_7 from '../../assets/methodology/en/1.7.png'
import en_1_8 from '../../assets/methodology/en/1.8.png'
import en_2_2 from '../../assets/methodology/en/2.2.png'
import es_0_1 from '../../assets/methodology/es/0.1.png'
import es_0_2 from '../../assets/methodology/es/0.2.png'
import es_0_3 from '../../assets/methodology/es/0.3.png'
import es_0_4 from '../../assets/methodology/es/0.4.png'
import es_0_5 from '../../assets/methodology/es/0.5.png'
import es_0_6 from '../../assets/methodology/es/0.6.png'
import es_1_1 from '../../assets/methodology/es/1.1.png'
import es_1_21 from '../../assets/methodology/es/1.2.1.png'
import es_1_22 from '../../assets/methodology/es/1.2.2.png'
import es_1_31 from '../../assets/methodology/es/1.3.1.png'
import es_1_32 from '../../assets/methodology/es/1.3.2.png'
import es_1_41 from '../../assets/methodology/es/1.4.1.png'
import es_1_42 from '../../assets/methodology/es/1.4.2.png'
import es_1_51 from '../../assets/methodology/es/1.5.1.png'
import es_1_52 from '../../assets/methodology/es/1.5.2.png'
import es_1_6 from '../../assets/methodology/es/1.6.png'
import es_1_7 from '../../assets/methodology/es/1.7.png'
import es_1_8 from '../../assets/methodology/es/1.8.png'
import es_2_2 from '../../assets/methodology/es/2.2.png'
import fr_0_1 from '../../assets/methodology/fr/0.1.png'
import fr_0_2 from '../../assets/methodology/fr/0.2.png'
import fr_0_3 from '../../assets/methodology/fr/0.3.png'
import fr_0_4 from '../../assets/methodology/fr/0.4.png'
import fr_0_5 from '../../assets/methodology/fr/0.5.png'
import fr_0_6 from '../../assets/methodology/fr/0.6.png'
import fr_1_1 from '../../assets/methodology/fr/1.1.png'
import fr_1_21 from '../../assets/methodology/fr/1.2.1.png'
import fr_1_22 from '../../assets/methodology/fr/1.2.2.png'
import fr_1_31 from '../../assets/methodology/fr/1.3.1.png'
import fr_1_32 from '../../assets/methodology/fr/1.3.2.png'
import fr_1_41 from '../../assets/methodology/fr/1.4.1.png'
import fr_1_42 from '../../assets/methodology/fr/1.4.2.png'
import fr_1_51 from '../../assets/methodology/fr/1.5.1.png'
import fr_1_52 from '../../assets/methodology/fr/1.5.2.png'
import fr_1_6 from '../../assets/methodology/fr/1.6.png'
import fr_1_7 from '../../assets/methodology/fr/1.7.png'
import fr_1_8 from '../../assets/methodology/fr/1.8.png'
import fr_2_2 from '../../assets/methodology/fr/2.2.png'
import pt_0_1 from '../../assets/methodology/pt/0.1.png'
import pt_0_2 from '../../assets/methodology/pt/0.2.png'
import pt_0_3 from '../../assets/methodology/pt/0.3.png'
import pt_0_4 from '../../assets/methodology/pt/0.4.png'
import pt_0_5 from '../../assets/methodology/pt/0.5.png'
import pt_0_6 from '../../assets/methodology/pt/0.6.png'
import pt_1_1 from '../../assets/methodology/pt/1.1.png'
import pt_1_21 from '../../assets/methodology/pt/1.2.1.png'
import pt_1_22 from '../../assets/methodology/pt/1.2.2.png'
import pt_1_31 from '../../assets/methodology/pt/1.3.1.png'
import pt_1_32 from '../../assets/methodology/pt/1.3.2.png'
import pt_1_41 from '../../assets/methodology/pt/1.4.1.png'
import pt_1_42 from '../../assets/methodology/pt/1.4.2.png'
import pt_1_51 from '../../assets/methodology/pt/1.5.1.png'
import pt_1_52 from '../../assets/methodology/pt/1.5.2.png'
import pt_1_6 from '../../assets/methodology/pt/1.6.png'
import pt_1_7 from '../../assets/methodology/pt/1.7.png'
import pt_1_8 from '../../assets/methodology/pt/1.8.png'
import pt_2_2 from '../../assets/methodology/pt/2.2.png'

export const renderMethodologyImage = ({ locale, imageId }) => {
  let src = null
  let width = null

  switch (imageId.replace('methodology.image.', '')) {
    case '0.1':
      switch (locale) {
        case 'en':
          src = en_0_1
          break
        case 'fr':
          src = fr_0_1
          break
        case 'es':
          src = es_0_1
          break
        case 'pt':
          src = pt_0_1
          break
        default:
          break
      }
      width = 600
      break
    case '0.2':
      switch (locale) {
        case 'en':
          src = en_0_2
          break
        case 'fr':
          src = fr_0_2
          break
        case 'es':
          src = es_0_2
          break
        case 'pt':
          src = pt_0_2
          break
        default:
          break
      }
      width = 600
      break
    case '0.3':
      switch (locale) {
        case 'en':
          src = en_0_3
          break
        case 'fr':
          src = fr_0_3
          break
        case 'es':
          src = es_0_3
          break
        case 'pt':
          src = pt_0_3
          break
        default:
          break
      }
      width = 600
      break
    case '0.4':
      switch (locale) {
        case 'en':
          src = en_0_4
          break
        case 'fr':
          src = fr_0_4
          break
        case 'es':
          src = es_0_4
          break
        case 'pt':
          src = pt_0_4
          break
        default:
          break
      }
      width = 600
      break
    case '0.5':
      switch (locale) {
        case 'en':
          src = en_0_5
          break
        case 'fr':
          src = fr_0_5
          break
        case 'es':
          src = es_0_5
          break
        case 'pt':
          src = pt_0_5
          break
        default:
          break
      }
      width = 550
      break
    case '0.6':
      switch (locale) {
        case 'en':
          src = en_0_6
          break
        case 'fr':
          src = fr_0_6
          break
        case 'es':
          src = es_0_6
          break
        case 'pt':
          src = pt_0_6
          break
        default:
          break
      }
      width = 450
      break
    case '0.7':
      switch (locale) {
        case 'en':
          src = en_0_7
          break
        case 'fr':
          src = fr_0_7
          break
        case 'es':
          src = es_0_7
          break
        case 'pt':
          src = pt_0_7
          break
        default:
          break
      }
      width = 472
      break
    case '1.1':
      switch (locale) {
        case 'en':
          src = en_1_1
          break
        case 'fr':
          src = fr_1_1
          break
        case 'es':
          src = es_1_1
          break
        case 'pt':
          src = pt_1_1
          break
        default:
          break
      }
      width = 700
      break
    case '1.2.1':
      switch (locale) {
        case 'en':
          src = en_1_21
          break
        case 'fr':
          src = fr_1_21
          break
        case 'es':
          src = es_1_21
          break
        case 'pt':
          src = pt_1_21
          break
        default:
          break
      }
      width = 300
      break
    case '1.2.2':
      switch (locale) {
        case 'en':
          src = en_1_22
          break
        case 'fr':
          src = fr_1_22
          break
        case 'es':
          src = es_1_22
          break
        case 'pt':
          src = pt_1_22
          break
        default:
          break
      }
      width = 300
      break
    case '1.3.1':
      switch (locale) {
        case 'en':
          src = en_1_31
          break
        case 'fr':
          src = fr_1_31
          break
        case 'es':
          src = es_1_31
          break
        case 'pt':
          src = pt_1_31
          break
        default:
          break
      }
      width = 300
      break
    case '1.3.2':
      switch (locale) {
        case 'en':
          src = en_1_32
          break
        case 'fr':
          src = fr_1_32
          break
        case 'es':
          src = es_1_32
          break
        case 'pt':
          src = pt_1_32
          break
        default:
          break
      }
      width = 300
      break
    case '1.4.1':
      switch (locale) {
        case 'en':
          src = en_1_41
          break
        case 'fr':
          src = fr_1_41
          break
        case 'es':
          src = es_1_41
          break
        case 'pt':
          src = pt_1_41
          break
        default:
          break
      }
      width = 300
      break
    case '1.4.2':
      switch (locale) {
        case 'en':
          src = en_1_42
          break
        case 'fr':
          src = fr_1_42
          break
        case 'es':
          src = es_1_42
          break
        case 'pt':
          src = pt_1_42
          break
        default:
          break
      }
      width = 300
      break
    case '1.5.1':
      switch (locale) {
        case 'en':
          src = en_1_51
          break
        case 'fr':
          src = fr_1_51
          break
        case 'es':
          src = es_1_51
          break
        case 'pt':
          src = pt_1_51
          break
        default:
          break
      }
      width = 300
      break
    case '1.5.2':
      switch (locale) {
        case 'en':
          src = en_1_52
          break
        case 'fr':
          src = fr_1_52
          break
        case 'es':
          src = es_1_52
          break
        case 'pt':
          src = pt_1_52
          break
        default:
          break
      }
      width = 300
      break
    case '1.6':
      switch (locale) {
        case 'en':
          src = en_1_6
          break
        case 'fr':
          src = fr_1_6
          break
        case 'es':
          src = es_1_6
          break
        case 'pt':
          src = pt_1_6
          break
        default:
          break
      }
      width = 435
      break
    case '1.7':
      switch (locale) {
        case 'en':
          src = en_1_7
          break
        case 'fr':
          src = fr_1_7
          break
        case 'es':
          src = es_1_7
          break
        case 'pt':
          src = pt_1_7
          break
        default:
          break
      }
      width = 387
      break
    case '1.8':
      switch (locale) {
        case 'en':
          src = en_1_8
          break
        case 'fr':
          src = fr_1_8
          break
        case 'es':
          src = es_1_8
          break
        case 'pt':
          src = pt_1_8
          break
        default:
          break
      }
      width = 600
      break
    case '2.1':
      switch (locale) {
        case 'en':
          src = en_2_1
          break
        case 'fr':
          src = fr_2_1
          break
        case 'es':
          src = es_2_1
          break
        case 'pt':
          src = pt_2_1
          break
        default:
          break
      }
      width = 144
      break
    case '2.2':
      switch (locale) {
        case 'en':
          src = en_2_2
          break
        case 'fr':
          src = fr_2_2
          break
        case 'es':
          src = es_2_2
          break
        case 'pt':
          src = pt_2_2
          break
        default:
          break
      }
      width = 600
      break
    case '3.1':
      switch (locale) {
        case 'en':
          src = en_3_1
          break
        case 'fr':
          src = fr_3_1
          break
        case 'es':
          src = es_3_1
          break
        case 'pt':
          src = pt_3_1
          break
        default:
          break
      }
      width = 600
      break
    default:
      break
  }

  return src ? (
    <img
      src={src}
      alt={`[${imageId}]`}
      style={{
        width: '100%',
        maxWidth: width ? `${width}px` : 'unset',
      }}
    />
  ) : (
    <p>{`[${imageId}]`}</p>
  )
}
