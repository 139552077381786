import './CitiIQCard.scss'
import Card from '@material-ui/core/Card'
import React from 'react'

export const CitiIQCard = ({
  classes,
  children,
  inheritHeightMobile,
  height,
  minHeight,
  ...other
}) => {
  let styleObj = height ? { height: height } : {}
  styleObj = minHeight ? { ...styleObj, minHeight } : styleObj
  return (
    <Card
      {...other}
      classes={
        classes || {
          root:
            'CitiIQCard' + (inheritHeightMobile ? ' inheritHeightMobile' : ''),
        }
      }
      style={styleObj}
    >
      {children}
    </Card>
  )
}
