import './rating.scss'
import PropTypes from 'prop-types'
import React from 'react'
import StarRatings from 'react-star-ratings'

const Rating = ({ starWidth, starSpacing, rating }) => (
  <div className="star-ratings-container">
    <StarRatings
      starDimension={starWidth}
      starSpacing={starSpacing}
      rating={rating}
      starRatedColor="#FBB03F"
      starEmptyColor="#505868"
      numberOfStars={5}
      name="rating"
      svgIconPath="M14.2095,4.95986L9.37783,4.44961L7.3999,0.0131836L5.42197,4.44961L0.590332,4.95986L4.19922,8.2125L3.1917,12.9644L7.3999,10.5374L11.6081,12.9644L10.6006,8.2125L14.2095,4.95986Z"
      svgIconViewBox="0 0 15 13"
    />
  </div>
)

Rating.propTypes = {
  rating: PropTypes.number,
  starWidth: PropTypes.string,
  starSpacing: PropTypes.string,
}

export default Rating
