import {
  GET_ADMIN_VIEW_INFO,
  UPDATE_SUBSCRIPTION,
  DELETE_SUBSCRIPTION,
  CREATE_SUBSCRIPTION,
  OPEN_FORCE_LOGOUT_DIALOG,
  GET_ALL_USER_ROLES,
} from '../constants/ActionTypes'
import {
  GET_ADMIN_VIEW_INFO_QUERY,
  UPDATE_SUBSCRIPTION_QUERY,
  CREATE_SUBSCRIPTION_QUERY,
  DELETE_SUBSCRIPTION_QUERY,
  GET_ALL_USER_ROLES_QUERY,
} from '../constants/QueryTypes'
import { hasUserSessionError } from '../helpers/errorHandler'
import query from '../services/GraphQLService'

export const getAllUsersSubscriptions = () => (dispatch) => {
  query('query', GET_ADMIN_VIEW_INFO_QUERY, {}).then(({ data, errors }) => {
    if (errors && hasUserSessionError(errors)) {
      // dispatch({ type: OPEN_FORCE_LOGOUT_DIALOG })
      return
    }
    dispatch({ type: GET_ADMIN_VIEW_INFO, payload: data })
  })
}

export const getAllUserRoles = () => (dispatch) => {
  query('query', GET_ALL_USER_ROLES_QUERY, {}).then(({ data, errors }) => {
    if (errors && hasUserSessionError(errors)) {
      // dispatch({ type: OPEN_FORCE_LOGOUT_DIALOG })
      return
    }
    dispatch({ type: GET_ALL_USER_ROLES, payload: data })
  })
}

export const updateSubscription = (id, city_id) => (dispatch) => {
  return new Promise((resolve, reject) => {
    query('mutate', UPDATE_SUBSCRIPTION_QUERY, { id, city_id })
      .then(({ data, errors }) => {
        if (errors && hasUserSessionError(errors)) {
          // dispatch({ type: OPEN_FORCE_LOGOUT_DIALOG })
          reject(errors)
          return
        }
        dispatch({
          type: UPDATE_SUBSCRIPTION,
          payload: data.updateSubscription,
        })
        resolve(data)
      })
      .catch((e) => {
        reject(e)
      })
  })
}

export const createSubscription = (company_id, city_id) => (dispatch) => {
  return new Promise((resolve, reject) => {
    query('mutate', CREATE_SUBSCRIPTION_QUERY, { company_id, city_id })
      .then(({ data, errors }) => {
        if (errors && hasUserSessionError(errors)) {
          // dispatch({ type: OPEN_FORCE_LOGOUT_DIALOG })
          reject(errors)
          return
        }
        dispatch({
          type: CREATE_SUBSCRIPTION,
          payload: data.createSubscription,
        })
        resolve(data)
      })
      .catch((e) => {
        reject(e)
      })
  })
}

export const deleteSubscription = (company_id, city_id) => (dispatch) => {
  return new Promise((resolve, reject) => {
    query('mutate', DELETE_SUBSCRIPTION_QUERY, { company_id, city_id })
      .then(({ data, errors }) => {
        if (errors && hasUserSessionError(errors)) {
          // dispatch({ type: OPEN_FORCE_LOGOUT_DIALOG })
          reject(errors)
          return
        }
        dispatch({
          type: DELETE_SUBSCRIPTION,
          payload: data.deleteSubscription,
        })
        resolve(data)
      })
      .catch((e) => {
        reject(e)
      })
  })
}
