import {
  CustomizedIndicatorsToolTip,
  CustomizedYAxisTickIndicators,
} from '../ChartHelperComponent/ChartHelperComponent.js'
import './IndicatorChart.scss'
import React from 'react'
import { injectIntl } from 'react-intl'
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

const HorizontalBar = ({
  data,
  selectedConsideration,
  searchKey,
  chartHeight,
  quartersCount,
  isShowingPrevQuarters,
  intl,
}) => {
  const [chart, setChart] = React.useState(null)
  React.useEffect(() => {
    if (data.length > 0) {
      setChart(renderHorizontalCharts())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, selectedConsideration, chartHeight, isShowingPrevQuarters])

  const renderHorizontalCharts = () => {
    const isMobile = window.innerWidth < 800
    return (
      <ResponsiveContainer
        width="100%"
        // add extra height so bars are not squished together in smaller charts
        height={chartHeight + 50}
        className={`${
          isShowingPrevQuarters ? 'isShowingPrevQuarters' : ''
        } quartersCount_${quartersCount}`}
      >
        <BarChart
          width={1000}
          height={2000}
          layout="vertical"
          data={data || []}
          margin={{
            top: 20,
            right: isMobile ? 10 : 100,
            left: isMobile ? 100 : 200,
            bottom: 20,
          }}
          barSize={10}
          barGap={100}
          barCategoryGap={100}
        >
          <CartesianGrid stroke="rgba(255,255,255,0.2)" horizontal={false} />
          <XAxis type="number" domain={[0, 100]} />
          <YAxis
            dataKey="name"
            type="category"
            interval={isShowingPrevQuarters ? quartersCount - 1 : 0}
            // interval={0}
            width={150}
            dy={50}
            tick={
              <CustomizedYAxisTickIndicators
                offset={isShowingPrevQuarters ? (24 / 4) * quartersCount : 0}
              />
            }
          />
          <Tooltip
            content={
              <CustomizedIndicatorsToolTip searchKey={searchKey} intl={intl} />
            }
          />
          <Bar dataKey="value" label="displayName">
            {data.map((entry, index) => {
              return (
                <Cell
                  cursor="pointer"
                  fill={entry.isSelectedQuarter ? '#9EC73E' : 'grey'}
                  key={`cell-${index}`}
                />
              )
            })}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    )
  }

  return chart ?? null
}

export default injectIntl(HorizontalBar)
