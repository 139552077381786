import useLanguage from '../../state/hooks/useLanguage'
import './LanguageSelector.scss'
import { FormControl, MenuItem, Select } from '@material-ui/core'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

const LanguageSelector = ({ userStore }) => {
  const { language, saveLanguage } = useLanguage()

  const handleChange = (e) => {
    saveLanguage(e.target.value)
  }

  return (
    <FormControl fullWidth>
      <Select
        value={language.locale}
        label="Language"
        onChange={handleChange}
        disableUnderline
        classes={{ root: 'LanguageSelector' }}
      >
        <MenuItem value="en">English</MenuItem>
        <MenuItem value="fr">Français</MenuItem>
        <MenuItem value="es">Español</MenuItem>
        <MenuItem value="pt">Português</MenuItem>
      </Select>
    </FormControl>
  )
}
function mapStateToProps(state) {
  return {
    userStore: state.UserStore,
  }
}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({}, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSelector)
