import { INVALID_SESSION } from '../constants/ServerErrors'

const Sentry = require('@sentry/react')
export function hasUserSessionError(graphqlErrorList) {
  let hasUserSessionError = false
  graphqlErrorList.forEach((graphqlError) => {
    if (graphqlError?.extensions?.code === INVALID_SESSION) {
      // hasUserSessionError = true
      Sentry.captureMessage('Session marked as invalid but allowing request', {
        level: 'warning',
        extra: {
          session,
          timestamp: new Date().toISOString(),
          stack: new Error().stack, // Capture stack trace to see where it's being called
          endpoint: session?.endpoint || 'unknown',
          headers: session?.headers || {},
          userAgent: session?.userAgent || 'unknown',
        },
        tags: {
          type: 'session_diagnostic',
          status: 'invalid_but_allowed',
        },
      })
    }
  })
  return hasUserSessionError
}
