import {
  GET_GOALS_USER_CITY,
  LOGOUT_USER,
  UPDATE_GOAL,
  CREATE_GOAL,
  DELETE_GOAL,
} from '../constants/ActionTypes'
import { gAnalytics, dimensionsLookup } from '../helpers/analytics'
import { fromJS } from 'immutable'

const initialState = fromJS({
  goals: [],
  lastFetched: null,
})

export default (state = initialState, action) => {
  let newState = state
  var goalsArr

  switch (action.type) {
    case GET_GOALS_USER_CITY:
      const goals = fromJS(
        action.payload.GetGoalByUserAndCity.map((r) => {
          return {
            value: r.goal_value,
            dimension: r.dimension,
            date: r.goal_date,
            id: r.id,
          }
        })
      )
      newState = newState.set('goals', goals)
      newState = newState.set('lastFetched', new Date().toISOString())
      return newState
    case UPDATE_GOAL:
      goalsArr = state.get('goals').toJS()
      const i = goalsArr.findIndex((g) => {
        return g.dimension === action.payload.updateGoal.dimension
      })
      const newVal = {
        value: action.payload.updateGoal.goal_value,
        dimension: action.payload.updateGoal.dimension,
        date: action.payload.updateGoal.goal_date,
        id: action.payload.updateGoal.id,
      }
      if (i >= 0) {
        goalsArr[i] = newVal
        newState = newState.set('goals', fromJS(goalsArr))
        newState = newState.set('lastFetched', new Date().toISOString())
        gAnalytics('event', [
          'Goal',
          'update',
          dimensionsLookup[newVal.dimension],
          newVal.value,
        ])
      }
      return newState
    case CREATE_GOAL:
      goalsArr = state.get('goals').toJS()
      const data = action.payload.createGoal
      const newGoal = {
        value: data.goal_value,
        date: data.goal_date,
        dimension: data.dimension,
        id: data.id,
      }
      let goalsArrUpdated = false
      goalsArr = goalsArr.map((g) => {
        if (g.dimension === newGoal.dimension) goalsArrUpdated = true
        return g.dimension === newGoal.dimension ? newGoal : g
      })
      if (!goalsArrUpdated) goalsArr.push(newGoal)
      gAnalytics('event', [
        'Goal',
        'add',
        dimensionsLookup[data.dimension],
        data.goal_value,
      ])
      newState = newState.set('goals', fromJS(goalsArr))
      newState = newState.set('lastFetched', new Date().toISOString())
      return newState
    case DELETE_GOAL:
      const removedArr = []
      goalsArr = state
        .get('goals')
        .toJS()
        .filter((g) => {
          if (g.dimension === action.dimension) {
            removedArr.push(g.dimension)
          }
          removedArr.forEach((dim) => {
            gAnalytics('event', ['Goal', 'delete', dimensionsLookup[dim]])
          })
          return g.dimension !== action.dimension
        })
      newState = newState.set('goals', fromJS(goalsArr))
      newState = newState.set('lastFetched', new Date().toISOString())
      return newState
    case LOGOUT_USER:
      if (action.payload.LogoutUser.success) return initialState
      return state
    default:
      return state
  }
}
