import * as types from '../constants/ActionTypes'
import { fromJS } from 'immutable'

const initialState = fromJS({
  allConsiderations: [],
  considerationsComparison: [],
  dimensionsComparison: [],
  allIndicators: [],
  currentPage: '',
  activeDimension: '',
  loadingInfo: false,
  isMultiCity: true,
  allInfo: fromJS({
    LatestCityIq: 0,
    LatestCityDimensions: [],
    LatestCityConsiderations: [],
    LatestCityIndicators: [],
    LatestCityIndicatorWeights: [],
    LatestSnapshotRevision: '',
    lastUpdated: '',
  }),
  multiCityInfos: fromJS({}),
  cityCountryMap: fromJS([]),
  allQuarters: fromJS([]),
  quarterCityMap: fromJS({}),
  indicatorChartData: fromJS([]),
  isLoadingSingleCityInfo: false,
  isLoadingMultiCityInfo: false,
})

export default (state = initialState, action) => {
  let newState = state

  switch (action.type) {
    case types.GET_DIMENSIONS_COMPARISON:
      if (action.payload !== null) {
        newState = state.set('dimensionsComparison', fromJS(action.payload))
      }
      return newState

    case types.GET_ALL_CONSIDERATIONS:
      newState = state.set('allConsiderations', fromJS(action.payload))
      return newState

    case types.GET_ALL_INDICATORS:
      newState = state.set('allIndicators', fromJS(action.payload))
      return newState

    case types.SET_PAGE:
      newState = state.set('currentPage', fromJS(action.payload.page))
      newState = newState.set('activeDimension', fromJS(action.payload.dim))
      return newState

    case types.LOADING_SINGLE_CITY_INFO:
      return (newState = newState.set('isLoadingSingleCityInfo', true))
    case types.LOADING_MULTI_CITY_INFO:
      return (newState = newState.set('isLoadingMultiCityInfo', true))

    case types.GET_ALL_INFO:
      if (
        action.payload === null ||
        action.payload.LatestCityInformation === null
      ) {
        return newState.set('loadingInfo', false)
      }
      const {
        dimensions,
        considerations,
        indicators,
        indicatorWeights,
        iq,
        iq_quality_star,
        snapshotRevision,
        lastUpdated,
      } = action.payload.LatestCityInformation
      if (
        considerations &&
        indicators &&
        indicatorWeights &&
        dimensions &&
        iq &&
        iq_quality_star &&
        snapshotRevision &&
        lastUpdated
      ) {
        newState = state.setIn(['allInfo', 'LatestCityIq'], fromJS(iq))
        newState = newState.setIn(
          ['allInfo', 'LatestCityStar'],
          fromJS(Number(iq_quality_star))
        )
        newState = newState.setIn(
          ['allInfo', 'LatestCityDimensions'],
          fromJS(dimensions)
        )
        newState = newState.setIn(
          ['allInfo', 'LatestCityConsiderations'],
          fromJS(considerations)
        )
        newState = newState.setIn(
          ['allInfo', 'LatestCityIndicators'],
          fromJS(indicators)
        )
        newState = newState.setIn(
          ['allInfo', 'LatestCityIndicatorWeights'],
          fromJS(indicatorWeights)
        )
        newState = newState.setIn(
          ['allInfo', 'LatestSnapshotRevision'],
          fromJS(snapshotRevision)
        )
        newState = newState.setIn(
          ['allInfo', 'lastUpdated'],
          fromJS(lastUpdated)
        )
      } else {
        console.error(
          'data received from API call was not structured as expected'
        )
      }
      newState = newState.set('isLoadingSingleCityInfo', false)
      return newState.set('loadingInfo', false)

    case types.GET_MULTI_CITY_INFO:
      if (action.payload) {
        newState = state.set(
          'multiCityInfos',
          fromJS(action.payload.citySnapshots)
        )
        newState = newState.set(
          'cityCountryMap',
          fromJS(action.payload.cityCountryMap)
        )
      } else {
        console.error(
          'data received from API call was not structured as expected'
        )
      }
      newState = newState.set('isLoadingMultiCityInfo', false)
      return newState.set('loadingInfo', false)

    case types.GET_ALL_AVAILABLE_QUARTERS:
      if (action.payload) {
        newState = state.set('allQuarters', fromJS(action.payload.quarters))
        newState = newState.set(
          'quarterCityMap',
          fromJS(action.payload.quarterCityMap)
        )
      } else {
        console.error(
          'data received from API call was not structured as expected'
        )
      }
      return newState.set('loadingInfo', false)

    case types.GET_INDICATOR_CHART_DATA:
      if (action.payload === null) return newState

      const data = state.get('indicatorChartData')?.toJS() ?? []
      // remove duplicates
      const allData = [...data, action.payload].filter((item, index) => {
        return (
          index ===
          [...data, action.payload].findIndex((obj) => {
            return (
              obj.year === item.year &&
              obj.quarter === item.quarter &&
              item.year !== null &&
              item.quarter !== null
            )
          })
        )
      })
      allData.sort((a, b) => {
        // make sure the year and quarters are sorted descending order
        const yearA = Number(`${a.year}${a.quarter}`)
        const yearB = Number(`${b.year}${b.quarter}`)
        if (yearA > yearB) {
          return -1
        } else if (yearB > yearA) {
          return 1
        }
        // a must be equal to b
        return 0
      })
      newState = state.set('indicatorChartData', fromJS(allData))
      return newState

    case types.CLEAR_INDICATOR_CHART_DATA:
      return newState.set('indicatorChartData', fromJS([]))

    case types.GET_CONSIDERATIONS_COMPARISON:
      if (action.payload === null) return newState
      newState = state.set('considerationsComparison', fromJS(action.payload))
      return newState

    case types.GET_QUARTERS_CONSIDERATIONS_COMPARISON:
      if (action.payload === null) return newState
      newState = state.set('considerationsComparison', fromJS(action.payload))
      return newState

    case types.IS_MULTI_CITY:
      return newState.set('isMultiCity', true)

    case types.IS_SINGLE_CITY:
      return newState.set('isMultiCity', false)

    case types.START_LOADING_INFO:
      return newState.set('loadingInfo', true)

    case types.LOGOUT_USER:
      if (action.payload.LogoutUser.success) return initialState
      return state

    default:
      return state
  }
}
