import Cards from './Cards'
import Table from './Table'
import { faListUl, faTh } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Icon } from '@material-ui/core'
import React from 'react'
import { FormattedMessage } from 'react-intl'

const DimensionData = ({
  actions,
  pageStore,
  getCitiesMap,
  selectedDimension,
}) => {
  const [dimensionView, setDimensionView] = React.useState('number') // 'number' | 'bar' | 'card'

  function renderCards() {
    const mapObj = { ...getCitiesMap() }
    return (
      <Cards
        dimension={selectedDimension}
        cities={pageStore.get('multiCityInfos').toJS()}
        formattedCityCountryMap={mapObj?.obj ?? {}}
      />
    )
  }
  function renderTables() {
    const cities =
      pageStore.get('multiCityInfos') && pageStore.get('multiCityInfos').toJS()

    const citiesMap = getCitiesMap()
    if (!cities || !citiesMap || !citiesMap.arr || citiesMap.arr.length === 0) {
      return null
    }
    return (
      <Table
        dimension={selectedDimension}
        dimensionView={dimensionView}
        cities={cities}
        formattedCityCountryMap={citiesMap}
        updateCurrentCityId={actions.updateCurrentCityId}
        toggleMultiCityView={actions.toggleMultiCityView}
      />
    )
  }

  function renderSelectedView() {
    return dimensionView === 'card' ? renderCards() : renderTables()
  }
  return (
    <>
      <h3>
        <span className="considerationsTableTitle--desktop">
          <FormattedMessage id={`dimensions.${selectedDimension}`} />{' '}
          <FormattedMessage id="dashboard.considerations.table" />
        </span>
        <span className="considerationsTableTitle--mobile">Considerations</span>
        <div className="considerationsTableButtons">
          <Button
            className={`considerationsTableButton ${
              dimensionView === 'number' ? 'active' : ''
            }`}
            onClick={() => setDimensionView('number')}
          >
            <FontAwesomeIcon icon={faTh} />
          </Button>
          <Button
            className={`considerationsTableButton ${
              dimensionView === 'bar' ? 'active' : ''
            }`}
            onClick={() => setDimensionView('bar')}
          >
            <FontAwesomeIcon icon={faListUl} />
          </Button>
          <Button
            className={`considerationsTableButton ${
              dimensionView === 'card' ? 'active' : ''
            }`}
            onClick={() => setDimensionView('card')}
          >
            <Icon
              style={{
                fontSize: '20px',
              }}
            >
              view_module
            </Icon>
          </Button>
        </div>
      </h3>
      {renderSelectedView()}
    </>
  )
}

export default DimensionData
