import { ColoredScore } from '../../components'
import { renderColor } from '../../constants/Constants'
import './InfoDonut.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Fade from '@material-ui/core/Fade'
import Tooltip from '@material-ui/core/Tooltip'
import React from 'react'
import { Doughnut } from 'react-chartjs-2'
import { FormattedMessage } from 'react-intl'

export const InfoDonut = ({
  size = 100,
  cutoutPercentage = 80,
  actual,
  target = 0,
  actualColor = '#4f91f9',
  targetColor = '#335b9b',
  backCircleColor = '#444',
  targetDate,
  displayText,
  textSize = '36px',
  overall = false,
  weights = [20, 20, 20, 20, 20],
  customFaIcon,
}) => {
  const [effectiveTarget, setEffectiveTarget] = React.useState(
    target ? Math.max(target - actual, 0) : 0
  )

  React.useEffect(() => {
    setEffectiveTarget(target ? Math.max(target - actual, 0) : 0)
  }, [actual, target])

  function renderOverall() {
    const dataArr = []
    const colorArr = []
    if (target) {
      dataArr.push(effectiveTarget)
      colorArr.push('rgba(0,0,0,0.2)')
    }
    dataArr.push(100 - actual - effectiveTarget)
    colorArr.push('rgba(0,0,0,0)')
    const data = {
      datasets: [
        {
          data: dataArr,
          backgroundColor: colorArr,
          borderWidth: [0, 0, 0, 0, 0, 0, 0],
        },
      ],
    }
    const options = {
      legend: {
        display: false,
      },
      tooltips: {
        enabled: false,
      },
      cutoutPercentage: cutoutPercentage,
      circumference: 2 * Math.PI,
    }
    const innerCircleData = {
      datasets: [
        {
          data: [100],
          backgroundColor: [backCircleColor],
          hoverBackgroundColor: [backCircleColor],
          borderWidth: [0],
        },
      ],
    }
    const innerCircleOptions = {
      legend: {
        display: false,
      },
      tooltips: {
        enabled: false,
      },
      cutoutPercentage: cutoutPercentage,
      circumference: 2 * Math.PI,
      animation: {
        duration: 0,
      },
    }
    const tooltip = !target ? (
      ''
    ) : (
      <div className="donutTooltip">
        <div className="leftTooltip">
          <span>
            <FormattedMessage id="tooltip.goal" />
          </span>
          {targetDate && (
            <span className="subText">
              <FormattedMessage id="tooltip.by" /> {targetDate}{' '}
            </span>
          )}
        </div>
        <div className="rightTooltip">
          <span>
            <ColoredScore
              value={target}
              backgroundColor={renderColor(0).main}
              fontSize={'18px'}
              width="45px"
              height="35px"
            />
          </span>
        </div>
      </div>
    )
    return (
      <Tooltip
        title={tooltip}
        placement="top"
        TransitionComponent={Fade}
        classes={{ popper: 'show', tooltip: 'donutTooltipOuter' }}
      >
        <div
          className="donutWrapper"
          style={{
            height: size + 'px',
            width: size + 'px',
          }}
        >
          <div
            className="innerDonut"
            style={{
              left: -(size * 1) + 'px',
              width: size * 3 + 'px',
            }}
          >
            <Doughnut
              data={innerCircleData}
              height={100}
              options={innerCircleOptions}
            />
          </div>
          <div
            className="innerDonut"
            style={{
              left: -(size * 1) + 'px',
              width: size * 3 + 'px',
            }}
          >
            <Doughnut data={data} height={100} options={options} />
          </div>
          <span
            style={{
              fontSize: isNaN(textSize) ? textSize : textSize + 'px',
            }}
            className="innerDonutText"
          >
            {customFaIcon ? (
              <FontAwesomeIcon
                icon={customFaIcon}
                style={{ fontSize: '15px', margin: '3px 0' }}
              />
            ) : (
              displayText || actual
            )}
          </span>
        </div>
      </Tooltip>
    )
  }

  function renderNormal() {
    const data = {
      datasets: [
        {
          data: [actual, effectiveTarget, 100 - actual - effectiveTarget],
          backgroundColor: [actualColor, targetColor, 'rgba(0,0,0,0)'],
          borderWidth: [0, 0, 0],
          hoverBackgroundColor: [actualColor, targetColor],
        },
      ],
    }
    const options = {
      legend: {
        display: false,
      },
      tooltips: {
        enabled: false,
      },
      cutoutPercentage: cutoutPercentage,
      circumference: 2 * Math.PI,
    }
    const innerCircleData = {
      datasets: [
        {
          data: [100],
          backgroundColor: [backCircleColor],
          hoverBackgroundColor: [backCircleColor],
          borderWidth: [0],
        },
      ],
    }
    const innerCircleOptions = {
      legend: {
        display: false,
      },
      tooltips: {
        enabled: false,
      },
      cutoutPercentage: cutoutPercentage,
      circumference: 2 * Math.PI,
      animation: {
        duration: 0,
      },
    }
    const tooltip = !target ? (
      ''
    ) : (
      <div className="donutTooltip">
        <div className="leftTooltip">
          <span>
            <FormattedMessage id="tooltip.goal" />
          </span>
          {targetDate && (
            <span className="subText">
              <FormattedMessage id="tooltip.by" /> {targetDate}{' '}
            </span>
          )}
        </div>
        <div className="rightTooltip">
          <span>
            <ColoredScore
              value={target}
              backgroundColor={actualColor}
              fontSize={'18px'}
              width="45px"
              height="35px"
            />
          </span>
        </div>
      </div>
    )
    return (
      <Tooltip
        title={tooltip}
        placement="top"
        TransitionComponent={Fade}
        classes={{ popper: 'show', tooltip: 'donutTooltipOuter' }}
      >
        <div
          className="donutWrapper"
          style={{
            height: size + 'px',
            width: size + 'px',
          }}
        >
          <div
            className="innerDonut"
            style={{
              left: -(size * 1) + 'px',
              width: size * 3 + 'px',
            }}
          >
            <Doughnut
              data={innerCircleData}
              height={100}
              options={innerCircleOptions}
            />
          </div>
          <div
            className="innerDonut"
            style={{
              left: -(size * 1) + 'px',
              width: size * 3 + 'px',
            }}
          >
            <Doughnut data={data} height={100} options={options} />
          </div>
          <span
            style={{
              fontSize: isNaN(textSize) ? textSize : textSize + 'px',
            }}
            className="innerDonutText"
          >
            {' '}
            {displayText || actual}{' '}
          </span>
        </div>
      </Tooltip>
    )
  }
  if (!overall) {
    return renderNormal()
  }
  return renderOverall()
}
