import * as types from '../constants/ActionTypes'
import { gAnalytics } from '../helpers/analytics'
import { fromJS } from 'immutable'

const initialState = fromJS({
  allMilestones: [],
})

export default (state = initialState, action) => {
  let newState = state

  switch (action.type) {
    case types.GET_MILESTONES_USER_CITY:
      if (fromJS(action.payload)) {
        newState = state.set('allMilestones', fromJS(action.payload))
      }
      return newState
    case types.CREATE_MILESTONE:
      gAnalytics('event', ['Milestone', 'create', action.payload.title])
      newState = newState.update('allMilestones', (allMilestones) =>
        allMilestones.push(fromJS(action.payload))
      )
      return newState
    case types.DELETE_MILESTONE:
      gAnalytics('event', ['Milestone', 'delete', action.payload.title])
      newState = newState.update('allMilestones', (allMilestones) => {
        return allMilestones.filter((m) => m.get('id') !== action.payload.id)
      })
      return newState
    case types.UPDATE_MILESTONE:
      const index = newState
        .get('allMilestones')
        .findLastIndex((x) => x.get('id') === action.payload.id)
      gAnalytics('event', ['Milestone', 'update', action.payload.title])
      newState = newState.setIn(
        ['allMilestones', index, 'title'],
        action.payload.title
      )
      newState = newState.setIn(
        ['allMilestones', index, 'date'],
        action.payload.date
      )
      return newState
    case types.LOGOUT_USER:
      if (action.payload.LogoutUser.success) return initialState
      return state
    default:
      return state
  }
}
