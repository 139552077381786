import Icon from '../../components/Icon/Icon'
import './HexProgress.scss'
import PropTypes from 'prop-types'
import React from 'react'
import { CSSTransition } from 'react-transition-group'

function shadeColor(color, percent) {
  let R = parseInt(color.substring(1, 3), 16)
  let G = parseInt(color.substring(3, 5), 16)
  let B = parseInt(color.substring(5, 7), 16)
  R = parseInt((R * (100 + percent)) / 100, 10)
  G = parseInt((G * (100 + percent)) / 100, 10)
  B = parseInt((B * (100 + percent)) / 100, 10)
  R = R < 255 ? R : 255
  G = G < 255 ? G : 255
  B = B < 255 ? B : 255
  const RR = R.toString(16).length === 1 ? '0' + R.toString(16) : R.toString(16)
  const GG = G.toString(16).length === 1 ? '0' + G.toString(16) : G.toString(16)
  const BB = B.toString(16).length === 1 ? '0' + B.toString(16) : B.toString(16)
  return '#' + RR + GG + BB
}

export const HexProgress = ({
  height = 100,
  backgroundColor = '#313539',
  mainColor = '#02bfe0',
  percent = 0,
  icon = 'Efficiency',
  secondaryColor = '',
}) => {
  const [mounted, setMounted] = React.useState(0)

  React.useEffect(() => {
    setMounted(1)
  }, [])

  React.useEffect(() => {
    if (mounted === 1) {
      setMounted(2)
    }
  }, [mounted])

  const width = height / 0.866
  const maskSquareSize = (2 * width) / 3
  const fillHeight = (percent * height) / 100
  const iconName = icon
    ? icon.replace('+', 'and').replace(/\s+/g, '-').toLowerCase()
    : 'efficiency'
  return (
    <div
      className="HexProgress"
      style={{
        height: height + 'px',
        width: width + 'px',
        background: secondaryColor || shadeColor(mainColor, 35),
      }}
    >
      <CSSTransition
        in={mounted === 2}
        classNames="fillT"
        unmountOnExit
        timeout={1200}
      >
        <div
          className="fill"
          style={{
            height: fillHeight + 'px',
            width: width + 'px',
            background: mainColor,
          }}
        />
      </CSSTransition>
      <div
        className="trdiv"
        style={{
          height: maskSquareSize + 'px',
          width: maskSquareSize + 'px',
          background: backgroundColor,
        }}
      />
      <div
        className="tldiv"
        style={{
          height: maskSquareSize + 'px',
          width: maskSquareSize + 'px',
          background: backgroundColor,
        }}
      />
      <div
        className="brdiv"
        style={{
          height: maskSquareSize + 'px',
          width: maskSquareSize + 'px',
          background: backgroundColor,
        }}
      />
      <div
        className="bldiv"
        style={{
          height: maskSquareSize + 'px',
          width: maskSquareSize + 'px',
          background: backgroundColor,
        }}
      />
      <Icon name={iconName} className="icon" />
    </div>
  )
}

HexProgress.propTypes = {
  height: PropTypes.number,
  mainColor: PropTypes.string,
  secondaryColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  percent: PropTypes.number,
}
