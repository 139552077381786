import LanguageContext, {
  initialLanguage,
  getLanguageFile,
  getLocale,
  updateLocale,
} from './LanguageContext'
import React, { useEffect, useState } from 'react'
import { IntlProvider } from 'react-intl'

export const LanguageProvider = ({ children, userId }) => {
  const [language, setLanguage] = useState(initialLanguage)

  useEffect(() => {
    const restoredLanguage = getLanguageFile(getLocale(userId))
    if (restoredLanguage) {
      setLanguage(restoredLanguage)
    }
  }, [userId])

  const saveLanguage = (locale) => {
    updateLocale({ locale, userId })
    setLanguage(getLanguageFile(locale))
  }

  const value = React.useMemo(
    () => ({
      language,
      saveLanguage,
    }),
    [userId, language.locale]
  )

  return (
    <LanguageContext.Provider value={value}>
      <IntlProvider
        locale={language.locale ?? 'en'}
        defaultLocale="en"
        messages={language.messages}
      >
        {children}
      </IntlProvider>
    </LanguageContext.Provider>
  )
}

export default LanguageProvider
