import client from '../apollo'

const query = async (client_query, tag, variables = {}, options = {}) => {
  let data

  if (client_query === 'mutate') {
    data = await client.mutate({
      mutation: tag,
      variables: variables,
      ...options,
    })
  } else if (client_query === 'query') {
    data = await client.query({
      query: tag,
      variables: variables,
      ...options,
    })
  }
  return data
}

export default query
