import {
  getIndicatorChartData,
  clearIndicatorChartData,
} from '../../actions/PageActions.js'
import {
  considerationToDimension,
  dimensionToColor,
  getSelectedQuarterString,
  parseSelectedQuarterValue,
  getConsiderationByDimensionKey,
} from '../../constants/Constants.js'
import { CitiIQCard } from '../CitiIQCard/CitiIQCard.js'
import { LoadingScreen } from '../LoadingScreen/LoadingScreen.js'
import HorizontalBar from './HorizontalBar.js'
import './IndicatorChart.scss'
import {
  Button,
  FormControlLabel,
  Input,
  MenuItem,
  Select,
  Switch,
  Typography,
} from '@material-ui/core'
import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

const IndicatorChart = ({ pageStore, userStore, intl, actions }) => {
  const [searchKey, setSearchKey] = React.useState('')
  const [selectedDimension, setSelectedDimenion] = React.useState(0)
  const [selectedConsideration, setSelectedConsideration] = React.useState(0)
  const [filteredChartData, setFilteredChartData] = React.useState([])
  const [mapByCid, setMapByCid] = React.useState({})
  const [chartHeight, setChartHeight] = React.useState(0)

  const [chartDataWithPrevQuarters, setChartDataWithPrevQuarters] =
    React.useState([])

  const [mapByIidWithPrevQuarters, setMapByIidWithPrevQuarters] =
    React.useState({})
  const [isShowingPrevQuarters, setIsShowingPrevQuarters] =
    React.useState(false)
  const isFilterApplied =
    (searchKey !== '' && searchKey.length >= 3) ||
    selectedDimension !== 0 ||
    selectedConsideration !== 0

  const allQuarters = pageStore.getIn(['allQuarters']).toJS()

  React.useEffect(() => {
    actions.clearIndicatorChartData()

    const selectedQ = userStore.getIn(['selectedQuarter'])
    const { year, quarter } = parseSelectedQuarterValue(selectedQ)
    const selectedCityId = userStore.getIn(['selectedCityId'])

    let total = 4 // we want to show 4 quarters (4 snapshots)
    let foundSnapshot = false // we want to start from the current quarter
    let snapshotToAdd = total // counter to check if we have to add more snapshots
    const prevQuartersList = allQuarters.filter((q, idx) => {
      if (year === q.year && quarter === q.quarter) {
        foundSnapshot = true // we found the current quarter
      }
      if (
        foundSnapshot && // check if we found the current quarter
        snapshotToAdd > 0 // check if we have to add more snapshots
      ) {
        snapshotToAdd-- // decrement the counter
        return true // add the snapshot
      }
      return false
    })
    prevQuartersList.forEach((q) => {
      actions.getIndicatorChartData(selectedCityId, q.year, q.quarter)
    })
  }, [
    userStore.getIn(['selectedQuarter']),
    userStore.getIn(['selectedCityId']),
  ])

  React.useEffect(() => {
    const chartData = pageStore.getIn(['indicatorChartData']).toJS()
    if (chartData.length === 0) return
    let mapCid = {}
    let mapIid = {}
    if ((chartData ?? []).length === 0) return
    chartData.forEach(({ indicatorWeights, year, quarter }, idx) => {
      const q = getSelectedQuarterString({ year, quarter })
      ;(indicatorWeights ?? []).forEach((w) => {
        const item = {
          i: w.i,
          c: w.c,
          w: w.w,
        }
        if (idx === 0) {
          if (!mapCid[item.c]) {
            mapCid[item.c] = []
          }
          mapCid[item.c].push(item)
        }
        if (!mapIid[q]) {
          mapIid[q] = {}
        }
        if (!mapIid[q][item.i]) {
          mapIid[q] = {
            ...mapIid[q],
            [item.i]: [],
          }
        }
        mapIid[q][item.i].push(item)
      })
    })
    setMapByCid(mapCid)
    setMapByIidWithPrevQuarters(mapIid)
  }, [pageStore.getIn(['indicatorChartData'])])
  React.useEffect(() => {
    const selectedQuarter = userStore.getIn(['selectedQuarter'])
    const chartData = pageStore.getIn(['indicatorChartData']).toJS()
    if ((chartData ?? []).length === 0) return
    if (Object.keys(mapByIidWithPrevQuarters).length === 0) return
    let allIndicators = []
    chartData.forEach(({ indicators, year, quarter }) => {
      allIndicators = [
        ...allIndicators,
        ...(indicators ?? []).map((w) => ({
          id: w.id,
          value: w.value,
          year,
          quarter,
          quarterString: getSelectedQuarterString({ year, quarter }),
          isSelectedQuarter:
            getSelectedQuarterString({ year, quarter }) === selectedQuarter,
        })),
      ]
    })
    const data = allIndicators
      .map((i) => ({
        ...i,
        name: `${intl.formatMessage({ id: `indicators.name.${i.id}` })}`,
        description: intl.formatMessage({
          id: `indicators.description.${i.id}`,
        }),
        value: Number(i.value) * 100,
        affectedConsiderations:
          mapByIidWithPrevQuarters && mapByIidWithPrevQuarters[i.quarterString]
            ? mapByIidWithPrevQuarters[i.quarterString][i.id]
            : [],
      }))
      .sort((a, b) => {
        return a.name.localeCompare(b.name)
      })
    setChartDataWithPrevQuarters(data)
  }, [
    intl,
    mapByIidWithPrevQuarters,
    pageStore.getIn(['indicatorChartData']),
    userStore.getIn(['selectedQuarter']),
  ])

  React.useEffect(() => {
    if (!isFilterApplied) {
      setFilteredChartData([])
      return
    }
    let filtered = [...chartDataWithPrevQuarters]
    if (!isShowingPrevQuarters) {
      filtered = filtered.filter((d) => d.isSelectedQuarter)
    }
    if (searchKey !== '' && searchKey.length >= 3) {
      filtered = filtered.filter((i) => {
        return `${i.name} ${i.description}`
          .toLowerCase()
          .includes(searchKey.toLowerCase())
      })
    }
    if (selectedConsideration !== 0) {
      filtered = filtered.filter((i) => {
        return i.affectedConsiderations.some(
          (c) => String(c.c) === String(selectedConsideration)
        )
      })
    }
    if (selectedDimension !== 0) {
      filtered = filtered.filter((i) => {
        return i.affectedConsiderations.some(
          (c) =>
            String(considerationToDimension[c.c].dimension) ===
            String(selectedDimension)
        )
      })
    }
    setFilteredChartData(filtered)
  }, [
    searchKey,
    selectedDimension,
    selectedConsideration,
    chartDataWithPrevQuarters,
    isFilterApplied,
    isShowingPrevQuarters,
  ])

  React.useEffect(() => {
    let data = isFilterApplied ? filteredChartData : chartDataWithPrevQuarters
    if (!isShowingPrevQuarters) {
      data = data.filter((d) => d.isSelectedQuarter)
    }
    const h =
      data.length *
      (isShowingPrevQuarters && !hasOnlyOneQuarterAvailable ? 15 : 30)
    setChartHeight(h < 250 ? 250 : h)
  }, [
    filteredChartData,
    chartDataWithPrevQuarters,
    isFilterApplied,
    isShowingPrevQuarters,
  ])

  const mayFilterDimensionIds = (items) => {
    if (selectedConsideration) {
      return [considerationToDimension[selectedConsideration].dimension]
    }
    return items
  }

  const mayFilterConsiderationIds = (items) => {
    if (selectedDimension) {
      return getConsiderationByDimensionKey(selectedDimension, true)
    } else if (selectedConsideration) {
      return getConsiderationByDimensionKey(
        considerationToDimension[selectedConsideration].dimension,
        true
      )
    }
    return items
  }

  const hasOnlyOneQuarterAvailable =
    pageStore.getIn(['indicatorChartData']).toJS().length === 1
  const chart = (
    <div style={{ height: '100%', width: '100%' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: '-2rem 1rem 2rem',
        }}
      >
        <div />
        <Button
          style={{ backgroundColor: '#9EC73E' }}
          onClick={() => {
            setSelectedConsideration(0)
            setSelectedDimenion(0)
            setSearchKey('')
          }}
        >
          <FormattedMessage id="indicator.chart.clearFilters" />
        </Button>
      </div>
      <div
        className="chartHelperWrapper"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: '1rem',
        }}
      >
        <div className="chartHelper">
          <div>
            <Typography variant="subtitle">
              <FormattedMessage id="indicator.chart.searchBy" />
            </Typography>
            <Input
              fullWidth
              value={searchKey}
              onChange={(e) => setSearchKey(e.target.value)}
              placeholder={intl.formatMessage({
                id: 'indicator.chart.searchPlaceholder',
              })}
            />
          </div>
        </div>
        <div className="chartHelper">
          <div className="select">
            <Typography variant="subtitle">
              {' '}
              <FormattedMessage id="indicator.chart.dimension" />{' '}
            </Typography>
            {selectedConsideration ? (
              <>
                <div>
                  <span
                    style={{
                      display: 'inline-block',
                      width: '10px',
                      height: '10px',
                      backgroundColor:
                        dimensionToColor[
                          considerationToDimension[selectedConsideration]
                            ?.dimension
                        ].main,
                      borderRadius: '50%',
                      marginRight: '5px',
                    }}
                  />
                  <FormattedMessage
                    id={`dimensions.${considerationToDimension[selectedConsideration]?.dimension}`}
                    description="option-text"
                  />
                </div>
              </>
            ) : (
              <Select
                value={selectedDimension}
                onChange={(e) => {
                  setSelectedDimenion(e.target.value)
                  setSelectedConsideration(0)
                }}
                inputProps={{
                  name: 'selectedDimension',
                }}
                disableUnderline
                autoWidth
                classes={{ select: 'dropdown-select' }}
              >
                <MenuItem key={0} value={0}>
                  --
                </MenuItem>
                {mayFilterDimensionIds([1, 2, 3, 4, 5]).map((d) => (
                  <MenuItem key={d} value={d}>
                    <span
                      style={{
                        display: 'inline-block',
                        width: '10px',
                        height: '10px',
                        backgroundColor: dimensionToColor[d].main,
                        borderRadius: '50%',
                        marginRight: '5px',
                      }}
                    />
                    <FormattedMessage
                      id={`dimensions.${d}`}
                      description="option-text"
                    />
                  </MenuItem>
                ))}
              </Select>
            )}
          </div>
          <div className="select">
            <Typography variant="subtitle">
              <FormattedMessage id="indicator.chart.consideration" />{' '}
            </Typography>
            <Select
              value={selectedConsideration}
              onChange={(e) => {
                setSelectedConsideration(e.target.value)
                setSelectedDimenion(0)
              }}
              inputProps={{
                name: 'selectedConsideration',
              }}
              disableUnderline
              autoWidth
              classes={{ select: 'dropdown-select' }}
            >
              <MenuItem key={0} value={0}>
                --
              </MenuItem>
              {mayFilterConsiderationIds(Object.keys(mapByCid)).map((c) => (
                <MenuItem key={c} value={c}>
                  <span
                    style={{
                      display: 'inline-block',
                      width: '10px',
                      height: '10px',
                      backgroundColor:
                        dimensionToColor[considerationToDimension[c].dimension]
                          .main,
                      borderRadius: '50%',
                      marginRight: '5px',
                    }}
                  />
                  <FormattedMessage
                    id={`considerations.${c}`}
                    description="option-text"
                  />
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>
      </div>
      <div style={{ margin: '0 1rem' }}>
        <FormControlLabel
          control={
            hasOnlyOneQuarterAvailable ? (
              <div style={{ width: '1rem' }} />
            ) : (
              <Switch
                checked={isShowingPrevQuarters}
                onChange={(e) => {
                  setIsShowingPrevQuarters(e.target.checked)
                }}
              />
            )
          }
          label={intl.formatMessage({
            id: hasOnlyOneQuarterAvailable
              ? 'indicator.chart.noPreviousQuarters'
              : 'indicator.chart.showPreviousQuarters',
          })}
        />
      </div>
      <div className="horizontalChart">
        {isFilterApplied && filteredChartData.length === 0 ? (
          <Typography
            style={{
              textAlign: 'center',
              margin: '2rem 0',
            }}
          >
            <FormattedMessage id="indicator.chart.notFound" />
          </Typography>
        ) : (
          <HorizontalBar
            isShowingPrevQuarters={isShowingPrevQuarters}
            quartersCount={
              pageStore.getIn(['indicatorChartData']).toJS().length
            }
            searchKey={searchKey.length >= 3 ? searchKey : ''}
            data={
              isFilterApplied
                ? filteredChartData
                : isShowingPrevQuarters
                ? chartDataWithPrevQuarters
                : chartDataWithPrevQuarters.filter((d) => d.isSelectedQuarter)
            }
            selectedConsideration={selectedConsideration}
            chartHeight={chartHeight}
          />
        )}
      </div>
    </div>
  )

  return (
    <CitiIQCard
      height={chartHeight + 400}
      className="dimension-comparison-wrapper"
    >
      <h3 className="chartHeader">
        <FormattedMessage
          id="indicator.comparison.navigation"
          description="header"
        />
      </h3>
      {pageStore.getIn(['isLoadingSingleCityInfo']) ? (
        <>
          <LoadingScreen />
        </>
      ) : (
        <div className="comparisonChartDiv">{chart}</div>
      )}
    </CitiIQCard>
  )
}

function mapStateToProps(state) {
  return {
    pageStore: state.PageStore,
    userStore: state.UserStore,
  }
}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        getIndicatorChartData,
        clearIndicatorChartData,
      },
      dispatch
    ),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(IndicatorChart))
