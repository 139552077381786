import {
  INIT_USER,
  LOGIN_USER,
  LOGOUT_USER,
  REGISTER_USER,
  TOGGLE_LOADING_USER,
  GET_COMPANY_PROFILE,
  VIEWED_INTRO,
  UPDATE_USER,
  UPDATE_PASSWORD,
  GET_ALL_CITIES,
  UPDATE_SELECTED_CITY,
  UPDATE_SELECTED_QUARTER,
  USER_FILTERED_CITIES_KEY,
  GET_USER_FILTERED_CITIES,
  SET_USER_FILTERED_CITIES,
  OPEN_FORCE_LOGOUT_DIALOG,
  TOGGLE_ATTEMPT_LOGIN,
} from '../constants/ActionTypes'
import {
  INIT_USER_QUERY,
  LOGIN_USER_QUERY,
  LOGOUT_USER_QUERY,
  REGISTER_USER_QUERY,
  GET_COMPANY_PROFILE_QUERY,
  FORGOT_PASSWORD_QUERY,
  RESET_PASSWORD_QUERY,
  VALIDATE_TOKEN_QUERY,
  VIEWED_INTRO_QUERY,
  CHECK_EMAIL_AVAILABILITY,
  UPDATE_USER_QUERY,
  UPDATE_PASSWORD_QUERY,
  GET_ALL_CITIES_QUERY,
  DELETE_USER_QUERY,
} from '../constants/QueryTypes'
import { hasUserSessionError } from '../helpers/errorHandler'
import query from '../services/GraphQLService'

export const toggleLoadingUser = () => (dispatch) => {
  dispatch({ type: TOGGLE_LOADING_USER })
}
export const deleteUser = (id) => {
  return new Promise((resolve, reject) => {
    query('mutate', DELETE_USER_QUERY, { userId: id })
      .then((response) => {
        resolve(response.data)
      })
      .catch((e) => {
        reject(e)
      })
  })
}

export const userInit = () => (dispatch) => {
  dispatch({ type: TOGGLE_LOADING_USER })
  return new Promise((resolve, reject) => {
    query('query', INIT_USER_QUERY)
      .then(({ data, errors }) => {
        if (errors && hasUserSessionError(errors)) {
          // dispatch({ type: OPEN_FORCE_LOGOUT_DIALOG })
          reject(errors)
          return
        }
        dispatch({ type: INIT_USER, payload: data })
        dispatch({ type: TOGGLE_LOADING_USER })
        resolve(data)
      })
      .catch((e) => {
        dispatch({ type: TOGGLE_LOADING_USER })
        reject(e)
      })
  })
}

export const loginUser = (userEmail, userPassword, origin) => (dispatch) => {
  dispatch({ type: TOGGLE_ATTEMPT_LOGIN })
  return new Promise((resolve, reject) => {
    query('query', LOGIN_USER_QUERY, { userEmail, userPassword })
      .then((response) => {
        if (origin) {
          dispatch({
            type: LOGIN_USER,
            payload: { ...response.data, origin },
          })
        } else {
          dispatch({ type: LOGIN_USER, payload: response.data })
        }
        dispatch({ type: TOGGLE_ATTEMPT_LOGIN })
        resolve(response)
      })
      .catch((e) => {
        dispatch({ type: TOGGLE_ATTEMPT_LOGIN })
        reject(e)
      })
  })
}

export const logoutUser = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    query('query', LOGOUT_USER_QUERY)
      .then((response) => {
        document.cookie = 'CitiIQ=; Max-Age=0'
        dispatch({ type: LOGOUT_USER, payload: response.data })
        resolve(response)
      })

      .catch((e) => {
        reject(e)
      })
  })
}

export const registerUser =
  (
    companyName,
    address1,
    address2,
    city,
    province,
    country,
    postalCode,
    firstName,
    lastName,
    password,
    email,
    phone,
    occupation,
    registerByAdmin,
    role = 1
  ) =>
  (dispatch) => {
    return new Promise((resolve, reject) => {
      query('mutate', REGISTER_USER_QUERY, {
        companyName,
        address1,
        address2,
        city,
        province,
        country,
        postalCode,
        firstName,
        lastName,
        password,
        email,
        phone,
        occupation,
        registerByAdmin,
        role,
      })
        .then((response) => {
          dispatch({ type: REGISTER_USER, payload: response.data })
          resolve(response)
        })
        .catch((e) => {
          reject(e)
        })
    })
  }

export const forgotPassword = (email) => (dispatch) => {
  return new Promise((resolve, reject) => {
    query('mutate', FORGOT_PASSWORD_QUERY, { email })
      .then((response) => {
        resolve(response.data.forgotPassword)
      })
      .catch((e) => {
        reject(e)
      })
  })
}

export const resetPassword = (resetToken, password) => (dispatch) => {
  return new Promise((resolve, reject) => {
    query('mutate', RESET_PASSWORD_QUERY, { resetToken, password })
      .then((response) => {
        resolve(response.data.resetPassword)
      })
      .catch((e) => {
        reject(e)
      })
  })
}

export const validateToken = (resetToken) => (dispatch) => {
  return new Promise((resolve, reject) => {
    query('mutate', VALIDATE_TOKEN_QUERY, { resetToken })
      .then((response) => {
        resolve(response.data.validateToken)
      })
      .catch((e) => {
        reject(e)
      })
  })
}

export const getCompanyProfile = (id) => (dispatch) => {
  query('query', GET_COMPANY_PROFILE_QUERY, { id }).then(({ data, errors }) => {
    if (errors && hasUserSessionError(errors)) {
      // dispatch({ type: OPEN_FORCE_LOGOUT_DIALOG })
      return
    }
    dispatch({
      type: GET_COMPANY_PROFILE,
      payload: data.GetUserCompanyProfile,
    })
  })
}

export const checkDuplicateEmail = (email) => (dispatch) => {
  return new Promise((resolve, reject) => {
    query('query', CHECK_EMAIL_AVAILABILITY, { email })
      .then((response) => {
        resolve(response)
      })
      .catch((e) => {
        reject(e)
      })
  })
}

export const viewedIntro = (userId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    query('mutate', VIEWED_INTRO_QUERY, { userId })
      .then(({ data, errors }) => {
        if (errors && hasUserSessionError(errors)) {
          // dispatch({ type: OPEN_FORCE_LOGOUT_DIALOG })
          return
        }
        dispatch({ type: VIEWED_INTRO, payload: data.viewedIntro })
        resolve(data.viewedIntro.viewed_intro)
      })
      .catch((e) => {
        reject(e)
      })
  })
}

export const updatePassword = (id, oldPassword, newPassword) => (dispatch) => {
  return new Promise((resolve, reject) => {
    query('mutate', UPDATE_PASSWORD_QUERY, { id, oldPassword, newPassword })
      .then(({ data, errors }) => {
        dispatch({
          type: UPDATE_PASSWORD,
          payload: data.updatePassword,
        })
        resolve(data.updatePassword)
      })
      .catch((e) => {
        reject(e)
      })
  })
}

export const updateUser =
  (id, firstName, lastName, occupation, email, phone) => (dispatch) => {
    return new Promise((resolve, reject) => {
      query('mutate', UPDATE_USER_QUERY, {
        id,
        firstName,
        lastName,
        occupation,
        email,
        phone,
      })
        .then(({ data, errors }) => {
          if (errors && hasUserSessionError(errors)) {
            // dispatch({ type: OPEN_FORCE_LOGOUT_DIALOG })
            return
          }
          dispatch({ type: UPDATE_USER, payload: data.updateUser })
          resolve(data.updateUser)
        })
        .catch((e) => {
          reject(e)
        })
    })
  }

export const getAllCities = () => (dispatch) => {
  query('query', GET_ALL_CITIES_QUERY, {}).then(({ data, errors }) => {
    if (errors && hasUserSessionError(errors)) {
      // dispatch({ type: OPEN_FORCE_LOGOUT_DIALOG })
      return
    }
    dispatch({ type: GET_ALL_CITIES, payload: data.AllCities })
  })
}

export const updateCurrentCityId = (id) => (dispatch) => {
  dispatch({ type: UPDATE_SELECTED_CITY, payload: id })
}

export const updateSelectedQuarter = (quarter) => (dispatch) => {
  dispatch({ type: UPDATE_SELECTED_QUARTER, payload: quarter })
}

export const getFilteredCities = (userId) => (dispatch) => {
  const list = localStorage.getItem(`${USER_FILTERED_CITIES_KEY}_${userId}`)
  dispatch({
    type: GET_USER_FILTERED_CITIES,
    payload: JSON.parse(list || '{"list":[]}').list,
  })
}

export const updateFilteredCities = (list, userId) => (dispatch) => {
  const processedList = JSON.stringify({ list: list })
  localStorage.setItem(`${USER_FILTERED_CITIES_KEY}_${userId}`, processedList)
  dispatch({ type: SET_USER_FILTERED_CITIES, payload: list })
}
