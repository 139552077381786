import { toggleMultiCityView } from '../../actions/PageActions'
import { loginUser, updateCurrentCityId } from '../../actions/UserActions'
import citiIQ_background from '../../assets/citiIQ_login_image.jpg'
import logo from '../../assets/citiiq-login-logo.png'
import LanguageSelector from '../../components/PageWrapper/LanguageSelector'
import { gAnalytics } from '../../helpers/analytics'
import { validateLoginOrigins } from '../../helpers/secureLogin'
import './Login.scss'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import React from 'react'
import Helmet from 'react-helmet'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'

const Login = ({ actions, history, userStore, intl }) => {
  const [email, setEmail] = React.useState('')
  const [emailFocus, setEmailFocus] = React.useState(false)
  const [password, setPassword] = React.useState('')
  const [passwordFocus, setPasswordFocus] = React.useState(false)

  React.useEffect(() => {
    if (window) {
      // This event hander will listen for messages from the parent
      window.addEventListener('message', handleMessage)

      // validate the parent window is on our domain
      if (validateLoginOrigins(document.referrer)) {
        // This tells parent window (waybase) that page is ready for login
        window.parent.postMessage('pageLoaded', document.referrer)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window])

  const handleMessage = ({ data, origin }) => {
    if (validateLoginOrigins(origin)) {
      processParentMessage(data, origin) // e.data hold the message
    }
  }

  const processParentMessage = (data, origin) => {
    let login = null

    if (data) {
      try {
        login = JSON.parse(data)
        if (login && login.email && login.secret) {
          actions.loginUser(login.email, login.secret, origin)
          const cityIdFormatted = parseInt(login.cityId)
          if (!isNaN(cityIdFormatted)) {
            actions.toggleMultiCityView(false)
            actions.updateCurrentCityId(cityIdFormatted)
          }
        }
      } catch (e) {
        console.error('Error parsing login data', e)
      }
    }
  }

  const handleInputChange = (e) => {
    switch (e.target.name) {
      case 'email':
        setEmail(e.target.value)
        break
      case 'password':
        setPassword(e.target.value)
        break
      default:
        break
    }
  }

  const handleFocus = (e) => {
    switch (e.target.name) {
      case 'email':
        setEmailFocus(true)
        break
      case 'password':
        setPasswordFocus(true)
        break
      default:
        break
    }
  }

  const handleBlur = (e) => {
    switch (e.target.name) {
      case 'email':
        setEmailFocus(false)
        break
      case 'password':
        setPasswordFocus(false)
        break
      default:
        break
    }
  }

  const loginRequest = () => {
    actions.loginUser(email, password)
  }

  const checkForEnter = (e) => {
    if (e.keyCode === 13) {
      loginRequest()
    }
  }

  const handleRegisterButtonClick = () => {
    gAnalytics('pageview', ['/register'])
    history.push('/register')
  }
  const handleForgotButtonClick = () => {
    gAnalytics('pageview', ['/forgot-password'])
    history.push('/forgot-password')
  }

  return (
    <div className="Login" style={{ backgroundImage: citiIQ_background }}>
      <Helmet>
        <title>CitiIQ - {intl.formatMessage({ id: 'login.login' })}</title>
      </Helmet>
      <img src={citiIQ_background} className="loginBackground" alt="" />
      <div className="loginDiv">
        <img className="siteLogo" src={logo} alt="" />
        <div className="registerDiv">
          <span>
            <FormattedMessage id="register.registerNoAccount" />
          </span>
          <Button
            onClick={() => handleRegisterButtonClick()}
            color="primary"
            classes={{ textPrimary: 'registerButton' }}
          >
            <FormattedMessage id="register.register" />
          </Button>
        </div>
        <React.Fragment>
          <p className="loginHeader">
            {' '}
            <FormattedMessage
              id="login.welcomeMessage"
              description="A Greeting message"
            />{' '}
          </p>
          <p className="loginSubHeader">
            {' '}
            <FormattedMessage
              id="login.loginIntro"
              description="Very Concise site description"
            />{' '}
          </p>
          <div className="loginGridWrapper" onKeyDown={(e) => checkForEnter(e)}>
            <Grid container spacing={16}>
              <Grid item xs={12}>
                <FormattedMessage id="input.emailPlaceholder">
                  {(placeholder) => (
                    <input
                      className="loginInput"
                      name="email"
                      value={email}
                      onFocus={(e) => handleFocus(e)}
                      onChange={(e) => handleInputChange(e)}
                      onBlur={(e) => handleBlur(e)}
                      placeholder={!emailFocus ? placeholder : ''}
                    />
                  )}
                </FormattedMessage>
              </Grid>
              <Grid item xs={12}>
                <FormattedMessage id="input.passwordPlaceholder">
                  {(placeholder) => (
                    <input
                      className="loginInput"
                      name="password"
                      value={password}
                      onFocus={(e) => handleFocus(e)}
                      onChange={(e) => handleInputChange(e)}
                      onBlur={(e) => handleBlur(e)}
                      placeholder={!passwordFocus ? placeholder : ''}
                      type="password"
                    />
                  )}
                </FormattedMessage>
              </Grid>
              <Grid item xs={12}>
                <Button
                  onClick={() => loginRequest()}
                  color="primary"
                  classes={{ textPrimary: 'loginButton' }}
                >
                  <FormattedMessage id="login.login" />
                </Button>
              </Grid>
              <Grid item xs={12}>
                <div className="forgotPass">
                  <span onClick={() => handleForgotButtonClick()}>
                    <FormattedMessage id="login.forgotPass" />
                  </span>
                </div>
              </Grid>
            </Grid>
          </div>
          {!userStore.get('errorMessage') ? null : (
            <div className="errorDiv">
              <span className="errorMessage">
                {userStore.get('errorMessageKey')
                  ? intl.formatMessage({ id: userStore.get('errorMessageKey') })
                  : userStore.get('errorMessage')}
              </span>
            </div>
          )}
        </React.Fragment>
      </div>
      <div className="languageSelectorDiv">
        <div className="selector">
          <LanguageSelector />
        </div>
      </div>
    </div>
  )
}

function mapStateToProps(state) {
  return {
    userStore: state.UserStore,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        loginUser,
        toggleMultiCityView,
        updateCurrentCityId,
      },
      dispatch
    ),
  }
}

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(Login))
)
