import { dimensionToColor } from '../../constants/Constants'
import {
  CustomizedBarChartToolTip,
  CustomizedMultiBarChartToolTip,
  CustomizedXAxisTickConsiderations,
} from './../ChartHelperComponent/ChartHelperComponent.js'
import './ComparisonChart.scss'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

const HorizontalBar = ({
  data,
  dimensionValue,
  isDashboard,
  goalStore,
  hideGoals,
}) => {
  const [chart, setChart] = React.useState(null)

  const chartLegendHeight = 80
  const isMobile = window.innerWidth < 800
  const hasCurrQuarter = data.some((d) => d.curr)
  const hasPrevQuarter = data.some((d) => d.prev_1)
  const hasPrev2Quarter = data.some((d) => d.prev_2)
  const hasPrev3Quarter = data.some((d) => d.prev_3)

  let quarterCount = [
    hasCurrQuarter,
    hasPrevQuarter,
    hasPrev2Quarter,
    hasPrev3Quarter,
  ].filter((i) => Boolean(i)).length
  if (quarterCount > 2 && isDashboard) quarterCount = 2

  let chartHeight = data.length * quarterCount * 15
  chartHeight = (chartHeight < 200 ? 200 : chartHeight) + chartLegendHeight

  React.useEffect(() => {
    if (data.length > 0) {
      setChart(isDashboard ? renderDashboardChart() : renderHorizontalCharts())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isDashboard, dimensionValue])

  const renderLegendValue = (value) => {
    if (value === 'prev') {
      return <FormattedMessage id="legend.prev" description="legend-text" />
    } else if (value === 'curr') {
      return <FormattedMessage id="legend.curr" description="legend-text" />
    } else if (value === 'prevs') {
      return <FormattedMessage id="legend.prevs" description="legend-text" />
    }
  }
  const renderDashboardChart = () => {
    return (
      <div className="horizontalChart">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={1000}
            height={2000}
            layout="vertical"
            data={data}
            margin={{
              top: 20,
              right: isMobile ? 10 : 60,
              left: isMobile ? 100 : 200,
              bottom: 20,
            }}
            barGap={0}
            barSize={8}
          >
            <CartesianGrid stroke="rgba(255,255,255,0.2)" horizontal={false} />
            <XAxis type="number" />
            <YAxis
              dataKey="id"
              type="category"
              tick={<CustomizedXAxisTickConsiderations />}
            />
            <Tooltip content={<CustomizedBarChartToolTip />} />
            <Legend
              iconType="circle"
              align="center"
              verticalAlign="top"
              payload={[
                {
                  id: 'prev',
                  value: renderLegendValue('prev'),
                  type: 'circle',
                  color: '#9E9E9E',
                },
                {
                  id: 'curr',
                  value: renderLegendValue('curr'),
                  type: 'circle',
                  color: dimensionToColor[dimensionValue].main,
                },
              ]}
            />

            {hasCurrQuarter && (
              <Bar
                dataKey="curr"
                fill={dimensionToColor[dimensionValue].main}
              />
            )}
            {hasPrevQuarter && <Bar dataKey="prev_1" fill="#9E9E9E" />}
            {!!goalStore &&
              !hideGoals &&
              goalStore.get('goals').map((g, i) => {
                const goal = g.toJS()
                if (goal.dimension === dimensionValue) {
                  return (
                    <ReferenceLine
                      x={goal.value}
                      strokeWidth={3}
                      stroke={dimensionToColor[goal.dimension].main.main}
                      strokeDasharray="15 8"
                      key={i}
                    />
                  )
                } else {
                  return false
                }
              })}
          </BarChart>
        </ResponsiveContainer>
      </div>
    )
  }

  const renderHorizontalCharts = () => {
    return (
      <ResponsiveContainer width="100%" height={chartHeight}>
        <BarChart
          width={1000}
          height={2000}
          layout="vertical"
          data={data || []}
          margin={{
            top: 20,
            right: isMobile ? 10 : 100,
            left: isMobile ? 100 : 200,
            bottom: 20,
          }}
          barGap={2}
          barSize={10}
        >
          <CartesianGrid stroke="rgba(255,255,255,0.2)" horizontal={false} />
          <XAxis type="number" />
          <YAxis
            // minTickGap={8}
            // tickMargin={5}
            dataKey="id"
            type="category"
            tick={<CustomizedXAxisTickConsiderations />}
          />
          <Tooltip content={<CustomizedMultiBarChartToolTip />} />

          <Legend
            iconType="circle"
            align="center"
            verticalAlign="top"
            height={36}
            payload={[
              {
                id: 'prev',
                value: renderLegendValue('prev'),
                type: 'circle',
                color: '#9E9E9E',
              },
              {
                id: 'curr',
                value: renderLegendValue('curr'),
                type: 'circle',
                color: dimensionToColor[dimensionValue].main,
              },
            ]}
          />
          {hasCurrQuarter && (
            <Bar dataKey="curr" fill={dimensionToColor[dimensionValue].main} />
          )}
          {hasPrevQuarter && <Bar dataKey="prev_1" fill="#9E9E9E" />}
          {hasPrev2Quarter && <Bar dataKey="prev_2" fill="#9E9E9E" />}
          {hasPrev3Quarter && <Bar dataKey="prev_3" fill="#9E9E9E" />}
          {!!goalStore &&
            !hideGoals &&
            goalStore.get('goals').map((g, i) => {
              const goal = g.toJS()
              if (goal.dimension === dimensionValue) {
                return (
                  <ReferenceLine
                    x={goal.value}
                    strokeWidth={3}
                    stroke={dimensionToColor[goal.dimension].main.main}
                    strokeDasharray="15 8"
                    key={i}
                  />
                )
              } else {
                return false
              }
            })}
        </BarChart>
      </ResponsiveContainer>
    )
  }

  return chart ?? null
}

export default HorizontalBar
