import {
  clearConsiderationsComparisons,
  getConsiderationsComparison,
  getDimensionsComparison,
  getQuartersConsiderationsComparison,
} from '../../actions/PageActions'
import { dimensionToColor } from '../../constants/Constants'
import {
  CustomizedAreaChartToolTip,
  CustomizedCircle,
  CustomizedDiamond,
  CustomizedMilestonesCircle,
  CustomizedSqaure,
  CustomizedStar,
  CustomizedTriangle,
} from './../ChartHelperComponent/ChartHelperComponent.js'
import './ComparisonChart.scss'
import { Button, CircularProgress, Grid } from '@material-ui/core'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import {
  Area,
  AreaChart,
  CartesianGrid,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { bindActionCreators } from 'redux'

const ComparisonAreaChart = ({
  showHeader,
  dimension,
  milestones_array,
  pageStore,
  userStore,
  goalStore,
  actions,
}) => {
  const [selectedLines, setSelectedLines] = React.useState([])
  const [animationComplete, setAnimationComplete] = React.useState(false)
  const [dimensionValue, setDimensionValue] = React.useState(dimension ?? 1)
  const [hideGoals, setHideGoals] = React.useState(false)
  const [rawData, setRawData] = React.useState([])
  const [chartData, setChartData] = React.useState([])
  const [refLinesData, setRefLinesData] = React.useState([])
  const [minChartYValue, setMinChartYValue] = React.useState(0)
  const [maxChartYValue, setMaxChartYValue] = React.useState(0)
  const [minChartYValueIncludingGoals, setMinChartYValueIncludingGoals] =
    React.useState(0)
  const [maxChartYValueIncludingGoals, setMaxChartYValueIncludingGoals] =
    React.useState(0)

  const filterSeries = (e) => {
    const clicked = `dimensions.${e.id}`
    if (selectedLines.includes(clicked)) {
      const remove = selectedLines.indexOf(clicked)
      selectedLines.splice(remove, 1)
    } else {
      selectedLines.push(clicked)
    }
    setSelectedLines([...selectedLines])
  }

  React.useEffect(() => {
    if (dimension) setDimensionValue(dimension)
  }, [dimension, milestones_array])

  React.useEffect(() => {
    let refLines = (goalStore?.get('goals') ?? []).map((g) => g.toJS())
    let vals = []
    const addOffset = (v) => {
      let newVal = v + 0.15
      if (vals.includes(newVal)) {
        return addOffset(newVal)
      } else {
        return newVal
      }
    }
    refLines = refLines.map((g) => {
      const v = Number(g.value)
      if (vals.includes(v)) {
        vals.push(addOffset(v))
        return {
          ...g,
          value: addOffset(v),
        }
      } else {
        vals.push(v)
        return g
      }
    })
    setRefLinesData(refLines)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goalStore?.get('lastFetched')])

  React.useEffect(() => {
    const selectedCityId = userStore.getIn(['selectedCityId'])
    const user_id = userStore.getIn(['userInfo', 'id'])

    actions.getDimensionsComparison(selectedCityId, user_id, true)

    setTimeout(() => {
      setAnimationComplete(true)
    }, 1100)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dimension, dimensionValue, userStore])

  React.useEffect(() => {
    setRawData(pageStore.get('dimensionsComparison').toJS())
  }, [pageStore, dimension, dimensionValue])
  React.useEffect(() => {
    const min = hideGoals ? minChartYValue : minChartYValueIncludingGoals
    setChartData(
      rawData.map((item) => ({
        ...item,
        milestone: item.milestone === 0 ? min - 5 : item.milestone,
      }))
    )
  }, [
    rawData,
    hideGoals,
    minChartYValue,
    minChartYValueIncludingGoals,
    goalStore.get('goals'),
  ])
  React.useEffect(() => {
    let min = minChartYValue
    goalStore.get('goals').forEach((g, i) => {
      const goal = g.toJS()
      if (goal.value < min) min = goal.value
    })
    setMinChartYValueIncludingGoals(min)
  }, [minChartYValue, goalStore.get('goals')])
  React.useEffect(() => {
    let max = maxChartYValue
    goalStore.get('goals').forEach((g, i) => {
      const goal = g.toJS()
      if (goal.value > max) max = goal.value
    })
    setMaxChartYValueIncludingGoals(max)
  }, [maxChartYValue, goalStore.get('goals')])

  const isDimensionVisible = (value) => {
    const string = 'dimensions.' + value
    return selectedLines.indexOf(string) === -1
  }

  const renderLegendValue = (value) => {
    if (value === 'prev') {
      return <FormattedMessage id="legend.prev" description="legend-text" />
    } else if (value === 'curr') {
      return <FormattedMessage id="legend.curr" description="legend-text" />
    } else if (value === 'prevs') {
      return <FormattedMessage id="legend.prevs" description="legend-text" />
    } else {
      return (
        <FormattedMessage
          id={'dimensions.' + value}
          description="legend-text"
        />
      )
    }
  }

  const renderColor = (dimension_id) => {
    return dimensionToColor[Number(dimension_id)].main
  }

  const renderFillColor = (dimension_id) => {
    return dimensionToColor[Number(dimension_id)].fill
  }

  React.useEffect(() => {
    const chart_data = pageStore.get('dimensionsComparison')
    let _minChartYValue = 100
    let _maxChartYValue = 0
    chart_data.forEach((cd) => {
      cd = cd.toJS()
      const dimensionScores = []
      Object.keys(cd).forEach((cdKey) => {
        //taking averages to have a more appropriate graph
        if (cdKey.includes('dimension')) {
          dimensionScores.push(Number(cd[cdKey].toFixed(1)))
        }
      })
      const tempMax = Math.floor(Math.max(...dimensionScores)) + 2
      const tempMin = Math.floor(Math.min(...dimensionScores)) - 2
      _maxChartYValue = tempMax > _maxChartYValue ? tempMax : _maxChartYValue
      _minChartYValue = tempMin < _minChartYValue ? tempMin : _minChartYValue
    })
    setMinChartYValue(_minChartYValue)
    setMaxChartYValue(_maxChartYValue)
  }, [pageStore])

  const isPrivateUser = userStore.get('role').toJS().title === 'private_user'

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <div className="areaChart">
        {showHeader && (
          <Grid item xs={6} className="areaHeader">
            <span>
              <FormattedMessage id="consideration.comparison.areaHeader" />
            </span>
          </Grid>
        )}
        {chartData && !isPrivateUser && (
          <Button
            classes={{ root: 'goalsBtn' }}
            onClick={() => setHideGoals(!hideGoals)}
          >
            {hideGoals ? (
              <FormattedMessage id="chart.showGoals" />
            ) : (
              <FormattedMessage id="chart.hideGoals" />
            )}
          </Button>
        )}
        {chartData ? (
          <ResponsiveContainer width="100%" height="100%">
            <AreaChart
              width={600}
              height={300}
              data={chartData}
              margin={{ top: 5, right: 30, left: 0, bottom: 5 }}
            >
              <CartesianGrid stroke="rgba(255,255,255,0.2)" vertical={false} />
              <Legend
                height={75}
                align="center"
                verticalAlign="top"
                onClick={(e) => filterSeries(e)}
                payload={[
                  {
                    id: 1,
                    value: renderLegendValue(1),
                    type: 'circle',
                    color: isDimensionVisible(1) ? renderColor(1) : 'grey',
                  },
                  {
                    id: 2,
                    value: renderLegendValue(2),
                    type: 'square',
                    color: isDimensionVisible(2) ? renderColor(2) : 'grey',
                  },
                  {
                    id: 3,
                    value: renderLegendValue(3),
                    type: 'diamond',
                    color: isDimensionVisible(3) ? renderColor(3) : 'grey',
                  },
                  {
                    id: 4,
                    value: renderLegendValue(4),
                    type: 'triangle',
                    color: isDimensionVisible(4) ? renderColor(4) : 'grey',
                  },
                  {
                    id: 5,
                    value: renderLegendValue(5),
                    type: 'star',
                    color: isDimensionVisible(5) ? renderColor(5) : 'grey',
                  },
                ]}
              />
              <XAxis dataKey="snapshot" />
              <YAxis
                domain={[
                  (hideGoals ? minChartYValue : minChartYValueIncludingGoals) -
                    5,
                  (hideGoals ? maxChartYValue : maxChartYValueIncludingGoals) +
                    5,
                ]}
                allowDecimals={false}
              />
              <Tooltip
                content={
                  <CustomizedAreaChartToolTip
                    milestones_array={milestones_array}
                  />
                }
              />
              <Area
                isAnimationActive={!animationComplete}
                className="line1"
                type="linear"
                strokeWidth={4}
                dataKey={isDimensionVisible(1) ? 'dimension1' : 'dimension1 '}
                stroke={renderColor(1)}
                fill={renderFillColor(1)}
                dot={
                  isDimensionVisible(1) && (
                    <CustomizedCircle colour={renderColor(1)} />
                  )
                }
              />
              <Area
                isAnimationActive={!animationComplete}
                type="linear"
                strokeWidth={4}
                dataKey={isDimensionVisible(2) ? 'dimension2' : 'dimension2 '}
                stroke={renderColor(2)}
                fill={renderFillColor(2)}
                dot={
                  isDimensionVisible(2) && (
                    <CustomizedSqaure colour={renderColor(2)} />
                  )
                }
              />
              <Area
                isAnimationActive={!animationComplete}
                type="linear"
                strokeWidth={4}
                dataKey={isDimensionVisible(3) ? 'dimension3' : 'dimension3 '}
                stroke={renderColor(3)}
                fill={renderFillColor(3)}
                dot={
                  isDimensionVisible(3) && (
                    <CustomizedDiamond colour={renderColor(3)} />
                  )
                }
              />
              <Area
                isAnimationActive={!animationComplete}
                type="linear"
                strokeWidth={4}
                dataKey={isDimensionVisible(4) ? 'dimension4' : 'dimension4 '}
                stroke={renderColor(4)}
                fill={renderFillColor(4)}
                dot={
                  isDimensionVisible(4) && (
                    <CustomizedTriangle colour={renderColor(4)} />
                  )
                }
              />
              <Area
                isAnimationActive={!animationComplete}
                type="linear"
                strokeWidth={4}
                dataKey={isDimensionVisible(5) ? 'dimension5' : 'dimension5 '}
                stroke={renderColor(5)}
                fill={renderFillColor(5)}
                dot={
                  isDimensionVisible(5) && (
                    <CustomizedStar colour={renderColor(5)} />
                  )
                }
              />
              <Area
                isAnimationActive={!animationComplete}
                type="monotone"
                dataKey="milestone"
                dot={<CustomizedMilestonesCircle colour="#9EC73D" />}
              />
              {!hideGoals &&
                refLinesData.map((g, i) => {
                  return isDimensionVisible(g.dimension) ? (
                    <ReferenceLine
                      y={g.value}
                      strokeWidth={2}
                      stroke={renderColor(g.dimension)}
                      fill={renderFillColor(g.dimension)}
                      strokeDasharray="15 8"
                      key={i}
                    />
                  ) : (
                    <div />
                  )
                })}
            </AreaChart>
          </ResponsiveContainer>
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              height: '100%',
              alignItems: 'center',
            }}
          >
            <CircularProgress
              style={{ color: '#b1ff44' }}
              classes={{ root: 'spinner' }}
            />
          </div>
        )}
      </div>
    </div>
  )
}

function mapStateToProps(state) {
  return {
    pageStore: state.PageStore,
    userStore: state.UserStore,
    goalStore: state.GoalStore,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        clearConsiderationsComparisons,
        getConsiderationsComparison,
        getDimensionsComparison,
        getQuartersConsiderationsComparison,
      },
      dispatch
    ),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ComparisonAreaChart)
