import gql from 'graphql-tag'

export const INIT_USER_QUERY = gql`
  query {
    InitUser {
      user {
        id
        company_id
        email
        first_name
        last_name
        occupation
        phone
        viewed_intro
        role_id
      }
      subscriptions {
        id
        city_id
      }
      role {
        id
        title
      }
      exists
      message
      message_key
    }
  }
`

export const LOGIN_USER_QUERY = gql`
  query LoginUser($userEmail: String, $userPassword: String) {
    LoginUser(userEmail: $userEmail, userPassword: $userPassword) {
      user {
        id
        company_id
        email
        first_name
        last_name
        occupation
        phone
        viewed_intro
      }
      subscriptions {
        city_id
      }
      role {
        id
        title
      }
      exists
      message
      message_key
    }
  }
`

export const LOGOUT_USER_QUERY = gql`
  query {
    LogoutUser {
      success
      message
      message_key
    }
  }
`

export const LATEST_CITY_CONSIDERATIONS = gql`
  query LatestCityConsiderations($id: Int) {
    LatestCityConsiderations(id: $id) {
      id
      value
    }
  }
`

export const CHECK_EMAIL_AVAILABILITY = gql`
  query checkDuplicateEmail($email: String) {
    CheckDuplicateEmail(email: $email)
  }
`

export const GET_CITY_PROFILE_QUERY = gql`
  query CityProfile($city_id: Int) {
    CityProfile(city_id: $city_id) {
      sdg
      area
      population
      weather
      umfEnabled
      lang
    }
  }
`

export const ALL_CITY_INFO = gql`
  query LatestCityInformation($id: Int, $year: Int, $quarter: Int) {
    LatestCityInformation(id: $id, year: $year, quarter: $quarter) {
      indicators {
        description
        id
        value
        name
        sensitivity
        source
        citations {
          type
          body
          date
        }
      }
      considerations {
        id
        name
        value
        weightedValue
        weightedValueOld
      }
      dimensions {
        id
        name
        value
        weightedValue
      }
      iq
      iq_quality_star
      indicatorWeights {
        i
        c
        w
      }
      snapshotRevision
      lastUpdated
    }
  }
`

export const REGISTER_USER_QUERY = gql`
  mutation RegisterUser(
    $companyName: String
    $address1: String
    $address2: String
    $city: String
    $province: String
    $country: String
    $postalCode: String
    $firstName: String
    $lastName: String
    $password: String
    $email: String
    $phone: String
    $occupation: String
    $registerByAdmin: Boolean
    $role: Int
  ) {
    registerUser(
      companyName: $companyName
      address1: $address1
      address2: $address2
      city: $city
      province: $province
      country: $country
      postalCode: $postalCode
      firstName: $firstName
      lastName: $lastName
      password: $password
      email: $email
      phone: $phone
      occupation: $occupation
      registerByAdmin: $registerByAdmin
      role: $role
    ) {
      success
      error
      message
      message_key
    }
  }
`

export const FORGOT_PASSWORD_QUERY = gql`
  mutation forgotPassword($email: String) {
    forgotPassword(email: $email) {
      success
      error
      message
      message_key
    }
  }
`

export const RESET_PASSWORD_QUERY = gql`
  mutation resetPassword($resetToken: String, $password: String) {
    resetPassword(resetToken: $resetToken, password: $password) {
      success
      error
      message
      message_key
    }
  }
`

export const VALIDATE_TOKEN_QUERY = gql`
  mutation validateToken($resetToken: String) {
    validateToken(resetToken: $resetToken) {
      success
      error
      message
      message_key
    }
  }
`

export const LATEST_CITY_DIMENSIONS_COMPARISON = gql`
  query LatestCityDimensionsComparison(
    $id: Int
    $user_id: Int
    $milestone: Boolean
  ) {
    LatestCityDimensionsComparison(
      id: $id
      user_id: $user_id
      milestone: $milestone
    ) {
      snapshot
      dimension1
      dimension2
      dimension3
      dimension4
      dimension5
      milestone
    }
  }
`

export const LATEST_CITY_INDICATORS = gql`
  query LatestCityConsiderations($id: Int) {
    LatestCityIndicators(id: $id) {
      id
      value
      name
      sensitivity
      citations {
        type
        body
        date
      }
    }
  }
`

export const LATEST_CITY_CONSIDERATIONS_COMPARISON = gql`
  query LatestCityConsiderationsComparison($id: Int, $dimension_id: Int) {
    LatestCityConsiderationsComparison(id: $id, dimension_id: $dimension_id) {
      id
      prev
      curr
    }
  }
`

export const GET_CITY_QUARTERS_CONSIDERATIONS_COMPARISON = gql`
  query GetCityQuartersConsiderationsComparison(
    $id: Int
    $dimension_id: Int
    $quarters: Int
  ) {
    GetCityQuartersConsiderationsComparison(
      id: $id
      dimension_id: $dimension_id
      quarters: $quarters
    ) {
      id
      curr
      prev_1
      prev_2
      prev_3
      prev_1_time
      prev_2_time
      prev_3_time
      curr_time
    }
  }
`

export const GET_INDICATOR_CHART_DATA = gql`
  query GetIndicatorChartData($id: Int, $year: Int, $quarter: Int) {
    GetIndicatorChartData(id: $id, year: $year, quarter: $quarter) {
      year
      quarter
      indicators {
        id
        value
      }
      indicatorWeights {
        i
        c
        w
      }
    }
  }
`

export const GET_COMPANY_PROFILE_QUERY = gql`
  query GetUserCompanyProfile($id: Int) {
    GetUserCompanyProfile(id: $id) {
      name
      address1
      address2
      city
      province
      country
      postal_code
    }
  }
`

export const GET_MILESTONES_USER_CITY_QUERY = gql`
  query GetMilestonesByUserCity($user_id: Int, $city_id: Int) {
    GetMilestonesByUserCity(user_id: $user_id, city_id: $city_id) {
      id
      title
      date
    }
  }
`

export const UPDATE_MILESTONE_QUERY = gql`
  mutation updateMilestone($id: Int, $title: String, $date: String) {
    updateMilestone(id: $id, title: $title, date: $date) {
      id
      title
      date
    }
  }
`

export const CREATE_MILESTONE_QUERY = gql`
  mutation createMilestone(
    $title: String
    $date: String
    $user_id: Int
    $city_id: Int
  ) {
    createMilestone(
      title: $title
      date: $date
      user_id: $user_id
      city_id: $city_id
    ) {
      id
      title
      date
    }
  }
`

export const DELETE_MILESTONE_QUERY = gql`
  mutation deleteMilestone($id: Int) {
    deleteMilestone(id: $id) {
      success
    }
  }
`
export const DELETE_USER_QUERY = gql`
  mutation deleteUser($userId: Int!) {
    deleteUser(userId: $userId)
  }
`

export const VIEWED_INTRO_QUERY = gql`
  mutation viewedIntro($userId: Int) {
    viewedIntro(userId: $userId) {
      viewed_intro
    }
  }
`
export const GET_GOALS_USER_CITY_QUERY = gql`
  query GetGoalByUserAndCity($user_id: Int, $city_id: Int) {
    GetGoalByUserAndCity(user_id: $user_id, city_id: $city_id) {
      id
      dimension
      goal_value
      goal_date
    }
  }
`

export const UPDATE_GOAL_QUERY = gql`
  mutation updateGoal(
    $user_id: Int
    $dimension: String
    $goal_value: Float
    $goal_date: String
  ) {
    updateGoal(
      user_id: $user_id
      dimension: $dimension
      goal_value: $goal_value
      goal_date: $goal_date
    ) {
      id
      dimension
      goal_value
      goal_date
      user_id
    }
  }
`

export const CREATE_GOAL_QUERY = gql`
  mutation createGoal(
    $dimension: String
    $goal_value: Float
    $goal_date: String
    $city_id: Int
    $user_id: Int
  ) {
    createGoal(
      dimension: $dimension
      goal_value: $goal_value
      goal_date: $goal_date
      city_id: $city_id
      user_id: $user_id
    ) {
      id
      dimension
      goal_value
      goal_date
      user_id
    }
  }
`
export const DELETE_GOAL_QUERY = gql`
  mutation deleteGoal($user_id: Int, $dimension: String) {
    deleteGoal(user_id: $user_id, dimension: $dimension) {
      success
    }
  }
`
export const CREATE_WATCH_CONSIDERATION_QUERY = gql`
  mutation createWatchConsideration(
    $userId: Int
    $considerationId: Int
    $sortOrder: Int
  ) {
    createWatchConsideration(
      userId: $userId
      considerationId: $considerationId
      sortOrder: $sortOrder
    ) {
      id
      consideration_id
      sort_order
    }
  }
`
export const DELETE_WATCH_CONSIDERATION_QUERY = gql`
  mutation deleteWatchConsideration($id: Int) {
    deleteWatchConsideration(id: $id) {
      id
      success
    }
  }
`

export const GET_WATCH_CONSIDERATIONS_QUERY = gql`
  query UserWatchConsiderations {
    UserWatchConsiderations {
      id
      consideration_id
      sort_order
    }
  }
`

export const UPDATE_USER_QUERY = gql`
  mutation updateUser(
    $id: Int
    $firstName: String
    $lastName: String
    $occupation: String
    $email: String
    $phone: String
  ) {
    updateUser(
      id: $id
      firstName: $firstName
      lastName: $lastName
      occupation: $occupation
      email: $email
      phone: $phone
    ) {
      id
      first_name
      last_name
      occupation
      email
      phone
    }
  }
`

export const UPDATE_PASSWORD_QUERY = gql`
  mutation updatePassword(
    $id: Int
    $oldPassword: String
    $newPassword: String
  ) {
    updatePassword(
      id: $id
      oldPassword: $oldPassword
      newPassword: $newPassword
    ) {
      success
      message
      message_key
    }
  }
`
export const UPDATE_SUBSCRIPTION_QUERY = gql`
  mutation updateSubscription($id: Int, $city_id: Int) {
    updateSubscription(id: $id, city_id: $city_id) {
      id
      city_id
      company_id
    }
  }
`

export const DELETE_SUBSCRIPTION_QUERY = gql`
  mutation deleteSubscription($company_id: Int!, $city_id: Int!) {
    deleteSubscription(company_id: $company_id, city_id: $city_id) {
      id
      city_id
      company_id
    }
  }
`
export const CREATE_SUBSCRIPTION_QUERY = gql`
  mutation createSubscription($company_id: Int!, $city_id: Int!) {
    createSubscription(company_id: $company_id, city_id: $city_id) {
      id
      city_id
      company_id
    }
  }
`

export const GET_ALL_CITIES_QUERY = gql`
  query getAllCities {
    AllCities {
      id
      name
      location
    }
  }
`

export const GET_ALL_USER_ROLES_QUERY = gql`
  query {
    AllUserRoles {
      id
      title
    }
  }
`

export const GET_ADMIN_VIEW_INFO_QUERY = gql`
  query GetAllUsersSubscriptions {
    AllCities {
      id
      name
      country
    }
    GetAllUsersSubscriptions {
      user {
        id
        company_id
        first_name
        last_name
        email
        role_id
      }
      subscriptions {
        id
        city_id
      }
    }
  }
`

export const ADD_ENTRY_TO_LOCALE_QUERY = gql`
  mutation addEditEntryInLocale(
    $locale: String
    $label: String
    $value: String
  ) {
    addEditEntryInLocale(locale: $locale, label: $label, value: $value) {
      id
      locale
      entries
    }
  }
`

export const CREATE_LANGUAGE_LOCALE_QUERY = gql`
  mutation ($locale: String) {
    createLocale(locale: $locale) {
      id
      locale
      entries
    }
  }
`

export const GET_LANGUAGE_QUERY = gql`
  query GetLanguage($locale: String) {
    GetLanguage(locale: $locale) {
      id
      locale
      entries
    }
  }
`

export const UPDATE_LOCALE_ENTRIES_QUERY = gql`
  mutation updateLocaleEntries($locale: String, $entries: JSON) {
    updateLocaleEntries(locale: $locale, entries: $entries) {
      id
      locale
      entries
    }
  }
`

export const MULTI_CITY_BASIC_INFO = gql`
  query LatestCityInformations($ids: [Int!]!, $year: Int!, $quarter: Int!) {
    LatestCityInformations(ids: $ids, year: $year, quarter: $quarter) {
      considerations {
        id
        name
        value
        weightedValue
        weightedValueOld
      }
      dimensions {
        id
        name
        value
        weightedValue
      }
      iq
      iq_quality_star
      city_id
      snapshotRevision
      lastUpdated
    }
    AllCities {
      id
      name
      country
      location
      profile {
        lang
      }
    }
  }
`

export const ALL_AVAILABLE_SNAPSHOT_QUATERS = gql`
  query AllAvailableQuartersForCities($ids: [Int!]!) {
    AllAvailableQuartersForCities(ids: $ids) {
      id
      city_id
      year
      quarter
      snapshot_number
    }
  }
`
