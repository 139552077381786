import * as types from '../constants/ActionTypes'
import { gAnalytics, considerationsLookup } from '../helpers/analytics'
import { fromJS } from 'immutable'

const initialState = fromJS({
  watchList: [],
})

export default (state = initialState, action) => {
  let newState = state

  switch (action.type) {
    case types.CREATE_WATCH_CONSIDERATION:
      gAnalytics('event', [
        'watchList',
        'create',
        considerationsLookup[action.payload.consideration_id],
      ])
      newState = newState.update('watchList', (list) =>
        list.push(fromJS(action.payload))
      )
      return newState
    case types.GET_WATCH_CONSIDERATIONS:
      if (fromJS(action.payload)) {
        newState = state.set('watchList', fromJS(action.payload))
      }
      return newState
    case types.DELETE_WATCH_CONSIDERATION:
      gAnalytics('event', ['watchList', 'delete', action.payload.id.toString()])
      const watchIndex = state.get('watchList').findIndex((item) => {
        return item.get('id') === action.payload.id
      })
      newState = state.update('watchList', (list) => list.delete(watchIndex))
      return newState
    case types.LOGOUT_USER:
      if (action.payload.LogoutUser.success) return initialState
      return state
    default:
      return state
  }
}
