import {
  GET_GOALS_USER_CITY,
  UPDATE_GOAL,
  CREATE_GOAL,
  DELETE_GOAL,
  OPEN_FORCE_LOGOUT_DIALOG,
} from '../constants/ActionTypes'
import {
  GET_GOALS_USER_CITY_QUERY,
  UPDATE_GOAL_QUERY,
  CREATE_GOAL_QUERY,
  DELETE_GOAL_QUERY,
} from '../constants/QueryTypes'
import { hasUserSessionError } from '../helpers/errorHandler'
import query from '../services/GraphQLService'

export const getGoalByUserAndCity = (user_id, city_id) => (dispatch) => {
  return new Promise((resolve, reject) => {
    query('query', GET_GOALS_USER_CITY_QUERY, { user_id, city_id })
      .then(({ data, errors }) => {
        if (errors && hasUserSessionError(errors)) {
          // dispatch({ type: OPEN_FORCE_LOGOUT_DIALOG })
          reject(errors)
          return
        }
        dispatch({ type: GET_GOALS_USER_CITY, payload: data })
        resolve(data)
      })
      .catch((e) => {
        reject(e)
      })
  })
}
export const updateGoal =
  (user_id, dimension, goal_value, goal_date) => (dispatch) => {
    return new Promise((resolve, reject) => {
      query('mutate', UPDATE_GOAL_QUERY, {
        user_id,
        dimension,
        goal_value,
        goal_date,
      })
        .then(({ data, errors }) => {
          if (errors && hasUserSessionError(errors)) {
            // dispatch({ type: OPEN_FORCE_LOGOUT_DIALOG })
            reject(errors)
            return
          }
          dispatch({ type: UPDATE_GOAL, payload: data })
          resolve(data)
        })
        .catch((e) => {
          reject(e)
        })
    })
  }
export const createGoal =
  (dimension, goal_value, goal_date, city_id, user_id) => (dispatch) => {
    return new Promise((resolve, reject) => {
      query('mutate', CREATE_GOAL_QUERY, {
        dimension,
        goal_value,
        goal_date,
        city_id,
        user_id,
      })
        .then(({ data, errors }) => {
          if (errors && hasUserSessionError(errors)) {
            // dispatch({ type: OPEN_FORCE_LOGOUT_DIALOG })
            reject(errors)
            return
          }
          dispatch({ type: CREATE_GOAL, payload: data })
          resolve(data)
        })
        .catch((e) => {
          reject(e)
        })
    })
  }

export const deleteGoal = (user_id, dimension) => (dispatch) => {
  return new Promise((resolve, reject) => {
    query('mutate', DELETE_GOAL_QUERY, { user_id, dimension })
      .then(({ data, errors }) => {
        if (errors && hasUserSessionError(errors)) {
          // dispatch({ type: OPEN_FORCE_LOGOUT_DIALOG })
          reject(errors)
          return
        }
        dispatch({
          type: DELETE_GOAL,
          payload: data,
          dimension: dimension,
        })
        resolve(data)
      })
      .catch((e) => {
        reject(e)
      })
  })
}
