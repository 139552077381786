import { Theme } from './Theme'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import MUIDataTable from 'mui-datatables'
import React from 'react'
import { injectIntl } from 'react-intl'

const DataTable = ({
  title,
  rows,
  options,
  columns,
  className,
  handleClick,
  intl,
}) => {
  let customizedTheme = { ...Theme }
  // customizedTheme.overrides.MuiTableCell.root['width'] = `${100 / columnsCount}%`
  const getMuiTheme = () => createMuiTheme(customizedTheme)
  const rowsPerPageOptions = [20, 50, 100]

  const defaultOptions = {
    filter: true,
    filterType: 'dropdown',
    responsive: 'scroll',
    rowsPerPageOptions: rowsPerPageOptions,
    rowsPerPage: 20,
    print: false,
    download: false,
    selectableRowsHeader: false,
    selectableRows: 'none',
    viewColumns: true,
    sort: true,
    pagination: true,
    jumpToPage: true,
  }

  let tableOptions = {
    ...defaultOptions,
    ...options,
    textLabels: {
      pagination: {
        next: intl.formatMessage({ id: 'datatable.pagination.next' }),
        previous: intl.formatMessage({ id: 'datatable.pagination.previous' }),
        rowsPerPage: intl.formatMessage({
          id: 'datatable.pagination.rowsPerPage',
        }),
        displayRows: intl.formatMessage({
          id: 'datatable.pagination.displayRows',
        }),
      },
      toolbar: {
        search: intl.formatMessage({ id: 'datatable.toolbar.search' }),
        downloadCsv: intl.formatMessage({
          id: 'datatable.toolbar.downloadCsv',
        }),
        print: intl.formatMessage({ id: 'datatable.toolbar.print' }),
        viewColumns: intl.formatMessage({
          id: 'datatable.toolbar.viewColumns',
        }),
        filterTable: intl.formatMessage({
          id: 'datatable.toolbar.filterTable',
        }),
      },
      filter: {
        title: intl.formatMessage({ id: 'datatable.filter.title' }),
        reset: intl.formatMessage({ id: 'datatable.filter.reset' }),
      },
      viewColumns: {
        title: intl.formatMessage({ id: 'datatable.viewColumns.title' }),
      },
      selectedRows: {
        text: intl.formatMessage({ id: 'datatable.selectedRows.text' }),
        delete: intl.formatMessage({ id: 'datatable.selectedRows.delete' }),
      },
    },
  }
  return (
    <div className={`DataTable DataTable--${className}`}>
      <MuiThemeProvider theme={getMuiTheme()}>
        <MUIDataTable
          title={title || 'Untitled'}
          data={rows}
          columns={columns}
          options={tableOptions}
        />
      </MuiThemeProvider>
    </div>
  )
}
export default injectIntl(DataTable)
