import { setIndDialoginfo } from '../../actions/ModalActions'
import { Icon } from '../../components'
import './IndicatorButton.scss'
import Button from '@material-ui/core/Button'
import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

const IndicatorButton = ({ actions, data, weight, intl }) => {
  return (
    <Button
      classes={{ flat: 'IndicatorButton' }}
      onClick={() => {
        actions.setIndDialoginfo({
          indicator: data.id,
          grade: Math.round(parseFloat(data.value) * 100),
          citations: data.citations,
          source: data.source,
        })
      }}
    >
      <Icon name="indicator-icon" className={'icon'} />
      <div className="indicatorButtonScore">
        <div>{Math.round(parseFloat(data.value) * 100)}</div>
        <div className="weight">
          {String(weight).split('.')[1]?.length > 2
            ? weight.toFixed(2)
            : weight + '%'}
        </div>
      </div>
      <div className="indicatorButtonName">
        {intl.formatMessage({ id: `indicators.name.${data.id}` })}
      </div>
    </Button>
  )
}

IndicatorButton.propTypes = {
  data: PropTypes.object.isRequired,
  weight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}

function mapStateToProps(state) {
  return {
    pageStore: state.PageStore,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        setIndDialoginfo,
      },
      dispatch
    ),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(IndicatorButton))
