import {
  INIT_USER,
  LOGIN_USER,
  LOGOUT_USER,
  TOGGLE_LOADING_USER,
  REGISTER_USER,
  GET_COMPANY_PROFILE,
  VIEWED_INTRO,
  UPDATE_USER,
  GET_ALL_CITIES,
  UPDATE_SELECTED_CITY,
  UPDATE_SELECTED_QUARTER,
  GET_USER_FILTERED_CITIES,
  SET_USER_FILTERED_CITIES,
  TOGGLE_ATTEMPT_LOGIN,
} from '../constants/ActionTypes'
import { gAnalytics, gAnalyticsSetSesssion } from '../helpers/analytics'
import { fromJS } from 'immutable'

const initialState = fromJS({
  hasSession: false,
  loadingUser: false,
  attemptingLogin: false,
  loginUserExists: false,
  errorMessage: '',
  errorMessageKey: '',
  userInfo: fromJS({}),
  registrationError: '',
  registrationMessage: '',
  registrationSuccess: false,
  sessionVerified: false,
  companyInfo: fromJS({}),
  subscriptions: fromJS([]),
  role: fromJS({}),
  allCities: fromJS({}),
  selectedCityId: 0,
  login_origin: '',
})

export default (state = initialState, action) => {
  let newState = state

  switch (action.type) {
    case INIT_USER:
      if (action.payload.InitUser?.exists) {
        newState = state.set('userInfo', fromJS(action.payload.InitUser?.user))
        newState = newState.set('errorMessage', '')
        newState = newState.set('errorMessageKey', '')
        newState = newState.set(
          'loginUserExists',
          action.payload.InitUser?.exists
        )
        newState = newState.set(
          'subscriptions',
          fromJS(action.payload.InitUser?.subscriptions)
        )
        newState = newState.set('role', fromJS(action.payload.InitUser?.role))
        newState = newState.set('hasSession', true)

        // TODO: Update this to include all cities or none
        if (action.payload.InitUser?.subscriptions?.length) {
          gAnalyticsSetSesssion(
            action.payload.InitUser?.user.id,
            action.payload.InitUser?.subscriptions[0].city_id
          )
        }
      } else {
        newState = state
      }
      return newState
    case TOGGLE_LOADING_USER:
      return state.set('loadingUser', !newState.get('loadingUser'))
    case TOGGLE_ATTEMPT_LOGIN:
      return state.set('attemptingLogin', !newState.get('attemptingLogin'))
    case LOGIN_USER:
      if (action.payload.LoginUser.exists) {
        newState = state.set('userInfo', fromJS(action.payload.LoginUser.user))
        newState = newState.set('errorMessage', '')
        newState = newState.set('errorMessageKey', '')
        newState = newState.set(
          'loginUserExists',
          action.payload.LoginUser.exists
        )
        newState = newState.set(
          'subscriptions',
          fromJS(action.payload.LoginUser.subscriptions)
        )
        newState = newState.set('role', fromJS(action.payload.LoginUser.role))
        newState = newState.set('hasSession', true)

        // TODO: Update this to include all cities or none
        if (action.payload.LoginUser.subscriptions.length) {
          gAnalyticsSetSesssion(
            action.payload.LoginUser.user.id,
            action.payload.LoginUser.subscriptions[0].city_id
          )
        }
        if (action.payload.origin) {
          newState = newState.set('login_origin', action.payload.origin)
        }
        gAnalytics('event', [
          'Login',
          'login',
          action.payload.LoginUser.user.id.toString(),
        ])
      } else {
        newState = newState.set(
          'errorMessage',
          action.payload.LoginUser.message
        )
        newState = newState.set(
          'errorMessageKey',
          action.payload.LoginUser.message_key
        )
      }
      return newState
    case LOGOUT_USER:
      if (action.payload.LogoutUser.success) {
        gAnalytics('event', [
          'Logout',
          'logout',
          state.getIn(['userInfo', 'id']).toString(),
        ])
        return initialState
      }
      return state
    case REGISTER_USER:
      newState = state.set(
        'registrationError',
        action.payload.registerUser.error
      )
      newState = newState.set(
        'registrationMessage',
        action.payload.registerUser.message
      )
      newState = newState.set(
        'registrationSuccess',
        action.payload.registerUser.success
      )
      return newState
    case GET_COMPANY_PROFILE:
      newState = state.set('companyInfo', fromJS(action.payload))
      return newState
    case VIEWED_INTRO:
      return state.setIn(
        ['userInfo', 'viewed_intro'],
        action.payload.viewed_intro
      )
    case UPDATE_USER:
      if (action.payload !== null) {
        newState = newState.set('userInfo', fromJS(action.payload))
      }
      return newState
    case GET_ALL_CITIES:
      newState = newState.set('allCities', fromJS(action.payload))
      return newState
    case UPDATE_SELECTED_CITY:
      newState = newState.set('selectedCityId', fromJS(action.payload))
      return newState
    case UPDATE_SELECTED_QUARTER:
      newState = newState.set('selectedQuarter', fromJS(action.payload))
      return newState
    case GET_USER_FILTERED_CITIES:
      newState = newState.set('filteredCities', action.payload)
      return newState
    case SET_USER_FILTERED_CITIES:
      newState = newState.set('filteredCities', action.payload)
      return newState

    default:
      return state
  }
}
