export const renderParagraphWithLinebreaks = ({
  intl,
  paragraphClassName = '',
  messageId,
}) => {
  const str = intl?.formatMessage({ id: messageId })
  if ((str ?? '').trim() === '' || str === messageId) {
    return null
  }
  return (
    <p className={paragraphClassName}>
      {str.split('\n').map((text) => (
        <>
          {text}
          <br />
        </>
      ))}
    </p>
  )
}
