import { setIndDialoginfo } from '../../actions/ModalActions'
import { ColoredScore } from '../../components'
import { primaryGreen } from '../DataTable/Theme'
import { getSorting } from './../../helpers/parsers'
import './IndicatorRanking.scss'
import Button from '@material-ui/core/Button'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

const IndicatorRanking = ({ pageStore, actions }) => {
  const [order, setOrder] = React.useState('desc')

  return (
    <div className="sorting-table">
      <Table>
        <TableHead
          classes={{ root: 'stickyHeader' }}
          style={{ borderBottom: '1px solid rgba(81, 81, 81, 1)' }}
        >
          <TableRow>
            <TableCell
              className="rankingsHeader"
              style={{ borderBottom: 'none' }}
            >
              <span>
                <FormattedMessage
                  id="indicator.rankings.header"
                  description="Header"
                />
              </span>
            </TableCell>
            <TableCell
              key={'value'}
              className="rankingsScore"
              onClick={() => {
                order === 'desc' ? setOrder('asc') : setOrder('desc')
              }}
              sortDirection={order}
            >
              <TableSortLabel
                active
                direction={order}
                onClick={() => {
                  order === 'desc' ? setOrder('asc') : setOrder('desc')
                }}
              >
                {order === 'desc' && (
                  <FormattedMessage
                    id="indicator.rankings.sort.desc"
                    description="Header"
                  />
                )}

                {order === 'asc' && (
                  <FormattedMessage
                    id="indicator.rankings.sort.asc"
                    description="Header"
                  />
                )}
              </TableSortLabel>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody classes={{ root: 'tableBody' }}>
          {pageStore
            .getIn(['allInfo', 'LatestCityIndicators'])
            .sort(getSorting(order, 'value'))
            .map((n, i) => {
              const id = n.get('id')
              // const dim = considerationToDimension[id].dimension
              // const color = dim ? dimensionToColor[dim].main : '#34ACE0'
              const grade = Math.round(parseFloat(n.get('value')) * 100)
              return (
                <TableRow key={n.get('id')} classes={{ root: 'fullWidth' }}>
                  <TableCell component="th" scope="row">
                    <Button
                      className="ranking-name-btn"
                      onClick={() => {
                        actions.setIndDialoginfo({
                          indicator: n.get('id'),
                          grade: grade,
                          citations: n.get('citations'),
                          source: n.get('source'),
                        })
                      }}
                    >
                      <FormattedMessage
                        id={'indicators.name.' + id}
                        description="Header"
                      />
                    </Button>
                  </TableCell>
                  <TableCell classes={{ body: 'moveContentsRight' }}>
                    <div style={{ display: 'inline-block' }}>
                      <ColoredScore
                        backgroundColor={primaryGreen}
                        fontSize={'14px'}
                        width="45px"
                        height="28px"
                        value={grade}
                      />
                    </div>
                  </TableCell>
                </TableRow>
              )
            })}
        </TableBody>
      </Table>
    </div>
  )
}

function mapStateToProps(state) {
  return {
    pageStore: state.PageStore,
  }
}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        setIndDialoginfo,
      },
      dispatch
    ),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(IndicatorRanking)
