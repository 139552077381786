// You'll find that downshift is a primitive component and
// you'll be most successful wrapping it with another component
// like the MultiDownshift one you see here:
// Referencing this example:
// https://codesandbox.io/s/github/kentcdodds/downshift-examples/tree/master/?module=%2Fsrc%2Fordered-examples%2F04-multi-select.js&moduleview=1
import {
  getFilteredCities,
  updateFilteredCities,
} from '../../actions/UserActions.js'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Grid } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Downshift from 'downshift'
import matchSorter from 'match-sorter'
import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

// import { assertValidSDLExtension } from 'graphql/validation/validate'

class MultiDownshift extends React.Component {
  // state = { selectedItems: [] }
  state = { selectedItems: this.props.selectedCities || [] }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.selectedCities &&
      nextProps.selectedCities.length !== this.props.selectedCities &&
      this.props.selectedCities.length
    ) {
      this.setState({ selectedItems: this.props.selectedCities || [] })
    }
  }

  stateReducer = (state, changes) => {
    switch (changes.type) {
      case Downshift.stateChangeTypes.keyDownEnter:
      case Downshift.stateChangeTypes.clickItem:
        return {
          ...changes,
          highlightedIndex: state.highlightedIndex,
          isOpen: true,
          inputValue: '',
        }
      default:
        return changes
    }
  }

  handleSelection = (selectedItem, downshift) => {
    const callOnChange = () => {
      const { onSelect, onChange } = this.props
      const { selectedItems } = this.state
      if (onSelect) {
        onSelect(selectedItems, this.getStateAndHelpers(downshift))
      }
      if (onChange) {
        onChange(selectedItems, this.getStateAndHelpers(downshift))
      }
    }
    if (this.state.selectedItems.includes(selectedItem)) {
      this.removeItem(selectedItem, callOnChange, downshift)
    } else {
      this.addSelectedItem(selectedItem, callOnChange)
    }
  }

  removeItem = (item, cb, downshift) => {
    const { onChange } = this.props
    this.setState(({ selectedItems }) => {
      onChange(
        selectedItems.filter((i) => i !== item),
        this.getStateAndHelpers(downshift)
      )
      return {
        selectedItems: selectedItems.filter((i) => i !== item),
      }
    }, cb)
  }
  addSelectedItem(item, cb) {
    this.setState(
      ({ selectedItems }) => ({
        selectedItems: [...selectedItems, item],
      }),
      cb
    )
  }

  getRemoveButtonProps = ({ onClick, item, ...props } = {}) => {
    return {
      onClick: (e) => {
        // TODO: use something like downshift's composeEventHandlers utility instead
        onClick && onClick(e)
        e.stopPropagation()
        this.removeItem(item)
      },
      ...props,
    }
  }

  getStateAndHelpers(downshift) {
    const { selectedItems } = this.state
    const { getRemoveButtonProps, removeItem } = this
    return {
      getRemoveButtonProps,
      removeItem,
      selectedItems,
      ...downshift,
    }
  }
  render() {
    const { render, children = render, ...props } = this.props
    // TODO: compose together props (rather than overwriting them) like downshift does
    return (
      <Downshift
        {...props}
        stateReducer={this.stateReducer}
        onChange={this.handleSelection}
        selectedItem={null}
      >
        {(downshift) => children(this.getStateAndHelpers(downshift))}
      </Downshift>
    )
  }
}

class Select extends React.Component {
  state = {
    loading: false,
    filteredCities: [],
  }
  input = React.createRef()
  itemToString = (item) => (item ? item.name : '')
  handleChange = (selectedItems) => {
    // Note: do not set state or call props function in this context
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.selectedCities &&
      nextProps.selectedCities.length !== this.props.selectedCities &&
      this.props.selectedCities.length
    ) {
      this.setState({ selectedItems: this.props.selectedCities || [] })
    }
  }

  compare(a, b) {
    if (a.name > b.name) return 1
    if (a.name < b.name) return -1
    if (a.name === b.name) return 0
  }

  sort(list) {
    return list.sort((a, b) => this.compare(a, b))
  }

  getItems(filter) {
    const { allCities, userStore } = this.props
    var results = []
    userStore
      .get('subscriptions')
      .toJS()
      .forEach((item) => {
        var validCity = allCities.find((city) => city.id === item.city_id)
        results.push(validCity)
      })
    results = this.sort(
      filter ? matchSorter(results, filter, { keys: ['name'] }) : results
    )
    return [...new Set(results)]
  }

  renderItemTag(item, getRemoveButtonProps) {
    return (
      <div
        item
        container
        key={item.id}
        style={{
          minWidth: '100px',
          background: '#31353D',
          border: '1px solid rgba(255, 255, 255, 0.25)',
          boxSizing: 'border-box',
          borderRadius: '6px',
          padding: '5px 10px',
          marginRight: '8px',
          marginBottom: '8px',
          display: 'inline-block',
          wordWrap: 'none',
        }}
      >
        <Grid
          container
          style={{
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Grid item>
            <Typography variant="body1" style={{ fontSize: '14px' }}>
              {item.name}
            </Typography>
            <Typography
              variant="body1"
              style={{
                fontSize: '12px',
                opacity: 0.6,
                maxWidth: '155px',
              }}
            >
              {item.country || ''}
            </Typography>
          </Grid>
          <Grid item>
            <button
              {...getRemoveButtonProps({ item })}
              style={{
                cursor: 'pointer',
                lineHeight: 0.8,
                border: 'none',
                backgroundColor: 'transparent',
                padding: '0',
                fontSize: '16px',
              }}
            >
              <FontAwesomeIcon
                icon={faTimesCircle}
                style={{
                  fontSize: '15px',
                  marginLeft: '10px',
                  color: 'white',
                  opacity: 0.5,
                }}
              />
            </button>
          </Grid>
        </Grid>
      </div>
    )
  }

  renderItemInDropdown(
    item,
    index,
    selectedItems,
    highlightedIndex,
    getItemProps
  ) {
    if (item)
      return !selectedItems.map((s) => s.id).includes(item.id) ? (
        <li
          style={{
            borderTop: '2px solid rgb(49, 53, 61)',
            listStyleType: 'none',
            margin: 0,
            padding: '10px 20px',
            backgroundColor:
              highlightedIndex === index
                ? 'rgb(49, 53, 61, .3)'
                : 'rgba(49, 53, 61, 0)',
          }}
          key={item.id}
          {...getItemProps({
            item,
            index,
            isActive: highlightedIndex === index,
            isSelected: selectedItems.includes(item),
          })}
        >
          <Typography variant="body1">{item.name}</Typography>
          <Typography
            variant="body1"
            style={{ fontSize: '12px', opacity: 0.6 }}
          >
            {item.country || ''}
          </Typography>
        </li>
      ) : null
  }

  renderMultiDownshiftContent({
    getInputProps,
    getToggleButtonProps,
    getMenuProps,
    // note that the getRemoveButtonProps prop getter and the removeItem
    // action are coming from MultiDownshift composibility for the win!
    getRemoveButtonProps,
    removeItem,
    isOpen,
    inputValue,
    selectedItems,
    getItemProps,
    highlightedIndex,
    toggleMenu,
  }) {
    const { allCities, userStore, actions, intl } = this.props
    const dropdownProps = getMenuProps({ isOpen })

    if (!isOpen) {
      toggleMenu()
    }

    return (
      <div style={{ width: 500, margin: 'auto', position: 'relative' }}>
        <Grid
          container
          style={{
            alignItems: 'baseline',
            justifyContent: 'space-between',
            marginBottom: '10px',
          }}
        >
          <Grid item>
            <Typography variant="h5">
              <FormattedMessage id="cities.filter.addCitiesToCompare" />
            </Typography>
          </Grid>
          {Boolean(selectedItems.length) && (
            <Grid item>
              <Typography variant="body1">
                {selectedItems.length === 1
                  ? intl.formatMessage({ id: 'cities.filter.oneCitySelected' })
                  : intl.formatMessage(
                      { id: 'cities.filter.multipleCitiesSelected' },
                      { count: selectedItems.length }
                    )}
              </Typography>
            </Grid>
          )}
        </Grid>
        <Grid
          spacing={8}
          style={{
            cursor: 'pointer',
            position: 'relative',
            background: '#282729',
            borderRadius: '3px',
            padding: '12px',
          }}
          onClick={() => {
            !isOpen &&
              allCities?.length !== selectedItems.length &&
              this.input.current.focus()
          }}
        >
          <div
            container
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              alignItems: 'stretch',
            }}
          >
            {selectedItems.length > 0
              ? selectedItems.map((item) =>
                  this.renderItemTag(item, getRemoveButtonProps)
                )
              : null}
            {allCities?.length === selectedItems.length ? (
              <Typography
                variant="body1"
                style={{
                  position: 'absolute',
                  bottom: '0',
                  right: '0',
                  fontStyle: 'italic',
                  margin: '10px 15px',
                  opacity: '.7',
                }}
              >
                All cities have been selected.
              </Typography>
            ) : (
              <input
                placeholder={intl.formatMessage({
                  id: 'cities.filter.placeholder',
                })}
                style={{
                  marginLeft: '6px',
                  flex: '1 1 0%',
                  fontSize: '14px',
                  background: 'inherit',
                  color: 'inherit',
                  minHeight: '27px',
                  border: '0',
                  outline: 'none !important !important',
                }}
                {...getInputProps({
                  ref: this.input,
                  onKeyDown(event) {
                    if (event.key === 'Backspace' && !inputValue) {
                      removeItem(selectedItems[selectedItems.length - 1])
                    }
                  },
                })}
              />
            )}
          </div>
        </Grid>
        <ul
          {...dropdownProps}
          style={{
            listStyleType: 'none',
            margin: 0,
            padding: 0,
            maxHeight: '230px',
            overflow: 'scroll',
            background: 'rgb(40, 39, 41)',
            position: 'relative',
            left: '0',
            width: '100%',
          }}
        >
          {isOpen
            ? this.getItems(inputValue).map((item, index) =>
                this.renderItemInDropdown(
                  item,
                  index,
                  selectedItems,
                  highlightedIndex,
                  getItemProps
                )
              )
            : null}
        </ul>
        <Grid
          style={{
            marginTop: '10px',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            style={{ color: '#00AEEF', width: '112px' }}
            onClick={this.props.onCloseDialog}
          >
            <FormattedMessage id="cities.filter.cancel" />
          </Button>
          <Button
            style={{
              background: '#9EC73D',
              color: '#1E1D1F',
              width: '112px',
            }}
            onClick={() => {
              const userId = userStore.getIn(['userInfo', 'id'])
              actions.updateFilteredCities(selectedItems, userId)
              this.props.onCloseDialog()
            }}
          >
            <FormattedMessage id="cities.filter.save" />
          </Button>
        </Grid>
      </div>
    )
  }

  render() {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '20px 18px',
          color: 'white',
        }}
      >
        <MultiDownshift
          selectedCities={this.props.selectedCities}
          onChange={this.handleChange}
          itemToString={this.itemToString}
          intl={this.props.intl}
        >
          {(props) => this.renderMultiDownshiftContent(props)}
        </MultiDownshift>
      </div>
    )
  }
}
function mapStateToProps(state) {
  return {
    userStore: state.UserStore,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        updateFilteredCities,
        getFilteredCities,
      },
      dispatch
    ),
  }
}
export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Select))
