import { default as AdminStore } from './AdminReducer'
import { default as CityStore } from './CityReducer'
import { default as GoalStore } from './GoalReducer'
import { default as MilestoneStore } from './MilestoneReducer'
import { default as ModalStore } from './ModalReducer'
import { default as PageStore } from './PageReducer'
import { default as UserStore } from './UserReducer'
import { default as WatchListStore } from './WatchListReducer'
import { combineReducers } from 'redux'

export default combineReducers({
  AdminStore,
  CityStore,
  GoalStore,
  MilestoneStore,
  ModalStore,
  PageStore,
  UserStore,
  WatchListStore,
})
