import { resetPassword, validateToken } from '../../actions/UserActions'
import citiIQ_background from '../../assets/citiIQ_login_image.jpg'
import logo from '../../assets/citiiq-login-logo.png'
import { LoadingScreen } from '../../components'
import LanguageSelector from '../../components/PageWrapper/LanguageSelector'
import './ResetPassword.scss'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import React from 'react'
import Helmet from 'react-helmet'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'

const ForgotPassword = ({ actions, history, match, intl }) => {
  // eslint-disable-next-line no-unused-vars
  const [resetToken, setResetToken] = React.useState(match.params.token || null)
  const [newPassword, setNewPassword] = React.useState('')
  const [newPasswordFocus, setNewPasswordFocus] = React.useState(false)
  const [confirmPassword, setConfirmPassword] = React.useState('')
  const [confirmPasswordFocus, setConfirmPasswordFocus] = React.useState(false)
  const [initTokenCall, setInitTokenCall] = React.useState(true)
  const [validToken, setValidToken] = React.useState(false)
  // eslint-disable-next-line no-unused-vars
  const [tokenError, setTokenError] = React.useState('')
  // eslint-disable-next-line no-unused-vars
  const [passwordsMatch, setPasswordsMatch] = React.useState(false)
  const [resetRequestSuccess, setResetRequestSuccess] = React.useState(false)

  React.useEffect(() => {
    actions.validateToken(resetToken).then((response) => {
      if (response.success) {
        setValidToken(true)
        setInitTokenCall(false)
      } else {
        setTokenError(response.error)
        setInitTokenCall(false)
      }
    })
  }, [actions, resetToken])

  const resetPassword = () => {
    if (newPassword === confirmPassword) {
      actions.resetPassword(resetToken, newPassword).then((response) => {
        if (response.success) {
          setResetRequestSuccess(true)
        }
      })
    } else {
      setPasswordsMatch(true)
    }
  }

  const handleInputChange = (e) => {
    switch (e.target.name) {
      case 'newPassword':
        setNewPassword(e.target.value)
        break
      case 'confirmPassword':
        setConfirmPassword(e.target.value)
        break
      default:
        break
    }
  }

  const handleFocus = (e) => {
    switch (e.target.name) {
      case 'newPassword':
        setNewPasswordFocus(true)
        break
      case 'confirmPassword':
        setConfirmPasswordFocus(true)
        break
      default:
        break
    }
  }

  const handleBlur = (e) => {
    switch (e.target.name) {
      case 'newPassword':
        setNewPasswordFocus(false)
        break
      case 'confirmPassword':
        setConfirmPasswordFocus(false)
        break
      default:
        break
    }
  }

  if (initTokenCall) {
    return <LoadingScreen />
  }
  if (!validToken) {
    return <Redirect to="/" />
  }
  if (resetRequestSuccess) {
    return (
      <div
        className="forgotPass"
        style={{ backgroundImage: citiIQ_background }}
      >
        <Helmet>
          <title>
            CitiIQ - {intl.formatMessage({ id: 'login.forgotMessage' })}
          </title>
        </Helmet>
        <img src={citiIQ_background} className="forgotPassBackground" alt="" />
        <div className="forgotPassDiv">
          <img className="siteLogo" src={logo} alt="" />
          <div className="registerDiv">
            <span>
              <FormattedMessage id="login.goToLoginText" />
            </span>
            <Button
              onClick={() => history.push('/')}
              color="primary"
              classes={{ textPrimary: 'registerButton' }}
            >
              <FormattedMessage id="login.login" />
            </Button>
          </div>
          <React.Fragment>
            <p className="forgotPassHeader bottomPadded">
              <FormattedMessage id="resetpass.successHeader" />
            </p>
            <Grid container spacing={16}>
              <Grid item xs={10} style={{ margin: '0 auto', color: '#eee' }}>
                <FormattedMessage id="resetpass.successMessage" />
              </Grid>
              <Grid item xs={6} style={{ margin: '0 auto' }}>
                <Button
                  onClick={() => history.push('/')}
                  color="primary"
                  classes={{ textPrimary: 'loginButton' }}
                >
                  <FormattedMessage id="login.login" />
                </Button>
              </Grid>
            </Grid>
          </React.Fragment>
        </div>
      </div>
    )
  }

  return (
    <div className="forgotPass" style={{ backgroundImage: citiIQ_background }}>
      <Helmet>
        <title>CitiIQ - {intl.formatMessage({ id: 'reset.password' })}</title>
      </Helmet>
      <img src={citiIQ_background} className="forgotPassBackground" alt="" />
      <div className="forgotPassDiv">
        <img className="siteLogo" src={logo} alt="" />
        <div className="registerDiv">
          <span>
            <FormattedMessage id="login.goToLoginText" />
          </span>
          <Button
            onClick={() => history.push('/')}
            color="primary"
            classes={{ textPrimary: 'registerButton' }}
          >
            <FormattedMessage id="login.login" />
          </Button>
        </div>
        <React.Fragment>
          <p className="forgotPassHeader bottomPadded">
            <FormattedMessage id="reset.password" />
          </p>
          <div className="forgotPassGridWrapper">
            <Grid container spacing={16}>
              <Grid item xs={12}>
                <input
                  type="password"
                  className="forgotPassInput"
                  value={newPassword}
                  name="newPassword"
                  onFocus={(e) => handleFocus(e)}
                  onChange={(e) => handleInputChange(e)}
                  onBlur={(e) => handleBlur(e)}
                  placeholder={
                    !newPasswordFocus
                      ? intl.formatMessage({
                          id: 'input.passwordPlaceholder',
                        })
                      : ''
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <input
                  type="password"
                  className="forgotPassInput"
                  value={confirmPassword}
                  name="confirmPassword"
                  onFocus={(e) => handleFocus(e)}
                  onChange={(e) => handleInputChange(e)}
                  onBlur={(e) => handleBlur(e)}
                  placeholder={
                    !confirmPasswordFocus
                      ? intl.formatMessage({
                          id: 'input.confirmPassPlaceholder',
                        })
                      : ''
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  onClick={() => resetPassword()}
                  color="primary"
                  classes={{ textPrimary: 'forgotPassButton' }}
                >
                  {intl.formatMessage({ id: 'reset.password' })}
                </Button>
              </Grid>
            </Grid>
          </div>
        </React.Fragment>
        <div className="languageSelectorDiv">
          <div className="selector">
            <LanguageSelector />
          </div>
        </div>
      </div>
    </div>
  )
}

function mapStateToProps(state) {
  return {
    userStore: state.UserStore,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        resetPassword,
        validateToken,
      },
      dispatch
    ),
  }
}

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(ForgotPassword))
)
