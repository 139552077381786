import {
  getAllUsersSubscriptions,
  getAllUserRoles,
  deleteSubscription,
  createSubscription,
} from '../../actions/AdminActions'
import { deleteUser } from '../../actions/UserActions'
import { CitiIQCard } from '../../components'
import { AdminTheme } from '../../components/DataTable/AdminTheme'
import { getSorting } from './../../helpers/parsers'
import AddUser from './AddUser'
import './AdminView.scss'
import { Input } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Snackbar from '@material-ui/core/Snackbar'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import { PersonAdd, VerticalAlignBottom } from '@material-ui/icons'
import ArrowBack from '@material-ui/icons/ArrowBack'
import MUIDataTable from 'mui-datatables'
import React from 'react'
import Helmet from 'react-helmet'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'

const AdminView = ({ actions, adminStore, userStore, intl }) => {
  const [updateSubscriptionSnackbar, setUpdateSubscriptionSnackbar] =
    React.useState(false)
  const [newUserAdded, setNewUserAdded] = React.useState(false)
  const [selectValue, setSelectValue] = React.useState({})
  const [showAddUserDialog, setShowAddUserDialog] = React.useState(false)
  const [isSavingSubscription, setIsSavingSubscription] = React.useState(false)
  const [filteringUserId, setFilteringUserId] = React.useState()
  const [searchKey, setSearchKey] = React.useState('')

  React.useEffect(() => {
    actions.getAllUsersSubscriptions()
  }, [actions, updateSubscriptionSnackbar, newUserAdded])

  React.useEffect(() => {
    actions.getAllUserRoles()
  }, [actions])

  if (!userStore.get('userInfo')) return null
  if (!userStore.get('subscriptions')) return null

  async function handleSubscriptionChange(
    company_id,
    existingSubscriptions,
    changedSubscriptions,
    userId
  ) {
    setSelectValue({ ...selectValue, [userId]: null })

    const difference = (a, b) => new Set([...a].filter((x) => !b.has(x)))

    const existing = new Set(existingSubscriptions)
    const changed = new Set(changedSubscriptions)

    const deleted = difference(existing, changed)
    const created = difference(changed, existing)

    // Something was deleted
    if (deleted.size > 0) {
      await Promise.all(
        [...deleted].map((city_id) =>
          actions.deleteSubscription(company_id, city_id)
        )
      )
      setIsSavingSubscription(false)
      setUpdateSubscriptionSnackbar(true)
    }
    // Something was added
    else if (created.size > 0) {
      await Promise.all(
        [...created].map((city_id) =>
          actions.createSubscription(company_id, city_id)
        )
      )
      setIsSavingSubscription(false)
      setUpdateSubscriptionSnackbar(true)
    }
  }

  function getRoleName(roleId) {
    const roles = adminStore.get('allUserRoles')
    const name = (roles ?? []).reduce((prev, r) => {
      if (!prev && r.get('id') === roleId) {
        return r.get('title')
      } else {
        return prev
      }
    }, null)
    if (name == null) return roleId
    else return name
  }

  function listOfUsers() {
    var list = []
    adminStore.get('allUsersSubscriptions').forEach((user) => {
      var sub = user
        .get('subscriptions')
        .map((s) => s.get('city_id'))
        .toJS()
      list.push({
        user:
          user.getIn(['user', 'first_name']) +
          ' ' +
          user.getIn(['user', 'last_name']),
        role: getRoleName(user.getIn(['user', 'role_id'])),
        email: getRoleName(user.getIn(['user', 'email'])),
        city: {
          city_id: sub,
          user_id: user.getIn(['user', 'id']),
          company_id: user.getIn(['user', 'company_id']),
        },
      })
    })
    return list
  }

  const promptDelete = async (arrayToDelete) => {
    var temp = ''
    arrayToDelete.forEach((account) => {
      temp += account.User
    })
    // eslint-disable-next-line no-restricted-globals
    var confirmDeletionViaPopup = confirm(
      `Are you sure you want to delete the following users: ${temp}?`
    )
    if (confirmDeletionViaPopup) {
      await Promise.all(arrayToDelete.map((u) => deleteUser(u.City.user_id)))
    }
  }

  let customizedTheme = { ...AdminTheme }
  const getMuiTheme = () => createMuiTheme(customizedTheme)

  const mayFilterBySearch = (cities, userId) => {
    if (filteringUserId !== userId) return cities
    return cities.filter((city) => {
      const { id, name, country } = city.toJS()
      if (searchKey.length < 3) return true
      return (
        (selectValue[userId] ?? []).includes(id) ||
        (name ?? '').toLowerCase().includes(searchKey.toLowerCase()) ||
        (country ?? '').toLowerCase().includes(searchKey.toLowerCase())
      )
    })
  }

  const allCitiesList = adminStore
    .get('allCities')
    .sort(getSorting('asc', 'name'))

  return (
    <div className="Methodology">
      <Helmet>
        <title>CitiIQ - {intl.formatMessage({ id: 'admin.navigation' })}</title>
      </Helmet>
      <VerticalAlignBottom
        className="admin-icon"
        onClick={() =>
          window.open(`${process.env.REACT_APP_BACKEND_URL}/export_users`)
        }
      />
      <PersonAdd
        className="admin-icon"
        onClick={() => setShowAddUserDialog(true)}
      />
      <Link to="/dashboard" className="navigation adminPage-navigation">
        <h1>
          <ArrowBack className="nav-icon" />
          <FormattedMessage id="admin.navigation" description="nav" />
        </h1>
      </Link>
      <Grid container spacing={8}>
        <Grid item xs={12}>
          <CitiIQCard height="auto">
            <MuiThemeProvider theme={getMuiTheme()}>
              <MUIDataTable
                title={intl.formatMessage({ id: 'admin.table.title' })}
                data={listOfUsers()}
                columns={[
                  {
                    name: 'user',
                    label: intl.formatMessage({ id: 'admin.table.name' }),
                    options: {
                      filter: false,
                      sort: true,
                    },
                  },
                  {
                    name: 'email',
                    label: intl.formatMessage({ id: 'admin.table.email' }),
                    options: {
                      filter: false,
                      sort: true,
                    },
                  },
                  {
                    name: 'role',
                    label: intl.formatMessage({ id: 'admin.table.role' }),
                    options: {
                      filter: true,
                      sort: true,
                      customBodyRender: (value) =>
                        String(value).split('_').join(' '),
                    },
                  },
                  {
                    name: 'city',
                    label: intl.formatMessage({ id: 'admin.table.city' }),
                    options: {
                      sort: false,
                      sortDirection: 'asc',
                      filter: false,
                      customBodyRender: (value, tableMeta) => {
                        if (value?.user_id === undefined) return
                        return (
                          <FormControl
                            className="dropdown-selector"
                            style={{ flexDirection: 'unset' }}
                          >
                            <Select
                              multiple
                              value={
                                selectValue[value.user_id] || value.city_id
                              }
                              defaultValue={value.city_id}
                              onChange={(event) => {
                                setSelectValue({
                                  [value.user_id]: event.target.value,
                                })
                              }}
                              inputProps={{
                                name: 'selectedCityId',
                              }}
                              MenuProps={{ disableAutoFocusItem: true }}
                              disableUnderline
                              autoWidth
                              classes={{ select: 'dropdown-select' }}
                            >
                              <Input
                                autoFocus
                                fullWidth
                                placeholder={intl.formatMessage({
                                  id: 'cityMenu.searchBy',
                                })}
                                onChange={(e) => {
                                  setFilteringUserId(value?.user_id)
                                  setSearchKey(e.target.value)
                                }}
                                value={searchKey}
                                style={{
                                  padding: '0.5rem 1rem',
                                }}
                              />
                              {mayFilterBySearch(
                                allCitiesList,
                                value?.user_id
                              ).map((city) => {
                                const { id, name, country } = city.toJS()
                                return (
                                  <MenuItem key={id} value={id}>
                                    {`${name} ${country}`}
                                  </MenuItem>
                                )
                              })}
                            </Select>
                            <Button
                              style={{
                                background: 'rgb(30, 29, 31)',
                                color: 'rgb(158, 199, 61)',
                                visibility: selectValue[value.user_id]
                                  ? 'unset'
                                  : 'hidden',
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                marginTop: '-3px',
                                marginLeft: '-75px',
                                zIndex: 1,
                              }}
                              onClick={() => {
                                setIsSavingSubscription(true)
                                setFilteringUserId(null)
                                handleSubscriptionChange(
                                  value.company_id,
                                  value.city_id,
                                  selectValue[value.user_id],
                                  value.user_id
                                )
                              }}
                            >
                              {intl.formatMessage({
                                id: isSavingSubscription
                                  ? 'admin.table.saving'
                                  : 'admin.table.save',
                              })}
                            </Button>
                          </FormControl>
                        )
                      },
                    },
                  },
                ]}
                options={{
                  filter: true,
                  filterType: 'dropdown',
                  responsive: 'scroll',
                  rowsPerPageOptions: [],
                  rowsPerPage: 20,
                  print: false,
                  download: false,
                  // selectableRowsHeader: false,
                  // selectableRows: 'none',
                  viewColumns: true,
                  sort: true,
                  sortOrder: {
                    name: 'User',
                    direction: 'asc',
                  },
                  onRowsDelete: (deletedRows) => {
                    var allUsersInTable = listOfUsers()
                    var usersToDelete = []
                    deletedRows.data.forEach((row) => {
                      usersToDelete.push(allUsersInTable[row.dataIndex])
                    })
                    promptDelete(usersToDelete)
                  },
                }}
              />
            </MuiThemeProvider>
          </CitiIQCard>
        </Grid>
      </Grid>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={updateSubscriptionSnackbar}
        autoHideDuration={3000}
        message={'Subscription Updated! Refresh page to see the update.'}
        onClose={() => setUpdateSubscriptionSnackbar(false)}
      />
      <Dialog aria-labelledby="simple-dialog-title" open={showAddUserDialog}>
        <AddUser
          onOpenDialog={() => setShowAddUserDialog(true)}
          onCloseDialog={() => setShowAddUserDialog(false)}
          handleUserAdded={() => setNewUserAdded(true)}
        />
      </Dialog>
    </div>
  )
}

function mapStateToProps(state) {
  return {
    userStore: state.UserStore,
    adminStore: state.AdminStore,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        getAllUsersSubscriptions,
        getAllUserRoles,
        deleteSubscription,
        createSubscription,
      },
      dispatch
    ),
  }
}

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(AdminView)
)
