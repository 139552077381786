import { cityProfile } from '../../actions/CityActions.js'
import { getGoalByUserAndCity } from '../../actions/GoalActions.js'
import { closeDrawer, closeIndDialog } from '../../actions/ModalActions.js'
import {
  getAllConsiderations,
  setCurrentPage,
  toggleMultiCityView,
} from '../../actions/PageActions.js'
import { logoutUser, viewedIntro } from '../../actions/UserActions.js'
import { updateCurrentCityId } from '../../actions/UserActions.js'
import { getWatchConsiderations } from '../../actions/WatchListActions.js'
import logo from '../../assets/citiiqlogo.png'
import sideBarImg from '../../assets/sidebar-cityscape.jpg'
import { renderParagraphWithLinebreaks } from '../../helpers/string.js'
import useLanguage from '../../state/hooks/useLanguage'
import ForceLogoutDialog from '../ForceLogoutDialog/ForceLogoutDialog.js'
import { InfoDrawer, IndicatorDialog, Icon, IntroDialog } from '../index.js'
import LanguageSelector from './LanguageSelector.jsx'
import './PageWrapper.scss'
import {
  renderArea,
  renderPopulation,
  renderFeaturedAttributes,
  renderWeather,
  renderAppBarContents,
  renderSingleCityLinks,
} from './PageWrapperComponents.js'
import AppBar from '@material-ui/core/AppBar'
import Collapse from '@material-ui/core/Collapse'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Snackbar from '@material-ui/core/Snackbar'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import Toolbar from '@material-ui/core/Toolbar'
import AccountCircle from '@material-ui/icons/AccountCircle'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import Menu from '@material-ui/icons/Menu'
import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'

const PageWrapper = ({
  children,
  actions,
  history,
  modalStore,
  goalStore,
  cityStore,
  userStore,
  pageStore,
  intl,
}) => {
  const [openSidebar, setOpenSidebar] = React.useState(false)
  const [openTrends, setOpenTrends] = React.useState(false)
  const [openProfileOptions, setOpenProfileOptions] = React.useState(false)
  const [showIntroModal, setShowIntroModal] = React.useState(
    !userStore.getIn(['userInfo', 'viewed_intro'])
  )
  const [selectCitySnackbarPageName, setSelectCitySnackbarPageName] =
    React.useState('')
  const [showSelectCitySnackbar, setShowSelectCitySnackbar] =
    React.useState(false)

  const [selectedCityId, setSelectedCityId] = React.useState(
    userStore.getIn(['selectedCityId'])
  )
  const [cityCountryMap, setCityCountryMap] = React.useState(
    pageStore.get('cityCountryMap').toJS()
  )

  const [featuredA, setFeaturedA] = React.useState([])
  const [area, setArea] = React.useState(0)
  const [population, setPopulation] = React.useState({})
  // eslint-disable-next-line no-unused-vars
  const [sdg, setSdg] = React.useState({})
  const [currentWeather, setCurrentWeather] = React.useState({})
  const [cityInfoLoading, setCityInfoLoading] = React.useState(false)
  const [umfEnabled, setUmfEnabled] = React.useState(false)
  const { language } = useLanguage()
  const { locale } = language

  React.useEffect(() => {
    // on component mount
    actions.getWatchConsiderations()
    addThrottledResizeListener()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    return () => {
      // on component unmount
      removeThrottledResizeListener()
    }
  }, [])

  React.useEffect(() => {
    if (Boolean(selectedCityId)) {
      setCityInfoLoading(true)
      actions.cityProfile(selectedCityId).then((response) => {
        const featuredAttributes = []
        ;(response?.profile?.attributes ?? []).forEach((a) => {
          a.metrics.forEach((m) => {
            if (m.featured) {
              featuredAttributes.push(m)
            }
          })
        })
        setFeaturedA(featuredAttributes)
        setArea(response?.area ?? 0)
        setPopulation(response?.population ?? {})
        setSdg(response?.sdg ?? {})
        setUmfEnabled(response?.umfEnabled ?? false)
        setCurrentWeather(response?.weather)
        setCityInfoLoading(false)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCityId])

  React.useEffect(() => {
    if (history.location.pathname === '/dashboard-multicity') {
      if (!pageStore.get('isMultiCity')) {
        actions.toggleMultiCityView(true)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.pathname, pageStore.get('isMultiCity')])

  React.useEffect(() => {
    const userId = userStore.getIn(['userInfo', 'id'])
    if ((userId || userId === 0) && (selectedCityId || selectedCityId === 0)) {
      actions.getGoalByUserAndCity(userId, selectedCityId)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userStore.getIn(['userInfo', 'id']), selectedCityId])

  React.useEffect(() => {
    if (
      userStore.getIn(['selectedCityId']) ||
      userStore.getIn(['selectedCityId']) === 0
    ) {
      actions.getGoalByUserAndCity(
        userStore.getIn(['userInfo', 'id']),
        userStore.getIn(['selectedCityId'])
      )
    }

    const newSelectedCityId = userStore.getIn(['selectedCityId'])
    setSelectedCityId(newSelectedCityId)
    if (Boolean(newSelectedCityId)) {
      actions.cityProfile(newSelectedCityId).then((response) => {
        const featuredAttributes = []
        ;(response?.profile?.attributes ?? []).forEach((a) => {
          a.metrics.forEach((m) => {
            if (m.featured) {
              featuredAttributes.push(m)
            }
          })
        })
        setFeaturedA(featuredAttributes)
        setArea(response?.area ?? 0)
        setPopulation(response?.population ?? {})
        setSdg(response?.sdg ?? {})
        setUmfEnabled(response?.umfEnabled ?? false)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userStore.getIn(['selectedCityId'])])

  React.useEffect(() => {
    setCityCountryMap(pageStore.get('cityCountryMap').toJS())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    pageStore.get('cityCountryMap'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    pageStore.get('multiCityInfos').size,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    userStore.getIn(['selectedQuarter']),
  ])

  const handleSidebarClose = () => {
    setOpenSidebar(false)
  }

  const handlePageChange = (page) => {
    if (page === 'city-scores') {
      actions.setCurrentPage(page, 0)
    } else {
      actions.setCurrentPage(page)
    }
    history.push('/' + page)
    setOpenSidebar(false)
  }

  const logoutUser = () => {
    actions.logoutUser().then((response) => {
      history.push('/')
    })
  }

  const viewedIntroModal = () => {
    actions
      .viewedIntro(userStore.getIn(['userInfo', 'id']))
      .then((response) => {
        if (response) {
          setShowIntroModal(false)
        }
      })
  }

  const addThrottledResizeListener = () => {
    var delay = 250,
      throttled = false
    window.addEventListener('resize', () => {
      if (!throttled) {
        throttled = true
        setTimeout(function () {
          throttled = false
        }, delay)
      }
    })
  }

  const removeThrottledResizeListener = () => {
    var delay = 250,
      throttled = false
    window.removeEventListener('resize', () => {
      if (!throttled) {
        throttled = true
        setTimeout(function () {
          throttled = false
        }, delay)
      }
    })
  }

  const showMultiCityPopup = (pageName) => {
    setSelectCitySnackbarPageName(pageName)
    setShowSelectCitySnackbar(true)
    setOpenSidebar(false)
  }

  const isDesktop = window.innerWidth > 980
  const panelWidth = 283
  const activePage = history.location.pathname.split('/')[1]
  const isMultiCity = pageStore.get('isMultiCity')

  // NOTE: private_user should not have access to 'User Profile' and 'Log out' options
  const isPrivateUser = userStore.get('role').toJS().title === 'private_user'
  return (
    <div className="pageWrapper">
      <AppBar position="fixed" className={'appBar'} id="mainHeader">
        <Toolbar
          classes={{ root: 'toolBar' + (isDesktop ? ' shuffleRight' : '') }}
        >
          <div className="appBarContentLeft">
            {!isDesktop && (
              <div
                onClick={() => setOpenSidebar(true)}
                className={'sideMenuDiv'}
              >
                <Menu fontSize="inherit" className={'sideMenuIcon'} />
              </div>
            )}
            {renderAppBarContents({
              actions,
              pageStore,
              userStore,
              selectedCityId,
              cityCountryMap,
              handlePageChange,
            })}
          </div>
          <div className="appBarContentRight">
            {pageStore.get('isMultiCity') ? (
              renderParagraphWithLinebreaks({
                intl: intl,
                messageId: 'dashboard.description',
              })
            ) : cityInfoLoading ? null : (
              <div className="cityAttributes">
                {renderArea({ area, intl, locale })}
                {renderPopulation({ population, intl, locale })}
                {renderFeaturedAttributes({ featuredA })}
                {renderWeather({
                  currentWeather,
                  intl,
                  locale,
                })}
                <Link to="/city-profile" className="attribute">
                  <span className="info">
                    <Icon name="city-profile" />
                  </span>
                </Link>
              </div>
            )}
            {/* Note: LanguageSelector is hidden for now */}
            <LanguageSelector />
          </div>
        </Toolbar>
      </AppBar>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{
          root: 'NewMuiSnackbar',
        }}
        open={showSelectCitySnackbar}
        autoHideDuration={5000}
        message={intl.formatMessage(
          { id: 'select.a.city.reminder' },
          {
            selectCitySnackbarPageName:
              selectCitySnackbarPageName == ''
                ? ''
                : intl.formatMessage({
                    id: selectCitySnackbarPageName,
                  }),
          }
        )}
        onClose={() => setShowSelectCitySnackbar(false)}
      />
      <SwipeableDrawer
        variant={isDesktop ? 'permanent' : 'temporary'}
        open={openSidebar || false}
        onOpen={() => setOpenSidebar(true)}
        onClose={() => handleSidebarClose()}
        classes={{ paperAnchorDockedLeft: 'sideDrawer' }}
      >
        <div className={'sidebarWrapper'}>
          <div className={'sidebarBackground'}>
            <img src={sideBarImg} alt={''} />{' '}
          </div>
          <div
            style={{ width: panelWidth + 'px', height: '100%' }}
            className={'sidebarContent'}
          >
            <img
              src={logo}
              className={'siteLogo'}
              alt=""
              onClick={() => handlePageChange('dashboard')}
            />
            <List component="nav">
              <ListItem
                button={isPrivateUser ? false : true}
                onClick={
                  isPrivateUser
                    ? null
                    : () => setOpenProfileOptions(!openProfileOptions)
                }
              >
                <ListItemIcon>
                  <AccountCircle classes={{ root: 'profileIcon' }} />
                </ListItemIcon>
                <ListItemText
                  primary={
                    userStore.getIn(['userInfo', 'first_name']) +
                    ' ' +
                    userStore.getIn(['userInfo', 'last_name'])
                  }
                  classes={{ primary: 'sidebarText' }}
                />
              </ListItem>
              {!isPrivateUser && (
                <Collapse in={openProfileOptions} timeout="auto" unmountOnExit>
                  <ListItem
                    button
                    onClick={() => handlePageChange('profile')}
                    classes={{
                      button: activePage === 'profile' ? 'darkened' : '',
                    }}
                  >
                    <ListItemText
                      primary={intl.formatMessage({ id: 'nav.side.profile' })}
                      classes={{
                        primary:
                          activePage === 'dimension-comparison' ? 'bolded' : '',
                      }}
                    />
                  </ListItem>
                  <ListItem button onClick={() => logoutUser()}>
                    <ListItemText
                      primary={intl.formatMessage({ id: 'nav.side.logout' })}
                    />
                  </ListItem>
                </Collapse>
              )}
            </List>
            <Divider />
            <List component="nav">
              <ListItem
                key="dashboard"
                button
                onClick={() => handlePageChange('dashboard')}
                classes={{
                  button: activePage === 'dashboard' ? 'darkened' : '',
                }}
              >
                <Icon name="dashboard" className={'icon'} />
                <ListItemText
                  primary={
                    <FormattedMessage
                      id="dashboard.navigation"
                      description="nav"
                    />
                  }
                  classes={{ primary: 'sidebarText' }}
                />
                <KeyboardArrowRight />
              </ListItem>
              {renderSingleCityLinks({
                isMultiCity,
                activePage,
                showMultiCityPopup,
                handlePageChange,
                openTrends,
                setOpenTrends,
              })}
              <ListItem
                key="methodology"
                button
                onClick={() => handlePageChange('methodology')}
                classes={{
                  button: activePage === 'methodology' ? 'darkened' : '',
                }}
              >
                <Icon name="methodology" className={'icon'} />
                <ListItemText
                  primary={
                    <FormattedMessage
                      id="methodology.navigation"
                      description="nav"
                    />
                  }
                  className={'sidebarText'}
                  classes={{ primary: 'sidebarText' }}
                />
                <KeyboardArrowRight />
              </ListItem>
              {umfEnabled && !pageStore.get('isMultiCity') ? (
                <ListItem
                  key="urban-monitoring-framework"
                  button
                  onClick={() => handlePageChange('urban-monitoring-framework')}
                  classes={{
                    button:
                      activePage === 'urban-monitoring-framework'
                        ? 'darkened'
                        : '',
                  }}
                >
                  <Icon name="urbanMonitoringFramework" className={'icon'} />
                  <ListItemText
                    primary={
                      <FormattedMessage
                        id="urbanMonitoringFramework.navigation"
                        description="nav"
                      />
                    }
                    className={'sidebarText'}
                    classes={{ primary: 'sidebarText' }}
                  />
                  <KeyboardArrowRight />
                </ListItem>
              ) : null}

              {userStore.getIn(['role', 'title']) === 'admin' && (
                <ListItem
                  key="admin-view"
                  button
                  onClick={() => handlePageChange('admin-view')}
                  classes={{
                    button: activePage === 'admin-view' ? 'darkened' : '',
                  }}
                >
                  <Icon name="admin" className={'icon'} />
                  <ListItemText
                    primary={
                      <FormattedMessage
                        id="admin.navigation"
                        description="nav"
                      />
                    }
                    className={'sidebarText'}
                    classes={{ primary: 'sidebarText' }}
                  />
                  <KeyboardArrowRight />
                </ListItem>
              )}
            </List>
          </div>
        </div>
      </SwipeableDrawer>
      <div
        className={`pageContent current-page-${pageStore.get('currentPage')} ${
          isMultiCity ? 'dashboard-isMultiCity' : ''
        }`}
        style={{ marginLeft: isDesktop ? panelWidth + 'px' : '0px' }}
      >
        <InfoDrawer />
        <IndicatorDialog
          open={modalStore.get('dialogIndicatorOpen')}
          onClose={actions.closeIndDialog}
        />
        <ForceLogoutDialog />
        {showIntroModal && (
          <IntroDialog
            open={showIntroModal}
            onClose={() => viewedIntroModal()}
          />
        )}
        {children}
      </div>
    </div>
  )
}

function mapStateToProps(state) {
  return {
    cityStore: state.CityStore,
    pageStore: state.PageStore,
    userStore: state.UserStore,
    modalStore: state.ModalStore,
    goalStore: state.GoalStore,
    watchListStore: state.WatchListStore,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        cityProfile,
        setCurrentPage,
        logoutUser,
        closeDrawer,
        getGoalByUserAndCity,
        closeIndDialog,
        getAllConsiderations,
        viewedIntro,
        getWatchConsiderations,
        updateCurrentCityId,
        toggleMultiCityView,
      },
      dispatch
    ),
  }
}

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(PageWrapper))
)
