export const getLocalizedCityName = (cityInfo, language) => {
  const { locale } = language
  const { name, lang } = cityInfo
  const defaultName = name
  if (!lang || !locale) return defaultName
  return lang.name_new.find((i) => i.lang === locale).value ?? defaultName
}
export const getLocalizedCountryName = (cityInfo, language) => {
  const { locale } = language
  const { country, lang } = cityInfo
  const defaultName = country
  if (!lang || !locale) return defaultName
  return lang.country_new.find((i) => i.lang === locale).value ?? defaultName
}
export const getLocalizedSdgLabel = (sdgData, locale) => {
  const { graphTitle_new, graphTitle } = sdgData
  const defaultName = graphTitle
  if (!graphTitle_new || !locale) return defaultName
  return graphTitle_new.find((i) => i.lang === locale).value ?? defaultName
}
export const getLocalizedPopulationLabel = (population, locale) => {
  const { label_new, label } = population
  const defaultName = label
  if (!label_new || !locale) return defaultName
  return label_new.find((i) => i.lang === locale).value ?? defaultName
}
