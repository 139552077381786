import {
  getAllCityInfo,
  getMultiCityInfos,
  getAllAvailableQuarters,
  toggleMultiCityView,
} from '../../actions/PageActions'
import { userInit, logoutUser } from '../../actions/UserActions'
import {
  getFilteredCities,
  updateCurrentCityId,
  updateSelectedQuarter,
} from '../../actions/UserActions'
import { LoadingScreen, PageWrapper } from '../../components'
import {
  getSelectedQuarterString,
  parseSelectedQuarterValue,
} from '../../constants/Constants'
import { gAnalytics } from '../../helpers/analytics'
import { validateLoginOrigins } from '../../helpers/secureLogin'
import {
  AdminView,
  CityProfile,
  CityScores,
  ConsiderationComparison,
  Dashboard,
  DimensionComparison,
  IndicatorComparison,
  ForgotPassword,
  Login,
  Methodology,
  SustainabilityDevelopmentGoals,
  Profile,
  Register,
  ResetPassword,
  UrbanMonitoringFramework,
} from '../../pages'
import LanguageProvider from '../../state/contexts/LanguageProvider'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {
  Redirect,
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom'
import { bindActionCreators } from 'redux'

const App = ({ actions, pageStore, userStore }) => {
  const pagePath = window.location.pathname.split('/')[1]
  const userHasSession = userStore.get('hasSession')
  const loginUserExists = userStore.get('loginUserExists')
  const selectedCityId = userStore.getIn(['selectedCityId'])
  const selectedQuarter = userStore.getIn(['selectedQuarter'])
  const allQuarters = pageStore.getIn(['allQuarters']).toJS()
  const userId = userStore.getIn(['userInfo', 'id'])
  const subscriptedCityIds = (userStore.get('subscriptions') || [])
    .map((s) => s.get('city_id'))
    .toJS()
  const cities = pageStore.get('multiCityInfos')
  const [currentPage, setCurrentPage] = useState(pagePath)
  const [loading, setLoading] = useState(true)
  const [initMultiCityInfoRequest, setInitMultiCityInfoRequest] =
    useState(false)

  function validateUserOrigin() {
    const origin = userStore.get('login_origin')
    if (!validateLoginOrigins(origin)) {
      actions.logoutUser()
    }
  }

  function handlePrivateUser() {
    validateUserOrigin()
    window.onbeforeunload = function (event) {
      event.preventDefault()
      actions.logoutUser()
      return true
    }
  }

  useEffect(() => {
    if (loginUserExists) {
      if (userStore.getIn(['role', 'title']) === 'private_user') {
        handlePrivateUser()
      }

      actions.getFilteredCities(userId)
      actions.getAllAvailableQuarters(subscriptedCityIds)
      // if initial login, default set selectedCityId to first item in array
      if (!selectedCityId && subscriptedCityIds.length > 0) {
        actions.updateCurrentCityId(subscriptedCityIds[0])
        if (subscriptedCityIds.length === 1) {
          actions.toggleMultiCityView(false)
        }
      }
    }
    // eslint-disable-next-line
  }, [loginUserExists, selectedQuarter])

  useEffect(() => {
    if (loginUserExists) {
      // if initial login, default set selectedQuarter to latest
      if (!selectedQuarter && allQuarters.length > 0) {
        const value = getSelectedQuarterString(allQuarters[0])
        actions.updateSelectedQuarter(value)
      }
      if (selectedQuarter && cities.size === 0 && !initMultiCityInfoRequest) {
        const { year, quarter } = parseSelectedQuarterValue(selectedQuarter)
        actions.getMultiCityInfos(subscriptedCityIds, year, quarter)
        setInitMultiCityInfoRequest(true)
      }
    } else {
      if (initMultiCityInfoRequest) {
        setInitMultiCityInfoRequest(false)
      }
    }
    // eslint-disable-next-line
  }, [loginUserExists, selectedQuarter, allQuarters, cities])

  useEffect(() => {
    if (loginUserExists && selectedCityId && selectedQuarter) {
      const { year, quarter } = parseSelectedQuarterValue(selectedQuarter)
      actions.getAllCityInfo(selectedCityId, year, quarter)
      actions.getMultiCityInfos(subscriptedCityIds, year, quarter)
    }
    // eslint-disable-next-line
  }, [selectedCityId, selectedQuarter])

  useEffect(() => {
    async function initUser() {
      setLoading(true)
      const response = await actions.userInit()
      if (response.InitUser.exists) {
        // dont do anything before loginUserExist
      }
      gAnalytics('pageview', [document.location.pathname])
      setLoading(false)
    }
    initUser()
    // eslint-disable-next-line
  }, [userHasSession])

  useEffect(() => {
    if (pagePath !== currentPage) {
      window.scrollTo(0, 0)
      setCurrentPage(pagePath)
      gAnalytics('pageview', [document.location.pathname])
    }
  }, [pagePath, currentPage])

  function waitToRender() {
    if (userStore.get('loadingUser')) return <LoadingScreen />
    if (!userStore.get('hasSession')) return <Login />
    return <Redirect to="/dashboard" />
  }

  if (loading) {
    return <LoadingScreen />
  } else {
    return (
      <LanguageProvider userId={userId}>
        <Router>
          <Switch>
            <Route path="/register" component={Register} />
            <Route path="/forgot-password/:token" component={ResetPassword} />
            <Route exact path="/forgot-password" component={ForgotPassword} />
            <PrivateRoute path="/city-profile" component={CityProfile} />
            <PrivateRoute path="/city-scores" component={CityScores} />
            <PrivateRoute path="/dashboard" component={Dashboard} />
            <PrivateRoute
              path="/consideration-comparison"
              component={ConsiderationComparison}
            />
            <PrivateRoute
              path="/dimension-comparison"
              component={DimensionComparison}
            />
            <PrivateRoute
              path="/indicator-comparison"
              component={IndicatorComparison}
            />
            <PrivateRoute path="/profile" component={Profile} />
            <PrivateRoute path="/methodology" component={Methodology} />
            <PrivateRoute
              path="/sdg"
              component={SustainabilityDevelopmentGoals}
            />
            <PrivateRoute
              path="/urban-monitoring-framework"
              component={UrbanMonitoringFramework}
            />
            {userStore.getIn(['role', 'title']) === 'admin' && (
              <PrivateRoute path="/admin-view" component={AdminView} />
            )}
            <Route render={waitToRender} />
          </Switch>
        </Router>
      </LanguageProvider>
    )
  }
}

function mapStateToProps(state) {
  return {
    userStore: state.UserStore,
    pageStore: state.PageStore,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        userInit,
        logoutUser,
        getAllCityInfo,
        getFilteredCities,
        getMultiCityInfos,
        getAllAvailableQuarters,
        updateCurrentCityId,
        updateSelectedQuarter,
        toggleMultiCityView,
      },
      dispatch
    ),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)

const PrivateRouteComponent = ({ component, path, userStore, ...props }) => {
  const Component = component
  return (
    <Route
      path={path}
      render={(props) => {
        if (userStore.get('loadingUser')) {
          return <LoadingScreen />
        }
        if (userStore.get('hasSession')) {
          return (
            <PageWrapper>
              <Component {...props} />
            </PageWrapper>
          )
        }
        return <Login />
      }}
    />
  )
}

const PrivateRoute = connect(
  mapStateToProps,
  mapDispatchToProps
)(PrivateRouteComponent)
