import IndicatorChart from '../../components/IndicatorChart/IndicatorChart'
import './IndicatorComparison.scss'
import { Grid } from '@material-ui/core'
import ArrowBack from '@material-ui/icons/ArrowBack'
import React from 'react'
import Helmet from 'react-helmet'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'

const IndicatorComparison = ({ pageStore, intl }) => {
  if (pageStore.get('isMultiCity')) {
    return <Redirect to={'dashboard'} />
  }
  return (
    <div className="IndicatorComparisonPage">
      <Helmet>
        <title>
          CitiIQ -{' '}
          {intl.formatMessage({ id: 'indicator.comparison.navigation' })}
        </title>
      </Helmet>
      <div className="top-strip">
        <Link to="/dashboard" className="navigation">
          <h1>
            <ArrowBack className="nav-icon" />
            <FormattedMessage id="trend.navigation" description="nav" />
          </h1>
        </Link>
      </div>
      <Grid container spacing={24}>
        <Grid item xs={12}>
          <IndicatorChart />
        </Grid>
      </Grid>
    </div>
  )
}

function mapStateToProps(state) {
  return {
    pageStore: state.PageStore,
  }
}

export default injectIntl(connect(mapStateToProps)(IndicatorComparison))
