import { getAllCityInfo, getMultiCityInfos } from '../../actions/PageActions'
import { updateSelectedQuarter } from '../../actions/UserActions'
import {
  getSelectedQuarterString,
  parseSelectedQuarterValue,
} from '../../constants/Constants'
import './QuarterSelector.scss'
import { FormControl, MenuItem, Select } from '@material-ui/core'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

const QuarterSelector = ({
  isMultiCity,
  selectedCityId,
  userStore,
  pageStore,
  actions,
}) => {
  const [isLoadingNewQuarter, setIsLoadingNewQuarter] = React.useState(false)
  const [allQuarters, setAllQuarters] = React.useState([])
  const selectedQuarter = userStore.getIn(['selectedQuarter'])
  const isLoadingMultiCityInfo = pageStore.getIn(['isLoadingMultiCityInfo'])
  const isLoadingSingleCityInfo = pageStore.getIn(['isLoadingSingleCityInfo'])

  React.useEffect(() => {
    if (isLoadingNewQuarter) {
      if (isMultiCity && !isLoadingMultiCityInfo) {
        setIsLoadingNewQuarter(false)
      }
      if (!isMultiCity && !isLoadingSingleCityInfo) {
        setIsLoadingNewQuarter(false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isLoadingNewQuarter,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    pageStore.getIn(['isLoadingMultiCityInfo']),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    pageStore.getIn(['isLoadingSingleCityInfo']),
  ])

  React.useEffect(() => {
    if (
      pageStore.get('allQuarters').toJS().length === 0 ||
      Object.keys(pageStore.get('quarterCityMap').toJS() ?? {}).length === 0
    )
      return

    const filteredCitiesList = userStore.getIn(['filteredCities'])

    if (isMultiCity && filteredCitiesList.length === 0) {
      // if no cities are selected, show all quarters
      setAllQuarters(
        pageStore
          .get('allQuarters')
          .toJS()
          .sort((a, b) => {
            return (
              Number(`${a.year}${a.quarter}`) - Number(`${b.year}${b.quarter}`)
            )
          })
          .reverse()
      )
      return
    }

    let quarterCityMap = pageStore.get('quarterCityMap').toJS()

    const all = Object.keys(quarterCityMap).reduce((total, q) => {
      const isQuarterIncluded =
        // if filtering by multiple cities (multi city dashboard), check if at least 1 city is included in the quarter
        isMultiCity
          ? filteredCitiesList.some((city) =>
              quarterCityMap[q].includes(city.id)
            )
          : // if filtering by single city (single city dashboard), check if the city is included in the quarter
            quarterCityMap[q].includes(selectedCityId)
      if (isQuarterIncluded) {
        total.push(parseSelectedQuarterValue(q))
      }
      return total
    }, [])

    setAllQuarters(
      all
        .sort((a, b) => {
          return (
            Number(`${a.year}${a.quarter}`) - Number(`${b.year}${b.quarter}`)
          )
        })
        .reverse()
    )
    if (
      !all.map((q) => getSelectedQuarterString(q)).includes(selectedQuarter)
    ) {
      actions.updateSelectedQuarter(all[0])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    pageStore.get('allQuarters').toJS().length,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    Object.keys(pageStore.get('quarterCityMap').toJS() ?? {}).length,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    selectedCityId,
    userStore.getIn(['filteredCities']),
  ])
  if (!selectedQuarter) return null

  const handleQuarterSelectChange = (value) => {
    // don't refetch data if the user selects the active quarter
    if (userStore.get('selectedQuarter') === value) return

    setIsLoadingNewQuarter(true)
    const subscriptedCityIds = (userStore.get('subscriptions') || [])
      .map((s) => s.get('city_id'))
      .toJS()
    const { year, quarter } = parseSelectedQuarterValue(value)

    actions.updateSelectedQuarter(value)
    actions.getMultiCityInfos(subscriptedCityIds, year, quarter)
    actions.getAllCityInfo(selectedCityId, year, quarter)
  }

  // Hide the quarter selector for single city view for now
  return !isMultiCity ? null : (
    <FormControl className="actions__button">
      <Select
        disableUnderline
        autoWidth
        classes={{
          root: `quarter-select ${
            isLoadingNewQuarter ? 'quarter-select--loading' : ''
          }`,
          select: `quarter-select__select`,
        }}
        value={selectedQuarter}
        onChange={
          isLoadingNewQuarter
            ? () => {}
            : (event) => handleQuarterSelectChange(event.target.value)
        }
      >
        {allQuarters.map((q, idx) => {
          const { year, quarter } = q
          const isSelected = selectedQuarter === `${year}-${quarter}`
          return (
            <MenuItem
              key={getSelectedQuarterString(q)}
              value={getSelectedQuarterString(q)}
              style={
                isSelected
                  ? {
                      backgroundColor: '#22252C',
                    }
                  : null
              }
            >{`Q${quarter}'${String(year).substr(2, 4)}`}</MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}

function mapStateToProps(state) {
  return {
    userStore: state.UserStore,
    pageStore: state.PageStore,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        updateSelectedQuarter,
        getMultiCityInfos,
        getAllCityInfo,
      },
      dispatch
    ),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(QuarterSelector)
