export const defaultOptions = {
  chart: {
    type: 'bar',
  },
  title: {
    text: '',
  },
  // NOTE:
  // for the horizontal bar
  // xAxis & yAxis are flipped
  xAxis: {
    categories: [
      'CityName 1',
      'CityName 2',
      'CityName 3',
      'CityName 4',
      'CityName 5',
    ],
  },
  // `reversedStacks`
  // If true, the first series in a stack
  // will be drawn on top in a positive, non-reversed Y axis.
  // If false, the first series is in
  // the base of the stack.
  // Defaults to true.
  yAxis: {
    min: 0,
    max: 100,
    startOnTick: false,
    endOnTick: false,
    reversedStacks: false,
    title: {
      text: '',
    },
  },
  tooltip: {
    useHTML: true,
    formatter: function () {
      const { name, points } = this.series
      // The first returned item is the header,
      // subsequent items are the points
      return [`<b>${name}</b></br>`, `<p></p>`].concat(
        points.map(
          (point) => `${point.category} : ${point.percentage.toFixed(1)} %<br/>`
        )
      )
    },
  },
  legend: {
    reversed: false,
    enabled: false,
  },
  plotOptions: {
    series: {
      borderWidth: 1,
      borderColor: '#31353D',
      stacking: 'normal',
      stickyTracking: false,
    },
  },
  series: [
    {
      name: 'Consideration 1',
      data: [5, 3, 4, 7, 2],
    },
    {
      name: 'Consideration 2',
      data: [2, 2, 3, 2, 1],
    },
    {
      name: 'Consideration 3',
      data: [3, 4, 4, 2, 5],
    },
    {
      name: 'Consideration 4',
      data: [5, 3, 4, 7, 2],
    },
    {
      name: 'Consideration 5',
      data: [2, 2, 3, 2, 1],
    },
    {
      name: 'Consideration 6',
      data: [3, 4, 4, 2, 5],
    },
    {
      name: 'Consideration 7',
      data: [5, 3, 4, 7, 2],
    },
    {
      name: 'Consideration 8',
      data: [2, 2, 3, 2, 1],
    },
    {
      name: 'Consideration 9',
      data: [3, 4, 4, 2, 5],
    },
  ],
  credits: {
    enabled: false,
  },
}
