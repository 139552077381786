export function gAnalytics(type, params) {
  if (!params.length) {
    return
  }
  function usableParams(type, params) {
    let newParams = params
    if (type === 'pageview') {
      newParams = newParams.map((p) => {
        let newParam = p.split('/').join('')
        if (!newParam) {
          newParam = 'login'
        }
        return newParam
      })
    }
    return newParams
  }
  if (process.env.REACT_APP_STAGE === 'production') {
    window.ga('send', type, ...usableParams(type, params))
  }
}

export function gAnalyticsSetSesssion(user, city) {
  if (process.env.REACT_APP_STAGE === 'production') {
    window.ga('set', { user_id: user, city_id: city })
  }
}

export const considerationsLookup = {
  35: 'Water supply',
  34: 'Waste Management',
  7: 'Energy supply',
  24: 'Public Welfare',
  27: 'Shelter + Housing',
  1: 'Basic Education',
  26: 'Safety + Security',
  6: 'Employment',
  10: 'Ethical Compass',
  23: 'Public Health',
  15: 'Food Security',
  13: 'Family',
  21: 'Mobility + Transportation',
  12: 'Fair + Just System',
  25: 'Resilient Infrastructure',
  4: 'Connectivity + Communication',
  20: 'Investment',
  32: 'Trade',
  5: 'Efficiency',
  22: 'Political Stability',
  2: 'Children + Youth',
  30: 'Talent Pool',
  19: 'Innovation + Entrepreneurship',
  17: 'Higher Education',
  14: 'Gender Equality',
  9: 'Environmental Stewardship',
  33: 'Urban Design',
  8: 'Entertainment',
  16: 'Healthy Lifestyle',
  29: 'Social Isolation',
  31: 'Tourism',
  3: 'Collaboration',
  11: 'Excellence',
  18: 'Hope',
}

export const dimensionsLookup = {
  0: 'Overall',
  1: 'Basic Needs',
  2: 'Competitiveness',
  3: 'Opportunity',
  4: 'Livability',
  5: 'Destiny',
}

export const indicatorsLookup = {
  1: 'Access to Education',
  2: 'Affordability of Dining',
  3: 'Affordability of Entertainment',
  4: 'Air Quality',
  5: 'Airport Throughput',
  6: 'Annual Economic Growth',
  7: 'Arts and Culture Community',
  8: 'Availability of Nursing Personnel',
  9: 'Balanced Distribution of Age Demographic',
  10: 'Basic Internet Connection',
  11: 'Business Excellence',
  12: 'Business Start-Up Time',
  13: 'Child Labour',
  14: 'Child Nutrition',
  15: 'City Restaraunts',
  16: 'Communications Infrastructure',
  17: 'Commute Time',
  18: 'Consumer Confidence',
  19: 'Corruption',
  20: 'Cost of Borrowing',
  21: 'Cost of Living',
  22: 'Credit Rating',
  23: 'Cross-Consideration Distinction',
  25: 'Dedicated Cycleways',
  26: 'Developed Business Climate',
  27: 'Distinction of City Transportation',
  28: 'Distinction of Innovation',
  29: 'Distinction of Municipal Architecture',
  30: 'Distribution of Gross Domestic Product',
  31: 'Domestic and International Security',
  32: 'Energy Network Efficiency',
  33: 'Entertainment Reputation',
  34: 'Female Voter Participation',
  35: 'Food Supply Reliability',
  36: 'Foreign Investment',
  37: 'Freedom of Speech',
  38: 'Global Prominence',
  39: 'Government Fiscal Independence',
  40: 'Green Space',
  41: 'Greenhouse Gas Emissions',
  42: 'Healthcare Personell',
  43: 'Healthy Population Weight',
  44: 'High Capacity Public Transport',
  45: 'High Speed Cellular Services',
  46: 'High Speed Internet Connection',
  47: 'Homelessness',
  48: 'Hospital Capacity',
  49: 'Household Capacity',
  50: 'Housing Affordability',
  51: 'Income Equality',
  52: 'Innovative Research Institutions',
  53: 'International Trade',
  54: 'Internationally Influential Companies',
  55: 'Investment Risk',
  56: 'Job Creation',
  57: 'Labour Force Gender Equality',
  58: 'Median Household Income',
  59: 'Mitigation of Diabetes',
  60: 'Mitigation of Infectious Diseases',
  61: 'Mobile Phone Usage',
  62: 'National Economic Strength',
  63: 'National Gender Equality',
  64: 'National Population Happiness',
  65: 'New Mother Maturity',
  66: 'Patented Innovations',
  67: 'Population Above Poverty Threshold',
  68: 'Population Density ',
  69: 'Population Electricity Access',
  70: 'Population Equality',
  71: 'Population Life Expectancy',
  72: 'Population Literacy',
  73: 'Postgraduate Degrees',
  74: 'Prevention of Child Mortality',
  75: 'Prevention of Drug-Related Deaths',
  77: 'Primary Level Education Ensurance',
  78: 'Proportion of Two-Parent Families',
  79: 'Protection Against Natural Disaster',
  80: 'Protection against Sexual Violence',
  81: 'Provision for Paid Parental Leave',
  82: 'Public Access to Appropriate Shelter',
  84: 'Public Transport Ridership',
  85: 'Quality Of Living',
  86: 'Recycling Services',
  87: 'Refuse Collection Services',
  88: 'Renewable Energy',
  89: 'Research and Development',
  90: 'Safety from Domestic Abuse',
  91: 'Secondary Level Education Endurance',
  92: 'Security from Homocide',
  93: 'Sights and Landmarks',
  94: 'Sporting and Event Facilities',
  95: 'Strength of Fire-Related Emergency Services',
  96: 'Strength of Police Related Emergency Services',
  97: 'Suicide Prevention',
  98: 'Sustainable Access to Basic Sanitation',
  99: 'Sustainable Access to Basic Water Sources',
  100: 'Sustainable Access to Safely Managed Sanitation',
  101: 'Sustainable Access to Safely Managed Water Sources',
  102: 'Sustainable Population Fertility',
  103: 'Taxation of Income',
  104: 'Taxational Barriers to Investment',
  105: 'Tertiary Degrees',
  106: 'Tourism Revenue',
  107: 'Tourism Visits',
  108: 'Transportation Fatalities',
  109: 'Unemployment',
  110: 'Universities Ranking',
  111: 'Unrestricted Business Collaboration',
  112: 'Voter Participation',
  113: 'Youth Suicide Prevention',
  114: 'Youth Wellbeing',
}
