// Define list of validate login origins here (for role=`private_user`)
export function validateLoginOrigins(url = '') {
  if (url.includes(process.env.REACT_APP_WAYBASE_LIVE_URL)) {
    return true
  }
  if (url.includes(process.env.REACT_APP_WAYBASE_STAGE_URL)) {
    return true
  }
  if (url.includes(process.env.REACT_APP_WAYBASE_DEVTEST_URL)) {
    return true
  }
  if (url.includes(process.env.REACT_APP_CITIIQ_AFRICAN_CITIES_URL)) {
    return true
  }
  if (url.includes(process.env.REACT_APP_CITIIQ_RADIUS_GLOBAL_URL)) {
    return true
  }
  if (url.includes(process.env.REACT_APP_CITIIQ_RADIUS_GLOBAL_URL)) {
    return true
  }
  if (url.includes('https://app.citiiq.com')) {
    return true
  }
  if (url.includes('localhost') || url.includes('127.0.0.1')) {
    return true
  }
  console.error(`Failed to validate login origin for ${url}`)
  return false
}
