import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { injectIntl } from 'react-intl'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <div p={3}>{children}</div>}
    </Typography>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  }
}

function FullWidthTabs({ dimension, switchDimension, buttons, intl }) {
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
    switch (newValue) {
      case 0:
        switchDimension(0)
        break
      case 1:
        switchDimension(1)
        break
      default:
        break
    }
  }

  useEffect(() => {
    switch (dimension) {
      case 0:
        setValue(0)
        break
      default:
        setValue(1)
        break
    }
  }, [dimension])

  return (
    <div>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="fullWidth"
        aria-label="Toggle between overall view and dimension view"
      >
        <Tab
          textColor={'null'}
          variant="fullWidth"
          label={intl.formatMessage({ id: 'dashboard.tabs.overall' })}
          {...a11yProps(0)}
        />
        <Tab
          textColor={'null'}
          variant="fullWidth"
          label={intl.formatMessage({ id: 'dashboard.tabs.dimensions' })}
          {...a11yProps(1)}
        />
      </Tabs>
      <div>
        <TabPanel value={value} index={0}></TabPanel>
        <TabPanel value={value} index={1}>
          {buttons}
        </TabPanel>
      </div>
    </div>
  )
}
export default injectIntl(FullWidthTabs)
