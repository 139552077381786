import { Icon } from '../../components'
import { getLocalizedPopulationLabel } from '../../helpers/lang.js'
import CityMenu from './CityMenu.js'
import Collapse from '@material-ui/core/Collapse'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import millify from 'millify'
import React from 'react'
import { FormattedMessage } from 'react-intl'

export const renderArea = ({ area, locale }) => {
  if (!area) {
    return <div />
  }
  const areaValue =
    String(area).split('.')[1]?.length > 2
      ? Number(area).toFixed(2)
      : Number(area)
  return (
    <div className="attribute">
      <div className="attrName">
        <FormattedMessage id="cityInfo.area" description="Area" />
      </div>
      <div style={{ textAlign: 'center' }}>
        <Icon name="urban-area-shape" />
      </div>
      <span className="numberText">
        {Intl.NumberFormat(locale, {
          style: 'decimal',
        }).format(areaValue)}
      </span>
      &nbsp;
      <span className="subText">km</span>
      <sup className="subText">2</sup>
    </div>
  )
}
export const renderPopulation = ({ population, locale }) => {
  if (!population) {
    return <div />
  }
  return (
    <div className="attribute">
      <div className="attrName">
        {getLocalizedPopulationLabel(population, locale)}
      </div>
      <div style={{ textAlign: 'center' }}>
        <Icon name="metro-pop-people" />
      </div>
      <span className="numberText">
        {millify(population.value ?? 0, {
          precision: 2,
          locales: locale,
        })}
      </span>
    </div>
  )
}
export const renderFeaturedAttributes = ({ featuredA }) => {
  if (!featuredA.length) {
    return
  }
  return featuredA.map((a, index) => {
    let icon = ''
    const title = a.title.toUpperCase()
    if (title.includes('POPULATION') || title.includes('AREA')) {
      return <div />
    }
    if (title.includes('GDP')) {
      icon = 'gdp-hand'
    }
    if (title.includes('CURRENCY')) {
      icon = 'currency-coins'
    }
    return (
      <span className="attribute" key={index}>
        <div className="attrName">{a.title}</div>
        <div style={{ textAlign: 'center' }}>
          <Icon name={icon} />
        </div>
        {a.abbrev && <span className="numberText">{a.abbrev}</span>}
        {!a.abbrev &&
          a.value.split(' ').map((word, i) => {
            return (
              <span key={i} className={i === 0 ? 'numberText' : ''}>
                {' '}
                {word}{' '}
              </span>
            )
          })}
      </span>
    )
  })
}

export const renderWeather = ({ currentWeather, locale }) => {
  if (!currentWeather) {
    return null
  }
  return (
    <div className="attribute">
      <div className="attrName">
        <FormattedMessage id="cityInfo.weather" description="weather" />
      </div>
      <div className="weatherIcon" style={{ textAlign: 'center' }}>
        <img
          src="/assets/icons/clear.svg"
          alt="weather icon"
          maxWidth="100%"
          height="auto"
          width="auto"
        />
      </div>

      <span className="numberText">
        {Intl.NumberFormat(locale, {
          style: 'decimal',
        }).format(Math.round(currentWeather)) + '°C'}
      </span>
    </div>
  )
}

export const renderAppBarContents = ({
  actions,
  pageStore,
  userStore,
  selectedCityId,
  cityCountryMap,
  handlePageChange,
}) => {
  const isMultiCity = pageStore.get('isMultiCity')
  const subscriptedCityIds = (userStore.get('subscriptions') || [])
    .map((s) => s.get('city_id'))
    .toJS()

  return (
    <>
      <div className="headerContentContainer">
        <div className="appBarMenuContainer">
          <CityMenu
            hideSelect={subscriptedCityIds.length === 1}
            selectedCityId={selectedCityId}
            cityCountryMap={cityCountryMap}
            updateCurrentCityId={actions.updateCurrentCityId}
            selectedQuarter={userStore.getIn(['selectedQuarter'])}
            toggleMultiCityView={actions.toggleMultiCityView}
            handlePageChange={handlePageChange}
            isMultiCity={isMultiCity}
            cities={pageStore.get('multiCityInfos').toJS()}
          />
        </div>
      </div>
    </>
  )
}

export const renderSingleCityLinks = ({
  isMultiCity,
  activePage,
  showMultiCityPopup,
  handlePageChange,
  openTrends,
  setOpenTrends,
}) => {
  return [
    <ListItem
      key="city-scores"
      button
      onClick={
        isMultiCity
          ? (event) => {
              showMultiCityPopup('city.scores.navigation')
            }
          : () => {
              handlePageChange('city-scores')
            }
      }
      classes={{
        button: activePage === 'city-scores' ? 'darkened' : '',
      }}
    >
      <Icon name="city-scores" className={'icon'} />
      <ListItemText
        primary={
          <FormattedMessage id="city.scores.navigation" description="nav" />
        }
        className={'sidebarText'}
        classes={{ primary: 'sidebarText' }}
      />
      <KeyboardArrowRight />
    </ListItem>,
    <ListItem
      key="city-profile"
      button
      onClick={
        isMultiCity
          ? (event) => {
              showMultiCityPopup('city.profile.navigation')
            }
          : () => handlePageChange('city-profile')
      }
      classes={{
        button: activePage === 'city-profile' ? 'darkened' : '',
      }}
    >
      <Icon name="city-profile" className={'icon'} />
      <ListItemText
        primary={
          <FormattedMessage id="cProfile.navigation" description="nav" />
        }
        className={'sidebarText'}
        classes={{ primary: 'sidebarText' }}
      />
      <KeyboardArrowRight />
    </ListItem>,
    <ListItem
      key="sdg"
      button
      onClick={
        isMultiCity
          ? (event) => {
              showMultiCityPopup('sdg.navigation')
            }
          : () => handlePageChange('sdg')
      }
      classes={{
        button: activePage === 'sdg' ? 'darkened' : '',
      }}
    >
      <Icon name="sdg" className={'icon'} />
      <ListItemText
        primary={<FormattedMessage id="sdg.navigation" description="nav" />}
        className={'sidebarText'}
        classes={{ primary: 'sidebarText' }}
      />
      <KeyboardArrowRight />
    </ListItem>,
    <ListItem
      key="trends"
      button
      onClick={
        isMultiCity
          ? (event) => {
              showMultiCityPopup('trend.navigation')
            }
          : () => setOpenTrends(!openTrends)
      }
      classes={{
        button:
          activePage === 'dimension-comparison' ||
          activePage === 'consideration-comparison' ||
          activePage === 'indicator-comparison'
            ? 'darkened'
            : '',
      }}
    >
      <Icon name="trend" className={'icon'} />
      <ListItemText
        primary={<FormattedMessage id="trend.navigation" description="nav" />}
        classes={{ primary: 'sidebarText' }}
      />
      {!openTrends && <KeyboardArrowRight />}
      {openTrends && <KeyboardArrowDown />}
    </ListItem>,
    <Collapse
      key="collapse"
      in={openTrends && !isMultiCity}
      timeout="auto"
      unmountOnExit
      classes={{ wrapper: 'subMenu' }}
    >
      <ListItem
        key="dimension-comparison"
        button
        onClick={() => handlePageChange('dimension-comparison')}
        classes={{
          button:
            activePage === 'dimension-comparison' ||
            activePage === 'consideration-comparison' ||
            activePage === 'indicator-comparison'
              ? 'darkened'
              : '',
        }}
      >
        <ListItemText
          primary={
            <FormattedMessage
              id="dimension.comparison.navigation"
              description="nav"
            />
          }
          classes={{
            primary:
              activePage === 'dimension-comparison'
                ? 'sidebarText bolded'
                : 'sidebarText',
          }}
        />
      </ListItem>
      <ListItem
        key="consideration-comparison"
        button
        onClick={() => handlePageChange('consideration-comparison')}
        classes={{
          button:
            activePage === 'dimension-comparison' ||
            activePage === 'consideration-comparison' ||
            activePage === 'indicator-comparison'
              ? 'darkened'
              : '',
        }}
      >
        <ListItemText
          primary={
            <FormattedMessage
              id="dimension.summary.navigation"
              description="nav"
            />
          }
          classes={{
            primary:
              activePage === 'consideration-comparison'
                ? 'sidebarText bolded'
                : 'sidebarText',
          }}
        />
      </ListItem>
      <ListItem
        key="indicator-comparison"
        button
        onClick={() => handlePageChange('indicator-comparison')}
        classes={{
          button:
            activePage === 'dimension-comparison' ||
            activePage === 'consideration-comparison' ||
            activePage === 'indicator-comparison'
              ? 'darkened'
              : '',
        }}
      >
        <ListItemText
          primary={
            <FormattedMessage
              id="indicator.comparison.navigation"
              description="nav"
            />
          }
          classes={{
            primary:
              activePage === 'indicator-comparison'
                ? 'sidebarText bolded'
                : 'sidebarText',
          }}
        />
      </ListItem>
    </Collapse>,
  ]
}
