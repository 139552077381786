import { registerUser, checkDuplicateEmail } from '../../actions/UserActions'
import citiIQ_background from '../../assets/citiIQ_login_image.jpg'
import logo from '../../assets/citiiq-login-logo.png'
import LanguageSelector from '../../components/PageWrapper/LanguageSelector'
import { gAnalytics } from '../../helpers/analytics'
import './Register.scss'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import Dialog from '@material-ui/core/Dialog'
import Grid from '@material-ui/core/Grid'
import React from 'react'
import Helmet from 'react-helmet'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'

const Register = ({ actions, history, inline, intl }) => {
  const [inputValues, setInputValues] = React.useState({
    email: '',
    password: '',
    confirmPass: '',
    firstName: '',
    lastName: '',
    phone: '',
    occupation: '',
    company: '',
    address1: '',
    address2: '',
    city: '',
    region: '',
    country: '',
    postal: '',
  })
  const [inputFocus, setInputFocus] = React.useState({
    emailFocus: false,
    passwordFocus: false,
    firstNameFocus: false,
    lastNameFocus: false,
    companyFocus: false,
    address1Focus: false,
    address2Focus: false,
    occupationFocus: false,
    cityFocus: false,
    regionFocus: false,
    countryFocus: false,
    postalFocus: false,
    phoneFocus: false,
    confirmPassFocus: false,
  })
  const [registerStage, setRegisterStage] = React.useState(1)
  const [errorMessage, setErrorMessage] = React.useState([])
  const [attemptSubmit, setAttemptSubmit] = React.useState(false)
  const [badSubmit, setBadSubmit] = React.useState(false)
  const [acceptTerms, setAcceptTerms] = React.useState(false)
  const [showTerms, setShowTerms] = React.useState(false)

  React.useEffect(() => {
    if (attemptSubmit && errorMessage.length === 0) {
      registerUser()
    }
    if (attemptSubmit && errorMessage.length !== 0) {
      gAnalytics('event', ['Register', 'badInput', registerStage.toString()])
      setBadSubmit(true)
    }
    if (attemptSubmit) {
      setAttemptSubmit(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attemptSubmit, errorMessage])

  const checkForEnter = (e) => {
    if (e.keyCode === 13) {
      if (registerStage === 1) {
        handleStageChange(2)
      } else {
        validateFields('submit')
      }
    }
  }

  const addError = (str, errorMessage) => {
    const index = errorMessage.findIndex((e) => e === str)
    if (index < 0) {
      return errorMessage.concat(str)
    } else {
      return errorMessage
    }
  }

  const removeError = (str, errorMessage) => {
    const returnMessage = errorMessage
    const index = errorMessage.findIndex((e) => e === str)
    if (index >= 0) {
      returnMessage.splice(index, 1)
    }
    return returnMessage
  }

  const renderRegistrationErrors = (errorArr) => {
    let count = 0

    //Combine all "____ cannot be blank" messages
    let returnArr = errorArr.filter((err) => {
      let isBlank = [
        'err_3',
        'err_4',
        'err_7',
        'err_8',
        'err_9',
        'err_10',
        'err_11',
        'err_12',
        'err_13',
        'err_14',
      ].includes(err)
      if (isBlank) {
        count++
      }
      return !isBlank
    })
    if (count) {
      returnArr.push('noBlank')
    }

    return returnArr.map((e, i) => {
      return (
        <p className="errorMessage" key={i}>
          <FormattedMessage id={'register.' + e} />
        </p>
      )
    })
  }

  const checkCondition = (test, str, errorMessage) => {
    if (test) {
      return addError(str, errorMessage)
    } else {
      return removeError(str, errorMessage)
    }
  }

  const validateFields = (checking, adding) => {
    const {
      email,
      password,
      confirmPass,
      firstName,
      lastName,
      postal,
      occupation,
      company,
      phone,
      city,
      region,
      country,
    } = inputValues

    let _err = [...errorMessage]
    const startLength = _err.length

    const checkEmail = (emailInput) => {
      var regex = new RegExp(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
      if (regex.test(emailInput)) return true
      else return false
    }

    //Check Passwords
    if (
      checking === 'confirmPass' ||
      checking === 'all' ||
      checking === 'submit' ||
      checking === 2
    ) {
      _err = checkCondition(password !== confirmPass, 'err_1', _err)
      _err = checkCondition(password.length < 8, 'err_2', _err)
    }

    // //Check Postal code
    // if (checking === 'postal' || checking === 'submit') {
    //   checkCondition(!postal, 'err_3', _err)
    // }

    //Check Email
    if (checking === 'submit' || checking === 2) {
      _err = checkCondition(!email, 'err_4', _err)
      _err = checkCondition(!checkEmail(email) && email, 'err_5', _err)
      actions.checkDuplicateEmail(email).then((response) => {
        _err = checkCondition(response.data.CheckDuplicateEmail, 'err_6', _err)
      })
    }

    //No fields can be left blank
    if (checking === 'submit' || checking === 2) {
      _err = checkCondition(!firstName, 'err_7', _err)
    }
    if (checking === 'submit' || checking === 2) {
      _err = checkCondition(!lastName, 'err_8', _err)
    }
    if (checking === 'submit' || checking === 2) {
      _err = checkCondition(!occupation, 'err_9', _err)
    }
    if (checking === 'submit' || checking === 2) {
      _err = checkCondition(!phone, 'err_10', _err)
    }
    if (checking === 'submit') {
      _err = checkCondition(!company, 'err_11', _err)
    }
    if (checking === 'submit') {
      _err = checkCondition(!city, 'err_12', _err)
    }
    if (checking === 'submit') {
      _err = checkCondition(!region, 'err_13', _err)
    }
    if (checking === 'submit') {
      _err = checkCondition(!region, 'err_13', _err)
    }
    if (checking === 'submit') {
      _err = checkCondition(!country, 'err_14', _err)
    }
    if (checking === 'submit') {
      _err = checkCondition(!postal, 'err_3', _err)
    }
    if (checking === 'submit') {
      _err = checkCondition(!acceptTerms, 'err_15', _err)
    }
    if (checking === 'submit' && !_err.length) {
      setAttemptSubmit(true)
    }
    if (checking === 'submit' && _err.length) {
      gAnalytics('event', ['Register', 'badInput', registerStage.toString()])
    }
    setErrorMessage(_err)
    return !(_err.length > startLength)
  }

  const handleInputChange = (e) => {
    const relations = {
      password: ['err_1', 'err_2'],
      confirmPass: ['err_1', 'err_2'],
      postal: ['err_3'],
      email: ['err_4', 'err_5', 'err_6'],
      firstName: ['err_7'],
      lastName: ['err_8'],
      occupation: ['err_9'],
      phone: ['err_10'],
      company: ['err_11'],
      city: ['err_12'],
      region: ['err_13'],
      country: ['err_14'],
    }
    let _err = [...errorMessage]
    if (relations[e.target.name]) {
      relations[e.target.name].forEach((e) => {
        _err = removeError(e, _err)
      })
    }
    setErrorMessage(_err)
    setInputValues({ ...inputValues, [e.target.name]: e.target.value })
  }

  const handleFocus = (e) => {
    setInputFocus({ ...inputFocus, [e.target.name + 'Focus']: true })
  }

  const handleBlur = (e) => {
    setInputFocus({ ...inputFocus, [e.target.name + 'Focus']: false })
  }

  const handleStageChange = (stage) => {
    if (validateFields(stage)) {
      if (stage === 2) {
        actions.checkDuplicateEmail(inputValues.email).then((response) => {
          let hasFirstScreenError = false
          errorMessage.forEach((error) => {
            if (
              [
                'err_1',
                'err_2',
                'err_4',
                'err_5',
                'err_7',
                'err_8',
                'err_9',
                'err_10',
              ].includes(error)
            ) {
              hasFirstScreenError = true
            }
          })
          const _errorMessage = checkCondition(
            response.data.CheckDuplicateEmail,
            'err_6',
            errorMessage
          )
          setErrorMessage(_errorMessage)

          if (!response.data.CheckDuplicateEmail && !hasFirstScreenError) {
            setRegisterStage(stage)
          } else {
            gAnalytics('event', [
              'Register',
              'badInput',
              registerStage.toString(),
            ])
          }
        })
      } else {
        setRegisterStage(stage)
      }
    } else {
      gAnalytics('event', ['Register', 'badInput', registerStage.toString()])
    }
  }

  const handleLoginButtonClick = () => {
    gAnalytics('pageview', ['/login'])
    history.push('/')
  }

  const registerUser = async () => {
    const {
      company,
      address1,
      address2,
      city,
      region,
      country,
      postal,
      firstName,
      lastName,
      password,
      email,
      phone,
      occupation,
    } = inputValues
    const registerByAdmin = false
    actions
      .registerUser(
        company,
        address1,
        address2,
        city,
        region,
        country,
        postal,
        firstName,
        lastName,
        password,
        email,
        phone,
        occupation,
        registerByAdmin
      )
      .then((res) => {
        if (res.data.registerUser.success === true) {
          gAnalytics('event', ['Register', 'register', email])
          setRegisterStage(3)
        }
      })
  }

  const renderTermsDialog = () => {
    const today = new Date()
    const event = new Date(
      Date.UTC(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0)
    )
    const options = { year: 'numeric', month: 'long', day: 'numeric' }
    const displayDate = event.toLocaleDateString('en-US', options)
    return (
      <Dialog open={showTerms} onClose={() => setShowTerms(false)}>
        <div className="termsOfUseDialog">
          <h1 className="termsOfUseHeader">
            <FormattedMessage id="termsofuse.title.main" />
          </h1>
          <div className="termsOfUseSpace">
            <FormattedMessage id="termsofuse.thanks" />
          </div>
          <FormattedMessage id="termsofuse.pleaseRead" />
          <div className="termsOfUseTitle">
            <FormattedMessage id="termsofuse.title.account" />
          </div>
          <div className="termsOfUseTitle">
            <FormattedMessage id="termsofuse.title.1" />
          </div>
          <div className="termsOfUseTitleFull">
            <FormattedMessage id="termsofuse.toUse.title" />
          </div>
          <ul>
            <li>
              <FormattedMessage id="termsofuse.toUse.1" />
            </li>
            <li>
              <FormattedMessage id="termsofuse.toUse.2" />
            </li>
            <li>
              <FormattedMessage id="termsofuse.toUse.3" />
            </li>
            <li>
              <FormattedMessage id="termsofuse.toUse.4" />
            </li>
          </ul>
          <FormattedMessage id="termsofuse.agreement.1" />
          <div className="termsOfUseTitle">
            <FormattedMessage id="termsofuse.title.2" />
          </div>
          <FormattedMessage id="termsofuse.agreement.2" />
          <div className="termsOfUseTitle">
            <FormattedMessage id="termsofuse.title.3" />
          </div>
          <FormattedMessage id="termsofuse.agreement.3" />
          <div className="termsOfUseTitle">
            <FormattedMessage id="termsofuse.title.4" />
          </div>
          <FormattedMessage id="termsofuse.agreement.4" />
          <div className="termsOfUseTitle">
            <FormattedMessage id="termsofuse.title.5" />
          </div>
          <FormattedMessage id="termsofuse.agreement.5" />
          <div className="termsOfUseTitle">
            <FormattedMessage id="termsofuse.title.6" />
          </div>
          <FormattedMessage id="termsofuse.agreement.6" />
          <div className="termsOfUseTitleFull">
            <FormattedMessage id="termsofuse.title.payment" />
          </div>
          <div className="termsOfUseTitle">
            <FormattedMessage id="termsofuse.title.7" />
          </div>
          <FormattedMessage id="termsofuse.agreement.7" />
          <div className="termsOfUseTitle">
            <FormattedMessage id="termsofuse.title.8" />
          </div>
          <FormattedMessage id="termsofuse.agreement.8" />
          <div className="termsOfUseTitle">
            <FormattedMessage id="termsofuse.title.9" />
          </div>
          <FormattedMessage id="termsofuse.agreement.9" />
          <div className="termsOfUseTitle">
            <FormattedMessage id="termsofuse.title.10" />
          </div>
          <FormattedMessage id="termsofuse.agreement.10" />
          <div className="termsOfUseTitle">
            <FormattedMessage id="termsofuse.title.11" />
          </div>
          <FormattedMessage id="termsofuse.agreement.11" />
          <div className="termsOfUseTitleFull">
            <FormattedMessage id="termsofuse.title.rights" />
          </div>
          <div className="termsOfUseTitle">
            <FormattedMessage id="termsofuse.title.12" />
          </div>
          <FormattedMessage id="termsofuse.agreement.12" />
          <div className="termsOfUseTitle">
            <FormattedMessage id="termsofuse.title.13" />
          </div>
          <FormattedMessage id="termsofuse.agreement.13" />
          <div className="termsOfUseTitle">
            <FormattedMessage id="termsofuse.title.rulesAndAbuse" />
          </div>
          <div className="termsOfUseTitle">
            <FormattedMessage id="termsofuse.title.14" />
          </div>
          <FormattedMessage id="termsofuse.byAgreeing.title" />
          <ul>
            <li>
              <FormattedMessage id="termsofuse.byAgreeing.1" />
            </li>
            <li>
              <FormattedMessage id="termsofuse.byAgreeing.2" />
            </li>
            <li>
              <FormattedMessage id="termsofuse.byAgreeing.3" />
            </li>
            <li>
              <FormattedMessage id="termsofuse.agreement.14" />
            </li>
          </ul>
          <div className="termsOfUseTitle">
            <FormattedMessage id="termsofuse.title.15" />
          </div>
          <FormattedMessage id="termsofuse.agreement.15" />
          <div className="termsOfUseTitle">
            <FormattedMessage id="termsofuse.title.16" />
          </div>
          <FormattedMessage id="termsofuse.agreement.16.1" />
          <FormattedMessage id="termsofuse.agreement.16.2" />
          <div className="termsOfUseTitle">
            <FormattedMessage id="termsofuse.title.17" />
          </div>
          <FormattedMessage id="termsofuse.agreement.17" />
          <div className="termsOfUseTitleFull">
            <FormattedMessage id="termsofuse.title.liability" />
          </div>
          <div className="termsOfUseTitle">
            <FormattedMessage id="termsofuse.title.18" />
          </div>
          <FormattedMessage id="termsofuse.agreement.18" />
          <div className="termsOfUseTitle">
            <FormattedMessage id="termsofuse.title.19" />
          </div>
          <FormattedMessage id="termsofuse.agreement.19" />
          <div className="termsOfUseTitle">
            <FormattedMessage id="termsofuse.title.20" />
          </div>
          <FormattedMessage id="termsofuse.agreement.20" />
          <div className="termsOfUseTitle">
            <FormattedMessage id="termsofuse.title.21" />
          </div>
          <FormattedMessage id="termsofuse.agreement.21" />
          <div className="termsOfUseTitle">
            <FormattedMessage id="termsofuse.title.22" />
          </div>
          <FormattedMessage id="termsofuse.agreement.22" />
          <div className="termsOfUseTitle">
            <FormattedMessage id="termsofuse.title.23" />
          </div>
          <FormattedMessage id="termsofuse.agreement.23" />
          <div className="termsOfUseTitle">
            <FormattedMessage id="termsofuse.title.24" />
          </div>
          <FormattedMessage id="termsofuse.agreement.24" />
          <div className="termsOfUseTitleFull">
            <FormattedMessage id="termsofuse.title.finePrint" />
          </div>
          <div className="termsOfUseTitle">
            <FormattedMessage id="termsofuse.title.25" />
          </div>
          <FormattedMessage id="termsofuse.agreement.25" />
          <div className="termsOfUseTitle">
            <FormattedMessage id="termsofuse.title.26" />
          </div>
          <FormattedMessage id="termsofuse.agreement.26" />
          <div className="termsOfUseTitle">
            <FormattedMessage id="termsofuse.title.27" />
          </div>
          <FormattedMessage id="termsofuse.agreement.27" />
          <div className="termsOfUseTitle">
            <FormattedMessage id="termsofuse.title.28" />
          </div>
          <FormattedMessage id="termsofuse.agreement.28" />
          <div className="termsOfUseTitle">
            <FormattedMessage id="termsofuse.title.29" />
          </div>
          <FormattedMessage id="termsofuse.agreement.29" />
          <div className="termsOfUseTitle">
            <FormattedMessage id="termsofuse.title.30" />
          </div>
          <FormattedMessage id="termsofuse.agreement.30" />
          <div className="termsOfUseTitle">
            <FormattedMessage id="termsofuse.title.31" />
          </div>
          <FormattedMessage id="termsofuse.agreement.31" />
          <div className="termsOfUseTitle">
            <FormattedMessage id="termsofuse.title.32" />
          </div>
          <div className="termsOfUseTitle">
            <FormattedMessage id="termsofuse.title.manyClients" />
          </div>
          <div className="termsOfUseTitle">
            <FormattedMessage id="termsofuse.title.33" />
          </div>
          <FormattedMessage id="termsofuse.agreement.33" />
          <div className="termsOfUseTitle">
            <FormattedMessage id="termsofuse.title.34" />
          </div>
          <FormattedMessage id="termsofuse.agreement.34" />
          <div className="termsOfUseTitle">
            <FormattedMessage id="termsofuse.title.35" />
          </div>
          <FormattedMessage id="termsofuse.agreement.35" />
          <div className="termsOfUseTitle">
            <FormattedMessage id="termsofuse.title.36" />
          </div>
          <div className="termsOfUseSpace">
            <FormattedMessage id="termsofuse.agreement.37" />
          </div>
          <div className="termsOfUseSpace">
            <FormattedMessage id="termsofuse.final.thanks" />
          </div>
          <div className="termsOfUseSpace">
            <FormattedMessage id="termsofuse.final.revision" />
            {displayDate}
          </div>
        </div>
      </Dialog>
    )
  }

  const renderErrorDialog = () => {
    return (
      <Dialog open={badSubmit} onClose={() => setBadSubmit(false)}>
        <div className="errorDialog">
          <FormattedMessage id="register.fixErrors" />
          <div className="errorDiv">
            {renderRegistrationErrors(errorMessage)}
          </div>
        </div>
      </Dialog>
    )
  }

  const renderFormBody = () => {
    const {
      firstName,
      lastName,
      email,
      occupation,
      phone,
      password,
      confirmPass,
      company,
      address1,
      address2,
      city,
      country,
      region,
      postal,
    } = inputValues
    const {
      emailFocus,
      passwordFocus,
      firstNameFocus,
      lastNameFocus,
      companyFocus,
      address1Focus,
      address2Focus,
      occupationFocus,
      cityFocus,
      regionFocus,
      countryFocus,
      postalFocus,
      phoneFocus,
      confirmPassFocus,
    } = inputFocus
    return (
      <div className="registerGridWrapper">
        {!!errorMessage.length && (
          <div className="errorDiv">
            {renderRegistrationErrors(errorMessage)}
          </div>
        )}
        {registerStage === 1 && (
          <Grid container spacing={16}>
            <Grid item xs={6}>
              <FormattedMessage id="input.firstNamePlaceholder">
                {(placeholder) => (
                  <input
                    className={
                      'registerInput' +
                      (errorMessage.includes('err_7') ? ' errorInput' : '')
                    }
                    value={firstName}
                    name="firstName"
                    onFocus={(e) => handleFocus(e)}
                    onChange={(e) => handleInputChange(e)}
                    onBlur={(e) => handleBlur(e)}
                    placeholder={
                      !firstNameFocus
                        ? `${intl.formatMessage({
                            id: 'input.firstNamePlaceholder',
                          })} *`
                        : ''
                    }
                  />
                )}
              </FormattedMessage>
            </Grid>
            <Grid item xs={6}>
              <FormattedMessage id="input.lastNamePlaceholder">
                {(placeholder) => (
                  <input
                    className={
                      'registerInput' +
                      (errorMessage.includes('err_8') ? ' errorInput' : '')
                    }
                    value={lastName}
                    name="lastName"
                    onFocus={(e) => handleFocus(e)}
                    onChange={(e) => handleInputChange(e)}
                    onBlur={(e) => handleBlur(e)}
                    placeholder={!lastNameFocus ? `${placeholder} *` : ''}
                  />
                )}
              </FormattedMessage>
            </Grid>
            <Grid item xs={12}>
              <FormattedMessage id="input.emailPlaceholder">
                {(placeholder) => (
                  <input
                    className={
                      'registerInput' +
                      (errorMessage.includes('err_4') ||
                      errorMessage.includes('err_5') ||
                      errorMessage.includes('err_6')
                        ? ' errorInput'
                        : '')
                    }
                    value={email}
                    name="email"
                    onFocus={(e) => handleFocus(e)}
                    onChange={(e) => handleInputChange(e)}
                    onBlur={(e) => handleBlur(e)}
                    placeholder={!emailFocus ? `${placeholder} *` : ''}
                  />
                )}
              </FormattedMessage>
            </Grid>
            <Grid item xs={12}>
              <FormattedMessage id="input.occupationPlaceholder">
                {(placeholder) => (
                  <input
                    className={
                      'registerInput' +
                      (errorMessage.includes('err_9') ? ' errorInput' : '')
                    }
                    value={occupation}
                    name="occupation"
                    onFocus={(e) => handleFocus(e)}
                    onChange={(e) => handleInputChange(e)}
                    onBlur={(e) => handleBlur(e)}
                    placeholder={
                      !occupationFocus
                        ? `${intl.formatMessage({
                            id: 'milestone.title',
                          })} *`
                        : ''
                    }
                  />
                )}
              </FormattedMessage>
            </Grid>
            <Grid item xs={12}>
              <FormattedMessage id="input.phonePlaceholder">
                {(placeholder) => (
                  <input
                    className={
                      'registerInput' +
                      (errorMessage.includes('err_10') ? ' errorInput' : '')
                    }
                    value={phone}
                    name="phone"
                    onFocus={(e) => handleFocus(e)}
                    onChange={(e) => handleInputChange(e)}
                    onBlur={(e) => handleBlur(e)}
                    placeholder={!phoneFocus ? `${placeholder} *` : ''}
                  />
                )}
              </FormattedMessage>
            </Grid>
            <Grid item xs={6}>
              <FormattedMessage id="input.passwordPlaceholder">
                {(placeholder) => (
                  <input
                    className={
                      'registerInput' +
                      (errorMessage.includes('err_2') ? ' errorInput' : '')
                    }
                    value={password}
                    name="password"
                    onFocus={(e) => handleFocus(e)}
                    onChange={(e) => handleInputChange(e)}
                    onBlur={(e) => handleBlur(e)}
                    placeholder={!passwordFocus ? `${placeholder} *` : ''}
                    type="password"
                  />
                )}
              </FormattedMessage>
            </Grid>
            <Grid item xs={6}>
              <FormattedMessage id="input.confirmPassPlaceholder">
                {(placeholder) => (
                  <input
                    className={
                      'registerInput' +
                      (errorMessage.includes('err_2') ||
                      errorMessage.includes('err_1')
                        ? ' errorInput'
                        : '')
                    }
                    value={confirmPass}
                    name="confirmPass"
                    onFocus={(e) => handleFocus(e)}
                    onChange={(e) => handleInputChange(e)}
                    onBlur={(e) => handleBlur(e)}
                    placeholder={!confirmPassFocus ? `${placeholder} *` : ''}
                    type="password"
                  />
                )}
              </FormattedMessage>
            </Grid>
            <Grid item xs={12}>
              <Button
                color="primary"
                classes={{ textPrimary: 'registerButton' }}
                onClick={() => handleStageChange(2)}
              >
                <FormattedMessage id="login.next" />
              </Button>
            </Grid>
          </Grid>
        )}
        {registerStage === 2 && (
          <Grid container spacing={16}>
            <Grid item xs={12}>
              <FormattedMessage id="input.companyPlaceholder">
                {(placeholder) => (
                  <input
                    className={
                      'registerInput' +
                      (errorMessage.includes('err_11') ? ' errorInput' : '')
                    }
                    value={company}
                    name="company"
                    onFocus={(e) => handleFocus(e)}
                    onChange={(e) => handleInputChange(e)}
                    onBlur={(e) => handleBlur(e)}
                    placeholder={!companyFocus ? `${placeholder} *` : ''}
                  />
                )}
              </FormattedMessage>
            </Grid>
            <Grid item xs={12}>
              <FormattedMessage id="input.address1Placeholder">
                {(placeholder) => (
                  <input
                    className={'registerInput'}
                    value={address1}
                    name="address1"
                    onFocus={(e) => handleFocus(e)}
                    onChange={(e) => handleInputChange(e)}
                    onBlur={(e) => handleBlur(e)}
                    placeholder={!address1Focus ? placeholder : ''}
                  />
                )}
              </FormattedMessage>
            </Grid>
            <Grid item xs={12}>
              <FormattedMessage id="input.address2Placeholder">
                {(placeholder) => (
                  <input
                    className={'registerInput'}
                    value={address2}
                    name="address2"
                    onFocus={(e) => handleFocus(e)}
                    onChange={(e) => handleInputChange(e)}
                    onBlur={(e) => handleBlur(e)}
                    placeholder={!address2Focus ? placeholder : ''}
                  />
                )}
              </FormattedMessage>
            </Grid>
            <Grid item xs={6}>
              <FormattedMessage id="input.cityPlaceholder">
                {(placeholder) => (
                  <input
                    className={
                      'registerInput' +
                      (errorMessage.includes('err_12') ? ' errorInput' : '')
                    }
                    value={city}
                    name="city"
                    onFocus={(e) => handleFocus(e)}
                    onChange={(e) => handleInputChange(e)}
                    onBlur={(e) => handleBlur(e)}
                    placeholder={!cityFocus ? `${placeholder} *` : ''}
                  />
                )}
              </FormattedMessage>
            </Grid>
            <Grid item xs={6}>
              <FormattedMessage id="input.countryPlaceholder">
                {(placeholder) => (
                  <input
                    className={
                      'registerInput' +
                      (errorMessage.includes('err_14') ? ' errorInput' : '')
                    }
                    value={country}
                    name="country"
                    onFocus={(e) => handleFocus(e)}
                    onChange={(e) => handleInputChange(e)}
                    onBlur={(e) => handleBlur(e)}
                    placeholder={!countryFocus ? `${placeholder} *` : ''}
                  />
                )}
              </FormattedMessage>
            </Grid>
            <Grid item xs={6}>
              <FormattedMessage id="input.regionPlaceholder">
                {(placeholder) => (
                  <input
                    className={
                      'registerInput' +
                      (errorMessage.includes('err_13') ? ' errorInput' : '')
                    }
                    value={region}
                    name="region"
                    onFocus={(e) => handleFocus(e)}
                    onChange={(e) => handleInputChange(e)}
                    onBlur={(e) => handleBlur(e)}
                    placeholder={!regionFocus ? `${placeholder} *` : ''}
                  />
                )}
              </FormattedMessage>
            </Grid>
            <Grid item xs={6}>
              <FormattedMessage id="input.postalPlaceholder">
                {(placeholder) => (
                  <input
                    className={
                      'registerInput' +
                      (errorMessage.includes('err_3') ? ' errorInput' : '')
                    }
                    value={postal}
                    name="postal"
                    onFocus={(e) => handleFocus(e)}
                    onChange={(e) => handleInputChange(e)}
                    onBlur={(e) => handleBlur(e)}
                    placeholder={!postalFocus ? `${placeholder} *` : ''}
                  />
                )}
              </FormattedMessage>
            </Grid>
            <Grid item xs={12}>
              <span className="termsOfUse">
                <FormattedMessage id="register.accept" />
              </span>
              <span
                onClick={() => setShowTerms(true)}
                className="termsOfUseClick"
              >
                <FormattedMessage id="register.termsofuse" />
              </span>
              <Checkbox
                color="default"
                value={acceptTerms ? 'true' : 'false'}
                onClick={() => setAcceptTerms(!acceptTerms)}
              />
            </Grid>
            <Grid item xs={6}>
              <Button
                color="primary"
                classes={{ textPrimary: 'backButton' }}
                onClick={() => handleStageChange(1)}
              >
                {' '}
                <FormattedMessage id="login.back" />{' '}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                color="primary"
                classes={{ textPrimary: 'registerButton' }}
                onClick={() => validateFields('submit')}
              >
                {' '}
                <FormattedMessage id="login.submit" />{' '}
              </Button>
            </Grid>
          </Grid>
        )}
        {registerStage === 3 && (
          <Grid container spacing={16}>
            <Grid item xs={6} style={{ margin: '0 auto' }}>
              <Button
                color="primary"
                classes={{ textPrimary: 'registerButton' }}
                onClick={() => history.push('/')}
              >
                {' '}
                <FormattedMessage id="login.login" />{' '}
              </Button>
            </Grid>
          </Grid>
        )}
      </div>
    )
  }
  const renderPage = () => {
    return (
      <div className="register" style={{ backgroundImage: citiIQ_background }}>
        <Helmet>
          <title>
            CitiIQ - {intl.formatMessage({ id: 'register.register' })}
          </title>
        </Helmet>
        <img
          src={citiIQ_background}
          className="registerBackground"
          alt="CitiIQ Background"
        />
        {renderErrorDialog()}
        {renderTermsDialog()}
        <div className="registerDiv" onKeyDown={(e) => checkForEnter(e)}>
          <Link to="/dashboard">
            <img className="siteLogo" src={logo} alt="" />
          </Link>
          <div className="loginDiv">
            <span>
              <FormattedMessage id="login.goToLoginText" />
            </span>
            <Button
              onClick={() => handleLoginButtonClick()}
              color="primary"
              classes={{ textPrimary: 'loginButton' }}
            >
              <FormattedMessage id="login.login" />
            </Button>
          </div>
          <React.Fragment>
            <p className="registerHeader">
              <FormattedMessage
                id={`register.header${registerStage}`}
                description="A Greeting message"
              />
            </p>
            <p className="registerSubHeader">
              <FormattedMessage
                id={
                  registerStage !== 3
                    ? 'register.registerIntro'
                    : 'register.awaitApproval'
                }
              />
            </p>
            {renderFormBody()}
          </React.Fragment>
        </div>
        <div className="languageSelectorDiv">
          <div className="selector">
            <LanguageSelector />
          </div>
        </div>
      </div>
    )
  }
  const renderInlineForm = () => {
    return (
      <div className="register register--inline">
        {renderErrorDialog()}
        {renderTermsDialog()}
        <div className="registerDiv">{renderFormBody()}</div>
      </div>
    )
  }

  if (inline) {
    return renderInlineForm()
  } else {
    return renderPage()
  }
}

function mapStateToProps(state) {
  return {
    userStore: state.UserStore,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        registerUser,
        checkDuplicateEmail,
      },
      dispatch
    ),
  }
}

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(Register)
)
