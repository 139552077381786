import {
  clearConsiderationsComparisons,
  getConsiderationsComparison,
  getDimensionsComparison,
  getQuartersConsiderationsComparison,
} from '../../actions/PageActions.js'
import { dimensionToColor } from '../../constants/Constants.js'
import './ComparisonChart.scss'
import HorizontalBar from './HorizontalBar.js'
import {
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

const ComparisonBarChart = ({
  dimension,
  selector,
  milestones_array,
  pageStore,
  userStore,
  goalStore,
  actions,
  isDashboard,
}) => {
  const [selectedLines, setSelectedLines] = React.useState([])
  const [animationComplete, setAnimationComplete] = React.useState(false)
  const [dimensionValue, setDimensionValue] = React.useState(dimension ?? 1)
  const [hideGoals, setHideGoals] = React.useState(false)
  const [data, setData] = React.useState([])

  React.useEffect(() => {
    if (dimension) setDimensionValue(dimension)
  }, [dimension, milestones_array])

  React.useEffect(() => {
    const selectedCityId = userStore.getIn(['selectedCityId'])

    actions.getQuartersConsiderationsComparison(
      selectedCityId,
      dimensionValue,
      2
    )
    setTimeout(() => {
      setAnimationComplete(true)
    }, 1100)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dimension, dimensionValue, userStore])

  React.useEffect(() => {
    const barChartData = pageStore.get('considerationsComparison').toJS()
    setData(barChartData)
  }, [pageStore, dimension, dimensionValue])

  const renderColor = (dimension_id) => {
    return dimensionToColor[Number(dimension_id)].main
  }

  const handleChange = (event) => {
    actions.clearConsiderationsComparisons()
    setDimensionValue(event.target.value)

    const selectedCityId = userStore.getIn(['selectedCityId'])
    actions.getConsiderationsComparison(selectedCityId, event.target.value)
  }

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <div className="horizontalChart">
        {selector && (
          <Grid container className="comparison-container">
            <Grid item xs={6} className="left-div">
              <span>
                <FormattedMessage id="consideration.comparison.navigation" />
              </span>
            </Grid>
            <Grid item xs={6} className="right-div">
              <FormControl className="dropdown-selector">
                <Select
                  value={dimensionValue}
                  onChange={(e) => handleChange(e)}
                  inputProps={{
                    name: 'dimensionValue',
                  }}
                  disableUnderline
                  autoWidth
                  classes={{ select: 'dropdown-select' }}
                  style={{
                    color: renderColor(dimensionValue),
                  }}
                >
                  <MenuItem value={1}>
                    <FormattedMessage
                      id={'dimensions.1'}
                      description="option-text"
                    />
                  </MenuItem>
                  <MenuItem value={2}>
                    <FormattedMessage
                      id={'dimensions.2'}
                      description="option-text"
                    />
                  </MenuItem>
                  <MenuItem value={3}>
                    <FormattedMessage
                      id={'dimensions.3'}
                      description="option-text"
                    />
                  </MenuItem>
                  <MenuItem value={4}>
                    <FormattedMessage
                      id={'dimensions.4'}
                      description="option-text"
                    />
                  </MenuItem>
                  <MenuItem value={5}>
                    <FormattedMessage
                      id={'dimensions.5'}
                      description="option-text"
                    />
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        )}

        <HorizontalBar
          data={data}
          dimensionValue={dimensionValue}
          isDashboard={isDashboard}
          goalStore={goalStore}
          hideGoals={hideGoals}
        />
      </div>
    </div>
  )
}

function mapStateToProps(state) {
  return {
    pageStore: state.PageStore,
    userStore: state.UserStore,
    goalStore: state.GoalStore,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        clearConsiderationsComparisons,
        getConsiderationsComparison,
        getDimensionsComparison,
        getQuartersConsiderationsComparison,
      },
      dispatch
    ),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ComparisonBarChart)
