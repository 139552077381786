export const getDimensionWeightsForOverallDonut = (cities, cityId) => {
  if (cityId === 'all') return [35, 25, 15, 15, 10]
  const cid = Number(cityId)
  const cityInfo = cities && cities[cid]
  if (cityInfo) {
    const { dimensions, iq } = cityInfo
    const arr = dimensions.map((d) => d.weightedValue)
    return [...arr, 100 - iq]
  } else {
    return [35, 25, 15, 15, 10]
  }
}
