import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import PropTypes from 'prop-types'
import React from 'react'

function SimpleDialog(props) {
  const { onClose, open } = props

  const handleClose = () => {
    onClose()
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      {props.children}
    </Dialog>
  )
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
}

export default function SimpleDialogButton({
  buttonText,
  open,
  children,
  disabled,
  onOpenDialog,
  onCloseDialog,
  hideButton,
}) {
  const handleClickOpen = () => {
    onOpenDialog()
  }

  const handleClose = (value) => {
    onCloseDialog()
  }

  return (
    <div>
      {!hideButton && (
        <Button
          variant="contained"
          style={{
            background: '#1E1D1F',
            color: '#9EC73D',
            textTransform: 'capitalize',
          }}
          disabled={disabled}
          onClick={handleClickOpen}
        >
          {buttonText}
          <FontAwesomeIcon
            icon={faPlusCircle}
            style={{ fontSize: '15px', marginLeft: '10px' }}
          />
        </Button>
      )}
      <SimpleDialog children={children} open={open} onClose={handleClose} />
    </div>
  )
}
