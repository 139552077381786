import './ColoredScore.scss'
import PropTypes from 'prop-types'
import React from 'react'

export const ColoredScore = ({
  backgroundColor,
  color,
  fontSize,
  height,
  value,
  width,
}) => (
  <div
    className="colored-score-div"
    style={{ backgroundColor, color, fontSize, height, width }}
  >
    <span className="colored-score-value">{value}</span>
  </div>
)

ColoredScore.defaultProps = {
  backgroundColor: '#000',
  color: '#fff',
  fontSize: '24px',
  height: '50px',
  value: 0,
  width: '100px',
}

ColoredScore.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  color: PropTypes.string,
  fontSize: PropTypes.string,
  height: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  width: PropTypes.string,
}
