export const StyledScrollbar = {
  scrollbarWidth: 'thin',
  scrollbarHeight: 'thin',
  scrollbarColor: 'rgba(255,255,255,.15) transparent',
  '&:hover': {
    scrollbarWidth: 'thin',
    scrollbarHeight: 'thin',
    scrollbarColor: 'rgba(255,255,255,.15) transparent',
  },
  '&::-webkit-scrollbar': {
    width: '4px',
    height: '4px',
  },
  '&::-webkit-scrollbar-track': {
    background: 'transparent',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(255,255,255,.15)',
    border: '2px solid transparent',
    borderRadius: '4px',
  },
}
