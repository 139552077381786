import * as types from '../constants/ActionTypes'
import {
  parseSelectedQuarterValue,
  getSelectedQuarterString,
  getCountryNameFromCode,
} from '../constants/Constants'
import * as queries from '../constants/QueryTypes'
import { hasUserSessionError } from '../helpers/errorHandler'
import query from '../services/GraphQLService'

export const setCurrentPage = (page, dim) => (dispatch) => {
  dispatch({ type: types.SET_PAGE, payload: { page, dim } })
}

export const getConsiderationsComparison = (id, dimension_id) => (dispatch) => {
  query('query', queries.LATEST_CITY_CONSIDERATIONS_COMPARISON, {
    id,
    dimension_id,
  }).then(({ data, errors }) => {
    if (errors && hasUserSessionError(errors)) {
      dispatch({ type: types.OPEN_FORCE_LOGOUT_DIALOG })
      return
    }
    dispatch({
      type: types.GET_CONSIDERATIONS_COMPARISON,
      payload: data.LatestCityConsiderationsComparison,
    })
  })
}

export const clearConsiderationsComparisons = () => (dispatch) => {
  dispatch({ type: types.GET_CONSIDERATIONS_COMPARISON, payload: [] })
}

export const getDimensionsComparison =
  (id, user_id, milestone) => (dispatch) => {
    query('query', queries.LATEST_CITY_DIMENSIONS_COMPARISON, {
      id,
      user_id,
      milestone,
    }).then(({ data, errors }) => {
      if (errors && hasUserSessionError(errors)) {
        dispatch({ type: types.OPEN_FORCE_LOGOUT_DIALOG })
        return
      }
      dispatch({
        type: types.GET_DIMENSIONS_COMPARISON,
        payload: data.LatestCityDimensionsComparison,
      })
    })
  }

export const getAllConsiderations = (id) => (dispatch) => {
  query('query', queries.LATEST_CITY_CONSIDERATIONS, { id }).then(
    ({ data, errors }) => {
      if (errors && hasUserSessionError(errors)) {
        dispatch({ type: types.OPEN_FORCE_LOGOUT_DIALOG })
        return
      }
      dispatch({
        type: types.GET_ALL_CONSIDERATIONS,
        payload: data.LatestCityConsiderations,
      })
    }
  )
}

export const getQuartersConsiderationsComparison =
  (id, dimension_id, quarters) => (dispatch) => {
    query('query', queries.GET_CITY_QUARTERS_CONSIDERATIONS_COMPARISON, {
      id,
      dimension_id,
      quarters,
    }).then(({ data, errors }) => {
      if (errors && hasUserSessionError(errors)) {
        dispatch({ type: types.OPEN_FORCE_LOGOUT_DIALOG })
        return
      }
      dispatch({
        type: types.GET_QUARTERS_CONSIDERATIONS_COMPARISON,
        payload: data.GetCityQuartersConsiderationsComparison,
      })
    })
  }

export const getIndicatorChartData = (id, year, quarter) => (dispatch) => {
  query('query', queries.GET_INDICATOR_CHART_DATA, {
    id,
    year,
    quarter,
  }).then(({ data, errors }) => {
    if (errors && hasUserSessionError(errors)) {
      dispatch({ type: types.OPEN_FORCE_LOGOUT_DIALOG })
      return
    }
    dispatch({
      type: types.GET_INDICATOR_CHART_DATA,
      payload: data.GetIndicatorChartData,
    })
  })
}

export const getAllIndicators = (id) => (dispatch) => {
  query('query', queries.LATEST_CITY_INDICATORS, { id }).then(
    ({ data, errors }) => {
      if (errors && hasUserSessionError(errors)) {
        dispatch({ type: types.OPEN_FORCE_LOGOUT_DIALOG })
        return
      }
      dispatch({
        type: types.GET_ALL_INDICATORS,
        payload: data.LatestCityIndicators,
      })
    }
  )
}

export const getAllCityInfo = (id, year, quarter) => (dispatch) => {
  dispatch({ type: types.START_LOADING_INFO })
  dispatch({ type: types.LOADING_SINGLE_CITY_INFO })

  query('query', queries.ALL_CITY_INFO, { id, year, quarter }).then(
    ({ data, errors }) => {
      if (errors && hasUserSessionError(errors)) {
        dispatch({ type: types.OPEN_FORCE_LOGOUT_DIALOG })
        return
      }
      dispatch({ type: types.GET_ALL_INFO, payload: data })
    }
  )
}

export const getMultiCityInfos = (ids, year, quarter) => (dispatch) => {
  dispatch({ type: types.START_LOADING_INFO })
  dispatch({ type: types.LOADING_MULTI_CITY_INFO })

  const idsSet = new Set(ids)
  const uniqueIds = [...idsSet]
  query(
    'query',
    queries.MULTI_CITY_BASIC_INFO,
    { ids: uniqueIds, year: year, quarter: quarter },
    { fetchPolicy: 'no-cache' }
  ).then(({ data, errors }) => {
    if (errors && hasUserSessionError(errors)) {
      dispatch({ type: types.OPEN_FORCE_LOGOUT_DIALOG })
      return
    }
    let { AllCities, LatestCityInformations } = data
    const citySnapshots = {}
    const cityCountryMap = { arr: [], obj: {} }

    // clean up cityCountryMap
    // only keep the cities that has a valid `LatestCityInformation` data returned

    AllCities.forEach((c) => {
      const cityInformation = LatestCityInformations.find(
        (info) => info && info.city_id === c.id
      )
      const citySummary = {
        name: c.name,
        country: getCountryNameFromCode(c.country),
        lang: c.profile.lang, // city and country name in languages
        id: c.id,
        iq_quality_star: cityInformation
          ? cityInformation.iq_quality_star
          : null,
      }

      cityCountryMap.arr.push(citySummary)
      cityCountryMap.obj[c.id] = citySummary

      const cMapped = {}
      if (cityInformation) {
        cityInformation.considerations.forEach((i) => {
          cMapped[Number(i.id)] = i
        })
        citySnapshots[c.id] = {
          ...cityInformation,
          considerationsMap: cMapped,
        }
      }
    })

    dispatch({
      type: types.GET_MULTI_CITY_INFO,
      payload: { citySnapshots, cityCountryMap },
    })
  })
}

export const getAllAvailableQuarters = (cityIds) => (dispatch) => {
  dispatch({ type: types.START_LOADING_INFO })
  const idsSet = new Set(cityIds)
  const uniqueIds = [...idsSet]
  query('query', queries.ALL_AVAILABLE_SNAPSHOT_QUATERS, {
    ids: uniqueIds,
  }).then(({ data, errors }) => {
    if (errors && hasUserSessionError(errors)) {
      dispatch({ type: types.OPEN_FORCE_LOGOUT_DIALOG })
      return
    }
    // quarterCityMap { "2022-2": [ 1299, 2222, 4444 ] }
    // quarters =  [ { year: 2022, quarter: 2 },  {year: 2022, quarter: 4 } ]
    const quarterCityMap = data.AllAvailableQuartersForCities.reduce(
      (total, city) => {
        const cityId = city[0] ? city[0].city_id : null
        if (!cityId) return total
        //get all the quarters for the city
        const citySnapshotsList = city.reduce((t, row) => {
          const quarterString = getSelectedQuarterString(row)
          if (!t.includes(quarterString)) {
            t.push(quarterString)
          }
          return t
        }, [])

        // adding the quarter to total if not yet added
        // if quarter exists, add cityID
        citySnapshotsList.forEach((c) => {
          total[c] = [...(total[c] ?? []), cityId]
        })
        return total
      },
      {}
    )

    const quarters = Object.keys(quarterCityMap)
      .map((q) => parseSelectedQuarterValue(q))
      .filter((q) => !!q)

    dispatch({
      type: types.GET_ALL_AVAILABLE_QUARTERS,
      payload: { quarters, quarterCityMap },
    })
  })
}

export const toggleMultiCityView = (flag) => (dispatch) => {
  switch (flag) {
    case true:
      dispatch({ type: types.IS_MULTI_CITY })
      break
    case false:
      dispatch({ type: types.IS_SINGLE_CITY })
      break
    default:
      break
  }
}
export const clearIndicatorChartData = () => (dispatch) => {
  dispatch({ type: types.CLEAR_INDICATOR_CHART_DATA })
}
