import { cityProfile } from '../../actions/CityActions'
import { getAllCities } from '../../actions/UserActions'
import useLanguage from '../../state/hooks/useLanguage'
import './UrbanMonitoringFramework.scss'
import { CircularProgress } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import React from 'react'
import Helmet from 'react-helmet'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { bindActionCreators } from 'redux'

const UrbanMonitoringFramework = ({ userStore, cityStore, intl }) => {
  const iframeRef = React.useRef()
  const [iframeLoaded, setIframeLoaded] = React.useState(false)
  const [umfEnabled, setUmfEnabled] = React.useState(
    cityStore?.getIn(['umfEnabled'])
  )
  const { language } = useLanguage()

  React.useEffect(() => {
    setUmfEnabled(cityStore.getIn(['umfEnabled']))
  }, [cityStore])

  React.useEffect(() => {
    if (iframeRef && iframeRef.current) {
      const iframeDoc =
        iframeRef.current.contentDocument ||
        iframeRef.current.contentWindow.document
      if (iframeDoc.readyState === 'complete') {
        setTimeout(() => {
          setIframeLoaded(true)
        }, 500)
        return
      }
    }
  }, [iframeRef])
  if (!umfEnabled) {
    return <Redirect to="dashboard" />
  }
  return (
    <div style={{ height: '100%', width: '100%' }}>
      <Helmet>
        <title>
          CitiIQ -{' '}
          {intl.formatMessage({ id: 'urbanMonitoringFramework.navigation' })}
        </title>
      </Helmet>
      <Grid
        style={{
          height: '80vh',
          width: '100vw',
          display: iframeLoaded ? 'none' : 'flex',
          flex: 1,
        }}
      >
        <CircularProgress style={{ display: 'flex', margin: 'auto' }} />
      </Grid>
      <Grid
        container
        spacing={50}
        className="iframe-container"
        style={{
          display: iframeLoaded ? '' : 'none',
        }}
      >
        <Grid item xs={12}>
          <iframe
            ref={iframeRef}
            src={`https://main.d2r99rih2ryi90.amplifyapp.com/city/${userStore.getIn(
              ['selectedCityId']
            )}?lang=${language.locale}`}
            height="100%"
            width="100%"
            title="Urban Monitoring Framework"
            style={{
              border: 'none',
            }}
          ></iframe>
        </Grid>
      </Grid>
    </div>
  )
}

function mapStateToProps(state) {
  return {
    cityStore: state.CityStore,
    userStore: state.UserStore,
    pageStore: state.PageStore,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        cityProfile,
        getAllCities,
      },
      dispatch
    ),
  }
}

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(UrbanMonitoringFramework)
)
