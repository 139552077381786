import DashboardMultiCity from '../DashboardMultiCity/DashboardMultiCity'
import DashboardSingleCity from './DashboardSingleCity'
import React from 'react'
import { connect } from 'react-redux'

const Dashboard = ({ pageStore, userStore }) => {
  const [showRestoreQuarterReminder, setShowRestoreQuarterReminder] =
    React.useState(false)
  const isMultiCity = pageStore.get('isMultiCity')
  const selectedCityId = userStore.getIn(['selectedCityId'])
  const selectedQuarter = userStore.getIn(['selectedQuarter'])

  const prevSelectedQuarterRef = React.useRef(selectedQuarter)
  React.useEffect(() => {
    // Only run this effect if we have the necessary data
    if (selectedCityId && selectedQuarter) {
      // Check if the quarter has changed
      if (
        !isMultiCity &&
        Boolean(prevSelectedQuarterRef.current) &&
        prevSelectedQuarterRef.current !== selectedQuarter
      ) {
        setShowRestoreQuarterReminder(true)
      }
      // Update the ref AFTER the comparison
      prevSelectedQuarterRef.current = selectedQuarter
    }
  }, [selectedQuarter, selectedCityId, isMultiCity])

  return isMultiCity ? (
    <DashboardMultiCity />
  ) : (
    <DashboardSingleCity
      showRestoreQuarterReminder={showRestoreQuarterReminder}
      setShowRestoreQuarterReminder={setShowRestoreQuarterReminder}
    />
  )
}

function mapStateToProps(state) {
  return {
    pageStore: state.PageStore,
    userStore: state.UserStore,
  }
}

export default connect(mapStateToProps)(Dashboard)
