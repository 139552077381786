import { CitiIQCard } from '../../components'
import {
  renderColor,
  getConsiderationByDimensionKey,
} from '../../constants/Constants'
import { sort } from './Helpers'
import { renderDonut, renderSimpleBar } from './Helpers'
import { CircularProgress, Grid } from '@material-ui/core'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import React, { useState, useEffect } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'

const Cards = ({ dimension, cities, formattedCityCountryMap, intl }) => {
  const considerationIds = getConsiderationByDimensionKey(dimension, true)
  const [loading, setLoading] = useState(true)
  const [dimensionArrayIdx, setDimensionArrayIdx] = useState(dimension - 1)
  const [sortedCitiesList, setSortedCitiesList] = useState(
    (cities && sort(dimension, cities)) || []
  )
  useEffect(() => {
    setLoading(true)
    cities && setSortedCitiesList(sort(dimension, cities))
    setDimensionArrayIdx(dimension - 1)
    setTimeout(() => {
      setLoading(false)
    }, 200)
  }, [dimension, cities])

  if (!cities) return null
  if (!formattedCityCountryMap) return null
  if (loading)
    return (
      <div
        style={{
          height: '80vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress style={{ color: '#b1ff44' }} />
      </div>
    )

  const filterCitiesInfoArray = sortedCitiesList
    .map((cityId) => {
      if (cities[cityId] && formattedCityCountryMap[cityId]) {
        const { considerations, dimensions, iq } = cities[cityId]
        return {
          city: formattedCityCountryMap[cityId].name,
          country: formattedCityCountryMap[cityId].country,
          dimension: dimensions[dimensionArrayIdx],
          iq: iq,
          considerations: considerations.filter((c) =>
            considerationIds.includes(String(c.id))
          ),
        }
      }
      return null
    })
    .filter((c) => !!c)

  return (
    <Grid container spacing={16}>
      {filterCitiesInfoArray.map((city) => {
        return <Card key={city.city} {...city} intl={intl} />
      })}
    </Grid>
  )
}

const Card = ({ city, country, dimension, iq, considerations, intl }) => {
  const { value, id } = dimension

  const overallDonut = renderDonut(
    [iq * 0.3, iq * 0.25, iq * 0.2, iq * 0.15, iq * 0.1, 100 - iq],
    [1, 2, 3, 4, 5].map((d) => renderColor(d).main).concat(['#444']),
    iq,
    'M'
  )
  const dimensionDonut = renderDonut({
    data: [value, 100 - value],
    colors: [renderColor(id).main, '#444'],
    label: Math.round(value),
    donutSize: 76,
  })

  function createData(id, value) {
    return {
      name: intl.formatMessage({ id: `considerations.${id}` }),
      value: Math.round(Number(value)),
    }
  }

  const rows = considerations.map(({ id, value }) => createData(id, value))

  return (
    <Grid item xs={12} md={6} lg={4}>
      <CitiIQCard height="auto">
        <Grid container style={{ padding: '20px', alignItems: 'center' }}>
          <Grid item>{dimensionDonut}</Grid>
          <Grid item style={{ flex: 1, padding: '0 20px' }}>
            <div style={{ color: renderColor(id).main }}>
              <FormattedMessage id={`dimensions.${id}`} />
            </div>
            <h2 style={{ margin: 0 }}>{city}</h2>
            <div style={{ opacity: 0.6 }}>{country || ''}</div>
          </Grid>
          <Grid item>{overallDonut}</Grid>
        </Grid>

        <Table aria-label="caption table">
          <TableHead>
            <TableRow>
              <TableCell
                style={{
                  borderBottom: '0px',
                }}
              >
                <h3>
                  <FormattedMessage id={`dimensions.${id}`} />
                </h3>
              </TableCell>
              <TableCell
                style={{
                  borderBottom: '0px',
                }}
                align="right"
              >
                <h3>Score</h3>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, idx) => (
              <TableRow key={row.name}>
                <TableCell
                  component="th"
                  scope="row"
                  style={{
                    borderBottom: '0px',
                    backgroundColor:
                      idx % 2 !== 0 ? 'inherit' : 'rgba(0,0,0,.11)',
                  }}
                >
                  {row.name}
                </TableCell>
                <TableCell
                  align="right"
                  style={{
                    borderBottom: '0px',
                    backgroundColor:
                      idx % 2 !== 0 ? 'inherit' : 'rgba(0,0,0,.11)',
                  }}
                >
                  <div style={{ display: 'flex' }}>
                    {renderSimpleBar(renderColor(id).main, row.value)}
                    {row.value}
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CitiIQCard>
    </Grid>
  )
}
export default injectIntl(Cards)
