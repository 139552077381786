import { cityProfile } from '../../actions/CityActions'
import {
  setDrawerinfo,
  closeDrawer,
  setIndDialoginfo,
} from '../../actions/ModalActions'
import { DimensionDashboard, HexScorecard } from '../../components'
import { considerationToDimension } from '../../constants/Constants'
import useLanguage from '../../state/hooks/useLanguage'
import './CityScores.scss'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import ArrowBack from '@material-ui/icons/ArrowBack'
import VerticalAlignBottom from '@material-ui/icons/VerticalAlignBottom'
import Tooltip from '@reach/tooltip'
import '@reach/tooltip/styles.css'
import { fromJS } from 'immutable'
import React from 'react'
import Workbook from 'react-excel-workbook'
import Helmet from 'react-helmet'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'
import { bindActionCreators } from 'redux'

const DownloadDataButton = () => (
  <Tooltip label="Download Scores">
    <span>
      <VerticalAlignBottom className="download-icon" />
    </span>
  </Tooltip>
)

const CityScores = ({ cityStore, pageStore, userStore, actions, intl }) => {
  const [activeDim, setActiveDim] = React.useState(
    pageStore.get('activeDimension')
  )
  const [loadingDownloadData, setLoadingDownloadData] = React.useState(true)
  const [infoModalOpen, setInfoModalOpen] = React.useState(false)
  const [currentInfoSlide, setCurrentInfoSlide] = React.useState(0)
  const [downloadData, setDownloadData] = React.useState({})
  const [sortScores, setSortScores] = React.useState('HighToLow')
  const { language } = useLanguage()
  const { locale } = language
  const [cityName, setCityName] = React.useState(null)
  const [countryName, setCountryName] = React.useState(null)
  React.useEffect(() => {
    if (!locale || !cityStore.getIn(['profile', 'name_new'])) return
    const names = cityStore.getIn(['profile', 'name_new']).toJS()
    if (names.length === 0) return
    setCityName(
      names.filter((c) => c.lang === locale)[0].value ??
        names.filter((c) => c.lang === 'en')[0].value
    )
  }, [locale, cityStore.getIn(['profile', 'name_new'])])

  React.useEffect(() => {
    if (!locale || !cityStore.getIn(['profile', 'country_new'])) return
    const names = cityStore.getIn(['profile', 'country_new']).toJS()
    if (names.length === 0) return
    setCountryName(
      names.filter((c) => c.lang === locale)[0].value ??
        names.filter((c) => c.lang === 'en')[0].value
    )
  }, [locale, cityStore.getIn(['profile', 'country_new'])])

  React.useEffect(() => {
    const selectedCityId = userStore.getIn(['selectedCityId'])
    if (cityName === null || !selectedCityId) return
    actions.cityProfile(selectedCityId).then(() => {
      if (pageStore.getIn(['allInfo', 'LatestCityConsiderations']).size) {
        formatDownloadData(pageStore)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    cityName,
    pageStore.getIn(['allInfo', 'LatestCityIq']),
    userStore.getIn(['selectedCityId']),
  ])

  function handleOpenDrawer(grade, color, name, consideration) {
    actions.setDrawerinfo({ consideration, grade })
  }

  function doSortScores(a, b) {
    if (sortScores === 'HighToLow') {
      return b.value - a.value
    }
    if (sortScores === 'LowToHigh') {
      return a.value - b.value
    }
    if (sortScores === 'AtoZ') {
      return b.name < a.name ? 1 : -1
    }
    if (sortScores === 'ZtoA') {
      return a.name < b.name ? 1 : -1
    }
    return 1
  }

  function setCurrentSlide(slideNum) {
    setCurrentInfoSlide(slideNum)
  }

  function renderConsiderationCards(dimensionId, color, backColor) {
    const arr = []
    pageStore.getIn(['allInfo', 'LatestCityConsiderations']).forEach((c) => {
      const cid = Number(c.get('id'))
      const name = c.get('name')
      const dimId = considerationToDimension[cid].dimension
      if (dimId === dimensionId) {
        arr.push({
          value: Math.round(c.get('value')),
          name: name,
          id: cid,
        })
      }
    })
    // If the dimension is "Basic Needs", then we break the page (Only for printing purpose)

    return (
      <React.Fragment>
        {arr
          .sort((a, b) => doSortScores(a, b))
          .map((c, i) => {
            return (
              <React.Fragment key={i}>
                {dimensionId === 1 && i > 0 && i % 9 === 0 && (
                  <React.Fragment>
                    <span className="pageBreak" />
                    <div className="page-spacing" />
                  </React.Fragment>
                )}
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={3}
                  key={i}
                  className="hex-div"
                >
                  <HexScorecard
                    score={c.value}
                    id={c.id}
                    mainColor={color}
                    secondaryColor={backColor}
                    icon={considerationToDimension[c.id].icon}
                    onClick={() =>
                      handleOpenDrawer(c.value, color, c.name, c.id)
                    }
                  />
                </Grid>
              </React.Fragment>
            )
          })}
      </React.Fragment>
    )
  }

  function sortColumns(a, b) {
    return a.name > b.name ? 1 : -1
  }

  function scoreReportCol(key) {
    return intl.formatMessage({ id: `score.report.${key}` })
  }

  function formatDownloadData(pageStore) {
    setLoadingDownloadData(true)

    let _downloadData = fromJS([])
    const allInfo = pageStore.get('allInfo').toJS()

    _downloadData = _downloadData.push(
      fromJS({
        [scoreReportCol('snapshotRevision')]: allInfo.LatestSnapshotRevision,
        [scoreReportCol('lastUpdated')]: allInfo.lastUpdated,
        [scoreReportCol('cityName')]: cityName,
        [scoreReportCol('cityIQ')]: allInfo.LatestCityIq,
      })
    )
    allInfo.LatestCityDimensions.sort(sortColumns).forEach((d, i) => {
      const dimensionName = intl.formatMessage({
        id: `dimensions.${d.id}`,
      })
      if (!!_downloadData.get(i)) {
        _downloadData = _downloadData.setIn(
          [i, scoreReportCol('dimensionName')],
          dimensionName
        )
        _downloadData = _downloadData.setIn(
          [i, scoreReportCol('dimensionScore')],
          d.value
        )
      } else {
        _downloadData = _downloadData.push(
          fromJS({
            [scoreReportCol('dimensionName')]: dimensionName,
            [scoreReportCol('dimensionScore')]: d.value,
          })
        )
      }
    })
    allInfo.LatestCityConsiderations.sort(sortColumns).forEach((c, i) => {
      const considerationName = intl.formatMessage({
        id: `considerations.${c.id}`,
      })
      if (!!_downloadData.get(i)) {
        _downloadData = _downloadData.setIn(
          [i, scoreReportCol('considerationName')],
          considerationName
        )
        _downloadData = _downloadData.setIn(
          [i, scoreReportCol('considerationScore')],
          c.value
        )
      } else {
        _downloadData = _downloadData.push(
          fromJS({
            [scoreReportCol('considerationName')]: considerationName,
            [scoreReportCol('considerationScore')]: c.value,
          })
        )
      }
    })
    allInfo.LatestCityIndicators.sort(sortColumns).forEach((ind, i) => {
      const indicatorName = intl.formatMessage({
        id: `indicators.name.${ind.id}`,
      })
      if (!!_downloadData.get(i)) {
        _downloadData = _downloadData.setIn(
          [i, scoreReportCol('indicatorName')],
          indicatorName
        )
        _downloadData = _downloadData.setIn(
          [i, scoreReportCol('indicatorScore')],
          ind.value
        )
      } else {
        _downloadData = _downloadData.push(
          fromJS({
            [scoreReportCol('indicatorName')]: indicatorName,
            [scoreReportCol('indicatorScore')]: ind.value,
          })
        )
      }
    })
    const sortedData = _downloadData.toJS().map((dataSet) => {
      const ordered = {}
      Object.keys(dataSet)
        .sort()
        .forEach((key) => {
          ordered[key] = dataSet[key]
        })
      return ordered
    })

    setLoadingDownloadData(false)
    setDownloadData(sortedData)
  }

  function renderFilter() {
    return (
      <div className="scoreSort">
        <span className="prefix">
          {' '}
          <FormattedMessage id="scoreSort.sortBy" />{' '}
        </span>
        <FormControl>
          <Select
            value={sortScores}
            onChange={(e) => setSortScores(e.target.value)}
            inputProps={{
              name: 'dimensionValue',
            }}
            disableUnderline={true}
            autoWidth
            classes={{ select: 'dropdown-select' }}
            style={{ color: '#fff' }}
          >
            <MenuItem value={'AtoZ'}>
              <FormattedMessage
                id={'scoreSort.AtoZ'}
                description="option-text"
              />
            </MenuItem>
            <MenuItem value={'ZtoA'}>
              <FormattedMessage
                id={'scoreSort.ZtoA'}
                description="option-text"
              />
            </MenuItem>
            <MenuItem value={'HighToLow'}>
              <FormattedMessage
                id={'scoreSort.HighToLow'}
                description="option-text"
              />
            </MenuItem>
            <MenuItem value={'LowToHigh'}>
              <FormattedMessage
                id={'scoreSort.LowToHigh'}
                description="option-text"
              />
            </MenuItem>
          </Select>
        </FormControl>
      </div>
    )
  }

  function getWorksheetInfo() {
    let worksheetInfo = {
      cityName: '',
      snapshotRevision: '',
    }
    if (downloadData.length) {
      worksheetInfo.cityName = cityName
      worksheetInfo.snapshotRevision =
        downloadData[0][scoreReportCol('snapshotRevision')]
    }
    return worksheetInfo
  }

  // render() {
  const [worksheetInfo, setWorksheetInfo] = React.useState(getWorksheetInfo())
  const [cleanedSnapshotRevision, setCleanedSnapshotRevision] =
    React.useState(null)

  React.useEffect(() => {
    setLoadingDownloadData(true)
    setWorksheetInfo(getWorksheetInfo())
  }, [downloadData, locale, cityName])

  React.useEffect(() => {
    if (!worksheetInfo.snapshotRevision) return
    // exclude cityid from snapshot version id, as we wanna hide it from the .xlxs file
    setCleanedSnapshotRevision(
      worksheetInfo.snapshotRevision
        .split('-')
        .map((a, idx) => (idx === 0 ? '' : a))
        .join('-')
        .replace('-', '')
    )
    setLoadingDownloadData(false)
  }, [worksheetInfo])

  React.useEffect(() => {
    if (cleanedSnapshotRevision !== null) setLoadingDownloadData(false)
  }, [cleanedSnapshotRevision])

  // if accidentally landed to /city-scores URL, redirect
  if (pageStore.get('isMultiCity')) {
    return <Redirect to={'dashboard'} />
  }
  const isShowAll = !activeDim || activeDim === 0

  return (
    <div className="CityScoresPage">
      <Helmet>
        <title>
          CitiIQ - {intl.formatMessage({ id: 'city.scores.navigation' })}
        </title>
      </Helmet>
      <div className="city-scores-top-strip">
        <Link to="/dashboard" className="navigation">
          <h1>
            <ArrowBack className="nav-icon" />
            <span className="city-name-print">{`${cityName} - `}</span>
            <FormattedMessage id="city.scores.navigation" description="nav" />
          </h1>
        </Link>
        <div className="action-icons">
          {loadingDownloadData ||
          !worksheetInfo.cityName ||
          !cleanedSnapshotRevision ? null : (
            <Workbook
              filename={`${worksheetInfo.cityName}-${countryName}-${cleanedSnapshotRevision}.xlsx`}
              element={<DownloadDataButton />}
            >
              <Workbook.Sheet data={downloadData} name={worksheetInfo.cityName}>
                <Workbook.Column
                  label={scoreReportCol('cityName')}
                  value={(row) => row[scoreReportCol('cityName')]}
                />
                <Workbook.Column
                  label={scoreReportCol('cityIQ')}
                  value={(row) =>
                    row[scoreReportCol('cityIQ')]
                      ? row[scoreReportCol('cityIQ')].toFixed(1)
                      : ''
                  }
                />
                <Workbook.Column
                  label={scoreReportCol('dimensionName')}
                  value={(row) => row[scoreReportCol('dimensionName')]}
                />
                <Workbook.Column
                  label={scoreReportCol('dimensionScore')}
                  value={(row) =>
                    row[scoreReportCol('dimensionScore')]
                      ? row[scoreReportCol('dimensionScore')].toFixed(1)
                      : ''
                  }
                />
                <Workbook.Column
                  label={scoreReportCol('considerationName')}
                  value={(row) => row[scoreReportCol('considerationName')]}
                />
                <Workbook.Column
                  label={scoreReportCol('considerationScore')}
                  value={(row) =>
                    row[scoreReportCol('considerationScore')]
                      ? row[scoreReportCol('considerationScore')].toFixed(1)
                      : ''
                  }
                />
                <Workbook.Column
                  label={scoreReportCol('indicatorName')}
                  value={(row) => row[scoreReportCol('indicatorName')]}
                />
                <Workbook.Column
                  label={scoreReportCol('indicatorScore')}
                  value={(row) =>
                    row[scoreReportCol('indicatorScore')]
                      ? (row[scoreReportCol('indicatorScore')] * 100).toFixed(1)
                      : ''
                  }
                />
                <Workbook.Column
                  label={scoreReportCol('snapshotRevision')}
                  value={(row) => row[scoreReportCol('snapshotRevision')]}
                />
                <Workbook.Column
                  label={scoreReportCol('lastUpdated')}
                  value={(row) =>
                    row[scoreReportCol('lastUpdated')]
                      ? row[scoreReportCol('lastUpdated')]
                      : ''
                  }
                />
              </Workbook.Sheet>
            </Workbook>
          )}
        </div>
      </div>
      <Grid container spacing={24}>
        <Grid item xs={12} className="dimension-dashboard-container">
          <DimensionDashboard
            onDimensionClick={(x) => setActiveDim(x)}
            selectedDimension={activeDim}
            infoModalOpen={infoModalOpen}
            openInfoModal={() => setInfoModalOpen(true)}
            closeInfoModal={() => {
              setInfoModalOpen(false)
              setCurrentInfoSlide(0)
            }}
            // 0 = 'basic needs'..., 4 = 'destiny', 5 = 'overall'
            defaultSlide={activeDim - 1 < 0 ? 0 : activeDim - 1}
            setCurrentSlide={(slideNum) => setCurrentSlide(slideNum)}
            selectable
          />
        </Grid>
        {renderFilter()}
      </Grid>
      <Grid container spacing={24} className="dimension-scores-container">
        {(isShowAll || activeDim === 1) && (
          <React.Fragment>
            <Grid item xs={12}>
              <div className="dimensionGridHeader" style={{ color: '#34ACE0' }}>
                <FormattedMessage id="dimensions.1" />
              </div>
            </Grid>
            {renderConsiderationCards(1, '#34ACE0', '#71C6EB')}
          </React.Fragment>
        )}
        {(isShowAll || activeDim === 2) && (
          <React.Fragment>
            <Grid item xs={12}>
              <div className="dimensionGridHeader" style={{ color: '#E8961B' }}>
                <FormattedMessage id="dimensions.2" />
              </div>
            </Grid>
            {renderConsiderationCards(2, '#E8961B', '#FBB03F')}
          </React.Fragment>
        )}
        {(isShowAll || activeDim === 3) && (
          <React.Fragment>
            <Grid item xs={12}>
              <div className="dimensionGridHeader" style={{ color: '#764B9E' }}>
                <FormattedMessage id="dimensions.3" />
              </div>
            </Grid>
            {renderConsiderationCards(3, '#764B9E', '#9163BB')}
          </React.Fragment>
        )}
        {!activeDim && (
          <React.Fragment>
            <span className="pageBreak" />
            <div className="page-spacing" />
          </React.Fragment>
        )}
        {(isShowAll || activeDim === 4) && (
          <React.Fragment>
            <Grid item xs={12}>
              <div className="dimensionGridHeader" style={{ color: '#5CC19C' }}>
                <FormattedMessage id="dimensions.4" />
              </div>
            </Grid>
            {renderConsiderationCards(4, '#5CC19C', '#7DDAB8')}
          </React.Fragment>
        )}
        {(isShowAll || activeDim === 5) && (
          <React.Fragment>
            <Grid item xs={12}>
              <div className="dimensionGridHeader" style={{ color: '#EF3488' }}>
                <FormattedMessage id="dimensions.5" />
              </div>
            </Grid>
            {renderConsiderationCards(5, '#EF3488', '#FA67A9')}
          </React.Fragment>
        )}
      </Grid>
    </div>
  )
  // }
}

function mapStateToProps(state) {
  return {
    cityStore: state.CityStore,
    userStore: state.UserStore,
    pageStore: state.PageStore,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        cityProfile,
        setDrawerinfo,
        closeDrawer,
        setIndDialoginfo,
      },
      dispatch
    ),
  }
}

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(CityScores)
)
