import { closeDrawer } from '../../actions/ModalActions.js'
import {
  createWatchConsideration,
  unwatchConsideration,
} from '../../actions/WatchListActions.js'
import { InfoDonut, Icon, IndicatorButton } from '../../components'
import {
  considerationToDimension,
  dimensionToColor,
} from '../../constants/Constants'
import { gAnalytics, considerationsLookup } from '../../helpers/analytics'
import { renderParagraphWithLinebreaks } from '../../helpers/string'
import './InfoDrawer.scss'
import Grid from '@material-ui/core/Grid'
import Snackbar from '@material-ui/core/Snackbar'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import ArrowBack from '@material-ui/icons/ArrowBack'
import Visibility from '@material-ui/icons/Visibility'
import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

export const InfoDrawer = ({
  pageStore,
  userStore,
  modalStore,
  watchListStore,
  actions,
  intl,
}) => {
  const considerationId = modalStore.get('drawerConsideration')
  const grade = modalStore.get('drawerGrade')
  const open = modalStore.get('drawerOpen')

  const [openSnack, setOpenSnack] = React.useState(false)
  const [add, setAdd] = React.useState(true)
  const scrollRef = React.useRef()

  const LatestCityIndicatorWeights = pageStore
    .getIn(['allInfo', 'LatestCityIndicatorWeights'])
    ?.toJS()
  const LatestCityIndicators = pageStore
    .getIn(['allInfo', 'LatestCityIndicators'])
    ?.toJS()
  const [indicators, setIndicators] = React.useState([])

  React.useEffect(() => {
    gAnalytics('event', [
      'Consideration',
      'open',
      considerationsLookup[considerationId],
    ])

    let indicatorW = [...(LatestCityIndicatorWeights ?? [])]
    indicatorW = indicatorW.filter((i) => i.c == considerationId)
    const _indicators = indicatorW.map((i, index) => {
      return {
        i: i,
        data: LatestCityIndicators.find((j) => j.id === i.i),
      }
    })

    setIndicators(_indicators)
  }, [considerationId, LatestCityIndicatorWeights !== undefined])

  React.useEffect(() => {
    if (modalStore.get('dialogIndicatorOpen')) return
    setTimeout(() => {
      scrollRef.current?.scrollIntoView()
    }, 100)
  }, [considerationId])

  if (!open) return null

  function renderIndicators() {
    return indicators
      .sort((a, b) => (a.data.name > b.data.name ? 1 : -1))
      .map(({ i, data }, index) => {
        return (
          <Grid item sm={6} xs={12} key={index} className="indicator-wrapper">
            <IndicatorButton data={data} weight={i.w} />
          </Grid>
        )
      })
  }

  function watchConsideration() {
    const userId = userStore.getIn(['userInfo', 'id'])
    actions.createWatchConsideration(userId, considerationId, 0)
    setOpenSnack(true)
    setAdd(true)
  }

  function unwatchConsideration() {
    let result_array
    let watchList_array
    watchList_array = watchListStore.get('watchList')
    result_array = watchList_array.filter(
      (x) => x.get('consideration_id') === considerationId
    )
    actions.unwatchConsideration(result_array.first().get('id'))
    setOpenSnack(true)
    setAdd(false)
  }

  function isWatched() {
    let result_array
    let watchList_array
    watchList_array = watchListStore.get('watchList')
    result_array = watchList_array.filter(
      (x) => x.get('consideration_id') === considerationId
    )
    return result_array.size > 0
  }
  function renderIconColor() {
    if (isWatched(considerationId)) {
      return 'clickable is-watched'
    } else {
      return 'clickable is-not-watched'
    }
  }

  function handleSnackbarClose() {
    setOpenSnack(false)
  }

  function renderSnackBar() {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={openSnack}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
      >
        <div className="goalSnackBar">
          <FormattedMessage id={'considerations.' + considerationId} />{' '}
          <FormattedMessage
            id={
              add ? 'snackbar.watchAddSuccess' : 'snackbar.watchRemoveSuccess'
            }
          />
        </div>
      </Snackbar>
    )
  }

  // NOTE: private_user should not be able to 'watch' considerations
  const isPrivateUser = userStore.get('role').toJS().title === 'private_user'
  var _data

  const isMobile = window.innerWidth < 980
  if (!considerationId) return null
  if (considerationId) {
    const id = considerationId
    const dimId = considerationToDimension[id].dimension
    const color = dimId ? dimensionToColor[dimId].main : '#34ACE0'
    const title = <FormattedMessage id={'considerations.' + id} />

    _data = {
      id,
      color,
      grade: !isNaN(grade)
        ? grade
        : pageStore
            .getIn(['allInfo', 'LatestCityConsiderations'])
            .find((c) => c.get('id') === id)
            .get('value'),
      icon: considerationToDimension[id].icon,
      indicators: [],
      title,
      weight: Math.round(
        pageStore
          .getIn(['allInfo', 'LatestCityConsiderations'])
          .find((c) => c.get('id') === id)
          .get('weightedValueOld')
      ),
    }
  }
  const panelWidth = Math.min(800, window.innerWidth)
  const iconName = _data.icon
    ? _data.icon.replace('+', 'and').replace(/\s+/g, '-').toLowerCase()
    : 'efficiency'
  const hexagon = (
    <svg
      width="112px"
      height="97px"
      viewBox="0 0 112 97"
      className="drawerHexagon"
    >
      <defs />
      <g
        id="City-Scores"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
      >
        <g id="Artboard" stroke={_data.color} strokeWidth="2">
          <polygon
            id="Polygon-Copy-6"
            transform="translate(56.000000, 48.500000) rotate(-270.000000) translate(-56.000000, -48.500000) "
            points="56 -5.5 103.5 21.5 103.5 75.5 56 102.5 8.5 75.5 8.5 21.5"
          />
        </g>
      </g>
    </svg>
  )
  return (
    <React.Fragment>
      <SwipeableDrawer
        variant={'temporary'}
        onClose={() => {
          actions.closeDrawer()
        }}
        anchor="right"
        className="sidebar-drawer"
        open={open || false}
      >
        <div
          style={{ width: panelWidth + 'px', height: '100%' }}
          className={'infoDrawer'}
        >
          <Grid className="sidebar-print" container spacing={24}>
            <div ref={scrollRef} className="scrollRef" />
            <Grid
              item
              xs={12}
              classes={{ item: 'itemRow' }}
              className="action-btns"
            >
              <ArrowBack
                style={{ float: 'left', cursor: 'pointer' }}
                onClick={() => {
                  actions.closeDrawer()
                }}
              />
              {!isPrivateUser && (
                <Visibility
                  className={renderIconColor()}
                  onClick={
                    isWatched()
                      ? () => unwatchConsideration()
                      : () => watchConsideration()
                  }
                />
              )}
            </Grid>
            <Grid item xs={12} classes={{ item: 'headerRow' }}>
              <Grid
                container
                spacing={24}
                direction="row"
                className="info-drawer-container"
              >
                <Grid
                  item
                  xs={12}
                  md={'auto'}
                  classes={{ item: 'centerContents' }}
                  className="hexagon-container"
                >
                  <div
                    className="drawerIconOuter"
                    style={{ color: _data.color }}
                  >
                    {hexagon}
                    <Icon name={iconName} className={'drawerIcon'} />
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={'auto'}
                  classes={{ item: 'centerContents' }}
                  className="drawerSubTitleContainer"
                >
                  <div className="drawerSubTitle">
                    <FormattedMessage id="dashboard.consideration" />
                  </div>
                  <div className="drawerTitle"> {_data.title} </div>
                </Grid>
              </Grid>
            </Grid>
            <div className="infoWrap">
              <div className="icon-container">
                <div className="rightAlignContents">
                  <InfoDonut
                    actual={Math.round(_data.grade)}
                    size={isMobile ? 85 : 100}
                    textSize={isMobile ? '32px' : '40px'}
                    actualColor={_data.color || undefined}
                  />
                </div>
              </div>
              <div className="icon-description">
                <div className="drawerSubTitle2">
                  <FormattedMessage id="drawer.score" />
                </div>
                <div className="drawerContent">
                  {/* <FormattedMessage id="drawer.scoreDescription" /> */}
                  {renderParagraphWithLinebreaks({
                    intl: intl,
                    messageId: `drawer.scoreDescription`,
                  })}
                </div>
              </div>
            </div>
            <div className="infoWrap">
              <div className="icon-container">
                <div className="rightAlignContents">
                  <InfoDonut
                    actual={_data.weight}
                    displayText={_data.weight + '%'}
                    size={isMobile ? 85 : 100}
                    textSize={isMobile ? '28px' : '40px'}
                    actualColor={_data.color || undefined}
                  />
                </div>
              </div>
              <div className="icon-description">
                <div className="drawerSubTitle2">
                  {renderParagraphWithLinebreaks({
                    intl: intl,
                    messageId: `drawer.weighting`,
                  })}
                </div>
                <div className="drawerContent">
                  {renderParagraphWithLinebreaks({
                    intl: intl,
                    messageId: `drawer.weightingDescription`,
                  })}
                </div>
              </div>
            </div>
            <Grid container spacing={24} className="consideration-desc">
              <Grid item xs={12}>
                {considerationId
                  ? renderParagraphWithLinebreaks({
                      intl: intl,
                      messageId: `considerations.description.${considerationId}`,
                    })
                  : null}
              </Grid>
              <Grid item xs={12}>
                {considerationId
                  ? renderParagraphWithLinebreaks({
                      intl: intl,
                      paragraphClassName: 'subHeader',
                      messageId: `drawer.indicators`,
                    })
                  : null}
                {renderParagraphWithLinebreaks({
                  intl: intl,
                  messageId: `drawer.indDescription`,
                })}
              </Grid>
              {renderIndicators()}
            </Grid>
          </Grid>
        </div>
      </SwipeableDrawer>
      {renderSnackBar()}
    </React.Fragment>
  )
}

function mapStateToProps(state) {
  return {
    pageStore: state.PageStore,
    userStore: state.UserStore,
    modalStore: state.ModalStore,
    watchListStore: state.WatchListStore,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        closeDrawer,
        unwatchConsideration,
        createWatchConsideration,
      },
      dispatch
    ),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(InfoDrawer))
