import {
  CREATE_MILESTONE,
  DELETE_MILESTONE,
  GET_MILESTONES_USER_CITY,
  OPEN_FORCE_LOGOUT_DIALOG,
  UPDATE_MILESTONE,
} from '../constants/ActionTypes'
import * as queries from '../constants/QueryTypes'
import { hasUserSessionError } from '../helpers/errorHandler'
import query from '../services/GraphQLService'

export const getMilestonesByUserCity = (user_id, city_id) => (dispatch) => {
  query('query', queries.GET_MILESTONES_USER_CITY_QUERY, {
    user_id,
    city_id,
  }).then(({ data, errors }) => {
    if (errors && hasUserSessionError(errors)) {
      // dispatch({ type: OPEN_FORCE_LOGOUT_DIALOG })
      return
    }
    dispatch({
      type: GET_MILESTONES_USER_CITY,
      payload: data.GetMilestonesByUserCity,
    })
  })
}

export const createMilestone =
  (title, date, user_id, city_id) => (dispatch) => {
    return new Promise((resolve, reject) => {
      query('mutate', queries.CREATE_MILESTONE_QUERY, {
        title,
        date,
        user_id,
        city_id,
      })
        .then(({ data, errors }) => {
          if (errors && hasUserSessionError(errors)) {
            // dispatch({ type: OPEN_FORCE_LOGOUT_DIALOG })
            reject(errors)
            return
          }
          dispatch({
            type: CREATE_MILESTONE,
            payload: data.createMilestone,
          })
          resolve(data)
        })
        .catch((e) => {
          reject(e)
        })
    })
  }

export const removeMilestone = (id) => (dispatch) => {
  return new Promise((resolve, reject) => {
    query('mutate', queries.DELETE_MILESTONE_QUERY, { id })
      .then(({ data, errors }) => {
        if (errors && hasUserSessionError(errors)) {
          // dispatch({ type: OPEN_FORCE_LOGOUT_DIALOG })
          reject(errors)
          return
        }
        dispatch({
          type: DELETE_MILESTONE,
          payload: { id, response: data.deleteMilestone },
        })
        resolve(data)
      })
      .catch((e) => {
        reject(e)
      })
  })
}

export const updateMilestone = (id, title, date) => (dispatch) => {
  return new Promise((resolve, reject) => {
    query('mutate', queries.UPDATE_MILESTONE_QUERY, { id, title, date })
      .then(({ data, errors }) => {
        if (errors && hasUserSessionError(errors)) {
          // dispatch({ type: OPEN_FORCE_LOGOUT_DIALOG })
          reject(errors)
          return
        }
        dispatch({
          type: UPDATE_MILESTONE,
          payload: data.updateMilestone,
        })
        resolve(data)
      })
      .catch((e) => {
        reject(e)
      })
  })
}
