import {
  GET_CITY_PROFILE,
  OPEN_FORCE_LOGOUT_DIALOG,
} from '../constants/ActionTypes'
import { GET_CITY_PROFILE_QUERY } from '../constants/QueryTypes'
import { hasUserSessionError } from '../helpers/errorHandler'
import query from '../services/GraphQLService'

export const cityProfile = (city_id) => (dispatch) => {
  return new Promise((resolve, reject) => {
    query('query', GET_CITY_PROFILE_QUERY, { city_id })
      .then(({ data, errors }) => {
        if (errors && hasUserSessionError(errors)) {
          // dispatch({ type: OPEN_FORCE_LOGOUT_DIALOG })
          reject(errors)
          return
        }
        dispatch({ type: GET_CITY_PROFILE, payload: data.CityProfile })
        resolve(data.CityProfile)
      })
      .catch((e) => {
        reject(e)
      })
  })
}
