/* eslint-disable react-hooks/rules-of-hooks */
import { toggleMultiCityView } from '../../actions/PageActions'
import { updateCurrentCityId } from '../../actions/UserActions'
import { DataTable } from '../../components'
import Rating from '../../components/Rating/rating'
import {
  renderColor,
  getConsiderationByDimensionKey,
} from '../../constants/Constants'
import { getDimensionWeightsForOverallDonut } from '../../helpers/donut'
import {
  getLocalizedCityName,
  getLocalizedCountryName,
} from '../../helpers/lang'
import useLanguage from '../../state/hooks/useLanguage'
import { renderDonut, renderSimpleBar } from './Helpers'
import './Table.scss'
import { CircularProgress } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import React from 'react'
import Workbook from 'react-excel-workbook'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

const Table = ({
  dimension,
  dimensionView,
  cities,
  formattedCityCountryMap,
  updateCurrentCityId,
  toggleMultiCityView,
  intl,
}) => {
  const [rows, setRows] = React.useState([])
  const [columns, setColumns] = React.useState([])

  const [sortedRowsForDownload, setSortedRowsForDownload] = React.useState([])
  const [sortByCol, setSortByCol] = React.useState(
    dimension === 0 ? 0 : 'overall_score'
  )
  const [sortDirection, setSortDirection] = React.useState('descending')

  const downloadXLSButton = React.useRef(null)
  const [loading, setLoading] = React.useState(true)

  const { language } = useLanguage()
  React.useEffect(() => {
    if (formattedCityCountryMap.arr.length > 0) {
      setRows(getRows())
      setColumns(getColumns())
    }

    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 200)

    // eslint-disable-next-line
  }, [dimension, dimensionView, formattedCityCountryMap, cities])

  React.useEffect(() => {
    const _sorted = [...rows]
    _sorted.sort((a, b) => {
      if (sortDirection === 'descending') {
        return b[sortByCol] - a[sortByCol]
      } else {
        return a[sortByCol] - b[sortByCol]
      }
    })
    setSortedRowsForDownload(_sorted)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows, columns, sortByCol, sortDirection])

  function overallColumnConfigForDimension(dimenstionIdNumber) {
    let config = {
      name: dimenstionIdNumber,
      label: <FormattedMessage id={`dimensions.${dimenstionIdNumber}`} />,
      options: {
        customBodyRender: (value, tableMeta) => {
          switch (dimenstionIdNumber) {
            case '0':
              const cityId = tableMeta.rowData[0].split('_')[1]
              const rating = tableMeta.rowData[tableMeta.rowData.length - 1]
              return (
                <div
                  style={{
                    marginTop: '20px',
                    marginBottom: '20px',
                  }}
                >
                  {renderDonut({
                    data: getDimensionWeightsForOverallDonut(cities, cityId),
                    colors: [1, 2, 3, 4, 5]
                      .map((d) => renderColor(d).main)
                      .concat(['#444']),
                    label: Math.round(value),
                    donutSize: 76,
                  })}
                  <Rating
                    rating={rating}
                    starWidth="13.62px"
                    starSpacing="1.5px"
                  />
                </div>
              )
            default:
              return (
                <div style={{ marginTop: '20px', marginBottom: '20px' }}>
                  {renderDonut({
                    data: [value, 100 - value],
                    colors: [renderColor(dimenstionIdNumber).main, '#444'],
                    label: Math.round(value),
                  })}
                </div>
              )
          }
        },
      },
    }
    // if (String(dimenstionIdNumber) === '0') {
    //   config.options.sortDirection = 'desc'
    // }
    return config
  }

  function columnConfigForDimension(dimenstionIdNumber) {
    let config = {
      name: 'overall_score',
      label: <FormattedMessage id={`dimensions.${dimenstionIdNumber}`} />,
      options: {
        customBodyRender: (value, _tableMeta) => (
          <div style={{ marginRight: '50px' }}>
            {renderDonut({
              data: [value, 100 - value],
              colors: [renderColor(dimenstionIdNumber).main, '#444'],
              label: Math.round(value),
            })}
          </div>
        ),
      },
    }
    return config
  }

  function columnConfigForConsiderations() {
    return getConsiderationByDimensionKey(dimension).map((c) => {
      return {
        name: c.id,
        label: intl.formatMessage({ id: `considerations.${c.id}` }),
        options: {
          customBodyRender: (value) => {
            switch (dimensionView) {
              case 'bar':
                return (
                  <div style={{ display: 'flex' }}>
                    {renderSimpleBar(renderColor(dimension).main, value)}
                    <span>{Math.round(value)}</span>
                  </div>
                )
              case 'number':
                return Math.round(value)
              default:
                break
            }
          },
        },
      }
    })
  }

  function getCityColumn() {
    return {
      name: 'city',
      label: `${intl.formatMessage({ id: 'dashboard.city' })}`,
      options: {
        customBodyRender: (value) => {
          const { obj } = formattedCityCountryMap
          const cityId = value.split('_')[value.split('_').length - 1]
          const cityName = getLocalizedCityName(obj[cityId], language)
          const countryName = getLocalizedCountryName(obj[cityId], language)
          return (
            <div
              style={{
                textAlign: 'left',
                cursor: 'pointer',
              }}
              onClick={() => {
                updateCurrentCityId(parseInt(cityId))
                toggleMultiCityView(false)
              }}
            >
              <p style={{ whiteSpace: 'no-wrap' }}>{cityName}</p>
              {dimension === 0 && (
                <p style={{ whiteSpace: 'no-wrap', opacity: 0.5 }}>
                  {countryName}
                </p>
              )}
            </div>
          )
        },
      },
    }
  }

  function getCountryColumn() {
    return {
      name: 'country',
      label: `${intl.formatMessage({ id: 'dashboard.country' })}`,
      options: {
        display: dimension !== 0,
        viewColumns: dimension !== 0,
        sort: true,
        customBodyRender: (value) => {
          return (
            <div
              style={{
                textAlign: 'left',
              }}
            >
              <p style={{ whiteSpace: 'no-wrap' }}>{value}</p>
            </div>
          )
        },
      },
    }
  }

  // hidden column just for accessing star rating value in `Overall` Column
  function addRatingColumn() {
    return {
      name: 'rating',
      label: intl.formatMessage({ id: `rating.column` }),
      options: {
        display: false,
      },
    }
  }

  function getOverallColumns() {
    return [getCityColumn(), getCountryColumn()].concat(
      ['0', '1', '2', '3', '4', '5']
        .map((d_id) => overallColumnConfigForDimension(d_id))
        .concat([addRatingColumn()])
    )
  }

  function getDimensionColumns() {
    return [getCityColumn(), getCountryColumn()]
      .concat([columnConfigForDimension(`${dimension}`)])
      .concat(columnConfigForConsiderations())
  }

  function getColumns() {
    switch (dimension) {
      case 0:
        const overallCols = getOverallColumns()
        return overallCols
      case 5:
      case 4:
      case 3:
      case 2:
      case 1:
        const dimensionCols = getDimensionColumns()
        return dimensionCols
      default:
        return null
    }
  }

  function getOverallRow(id, name, iq, iq_quality_star, dimensions) {
    const { obj } = formattedCityCountryMap

    const value = {
      city: `${name}_${id}`,
      country: getLocalizedCountryName(obj[id], language),
      0: iq,
      1: dimensions[0].value,
      2: dimensions[1].value,
      3: dimensions[2].value,
      4: dimensions[3].value,
      5: dimensions[4].value,
      rating: iq_quality_star,
    }
    return value
  }

  function getDimensionRow(id, name, dimensions, considerations) {
    const arrKey = dimension - 1
    const { obj } = formattedCityCountryMap
    const countryName = getLocalizedCountryName(obj[id], language)
    let rows = {
      city: `${name}_${id}`,
      country: countryName,
      [`overall_score`]: dimensions[arrKey].value,
    }

    considerations.forEach((c) => {
      if (
        getConsiderationByDimensionKey(dimension)
          .map((c) => c.id)
          .includes(String(c.id))
      ) {
        rows[c.id] = c.value
      }
    })
    /*
     * the values are used for table sorting
     * make sure it is not null or undefined here
     */

    return rows
  }

  function getRows() {
    return formattedCityCountryMap.arr
      .filter(({ id }) => !!cities[id])
      .map(({ id, name }) => {
        const { iq, iq_quality_star, dimensions, considerations } = cities[id]
        switch (dimension) {
          case 0:
            return getOverallRow(id, name, iq, iq_quality_star, dimensions)
          default:
            return getDimensionRow(id, name, dimensions, considerations)
        }
      })
      .sort((a, b) => {
        if (dimension === 0) {
          return a[0] > b[0] ? -1 : 1
        } else {
          return a['overall_score'] > b['overall_score'] ? -1 : 1
        }
      })
  }

  function onDownload() {
    downloadXLSButton.current.click()
    return false
  }

  function onColumnSortChange(changedColumn, direction) {
    setSortByCol(changedColumn)
    setSortDirection(direction)
  }

  const renderOverallWorkBook = () => {
    return (
      <Workbook
        filename="Multi-City-Scores.xlsx"
        element={
          <Button
            buttonRef={downloadXLSButton}
            sx={{ display: 'none' }}
            children={[]}
          />
        }
      >
        <Workbook.Sheet data={sortedRowsForDownload} name="Multi-City Scores">
          <Workbook.Column
            label="City Name"
            value={(row) => row['city'].split('_')[0]}
          />
          <Workbook.Column label="Country" value={(row) => row['country']} />
          <Workbook.Column
            label="Overall"
            value={(row) => (row['0'] ? row['0'].toFixed(1) : '')}
          />
          <Workbook.Column
            label="Basic Needs"
            value={(row) => (row['1'] ? row['1'].toFixed(1) : '')}
          />
          <Workbook.Column
            label="Competitiveness"
            value={(row) => (row['2'] ? row['2'].toFixed(1) : '')}
          />
          <Workbook.Column
            label="Opportunity"
            value={(row) => (row['3'] ? row['3'].toFixed(1) : '')}
          />
          <Workbook.Column
            label="Livability"
            value={(row) => (row['4'] ? row['4'].toFixed(1) : '')}
          />
          <Workbook.Column
            label="Destiny"
            value={(row) => (row['5'] ? row['5'].toFixed(1) : '')}
          />
          <Workbook.Column
            label="Rating"
            value={(row) => (row['rating'] ? row['rating'].toFixed(1) : '')}
          />
        </Workbook.Sheet>
      </Workbook>
    )
  }

  const renderDimensionWorkBook = () => {
    const dimensionLabel = intl.formatMessage({ id: `dimensions.${dimension}` })
    return (
      <Workbook
        filename={`Multi-City-${dimensionLabel}-Consideration-Scores.xlsx`}
        element={
          <Button buttonRef={downloadXLSButton} sx={{ display: 'none' }} />
        }
      >
        <Workbook.Sheet
          data={sortedRowsForDownload}
          name={`Dimension ${dimensionLabel}`}
        >
          <Workbook.Column
            label="City Name"
            value={(row) => row['city'].split('_')[0]}
          />
          <Workbook.Column label="Country" value={(row) => row['country']} />
          <Workbook.Column
            label={dimensionLabel}
            value={(row) =>
              row['overall_score'] ? row['overall_score'].toFixed(1) : ''
            }
          />
          {(getConsiderationByDimensionKey(dimension) ?? []).map((c, idx) => {
            return (
              <Workbook.Column
                label={c['label']}
                value={(row) =>
                  c['id']
                    ? row[Number(c['id'])]
                      ? row[Number(c['id'])].toFixed(1)
                      : ''
                    : ''
                }
              />
            )
          })}
        </Workbook.Sheet>
      </Workbook>
    )
  }

  const renderLoading = () => (
    <div
      style={{
        height: '80vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress style={{ color: '#b1ff44' }} />
    </div>
  )

  const dataTable = React.useMemo(() => {
    return loading ? null : (
      <DataTable
        title="   "
        rows={rows}
        columns={columns}
        options={{
          filter: false,
          download: true,
          print: true,
          onDownload: onDownload,
          onColumnSortChange: onColumnSortChange,
          textLabels: {
            toolbar: {
              downloadCsv: 'Download XLSX',
            },
          },
        }}
        className={dimension}
      />
    )
  }, [rows, loading])

  const workbook = React.useMemo(() => {
    switch (dimension) {
      case 0:
        return renderOverallWorkBook()
      default:
        return renderDimensionWorkBook()
    }
  }, [dimension, sortedRowsForDownload, loading])
  if (loading) return renderLoading()

  return rows.length == 0 || loading ? (
    renderLoading()
  ) : (
    <>
      {dataTable}
      {workbook}
    </>
  )
}

function mapStateToProps(state) {
  return {
    pageStore: state.PageStore,
    userStore: state.UserStore,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        updateCurrentCityId,
        toggleMultiCityView,
      },
      dispatch
    ),
  }
}
export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Table))
