import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloClient } from 'apollo-client'
import { ApolloLink } from 'apollo-link'
import { HttpLink } from 'apollo-link-http'

const cache = new InMemoryCache()

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_BACKEND_URL
    ? `${process.env.REACT_APP_BACKEND_URL}/graphql`
    : `${window.location.origin}/api/graphql`,
  credentials: 'include',
})

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'network-only',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  },
}

const link = ApolloLink.from([httpLink])

const client = new ApolloClient({
  link,
  cache,
  defaultOptions: defaultOptions,
})

export default client
