import { cityProfile } from '../../actions/CityActions'
import { getMultiCityInfos, setCurrentPage } from '../../actions/PageActions'
import {
  CitiIQCard,
  ComparisonBarChart,
  ComparisonAreaChart,
  DimensionDashboard,
  Icon,
  LoadingScreen,
  ScoreTable,
  WatchListComponent,
} from '../../components'
import IndicatorRanking from '../../components/IndicatorRanking/IndicatorRanking'
import Rating from '../../components/Rating/rating'
import { renderColor } from '../../constants/Constants'
import { getDimensionWeightsForOverallDonut } from '../../helpers/donut'
import useLanguage from '../../state/hooks/useLanguage'
import { renderDonut } from '../DashboardMultiCity/Helpers'
import './Dashboard.scss'
import QuarterSelector from './QuarterSelector'
import { Snackbar } from '@material-ui/core'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import React from 'react'
import Helmet from 'react-helmet'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'
import { bindActionCreators } from 'redux'

const DashboardSingleCity = ({
  cityStore,
  userStore,
  pageStore,
  actions,
  intl,
  showRestoreQuarterReminder,
  setShowRestoreQuarterReminder,
}) => {
  const [selectedCityId, setSelectedCityId] = React.useState(null)
  const [dimensionRedirect, setDimensionRedirect] = React.useState(null)
  const [infoModalOpen, setInfoModalOpen] = React.useState(false)
  const [currentInfoSlide, setCurrentInfoSlide] = React.useState(0)
  const { language } = useLanguage()
  const { locale } = language
  const [cityName, setCityName] = React.useState(null)

  React.useEffect(() => {
    if (!locale || !cityStore.getIn(['profile', 'name_new'])) return
    const names = cityStore.getIn(['profile', 'name_new']).toJS()
    if (names.length === 0) return
    setCityName(
      names.filter((c) => c.lang === locale)[0].value ??
        names.filter((c) => c.lang === 'en')[0].value
    )
  }, [locale, cityStore.getIn(['profile', 'name_new'])])

  React.useEffect(() => {
    const selectedCityId =
      userStore.getIn(['selectedCityId']) ||
      userStore
        .get('subscriptions')
        .map((s) => s.get('city_id'))
        .toJS()[0]

    setSelectedCityId(selectedCityId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    const newSelectedCityId =
      userStore.getIn(['selectedCityId']) ||
      userStore
        .get('subscriptions')
        .map((s) => s.get('city_id'))
        .toJS()[0]
    setSelectedCityId(newSelectedCityId)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userStore.getIn(['selectedCityId'])])

  function getYear() {
    return new Date().getFullYear()
  }

  function openModalWithOverride() {
    setInfoModalOpen(true)
    setCurrentInfoSlide(5)
  }

  function renderInfoDonuts() {
    return (
      <DimensionDashboard
        minHeightOverride="230px"
        onDimensionClick={(dim) => {
          setCurrentInfoSlide(dim)
          handleDimensionClick(dim)
        }}
        infoModalOpen={infoModalOpen}
        openInfoModal={() => {
          setCurrentInfoSlide(0)
          setInfoModalOpen(true)
        }}
        closeInfoModal={() => {
          setInfoModalOpen(false)
        }}
        defaultSlide={currentInfoSlide}
        setCurrentSlide={(slideNum) => setCurrentInfoSlide(slideNum)}
      />
    )
  }

  function renderOverallScore() {
    const iq = pageStore.getIn(['allInfo', 'LatestCityIq'])
    const rating = pageStore.getIn(['allInfo', 'LatestCityStar'])

    const multiCityInfos = pageStore.get('multiCityInfos').toJS() ?? {}
    return (
      <div
        className="overallScore"
        style={{
          padding: '10px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <div className="dimScoresHeader" style={{ padding: '0 0 8px' }}>
          <FormattedMessage id="dimensions.overallScores" />
          <Icon
            name="city-profile"
            className="overall-info"
            onClick={() => openModalWithOverride()}
          />
        </div>
        {iq && (
          <CSSTransition
            mountOnEnter
            appear
            in={true}
            timeout={0}
            classNames="fadeT"
            unmountOnExit
          >
            {/* <div style={{ marginTop: -10, marginBottom: -10 }}> */}
            {renderDonut({
              data: getDimensionWeightsForOverallDonut(
                multiCityInfos,
                selectedCityId
              ),
              colors: [1, 2, 3, 4, 5]
                .map((d) => renderColor(d).main)
                .concat(['#444']),
              label: Math.round(iq),
              donutSize: 120,
              fontSize: 50,
            })}
            {/* </div> */}
          </CSSTransition>
        )}
        {rating && (
          <div style={{ marginTop: '7px' }}>
            <Rating rating={rating} starWidth="23px" starSpacing="2px" />
          </div>
        )}
      </div>
    )
  }

  function handleDimensionClick(dim) {
    setDimensionRedirect(dim)
    actions.setCurrentPage('city-scores', dim)
  }

  // NOTE: private_user should not be able to 'watch' considerations
  const isPrivateUser = userStore.get('role').toJS().title === 'private_user'
  return (
    <div className="Dashboard">
      <Helmet>
        <title>
          CitiIQ - {intl.formatMessage({ id: 'dashboard.navigation' })}
        </title>
      </Helmet>
      {dimensionRedirect !== null && <Redirect to={'city-scores'} />}
      <div className="dashboardHeader">
        <div className="dashboardTitle">
          <h1>
            <span className="city-name-print">{`${cityName} - `}</span>
            <FormattedMessage id="dashboard.header" />
          </h1>
        </div>
        <div className="actions">
          <QuarterSelector
            isMultiCity={false}
            selectedCityId={selectedCityId}
          />
        </div>
      </div>
      <p className="dashboardDescription">
        <FormattedMessage id="dashboard.description" />
      </p>
      {pageStore.getIn(['isLoadingSingleCityInfo']) ? (
        <>
          <LoadingScreen />
        </>
      ) : (
        <>
          <Grid
            container
            spacing={16}
            justify="flex-start"
            style={{ marginTop: 0, marginBottom: 0 }}
          >
            <Grid
              item
              xs={12}
              md={12}
              lg={2}
              classes={{ item: 'overall-score' }}
            >
              <CitiIQCard height="230px">{renderOverallScore()}</CitiIQCard>
            </Grid>
            <Grid item xs={12} md={12} lg={10} className="detail-div">
              {renderInfoDonuts()}
            </Grid>
          </Grid>

          <Grid
            container
            spacing={16}
            justify="flex-start"
            className="dashboard-contents"
            style={{ marginTop: 0 }}
          >
            {!isPrivateUser && (
              <Grid
                item
                xs={12}
                sm={12}
                lg={5}
                xl={3}
                className="watched-list-container"
              >
                <CitiIQCard>
                  <WatchListComponent />
                </CitiIQCard>
              </Grid>
            )}
            <span className="pageBreak" />
            <Grid
              item
              xs={12}
              sm={12}
              lg={!isPrivateUser ? 7 : 12}
              xl={!isPrivateUser ? 6 : 12}
              className="components-row consideration-comparison-container"
            >
              <CitiIQCard>
                <ComparisonBarChart type="HorizontalBar" selector isDashboard />
              </CitiIQCard>
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
              <CitiIQCard className="score-table-wrapper" height="600px">
                <ScoreTable sort />
              </CitiIQCard>
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
              <CitiIQCard height="600px">
                <IndicatorRanking />
              </CitiIQCard>
            </Grid>
            <span className="pageBreak" />
            <Grid
              item
              xs={12}
              md={9}
              xl={6}
              className="components-row comparison-chart-container"
            >
              <CitiIQCard
                height="600px"
                className="dashboard-comparison-chart-container"
              >
                <ComparisonAreaChart showHeader />
              </CitiIQCard>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <CitiIQCard height="600px">
                <CardContent classes={{ root: 'method-grid' }}>
                  <div className="method-grid-div">
                    <p>
                      <FormattedMessage
                        id="methodology.header"
                        description="header"
                      />
                    </p>
                    <p className="subText">
                      <FormattedMessage
                        id="methodology.subtext"
                        description="subtext"
                      />
                    </p>
                    <Link to="/methodology" className="methodology-link">
                      <FormattedMessage
                        id="methodology.btn.text"
                        description="btn-text"
                      />
                    </Link>
                  </div>
                </CardContent>
              </CitiIQCard>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={16}
            justify="flex-start"
            className="featured-indices"
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              className="dashboard-footer"
              style={{ margin: '8px', borderRadius: '4px' }}
            >
              <div className="footer-copyright">
                <FormattedMessage id="footer.brand" description="btn-text" />
                &copy; {getYear()}{' '}
                <FormattedMessage
                  id="footer.copyright.notice"
                  description="btn-text"
                />
              </div>
            </Grid>
          </Grid>
        </>
      )}
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={showRestoreQuarterReminder}
        autoHideDuration={5000}
        onClose={() => setShowRestoreQuarterReminder(false)}
      >
        <div
          className="goalSnackBar"
          style={{
            marginTop: '120px',
          }}
        >
          <FormattedMessage id={'restored.current.quarter'} />
        </div>
      </Snackbar>
    </div>
  )
}

function mapStateToProps(state) {
  return {
    cityStore: state.CityStore,
    userStore: state.UserStore,
    pageStore: state.PageStore,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        cityProfile,
        setCurrentPage,
        getMultiCityInfos,
      },
      dispatch
    ),
  }
}

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(DashboardSingleCity)
)
