import En from '../../constants/language/En.json'
import Es from '../../constants/language/Es.json'
import Fr from '../../constants/language/Fr.json'
import Pt from '../../constants/language/Pt.json'
import { createContext } from 'react'

const USER_LANGUAGE_KEY = 'USER_LANGUAGE_KEY'

export interface Language {
  locale?: string
  messages?: Record<string, string>
}

export interface LanguageContextValue {
  language: Language
  saveLanguage: (locale: string) => void
}

export const initialLanguage: Language = {
  locale: 'en',
  messages: En,
}

export const getLanguageFile = (locale: string): Language | null => {
  let language: Language | null = null

  switch (locale) {
    case 'en':
      language = {
        locale: 'en',
        messages: En,
      }
      break
    case 'fr':
      language = {
        locale: 'fr',
        messages: Fr,
      }
      break
    case 'es':
      language = {
        locale: 'es',
        messages: Es,
      }
      break
    case 'pt':
      language = {
        locale: 'pt',
        messages: Pt,
      }
      break
    default:
      language = {
        ...initialLanguage,
      }
      break
  }
  return language
}

export const getLocale = (userId: string): string => {
  const storedLocale =
    window.localStorage.getItem(`${USER_LANGUAGE_KEY}_${userId}`) ??
    window.localStorage.getItem(`${USER_LANGUAGE_KEY}_nologin`)
  return storedLocale ?? 'en'
}

export const updateLocale = ({
  locale,
  userId,
}: {
  locale: string
  userId?: string
}): void => {
  if (userId !== undefined) {
    window.localStorage.setItem(`${USER_LANGUAGE_KEY}_${userId}`, locale)
  }
  window.localStorage.setItem(`${USER_LANGUAGE_KEY}_nologin`, locale)
}

const LanguageContext = createContext<LanguageContextValue>({
  language: initialLanguage,
  saveLanguage: () => {
    // Do nothing
  },
})

export const LanguageConsumer = LanguageContext.Consumer

export default LanguageContext
