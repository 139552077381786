import {
  getMilestonesByUserCity,
  createMilestone,
  removeMilestone,
} from '../../actions/MilestoneActions'
import {
  ComparisonAreaChart,
  CitiIQCard,
  MilestoneDialog,
} from '../../components'
import './DimensionComparison.scss'
import {
  Button,
  Grid,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'
import ArrowBack from '@material-ui/icons/ArrowBack'
import DeleteForever from '@material-ui/icons/DeleteForever'
import RemoveCircle from '@material-ui/icons/RemoveCircle'
import React from 'react'
import Helmet from 'react-helmet'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'
import { bindActionCreators } from 'redux'

const DataComparison = ({
  pageStore,
  userStore,
  milestoneStore,
  actions,
  intl,
}) => {
  const [openMilestoneDialog, setOpenMilestoneDialog] = React.useState(false)
  const [editMilestone, setEditMilestone] = React.useState(false)
  const [deleteMilestone, setDeleteMilestone] = React.useState(false)
  const [selectedMilestone, setSelectedMilestone] = React.useState({
    title: '',
    date: '',
    id: 0,
  })
  React.useEffect(() => {
    const selectedCityId = userStore.getIn(['selectedCityId'])
    const user_id = userStore.getIn(['userInfo', 'id'])
    actions.getMilestonesByUserCity(user_id, selectedCityId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userStore.getIn(['selectedCityId'])])

  function renderMilestoneDialog() {
    return (
      <MilestoneDialog
        isEditing={editMilestone}
        open={openMilestoneDialog}
        onClose={() => handleDialogClose()}
        title={selectedMilestone['title']}
        date={selectedMilestone['date']}
        milestone_id={selectedMilestone['id']}
      />
    )
  }

  function handleDialogClose() {
    setOpenMilestoneDialog(false)
  }

  function renderFormatDate(date) {
    //the date is a UTC date, we can keep the UTC time as we are not using time here
    const dateObject = new Date(parseInt(date))
    return dateObject.toISOString().split('T')[0]
  }

  function handleClick(id) {
    //find the milestone from the collection and load it to the dialog
    if (deleteMilestone) return

    let milestone = milestoneStore
      .get('allMilestones')
      .find((object) => object.get('id') === id)

    setOpenMilestoneDialog(true)
    setEditMilestone(true)
    setSelectedMilestone({
      title: milestone.get('title'),
      date: milestone.get('date'),
      id: id,
    })
  }

  function handleRemove(n) {
    actions.removeMilestone(n.get('id'))
  }

  function renderMilestones() {
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className="num-cell" padding="checkbox" />
            <TableCell padding="none">
              <FormattedMessage
                id="milestone.title"
                description="table-header"
              />
            </TableCell>
            <TableCell>
              <FormattedMessage
                id="milestone.date"
                description="table-header"
              />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {milestoneStore.get('allMilestones') &&
            milestoneStore.get('allMilestones').size > 0 &&
            milestoneStore.get('allMilestones').map((n, i) => {
              return (
                <TableRow
                  hover
                  key={n.get('id')}
                  onClick={() => handleClick(n.get('id'))}
                >
                  <TableCell className="num-cell" padding="checkbox">
                    {i + 1 + '. '}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    padding="none"
                    className="data-cell"
                  >
                    {n.get('title')}
                  </TableCell>
                  {!deleteMilestone &&
                    milestoneStore.get('allMilestones').size !== 0 && (
                      <TableCell
                        classes={{ body: 'moveContentsRight' }}
                        className="data-cell"
                      >
                        {renderFormatDate(n.get('date'))}
                      </TableCell>
                    )}
                  {deleteMilestone && (
                    <TableCell
                      classes={{ body: 'moveContentsRight' }}
                      className="data-cell"
                    >
                      <RemoveCircle
                        onClick={() => handleRemove(n)}
                        classes={{ root: 'RemoveCircle' }}
                      />
                    </TableCell>
                  )}
                </TableRow>
              )
            })}
          {milestoneStore.get('allMilestones').size === 0 && (
            <TableRow className="data-row">
              <TableCell className="num-cell" padding="checkbox" />
              <TableCell padding="none" className="data-cell">
                <FormattedMessage
                  id="milestone.empty"
                  description="table-row"
                />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    )
  }

  const isPrivateUser = userStore.get('role').toJS().title === 'private_user'
  if (pageStore.get('isMultiCity')) {
    return <Redirect to={'dashboard'} />
  }

  return (
    <div className="DataComparisonPage">
      <Helmet>
        <title>
          CitiIQ - {intl.formatMessage({ id: 'dimension.comparison.header' })}
        </title>
      </Helmet>
      {!isPrivateUser && renderMilestoneDialog()}
      <div className="top-strip">
        <Link to="/dashboard" className="navigation">
          <h1>
            <ArrowBack className="nav-icon" />
            <FormattedMessage id="trend.navigation" description="nav" />
          </h1>
        </Link>
      </div>
      <Grid container spacing={24}>
        <Grid item xs={12}>
          <CitiIQCard height="600px" className="dimension-comparison-wrapper">
            <h3 className="chartHeader">
              <FormattedMessage
                id="dimension.comparison.header"
                description="header"
              />
            </h3>
            <div className="comparisonChartDiv">
              <ComparisonAreaChart
                milestones_array={milestoneStore.get('allMilestones')}
              />
            </div>
          </CitiIQCard>
        </Grid>
      </Grid>

      {!isPrivateUser && (
        <Grid container spacing={24}>
          <Grid item xs={12}>
            <Card className="milestone-wrapper">
              <h3 className="chartHeader">
                <FormattedMessage id="milestone.header" description="header" />
                {milestoneStore.get('allMilestones').size !== 0 && (
                  <Button
                    classes={{
                      root: 'create-milestone-button delete-milestone-button',
                    }}
                    onClick={() => {
                      setDeleteMilestone(!deleteMilestone)
                      setEditMilestone(false)
                    }}
                  >
                    {!deleteMilestone && (
                      <DeleteForever classes={{ root: 'deleteIcon' }} />
                    )}
                    {deleteMilestone && (
                      <FormattedMessage
                        id={
                          !deleteMilestone
                            ? 'milestone.remove'
                            : 'watch.list.done'
                        }
                        description="btn-text"
                      />
                    )}
                  </Button>
                )}
                {!deleteMilestone && (
                  <Button
                    classes={{ root: 'create-milestone-button' }}
                    onClick={() => {
                      setOpenMilestoneDialog(true)
                      setEditMilestone(false)
                    }}
                  >
                    <FormattedMessage
                      id="milestone.add"
                      description="btn-text"
                    />
                  </Button>
                )}
              </h3>
              {renderMilestones()}
            </Card>
          </Grid>
        </Grid>
      )}
    </div>
  )
}

function mapStateToProps(state) {
  return {
    pageStore: state.PageStore,
    userStore: state.UserStore,
    milestoneStore: state.MilestoneStore,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        getMilestonesByUserCity,
        createMilestone,
        removeMilestone,
      },
      dispatch
    ),
  }
}

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(DataComparison)
)
