//Admin
export const GET_ADMIN_VIEW_INFO = 'GET_ADMIN_VIEW_INFO'
export const GET_ALL_USERS_SUBSCRIPTIONS = 'GET_ALL_USERS_SUBSCRIPTIONS'
export const UPDATE_SUBSCRIPTION = 'UPDATE_SUBSCRIPTION'
export const CREATE_SUBSCRIPTION = 'CREATE_SUBSCRIPTION'
export const DELETE_SUBSCRIPTION = 'DELETE_SUBSCRIPTION'
export const GET_ALL_USER_ROLES = 'GET_ALL_USER_ROLES'

// Charts
export const GET_CONSIDERATIONS_COMPARISON = 'GET_CONSIDERATIONS_COMPARISON'
export const GET_DIMENSIONS_COMPARISON = 'GET_DIMENSIONS_COMPARISON'
export const GET_QUARTERS_CONSIDERATIONS_COMPARISON =
  'GET_QUARTERS_CONSIDERATIONS_COMPARISON'
export const GET_INDICATOR_CHART_DATA = 'GET_INDICATOR_CHART_DATA'
export const CLEAR_INDICATOR_CHART_DATA = 'CLEAR_INDICATOR_CHART_DATA'

// Cities
export const GET_CITY_PROFILE = 'GET_CITY_PROFILE'
export const GET_CITY_SDG = 'GET_CITY_SDG'

// Considerations
export const GET_ALL_CONSIDERATIONS = 'GET_ALL_CONSIDERATIONS'

// Goals
export const GET_GOALS_USER_CITY = 'GET_GOALS_USER_CITY'
export const UPDATE_GOAL = 'UPDATE_GOAL'
export const CREATE_GOAL = 'CREATE_GOAL'
export const DELETE_GOAL = 'DELETE_GOAL'

// Indicators
export const GET_ALL_INDICATORS = 'GET_ALL_INDICATORS'
export const START_LOADING_INFO = 'START_LOADING_INFO'
export const GET_ALL_INFO = 'GET_ALL_INFO'

export const SET_INDICATOR_D = 'SET_INDICATOR_D'
export const CLOSE_INDICATOR_D = 'CLOSE_INDICATOR_D'
export const CLOSE_FORCE_LOGOUT_DIALOG = 'CLOSE_FORCE_LOGOUT_DIALOG'
export const OPEN_FORCE_LOGOUT_DIALOG = 'OPEN_FORCE_LOGOUT_DIALOG'

// Milestones
export const CREATE_MILESTONE = 'CREATE_MILESTONE'
export const UPDATE_MILESTONE = 'UPDATE_MILESTONE'
export const DELETE_MILESTONE = 'DELETE_MILESTONE'
export const GET_MILESTONES_USER_CITY = 'GET_MILESTONES_USER_CITY'

// Modals
export const SET_DRAWER = 'SET_DRAWER'
export const CLOSE_DRAWER = 'CLOSE_DRAWER'

// Users
export const INIT_USER = 'INIT_USER'
export const LOGIN_USER = 'LOGIN_USER'
export const LOGOUT_USER = 'LOGOUT_USER'
export const TOGGLE_LOADING_USER = 'TOGGLE_LOADING_USER'
export const TOGGLE_ATTEMPT_LOGIN = 'TOGGLE_ATTEMPT_LOGIN'
export const REGISTER_USER = 'REGISTER_USER'
export const SET_PAGE = 'SET_PAGE'
export const GET_COMPANY_PROFILE = 'GET_COMPANY_PROFILE'
export const VIEWED_INTRO = 'VIEWED_INTRO'
export const UPDATE_USER = 'UPDATE_USER'
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD'
export const GET_ALL_CITIES = 'GET_ALL_CITIES'
export const UPDATE_SELECTED_CITY = 'UPDATE_SELECTED_CITY'
export const USER_FILTERED_CITIES_KEY = 'CITIIQ_USER_FILTERED_CITIES_LIST_'
export const SET_USER_FILTERED_CITIES = 'SET_USER_FILTERED_CITIES'
export const GET_USER_FILTERED_CITIES = 'GET_USER_FILTERED_CITIES'
export const DELETE_USER = 'DELETE_USER'

// Watch Considerations
export const CREATE_WATCH_CONSIDERATION = 'CREATE_WATCH_CONSIDERATION_QUERY'
export const GET_WATCH_CONSIDERATIONS = 'GET_WATCH_CONSIDERATIONS'
export const DELETE_WATCH_CONSIDERATION = 'DELETE_WATCH_CONSIDERATION'

// Multi city
export const GET_MULTI_CITY_INFO = 'GET_MULTI_CITY_INFO'
export const IS_MULTI_CITY = 'IS_MULTI_CITY'
export const IS_SINGLE_CITY = 'IS_SINGLE_CITY'
export const LOADING_SINGLE_CITY_INFO = 'LOADING_SINGLE_CITY_INFO'
export const LOADING_MULTI_CITY_INFO = 'LOADING_MULTI_CITY_INFO'

// Toggle quarters
export const GET_ALL_AVAILABLE_QUARTERS = 'GET_ALL_AVAILABLE_QUARTERS'
export const UPDATE_SELECTED_QUARTER = 'UPDATE_SELECTED_QUARTER'
