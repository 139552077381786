import {
  getCompanyProfile,
  updateUser,
  updatePassword,
  getAllCities,
} from '../../actions/UserActions'
import './Profile.scss'
import './Profile.scss'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import Snackbar from '@material-ui/core/Snackbar'
import Tab from '@material-ui/core/Tab'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Tabs from '@material-ui/core/Tabs'
import AccountCircle from '@material-ui/icons/AccountCircle'
import ArrowBack from '@material-ui/icons/ArrowBack'
import Domain from '@material-ui/icons/Domain'
import Group from '@material-ui/icons/Group'
import React from 'react'
import Helmet from 'react-helmet'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'

const Profile = ({ actions, userStore, intl }) => {
  const [tabValue, setTabValue] = React.useState(0)
  const [username, setUsername] = React.useState('')
  const [email, setEmail] = React.useState(
    userStore.getIn(['userInfo', 'email'])
  )
  const [oldPassword, setOldPassword] = React.useState('')
  const [newPassword, setNewPassword] = React.useState('')
  const [retypePassword, setRetypePassword] = React.useState('')
  const [occupation, setOccupation] = React.useState(
    userStore.getIn(['userInfo', 'occupation'])
  )
  const [firstName, setFirstName] = React.useState(
    userStore.getIn(['userInfo', 'first_name'])
  )
  const [lastName, setLastName] = React.useState(
    userStore.getIn(['userInfo', 'last_name'])
  )
  const [phone, setPhone] = React.useState(
    userStore.getIn(['userInfo', 'phone'])
  )

  const [showPasswordDialog, setShowPasswordDialog] = React.useState(false)
  const [openSnackBar, setOpenSnackBar] = React.useState(false)
  // eslint-disable-next-line no-unused-vars
  const [updateSuccess, setUpdateSuccess] = React.useState(false)
  // eslint-disable-next-line no-unused-vars
  const [openPasswordSnackbar, setOpenPasswordSnackbar] = React.useState(false)
  // eslint-disable-next-line no-unused-vars
  const [pwUpdateSuccess, setPwUpdateSuccess] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState([])
  const [snackbarContent, setSnackbarContent] = React.useState('')

  React.useEffect(() => {
    actions.getCompanyProfile(userStore.getIn(['userInfo', 'id']))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChange = (event, value) => {
    setTabValue(value)
    if (value === 2) {
      actions.getAllCities()
    }
  }

  const handleInputChange = (e) => {
    switch (e.target.name) {
      case 'oldPassword':
        setOldPassword(e.target.value)
        break
      case 'newPassword':
        setNewPassword(e.target.value)
        break
      case 'retypePassword':
        setRetypePassword(e.target.value)
        break
      case 'username':
        setUsername(e.target.value)
        break
      case 'firstName':
        setFirstName(e.target.value)
        break
      case 'lastName':
        setLastName(e.target.value)
        break
      case 'occupation':
        setOccupation(e.target.value)
        break
      case 'email':
        setEmail(e.target.value)
        break
      case 'phone':
        setPhone(e.target.value)
        break
      default:
        break
    }
  }

  const handleSnackbarClose = () => {
    setOpenSnackBar(false)
  }

  const renderSnackBar = () => {
    const isError = snackbarContent.split('_')[1] > 3
    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={openSnackBar}
        autoHideDuration={!isError ? 4000 : 6000}
        onClose={handleSnackbarClose}
      >
        <div className={'goalSnackBar' + (isError ? ' snackbarError' : '')}>
          <FormattedMessage
            id={'profile.snackbar.' + snackbarContent}
            description="label"
          />
        </div>
      </Snackbar>
    )
  }

  const resetPasswordDialog = () => {
    setShowPasswordDialog(false)
    setOldPassword('')
    setNewPassword('')
    setRetypePassword('')
  }

  const changePassword = () => {
    // Check if passwords are long enough
    if (
      newPassword.length < 8 ||
      (!/\d/.test(newPassword) && newPassword === newPassword.toLowerCase())
    ) {
      setOpenSnackBar(true)
      setSnackbarContent('key_7')
    }
    // }

    if (newPassword === retypePassword) {
      actions
        .updatePassword(
          userStore.getIn(['userInfo', 'id']),
          oldPassword,
          newPassword
        )
        .then((response) => {
          if (response.success) {
            resetPasswordDialog()
            setOpenSnackBar(true)
            setPwUpdateSuccess(true)
            setSnackbarContent('key_3')
            setOpenPasswordSnackbar(false)
          } else {
            setOpenSnackBar(true)
            setSnackbarContent(response.message_key)
          }
        })
    } else {
      setOpenSnackBar(true)
      setPwUpdateSuccess(true)
      setSnackbarContent('key_5')
    }
  }

  const updateProfile = () => {
    setErrorMessage([])
    if (validateFields()) {
      actions
        .updateUser(
          userStore.getIn(['userInfo', 'id']),
          firstName,
          lastName,
          occupation,
          email,
          phone
        )
        .then((response) => {
          if (response !== null) {
            setOpenSnackBar(true)
            setUpdateSuccess(true)
            setSnackbarContent('key_1')
          } else {
            setOpenSnackBar(true)
            setUpdateSuccess(false)
            setSnackbarContent('key_2')
          }
        })
    }
  }
  const validateFields = () => {
    let validFields = true
    let errorMessageArray = []

    if (email === '') {
      errorMessageArray.push('err_4')
      validFields = false
    }
    if (email !== '') {
      var emailRegex = new RegExp(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
      if (!emailRegex.test(email)) {
        errorMessageArray.push('err_5')
        validFields = false
      }
    }

    if (firstName === '') {
      errorMessageArray.push('err_7')
      validFields = false
    }

    if (lastName === '') {
      errorMessageArray.push('err_8')
      validFields = false
    }

    if (phone === '') {
      errorMessageArray.push('err_10')
      validFields = false
    }

    if (occupation === '') {
      errorMessageArray.push('err_9')
      validFields = false
    }
    setErrorMessage(errorMessageArray)
    return validFields
  }

  const renderPasswordChangeDialog = () => (
    <Dialog
      open={showPasswordDialog}
      onClose={() => resetPasswordDialog()}
      maxWidth="md"
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">
        <FormattedMessage
          id="profile.tab.password.header"
          description="header"
        />
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={8} className="profile-container">
          <Grid item xs={12} className="input-row">
            <Grid container spacing={8}>
              <Grid item xs={3}>
                <label className="input-label">
                  <FormattedMessage
                    id="profile.tab.password.old.pw.label"
                    description="label"
                  />
                </label>
              </Grid>

              <Grid item xs={9} sm={7} md={6}>
                <input
                  className="input-field"
                  value={oldPassword}
                  name="oldPassword"
                  onChange={(e) => handleInputChange(e)}
                  type="password"
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} className="input-row">
            <Grid container spacing={8}>
              <Grid item xs={3}>
                <label className="input-label">
                  <FormattedMessage
                    id="profile.tab.password.new.pw.label"
                    description="label"
                  />
                </label>
              </Grid>

              <Grid item xs={9} sm={7} md={6}>
                <input
                  className="input-field"
                  value={newPassword}
                  name="newPassword"
                  onChange={(e) => handleInputChange(e)}
                  type="password"
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} className="input-row">
            <Grid container spacing={8}>
              <Grid item xs={3}>
                <label className="input-label">
                  <FormattedMessage
                    id="profile.tab.password.retype.pw.label"
                    description="label"
                  />
                </label>
              </Grid>

              <Grid item xs={9} sm={7} md={6}>
                <input
                  className="input-field"
                  value={retypePassword}
                  name="retypePassword"
                  onChange={(e) => handleInputChange(e)}
                  type="password"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant={'contained'}
          onClick={() => resetPasswordDialog()}
          color="primary"
        >
          <FormattedMessage
            id="profile.tab.password.btn.cancel"
            description="btn-text"
          />
        </Button>
        <Button
          variant={'contained'}
          onClick={() => changePassword()}
          color="primary"
        >
          <FormattedMessage
            id="profile.tab.password.btn.change"
            description="btn-text"
          />
        </Button>
      </DialogActions>
    </Dialog>
  )

  const renderErrors = () => {
    return errorMessage.map((e, i) => {
      return (
        <p className="errorMessage" key={i}>
          <FormattedMessage id={'register.' + e} />
        </p>
      )
    })
  }

  const renderProfile = () => {
    return (
      <React.Fragment>
        {showPasswordDialog && renderPasswordChangeDialog()}
        {renderSnackBar()}
        {!!errorMessage.length && (
          <div className="errorDiv">{renderErrors()}</div>
        )}
        <h4 className="container-title">
          <FormattedMessage
            id="profile.tab.profile.header"
            description="header"
          />
        </h4>
        <Grid item xs={12} className="input-row">
          <Grid container spacing={8}>
            <Grid item xs={3}>
              <label className="input-label">
                <FormattedMessage
                  id="profile.tab.profile.username.label"
                  description="label"
                />
              </label>
            </Grid>

            <Grid item xs={9} sm={7} md={6}>
              <input
                className="input-field"
                value={username}
                name="username"
                onChange={(e) => handleInputChange(e)}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} className="input-row">
          <Grid container spacing={8}>
            <Grid item xs={3}>
              <label className="input-label">
                <FormattedMessage
                  id="profile.tab.profile.first.name.label"
                  description="label"
                />
              </label>
            </Grid>

            <Grid item xs={9} sm={7} md={6}>
              <input
                className="input-field"
                value={firstName}
                name="firstName"
                onChange={(e) => handleInputChange(e)}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} className="input-row">
          <Grid container spacing={8}>
            <Grid item xs={3}>
              <label className="input-label">
                <FormattedMessage
                  id="profile.tab.profile.last.name.label"
                  description="label"
                />
              </label>
            </Grid>

            <Grid item xs={9} sm={7} md={6}>
              <input
                className="input-field"
                value={lastName}
                name="lastName"
                onChange={(e) => handleInputChange(e)}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} className="input-row">
          <Grid container spacing={8}>
            <Grid item xs={3}>
              <label className="input-label">
                <FormattedMessage
                  id="profile.tab.profile.occupation.label"
                  description="label"
                />
              </label>
            </Grid>

            <Grid item xs={9} sm={7} md={6}>
              <input
                className="input-field"
                value={occupation}
                name="occupation"
                onChange={(e) => handleInputChange(e)}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} className="input-row">
          <Grid container spacing={8}>
            <Grid item xs={3}>
              <label className="input-label">
                <FormattedMessage
                  id="profile.tab.profile.email.label"
                  description="label"
                />
              </label>
            </Grid>

            <Grid item xs={9} sm={7} md={6}>
              <input
                className="input-field"
                value={email}
                name="email"
                disabled
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} className="input-row">
          <Grid container spacing={8}>
            <Grid item xs={3}>
              <label className="input-label">
                <FormattedMessage
                  id="profile.tab.profile.phone.label"
                  description="label"
                />
              </label>
            </Grid>

            <Grid item xs={9} sm={7} md={6}>
              <input
                className="input-field"
                value={phone}
                name="phone"
                onChange={(e) => handleInputChange(e)}
              />
            </Grid>
          </Grid>
        </Grid>

        <hr />
        <Button
          variant={'contained'}
          onClick={() => setShowPasswordDialog(true)}
        >
          <FormattedMessage id="profile.tab.password.header" />
        </Button>

        <hr />

        <Button
          variant={'contained'}
          onClick={() => updateProfile()}
          className="update-btn"
          size="large"
        >
          <FormattedMessage id="profile.tab.btn.text" description="btn-text" />
        </Button>
      </React.Fragment>
    )
  }

  const renderOrganization = () => {
    return (
      <React.Fragment>
        <h4 className="container-title">
          <FormattedMessage
            id="profile.tab.organization.header"
            description="header"
          />
        </h4>

        <Grid item xs={12} className="input-row">
          <Grid container spacing={8}>
            <Grid item xs={3}>
              <label className="input-label">
                <FormattedMessage
                  id="profile.tab.organization.name.label"
                  description="label"
                />
              </label>
            </Grid>

            <Grid item xs={9} sm={7} md={6}>
              <input
                className="input-field"
                value={
                  userStore.get('companyInfo').get('name') === null
                    ? ''
                    : userStore.get('companyInfo').get('name')
                }
                disabled
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} className="input-row">
          <Grid container spacing={8}>
            <Grid item xs={3}>
              <label className="input-label">
                <FormattedMessage
                  id="profile.tab.organization.address1.label"
                  description="label"
                />
              </label>
            </Grid>

            <Grid item xs={9} sm={7} md={6}>
              <input
                className="input-field"
                value={
                  userStore.get('companyInfo').get('address1') === null
                    ? ''
                    : userStore.get('companyInfo').get('address1')
                }
                disabled
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} className="input-row">
          <Grid container spacing={8}>
            <Grid item xs={3}>
              <label className="input-label">
                <FormattedMessage
                  id="profile.tab.organization.address2.label"
                  description="label"
                />
              </label>
            </Grid>

            <Grid item xs={9} sm={7} md={6}>
              <input
                className="input-field"
                value={
                  userStore.get('companyInfo').get('address2') === null
                    ? ''
                    : userStore.get('companyInfo').get('address2')
                }
                disabled
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} className="input-row">
          <Grid container spacing={8}>
            <Grid item xs={3}>
              <label className="input-label">
                <FormattedMessage
                  id="profile.tab.organization.city.label"
                  description="label"
                />
              </label>
            </Grid>

            <Grid item xs={9} sm={7} md={6}>
              <input
                className="input-field"
                value={
                  userStore.get('companyInfo').get('city') === null
                    ? ''
                    : userStore.get('companyInfo').get('city')
                }
                disabled
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} className="input-row">
          <Grid container spacing={8}>
            <Grid item xs={3}>
              <label className="input-label">
                <FormattedMessage
                  id="profile.tab.organization.province.label"
                  description="label"
                />
              </label>
            </Grid>

            <Grid item xs={9} sm={7} md={6}>
              <input
                className="input-field"
                value={
                  userStore.get('companyInfo').get('province') === null
                    ? ''
                    : userStore.get('companyInfo').get('province')
                }
                disabled
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} className="input-row">
          <Grid container spacing={8}>
            <Grid item xs={3}>
              <label className="input-label">
                <FormattedMessage
                  id="profile.tab.organization.country.label"
                  description="label"
                />
              </label>
            </Grid>

            <Grid item xs={9} sm={7} md={6}>
              <input
                className="input-field"
                value={
                  userStore.get('companyInfo').get('country') === null
                    ? ''
                    : userStore.get('companyInfo').get('country')
                }
                disabled
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} className="input-row">
          <Grid container spacing={8}>
            <Grid item xs={3}>
              <label className="input-label">
                <FormattedMessage
                  id="profile.tab.organization.postal.label"
                  description="label"
                />
              </label>
            </Grid>

            <Grid item xs={9} sm={7} md={6}>
              <input
                className="input-field"
                value={
                  userStore.get('companyInfo').get('postal') === null
                    ? ''
                    : userStore.get('companyInfo').get('postal')
                }
                disabled
              />
            </Grid>
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }

  const renderCities = () => {
    if (!userStore) return null
    const { allCities, subscriptions } = userStore.toJS()

    if (allCities && allCities.length) {
      if (subscriptions.length > 0) {
        return (
          <Table className="cities-table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <h4 className="container-title">
                    <FormattedMessage
                      id="profile.tab.cities.header"
                      description="header"
                    />
                  </h4>
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {subscriptions.map(({ city_id }) => {
                const citySub = allCities.find(({ id }) => id === city_id)
                return (
                  <TableRow key={citySub.id}>
                    <TableCell component="th" scope="row">
                      {citySub.name}
                    </TableCell>
                    <TableCell classes={{ body: 'moveContentsRight' }}>
                      <FormattedMessage id="profile.tab.cities.subscribed" />
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        )
      } else {
        return (
          <FormattedMessage id="profile.tab.cities.empty" description="text" />
        )
      }
    }
  }

  // render() {
  // const firstName = userStore.getIn(['userInfo', 'first_name'])
  // const lastName = userStore.getIn(['userInfo', 'last_name'])
  return (
    <div className="ProfilePage">
      <Helmet>
        <title>
          CitiIQ - {intl.formatMessage({ id: 'profile.navigation' })}
        </title>
      </Helmet>

      <Link to="/dashboard" className="navigation">
        <h1>
          <ArrowBack className="nav-icon" />
          <FormattedMessage id="profile.navigation" description="nav" />
        </h1>
      </Link>

      <Grid container spacing={24} className="profile-container">
        <Grid item xs={12} className="profile-top-section">
          <Grid container spacing={8} className="profile-top-container">
            <Grid item xs={6}>
              <h2> {firstName + ' ' + lastName} </h2>
              <p>{occupation}</p>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Tabs
            value={tabValue}
            onChange={handleChange}
            scrollable
            scrollButtons="off"
            indicatorColor="primary"
            textColor="primary"
            className="tabs-selector"
            classes={{
              indicator: 'tab-indicator',
            }}
          >
            <Tab
              className={
                tabValue === 0 ? 'selectedTab tabs-options' : 'tabs-options'
              }
              label={<FormattedMessage id="profile.tabs.profile" />}
              icon={<AccountCircle />}
            />
            <Tab
              className={
                tabValue === 1 ? 'selectedTab tabs-options' : 'tabs-options'
              }
              label={<FormattedMessage id="profile.tabs.organization" />}
              icon={<Group />}
            />
            <Tab
              className={
                tabValue === 2 ? 'selectedTab tabs-options' : 'tabs-options'
              }
              label={<FormattedMessage id="profile.tabs.cities" />}
              icon={<Domain />}
            />
          </Tabs>

          {tabValue === 0 && renderProfile()}
          {tabValue === 1 && renderOrganization()}
          {tabValue === 2 && renderCities()}
        </Grid>
      </Grid>
    </div>
  )
  // }
}
function mapStateToProps(state) {
  return {
    userStore: state.UserStore,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        getCompanyProfile,
        updateUser,
        updatePassword,
        getAllCities,
      },
      dispatch
    ),
  }
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Profile))
