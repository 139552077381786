import * as types from '../constants/ActionTypes'
import { fromJS } from 'immutable'

const initialState = fromJS({
  drawerConsideration: '',
  drawerOpen: false,
  drawerGrade: 0,
  dialogIndicator: '',
  dialogIndicatorGrade: 0,
  dialogIndicatorOpen: false,
  dialogIndicatorCitations: [],
  forceLogoutDialogOpen: false,
  dialogIndicatorSource: '',
})

export default (state = initialState, action) => {
  let newState = state
  switch (action.type) {
    case types.SET_DRAWER:
      newState = state.set('drawerConsideration', action.payload.consideration)
      newState = newState.set('drawerGrade', action.payload.grade)
      newState = newState.set('drawerOpen', true)
      return newState
    case types.CLOSE_DRAWER:
      newState = state.set('drawerOpen', false)
      return newState
    case types.SET_INDICATOR_D:
      newState = state.set('dialogIndicator', action.payload.indicator)
      newState = newState.set('dialogIndicatorGrade', action.payload.grade)
      newState = newState.set('dialogIndicatorOpen', true)
      newState = newState.set(
        'dialogIndicatorCitations',
        action.payload.citations
      )
      newState = newState.set('dialogIndicatorSource', action.payload.source)
      return newState
    case types.CLOSE_INDICATOR_D:
      newState = state.set('dialogIndicatorOpen', false)
      return newState
    case types.OPEN_FORCE_LOGOUT_DIALOG:
      return state.set('forceLogoutDialogOpen', true)
    case types.CLOSE_FORCE_LOGOUT_DIALOG:
      return state.set('forceLogoutDialogOpen', false)
    case types.LOGOUT_USER:
      if (action.payload.LogoutUser.success) return initialState
      return state
    default:
      return state
  }
}
