import { renderColor } from '../../constants/Constants'
import { getDimensionWeightsForOverallDonut } from '../../helpers/donut'
import {
  getLocalizedCityName,
  getLocalizedCountryName,
} from '../../helpers/lang'
import { renderDonut } from '../../pages/DashboardMultiCity/Helpers'
import useLanguage from '../../state/hooks/useLanguage'
import {
  faAngleDown,
  faAngleUp,
  faInfinity,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Input } from '@material-ui/core'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import React, { useEffect, useState } from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

function CityMenu({
  hideSelect,
  selectedCityId,
  cities,
  cityCountryMap,
  updateCurrentCityId,
  selectedQuarter,
  toggleMultiCityView,
  handlePageChange,
  isMultiCity,
  pageStore,
  intl,
}) {
  const [anchorEl, setAnchorEl] = useState(null)
  const [sortedCityCountryMap, setSortedCityCountryMap] = useState(null)
  const [allCitiesList, setAllCitiesList] = useState({})
  const [searchKey, setSearchKey] = useState('')
  const { language } = useLanguage()
  const isLoading =
    pageStore.getIn(['isLoadingSingleCityInfo']) ||
    pageStore.getIn(['isLoadingMultiCityInfo'])
  useEffect(() => {
    var removedUndefinedCities = []

    Object.values(cities).forEach((city) => {
      if (city && cityCountryMap && Object.keys(cityCountryMap).length > 0) {
        if (cityCountryMap['obj'])
          Object.values(cityCountryMap['obj']).forEach((e) => {
            if (e.id === city.city_id) {
              removedUndefinedCities.push(e)
            }
          })
      }
    })
    const sortedCitiesCountries = removedUndefinedCities.sort((p1, p2) => {
      if (p1.name > p2.name) return 1
      if (p1.name < p2.name) return -1
      return 0
    })

    setAllCitiesList(sortedCitiesCountries)
  }, [cities, cityCountryMap])

  useEffect(() => {
    if (cityCountryMap?.arr && Array.isArray(cityCountryMap.arr)) {
      const sortedCitiesCountries = cityCountryMap.arr.sort((p1, p2) => {
        if (p1.name > p2.name) return 1
        if (p1.name < p2.name) return -1
        return 0
      })
      setSortedCityCountryMap(sortedCitiesCountries)
    }
  }, [cityCountryMap, selectedQuarter])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setSearchKey('')
    setAnchorEl(null)
  }

  const getCityIQ = (city) => {
    if (city.id === 'all') return city.iq
    if (cities && cities[city.id] && cities[city.id].iq) {
      return cities[city.id].iq
    }
    return 0
  }

  const getSelectedCityInfo = (selectedCityId) => {
    if (isMultiCity)
      return {
        id: 'all',
        name: intl.formatMessage({ id: 'cityMenu.all' }),
        iq: 100,
      }
    for (let i = 0; i < (sortedCityCountryMap || []).length; i++) {
      if (parseInt(sortedCityCountryMap[i].id) === parseInt(selectedCityId)) {
        return {
          ...sortedCityCountryMap[i],
          iq: getCityIQ(sortedCityCountryMap[i]),
        }
      }
    }
    return {}
  }

  const renderMenuItem = (cityInfo) => {
    const { iq, id } = cityInfo
    return (
      <div key={`content_${id}`} className="appbarCityMenuItemContent">
        <div className="appBarScore">
          {iq &&
            renderDonut({
              data: getDimensionWeightsForOverallDonut(cities, id),
              colors: [1, 2, 3, 4, 5]
                .map((d) => renderColor(d).main)
                .concat(['#444']),
              label: id === 'all' || isLoading ? null : Math.round(iq),
              donutSize: 55,
              fontSize: 25,
              iconLabel: id === 'all' || isLoading ? faInfinity : null,
            })}
        </div>
        <div className="appbarCityName">
          <div className="city">{getLocalizedCityName(cityInfo, language)}</div>
          <div className="country">
            {getLocalizedCountryName(cityInfo, language)}
          </div>
        </div>
      </div>
    )
  }

  const selectedCity = getSelectedCityInfo(selectedCityId)

  if (hideSelect) {
    return (
      <div className="appbarCityMenuItemWrapper">
        {renderMenuItem({
          ...selectedCity,
          dimensionWeights: getDimensionWeightsForOverallDonut(
            cities,
            selectedCityId
          ),
        })}
      </div>
    )
  }

  const mayFilterBySearch = (cities) => {
    return cities.filter((city) => {
      if (searchKey.length < 3) return true
      return (
        (city.name ?? '').toLowerCase().includes(searchKey.toLowerCase()) ||
        (city.country ?? '').toLowerCase().includes(searchKey.toLowerCase())
      )
    })
  }
  return (
    <div className="appbarCityMenu">
      <div
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        className="appbarCityMenuOpen"
      >
        {renderMenuItem({
          ...selectedCity,
          dimensionWeights: getDimensionWeightsForOverallDonut(
            cities,
            selectedCityId
          ),
        })}
        <FontAwesomeIcon icon={Boolean(anchorEl) ? faAngleUp : faAngleDown} />
      </div>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className="appbarCityMenuDropdown"
      >
        <Input
          fullWidth
          placeholder={intl.formatMessage({ id: 'cityMenu.searchBy' })}
          onChange={(e) => {
            setSearchKey(e.target.value)
          }}
          value={searchKey}
          style={{
            padding: '0.5rem 1rem',
            fontSize: '12px',
            width: '326px',
          }}
        />
        {mayFilterBySearch(
          [
            {
              id: 'all',
              name: intl.formatMessage({
                id: 'cityMenu.all',
              }),
              iq: 100,
            },
          ].concat(allCitiesList || [])
        ).map((city) => {
          return (
            <MenuItem
              key={`item_${city.id}`}
              className={`appbarCityMenuItem ${
                selectedCityId === city.id && !isMultiCity ? 'selected' : ''
              }`}
              onClick={() => {
                handleClose()
                switch (city.id) {
                  case 'all':
                    if (isMultiCity) return
                    toggleMultiCityView(true)
                    handlePageChange('dashboard')
                    break
                  default:
                    updateCurrentCityId(city.id)
                    if (!isMultiCity) return
                    toggleMultiCityView(false)
                    handlePageChange('dashboard')
                    break
                }
              }}
            >
              {renderMenuItem({
                ...city,
                iq: city ? getCityIQ(city) : null,
                dimensionWeights: getDimensionWeightsForOverallDonut(
                  cities,
                  city.id
                ),
              })}
            </MenuItem>
          )
        })}
      </Menu>
    </div>
  )
}
function mapStateToProps(state) {
  return {
    pageStore: state.PageStore,
  }
}

export default injectIntl(withRouter(connect(mapStateToProps)(CityMenu)))
