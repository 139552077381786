/* eslint-disable import/no-webpack-loader-syntax */
import { cityProfile } from '../../actions/CityActions'
import { getAllCities } from '../../actions/UserActions'
import { isFloat } from '../../helpers/isFloat'
import useLanguage from '../../state/hooks/useLanguage'
import '../Dashboard/Dashboard.scss'
import './CityProfile.scss'
import MapboxLanguage from '@mapbox/mapbox-gl-language'
import Grid from '@material-ui/core/Grid'
import ArrowBack from '@material-ui/icons/ArrowBack'
import mapboxgl from 'mapbox-gl'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import ReactMapGL, { Marker } from 'react-map-gl'
import { connect } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'
import { bindActionCreators } from 'redux'

/**
 * Workaround for bug in react-map-gl
 */
mapboxgl.workerClass =
  require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default

const CityProfile = ({ actions, cityStore, userStore, pageStore }) => {
  const weather = cityStore.get('weather')
  const [selectedCityId, setSelectedCityID] = React.useState(null)
  const { language } = useLanguage()
  const { locale } = language

  const mapRef = React.useRef(null)

  React.useEffect(() => {
    if (mapRef.current) {
      const map = mapRef.current.getMap()
      const languageControl = new MapboxLanguage({
        defaultLanguage: locale,
      })
      map.addControl(languageControl)
    }
  }, [locale])

  const [viewport, setViewport] = React.useState({
    width: 800,
    height: 600,
    latitude: 0,
    longitude: 0,
    zoom: 6,
  })
  const [selectedCity, setSelectedCity] = React.useState({
    latitude: 0,
    longitude: 0,
  })

  React.useEffect(() => {
    actions.getAllCities()
    setSelectedCityID(userStore.get('selectedCityId'))
    // eslint-disable-next-line
  }, [])

  React.useEffect(() => {
    if (userStore.get('allCities').size > 0) {
      const allCities = userStore.get('allCities').toJS()
      const city = allCities.filter((e) => e.id === selectedCityId)

      if (city[0]) {
        setViewport({
          width: 800,
          height: 600,
          latitude: parseFloat(city[0].location.lat),
          longitude: parseFloat(city[0].location.lon),
          zoom: 6,
        })
        setSelectedCity({
          latitude: parseFloat(city[0].location.lat),
          longitude: parseFloat(city[0].location.lon),
        })
      }
    }
    // eslint-disable-next-line
  }, [selectedCityId, userStore])

  React.useEffect(() => {
    if (userStore.getIn(['selectedCityId'])) {
      const newSelectedCityId =
        userStore.getIn(['selectedCityId']) ||
        userStore
          .get('subscriptions')
          .map((s) => s.get('city_id'))
          .toJS()[0]

      setSelectedCityID(newSelectedCityId)
    }
    // eslint-disable-next-line
  }, [userStore])

  React.useEffect(() => {
    if (!weather && !!selectedCityId) {
      actions.cityProfile(selectedCityId)
    }
    // eslint-disable-next-line
  }, [actions, weather])

  if (pageStore.get('isMultiCity')) {
    return <Redirect to={'dashboard'} />
  }

  function calcMapHeight() {
    const headerMaxHeight = 160
    const pageTitleHeight = 35
    return window.innerHeight - headerMaxHeight - pageTitleHeight
  }

  return (
    <div className="CityProfile">
      <Link to="/dashboard" className="navigation">
        <h1>
          <ArrowBack className="nav-icon" />
          <FormattedMessage id="city.profile.navigation" description="nav" />
        </h1>
      </Link>

      <Grid
        container
        direction="row"
        alignItems="stretch"
        classes={{ container: 'city-profile-container' }}
      >
        {isFloat(viewport.latitude) && isFloat(viewport.longitude) && (
          <Grid item xs={12} sm={12} md={12} lg={12} className="city-details">
            <div
              style={{
                height: calcMapHeight(),
                paddingTop: 5,
                paddingBottom: 5,
              }}
            >
              <ReactMapGL
                {...viewport}
                minZoom={2}
                maxZoom={10}
                width="100%"
                height="100%"
                mapStyle="mapbox://styles/mapbox/dark-v10"
                mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_API}
                onViewportChange={(viewport) => setViewport(viewport)}
                ref={mapRef}
              >
                {selectedCity ? (
                  <Marker
                    longitude={selectedCity.longitude}
                    latitude={selectedCity.latitude}
                  >
                    <div
                      style={{
                        height: '12px',
                        width: '12px',
                        backgroundColor: '#9EC73D',
                        borderRadius: '10px',
                      }}
                    />
                  </Marker>
                ) : null}
              </ReactMapGL>
            </div>
          </Grid>
        )}
      </Grid>
    </div>
  )
}

function mapStateToProps(state) {
  return {
    cityStore: state.CityStore,
    userStore: state.UserStore,
    pageStore: state.PageStore,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        cityProfile,
        getAllCities,
      },
      dispatch
    ),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CityProfile)
