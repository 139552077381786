import * as types from '../constants/ActionTypes'
import * as queries from '../constants/QueryTypes'
import { hasUserSessionError } from '../helpers/errorHandler'
import query from '../services/GraphQLService'

export const createWatchConsideration =
  (userId, considerationId, sortOrder) => (dispatch) => {
    return new Promise((resolve, reject) => {
      query('mutate', queries.CREATE_WATCH_CONSIDERATION_QUERY, {
        userId,
        considerationId,
        sortOrder,
      })
        .then(({ data, errors }) => {
          if (errors && hasUserSessionError(errors)) {
            dispatch({ type: types.OPEN_FORCE_LOGOUT_DIALOG })
            reject(errors)
            return
          }
          dispatch({
            type: types.CREATE_WATCH_CONSIDERATION,
            payload: data.createWatchConsideration,
          })
          resolve(data)
        })
        .catch((e) => {
          reject(e)
        })
    })
  }

export const getWatchConsiderations = () => (dispatch) => {
  query('query', queries.GET_WATCH_CONSIDERATIONS_QUERY).then(
    ({ data, errors }) => {
      if (errors && hasUserSessionError(errors)) {
        dispatch({ type: types.OPEN_FORCE_LOGOUT_DIALOG })
        return
      }
      dispatch({
        type: types.GET_WATCH_CONSIDERATIONS,
        payload: data.UserWatchConsiderations,
      })
    }
  )
}

export const unwatchConsideration = (id) => (dispatch) => {
  return new Promise((resolve, reject) => {
    query('mutate', queries.DELETE_WATCH_CONSIDERATION_QUERY, { id })
      .then(({ data, errors }) => {
        if (errors && hasUserSessionError(errors)) {
          dispatch({ type: types.OPEN_FORCE_LOGOUT_DIALOG })
          reject(errors)
          return
        }
        dispatch({
          type: types.DELETE_WATCH_CONSIDERATION,
          payload: data.deleteWatchConsideration,
        })
        resolve(data)
      })
      .catch((e) => {
        reject(e)
      })
  })
}
