/* eslint-disable react-hooks/exhaustive-deps */
import {
  renderColor,
  getConsiderationByDimensionKey,
} from '../../constants/Constants'
import { getLocalizedCityName } from '../../helpers/lang'
import { sort } from '../../pages/DashboardMultiCity/Helpers'
import useLanguage from '../../state/hooks/useLanguage'
import './ConsiderationChart.scss'
import { defaultOptions } from './defaultOptions'
import { defaultTheme } from './defaultTheme'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import AnnotationsFactory from 'highcharts/modules/annotations'
import React, { useEffect, useState } from 'react'
import { injectIntl } from 'react-intl'

AnnotationsFactory(Highcharts)

function getStyledTheme(dimension) {
  const colors = renderColor(dimension.slice(-1)).considerations
  return {
    ...defaultTheme,
    colors: colors,
  }
}

function getWeightedConsiderationForCity(city, cid, did) {
  if (!city) return 0
  const { considerations } = city
  for (let i = 0; i < considerations.length; i++) {
    if (`${considerations[i].id}` === cid) {
      const { weightedValue } = considerations[i]
      return weightedValue
    }
  }
  return 0
}

function getWeightedConsiderationValues(
  sortedCitiesList,
  cities,
  considerationId,
  dimensionId
) {
  return sortedCitiesList.map((cityId) => {
    return {
      id: `${cityId}_${considerationId}`,
      y: getWeightedConsiderationForCity(
        cities[cityId],
        considerationId,
        dimensionId
      ),
    }
  })
}

function calcChartHeight(dataLength) {
  return `${dataLength * 35 < 100 ? 100 : dataLength * 35}px`
}

function getOptions(
  sortedCitiesList,
  cities,
  cityCountryMap,
  considerations,
  dimensionId,
  annotationLabels,
  intl,
  language
) {
  return {
    ...defaultOptions,
    chart: {
      ...defaultOptions.chart,
      height: calcChartHeight(sortedCitiesList.length),
      spacingRight: 30,
    },
    plotOptions: {
      ...defaultOptions.plotOptions,
      series: {
        ...defaultOptions.plotOptions.series,
        point: {
          events: {
            mouseOver: function () {
              // for tooltip scrolling to city label
              // on hover point
              setTimeout(() => {
                const el = document.getElementById(`tooltip_label_${this.id}`)
                el?.offsetParent?.scroll(
                  0,
                  el.offsetTop <= 60 ? el.offsetTop : el.offsetTop - 30
                )
              }, 1)
            },
            mouseOut: function () {},
          },
        },
      },
    },
    series: considerations.map((c) => {
      return {
        id: c.id,
        name: c.id,
        data: getWeightedConsiderationValues(
          sortedCitiesList,
          cities,
          c.id,
          dimensionId
        ),
      }
    }),
    xAxis: {
      categories: sortedCitiesList.map((c) => {
        if (cities[c] !== undefined)
          return cityCountryMap[c] && cityCountryMap[c].id
        return null
      }),
      labels: {
        formatter: function () {
          return getLocalizedCityName(cityCountryMap[this.value], language)
        },
      },
    },
    tooltip: {
      useHTML: true,
      // style fix for tooltips being overlapped by annotations
      backgroundColor: 'black',
      padding: 0,
      borderWidth: 2,
      stickOnContact: true,
      snap: 0,
      formatter: function () {
        const { name, points } = this.series
        const considerationId = name
        const currentHoveringCityId = this.point.id.split('_')[0]
        // The first returned item is the header,
        // subsequent items are the points
        const considerationName = intl.formatMessage({
          id: `considerations.${considerationId}`,
        })
        const tooltipTitle = `<div class='customTooltipHead'><b>${considerationName}</b></div>`
        const tooltipContent = points
          .map((point) => {
            const cityId = point.category
            const cScore =
              cities[cityId]?.considerationsMap[Number(considerationId)]
                ?.value ?? 0
            const isHovering = String(currentHoveringCityId) === String(cityId)
            // id as an anchor, for scrolling to city label on hover series point
            const id = `tooltip_label_${cityId}_${considerationId}`
            return `${
              isHovering
                ? `<b id='${id}'>`
                : `<span style="color: #999" id='${id}'>`
            }${getLocalizedCityName(
              cityCountryMap[cityId],
              language
            )} : ${cScore.toFixed(1)}${isHovering ? '</b>' : '</span>'}<br/>`
          })
          .join('')
        return `<div class='stylized-scroll'>
        ${tooltipTitle}
        <br/>
        <div class='customTooltip' style='
        position: relative; 
        white-space: normal; 
        height: ${points.length * 18}px; 
        width: 150px; 
        overflow-y: scroll; 
        max-height: 400px;'>
        ${tooltipContent}
        </div>
        </div>`
      },
    },
    annotations: [
      {
        labels: annotationLabels,
        labelOptions: {
          useHTML: true,
          allowOverlap: true,
          align: 'right',
          distance: 15,
        },
      },
    ],
  }
}

function filterCities(cities, filteredCitiesList) {
  let filteredCityInformation = {}
  filteredCitiesList?.forEach((city) => {
    if (cities[city.id]) {
      filteredCityInformation[city.id] = cities[city.id]
    }
  })
  return filteredCityInformation
}

function genAnnotationLabels(
  dimension,
  sortedCitiesList,
  cities,
  considerations
) {
  return sortedCitiesList.map((cityId, idx) => {
    const cData = cities[cityId]
    const lastCid = [...considerations].pop()?.id
    if (!cData || !cData?.dimensions) return {}
    const dimScore = Number(cData.dimensions[dimension - 1].value).toFixed(1)
    return !lastCid
      ? {}
      : {
          point: `${cityId}_${lastCid}`,
          x: 0,
          y: 0,
          text: `${dimScore}`,
        }
  })
}

function ConsiderationChart({
  dimension,
  cities,
  cityCountryMap,
  filteredCitiesList,
  intl,
}) {
  const considerations = getConsiderationByDimensionKey(dimension)
  const [sortedCitiesList, setSortedCitiesList] = useState(
    sort(dimension, filterCities(cities, filteredCitiesList))
  )
  const { language } = useLanguage()
  const [loading, setLoading] = React.useState(false)
  const [options, setOptions] = React.useState(
    getOptions(
      sortedCitiesList,
      cities,
      cityCountryMap,
      considerations,
      dimension,
      genAnnotationLabels(dimension, sortedCitiesList, cities, considerations),
      intl,
      language
    )
  )
  useEffect(() => {
    setLoading(true)
    setSortedCitiesList(
      sort(dimension, filterCities(cities, filteredCitiesList))
    )
  }, [filteredCitiesList, cities, dimension])

  useEffect(() => {
    setOptions(
      getOptions(
        sortedCitiesList,
        cities,
        cityCountryMap,
        considerations,
        dimension,
        genAnnotationLabels(
          dimension,
          sortedCitiesList,
          cities,
          considerations
        ),
        intl,
        language
      )
    )
    setTimeout(() => {
      setLoading(false)
    }, 200)
  }, [sortedCitiesList])

  Highcharts.theme = getStyledTheme(`d_${dimension}`)
  Highcharts.setOptions(Highcharts.theme)

  return (
    <div className="ConsiderationChart">
      {options && !loading ? (
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
          updateArgs={[true]}
        />
      ) : null}
    </div>
  )
}
export default injectIntl(ConsiderationChart)
