export const considerationToDimension = {
  1: {
    icon: 'Basic Education',
    label: 'Basic Education',
    dimension: 1,
  },
  2: {
    icon: 'Children + Youth',
    label: 'Children + Youth',
    dimension: 3,
  },
  3: {
    icon: 'Collaboration',
    label: 'Collaboration',
    dimension: 5,
  },
  4: {
    icon: 'Connectivity + Communication',
    label: 'Connectivity + Communication',
    dimension: 2,
  },
  5: {
    icon: 'Efficiency',
    label: 'Efficiency',
    dimension: 2,
  },
  6: {
    icon: 'Employment',
    label: 'Employment',
    dimension: 1,
  },
  7: {
    icon: 'Energy supply',
    label: 'Energy supply',
    dimension: 1,
  },
  8: {
    icon: 'Entertainment',
    label: 'Entertainment',
    dimension: 4,
  },
  9: {
    icon: 'Environmental Stewardship',
    label: 'Environmental Stewardship',
    dimension: 2,
  },
  10: {
    icon: 'Ethical Compass',
    label: 'Ethical Compass',
    dimension: 1,
  },
  11: {
    icon: 'Excellence',
    label: 'Excellence',
    dimension: 5,
  },
  12: {
    icon: 'Fair + Just System',
    label: 'Fair + Just System',
    dimension: 2,
  },
  13: {
    icon: 'Family',
    label: 'Family',
    dimension: 1,
  },
  14: {
    icon: 'Equality',
    label: 'Gender Equality',
    dimension: 3,
  },
  15: {
    icon: 'Food Security',
    label: 'Food Security',
    dimension: 1,
  },
  16: {
    icon: 'Healthy Lifestyle',
    label: 'Healthy Lifestyle',
    dimension: 4,
  },
  17: {
    icon: 'Higher Education',
    label: 'Higher Education',
    dimension: 3,
  },
  18: {
    icon: 'Hope',
    label: 'Hope',
    dimension: 5,
  },
  19: {
    icon: 'Innovation + Entrepreneurship',
    label: 'Innovation + Entrepreneurship',
    dimension: 3,
  },
  20: {
    icon: 'Investment',
    label: 'Investment',
    dimension: 2,
  },
  21: {
    icon: 'Mobility + Transportation',
    label: 'Mobility + Transportation',
    dimension: 2,
  },
  22: {
    icon: 'Political Stability',
    label: 'Political Stability',
    dimension: 2,
  },
  23: {
    icon: 'Public Health',
    label: 'Public Health',
    dimension: 1,
  },
  24: {
    icon: 'Public Welfare',
    label: 'Public Welfare',
    dimension: 1,
  },
  25: {
    icon: 'Resilient Infrastructure',
    label: 'Resilient Infrastructure',
    dimension: 2,
  },
  26: {
    icon: 'Safety + Security',
    label: 'Safety + Security',
    dimension: 1,
  },
  27: {
    icon: 'Shelter + Housing',
    label: 'Shelter + Housing',
    dimension: 1,
  },
  29: {
    icon: 'Social Isolation',
    label: 'Social Isolation',
    dimension: 4,
  },
  30: {
    icon: 'Talent Pool',
    label: 'Talent Pool',
    dimension: 3,
  },
  31: {
    icon: 'Tourism',
    label: 'Tourism',
    dimension: 2,
  },
  32: {
    icon: 'Trade',
    label: 'Trade',
    dimension: 2,
  },
  33: {
    icon: 'Urban Design',
    label: 'Urban Design',
    dimension: 4,
  },
  34: {
    icon: 'Waste Management',
    label: 'Waste Management',
    dimension: 1,
  },
  35: {
    icon: 'Water supply',
    label: 'Water supply',
    dimension: 1,
  },
  36: {
    icon: 'Quality of Life',
    label: 'Quality of Life',
    dimension: 5,
  },
}

export const dimensionToColor = {
  0: {
    main: '#9EC73D',
    secondary: '#5b751f',
    fill: 'rgba(0,174,239,0.2)',
    target: '#5b751f',
  },
  1: {
    main: '#34ACE0',
    target: '#1b6687',
    secondary: '#71C6EB',
    fill: 'rgba(0,174,239,0.2)',
    considerations: [
      '#002263',
      '#003677',
      '#004A8B',
      '#005E9F',
      '#0072B3',
      '#0086C7',
      '#009ADB',
      '#00AEEF',
      '#14C2FF',
      '#28D6FF',
      '#3CEAFF',
      '#50FEFF',
    ],
  },
  2: {
    main: '#E8961B',
    target: '#99600a',
    secondary: '#FBB03F',
    fill: 'rgba(239,52,136,0.06)',
    considerations: [
      '#3D0C03',
      '#5B1000',
      '#833800',
      '#AB6000',
      '#D38817',
      '#FBB03F',
      '#FFD867',
      '#FFFF8F',
      '#FFFFB7',
      '#FFFFEA',
    ],
  },
  3: {
    main: '#764B9E',
    target: '#4c2b6b',
    secondary: '#9163BB',
    fill: 'rgba(118,75,158,0.24)',
    considerations: ['#26004E', '#4E2376', '#764B9E', '#9E73C6', '#C69BEE'],
  },
  4: {
    main: '#5CC19C',
    target: '#337c61',
    secondary: '#7DDAB8',
    fill: 'rgba(92,193,156,0.29)',
    considerations: [
      '#0C714C',
      '#349974',
      '#5CC19C',
      '#84E9C4',
      '#ACFFEC',
      '#D4FFFF',
    ],
  },
  5: {
    main: '#EF3488',
    target: '#961b52',
    secondary: '#FA67A9',
    fill: 'rgba(239,52,136,0.06)',
    considerations: ['#BD0256', '#EF3488', '#FF66BA', '#FF98EC'],
  },
}

export function renderColor(dim_id) {
  return dimensionToColor[dim_id] || dimensionToColor[0]
}

export function getConsiderationByDimensionKey(key, isOnlyGetConsiderationIds) {
  let considerations = []
  Object.keys(considerationToDimension).forEach((c) => {
    if (considerationToDimension[c].dimension === key) {
      if (isOnlyGetConsiderationIds) {
        considerations.push(c)
      } else {
        considerations.push({ ...considerationToDimension[c], id: c })
      }
    }
  })
  return considerations
}

export function getCountryNameFromCode(code) {
  return code
}

export function parseSelectedQuarterValue(q) {
  const match = /^([0-9]*)-([0-9])$/.test(q)
  let quarter = null
  if (match) {
    quarter = {
      year: parseInt(q.split('-') && q.split('-')[0]),
      quarter: parseInt(q.split('-') && q.split('-')[1]),
    }
  }
  return quarter
}
export function getSelectedQuarterString(q) {
  const { year, quarter } = q
  return `${year}-${quarter}`
}
