export const primaryGreen = '#9EC73D'
export const AdminTheme = {
  overrides: {
    MUIDataTable: {
      responsiveScroll: {
        overflowX: 'none',
        height: 'auto',
        maxHeight: 'unset !important',
      },
      tableRoot: {
        overflow: 'hidden',
      },
    },
    MuiPaper: {
      root: {
        backgroundColor: '#31353D',
        color: '#FFF',
      },
    },
    MuiInputLabel: {
      '&$focused': {
        color: `${primaryGreen} !important`,
      },
    },
    MuiButton: {
      textPrimary: {
        color: primaryGreen,
      },
    },
    MuiToolbar: {
      regular: {
        width: '100% !important',
        margin: 0,
      },
    },

    MUIDataTableHeadCell: {
      root: {
        textAlign: 'center',
        backgroundColor: '#31353D !important',
        color: '#FFF !important',
        minWidth: '80px',
        sortLabelRoot: {
          margin: 0,
          opacity: 0.1,
        },
      },

      data: {
        display: 'inline-block',
        textAlign: 'inherit',
        width: 'inherit',
        lineHeight: 1.75,
      },
      sortActive: {
        color: '#FFF !important',
        fontWeight: 'bold',
      },
      sortLabelRoot: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '15px auto',
      },
    },

    MuiTableSortLabel: {
      active: {
        color: '#FFF',
      },
    },
    MuiIconButton: {
      root: {
        color: '#FFF',
      },
    },
    MUIDataTableSearch: {
      searchIcon: {
        color: '#FFF',
      },
    },
    MUIDataTableViewCol: {
      title: {
        color: '#FFF',
      },
      label: {
        color: '#FFF',
      },
    },
    MuiSvgIcon: {
      root: {
        fill: '#FFF',
      },
    },
    MuiInput: {
      root: {
        color: '#FFF',
      },
      underline: {
        '&::before': {
          borderBottom: '1px solid rgba(255,255,255,.7)',
        },
        '&::after': {
          borderBottom: `2px solid ${primaryGreen}`,
        },
      },
    },
    MUIDataTableFilter: {
      root: {
        backgroundColor: 'inherit',
      },
      resetLink: {
        fontWeight: 'bold',
      },
      title: {
        color: '#fff',
      },
    },
    MuiFormLabel: {
      root: {
        color: '#fff',
      },
    },
    MuiMenuItem: {
      root: {
        color: '#fff',
      },
    },
    MuiTableRow: {
      root: {
        // '&:nth-child(odd)': {
        //   backgroundColor: 'rgba(0, 0, 0, 0.108214)',
        // },
      },
    },

    MUIDataTableSelectCell: {
      fixedHeaderCommon: {
        '&:nth-child(odd)': {
          backgroundColor: 'inherit',
        },
      },
    },
    MuiTablePagination: {
      caption: {
        color: 'white',
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.108214)',
        minWidth: '70px',
      },
      body: {
        color: 'white',
      },
      head: {
        minWidth: '70px',
        textAlign: 'left',
        position: 'relative',
        fontSize: '18px',
      },
    },
    MUIDataTableToolbarSelect: {
      root: {
        backgroundColor: 'rgba(0, 0, 0, 0.108214)',
      },
      title: {
        color: '#fff !important',
      },
    },
    MUIDataTableBody: {
      emptyTitle: {
        color: '#fff !important',
      },
    },
    MuiTableFooter: {},
    MUIDataTablePagination: {},
    MuiTypography: {
      h6: { color: 'white' },
      caption: {
        color: '#fff',
      },
    },
  },
}
