import { createGoal, updateGoal, deleteGoal } from '../../actions/GoalActions'
import { InfoDonut, CitiIQCard, Icon, LoadingScreen } from '../../components'
import { renderColor } from '../../constants/Constants'
import {
  getLocalizedCityName,
  getLocalizedCountryName,
} from '../../helpers/lang'
import { renderParagraphWithLinebreaks } from '../../helpers/string'
import useLanguage from '../../state/hooks/useLanguage'
import './DimensionDashboard.scss'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import Grid from '@material-ui/core/Grid'
import Input from '@material-ui/core/Input'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Snackbar from '@material-ui/core/Snackbar'
import Clear from '@material-ui/icons/Clear'
import { isEqual } from 'apollo-utilities'
import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { CSSTransition } from 'react-transition-group'
import { bindActionCreators } from 'redux'

export const DimensionDashboard = ({
  defaultSlide = 0,
  onDimensionClick = () => false,
  selectedDimension = '',
  infoModalOpen,
  openInfoModal,
  closeInfoModal,
  selectable = false,
  cityIqCardProps,
  multiCityDimensionScores,
  formattedCityCountryMap,
  heightOverride,
  minHeightOverride,
  pageStore,
  goalStore,
  userStore,
  actions,
  intl,
}) => {
  const { language } = useLanguage()
  const [openGoals, setOpenGoals] = React.useState(false)
  const [openSnack, setOpenSnack] = React.useState(false)
  const [saving, setSaving] = React.useState(false)
  const [goalsMap, setGoalsMap] = React.useState({})
  const [errorMessages, setErrorMessages] = React.useState({})

  // currentSlide: 5 = overall, 0-4 = dimensions
  const [currentSlide, setCurrentSlide] = React.useState(defaultSlide)
  const scrollRef = React.useRef()

  React.useEffect(() => {
    setCurrentSlide(defaultSlide)
  }, [defaultSlide])

  React.useEffect(() => {
    setTimeout(() => {
      scrollRef.current?.scrollIntoView()
    }, 10)
  }, [currentSlide])
  function generateGoalsMapFromProps() {
    var updatedGoalsMap = {
      0: null,
      1: null,
      2: null,
      3: null,
      4: null,
      5: null,
    }
    goalStore.get('goals').forEach((g, idx) => {
      const date = g.get('date')
      const index = g.get('dimension')
      updatedGoalsMap[Number(index)] = {
        value: g.get('value'),
        quarter: date.split('-')[1],
        year: date.split('-')[0],
      }
    })
    return updatedGoalsMap
  }

  function resetAll() {
    setGoalsMap(generateGoalsMapFromProps())
    setErrorMessages({})
    setSaving(false)
  }

  React.useEffect(() => {
    resetAll()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    resetAll()
    if (saving) {
      setSaving(false)
      setOpenGoals(false)
      setOpenSnack(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    goalStore.get('goals').size,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    userStore.getIn(['selectedCityId']),
  ])

  function nextSlide() {
    setCurrentSlide((currentSlide + 1 + 6) % 6)
  }

  function prevSlide() {
    setCurrentSlide((currentSlide - 1 + 6) % 6)
  }

  function renderInfoModal() {
    let actual
    if (currentSlide === 5) {
      actual = pageStore.getIn(['allInfo', 'LatestCityIq'])
    } else {
      const temp = pageStore
        .getIn(['allInfo', 'LatestCityDimensions'])
        .map((d) => {
          return d.get('value')
        })
      actual = temp.get(currentSlide)
    }
    const mainColorArr = [
      '#34ACE0',
      '#E8961B',
      '#764B9E',
      '#5CC19C',
      '#EF3488',
      '#9EC73D',
    ]
    const targetColorArr = [
      '#1b6687',
      '#99600a',
      '#4c2b6b',
      '#337c61',
      '#961b52',
      '#5b751f',
    ]
    const activeDim = currentSlide + 1
    const nextDim = ((currentSlide + 1) % 6) + 1
    const prevDim = ((currentSlide + 5) % 6) + 1

    return (
      <Dialog
        open={infoModalOpen || false}
        onClose={() => {
          closeInfoModal()
          setCurrentSlide(defaultSlide)
        }}
        classes={{ root: 'infoDialog widen', paper: 'infoDialogInner widen' }}
      >
        <div className="infoDialogInner">
          <div className="flavorText">
            <InfoDonut
              size={20}
              cutoutPercentage={40}
              textSize={0}
              displayText={''}
              actual={actual || 0}
              actualColor={mainColorArr[currentSlide]}
              targetColor={targetColorArr[currentSlide]}
            />
            <span>
              <FormattedMessage
                id={
                  currentSlide !== 5
                    ? 'dimensions.dimension'
                    : 'dimensions.brand'
                }
              />
            </span>
          </div>
          <h1 className="title">
            <FormattedMessage id={'dimensions.' + activeDim} />
          </h1>
          <div className="contentWrapper">
            <div ref={scrollRef} className="scrollRef" />
            <h3 className="subTitle">
              {renderParagraphWithLinebreaks({
                intl: intl,
                messageId: 'dimensions.subTitle.' + activeDim,
              })}
            </h3>
            <div className="description">
              {renderParagraphWithLinebreaks({
                intl: intl,
                messageId: 'dimensions.description.' + activeDim,
              })}
            </div>
            <h3 className="subTitle">
              {renderParagraphWithLinebreaks({
                intl: intl,
                messageId: 'dimensions.second_subTitle.' + activeDim,
              })}
            </h3>
            <div className="description">
              {renderParagraphWithLinebreaks({
                intl: intl,
                messageId: 'dimensions.second_description.' + activeDim,
              })}
            </div>
          </div>
          <div className="footer">
            <span className="goLeft" onClick={() => prevSlide()}>
              {'< '}
              <FormattedMessage id={'dimensions.' + prevDim} />
            </span>
            <span className="goRight" onClick={() => nextSlide()}>
              <FormattedMessage id={'dimensions.' + nextDim} /> {' >'}
            </span>
          </div>
        </div>
        <div
          className="infoDialogClose"
          onClick={() => {
            closeInfoModal()
            setCurrentSlide(defaultSlide)
          }}
        >
          <FontAwesomeIcon icon={faTimes} />
        </div>
      </Dialog>
    )
  }

  //Updating goal in state ONLY with this function
  function updateGoal(e, d_id, target) {
    var _goalsMap = { ...goalsMap }
    var newVal = _goalsMap[d_id]
    //Check if input is valid (numeric, between 0 and 100, etc.), return if not.
    if (
      isNaN(e.target.value) ||
      (target === 'value' && e.target.value > 100) ||
      e.target.value < 0 ||
      (target === 'quarter' && e.target.value > 4)
    ) {
      return
    }
    if (!newVal) {
      newVal = { quarter: '', year: '', value: '' }
    }
    newVal[target] = e.target.value

    _goalsMap[d_id] = isEqual(newVal, { quarter: '', year: '', value: '' })
      ? null
      : newVal
    setGoalsMap(_goalsMap)
  }

  //Saving goals to db
  function saveGoals() {
    const dimIds = [0, 1, 2, 3, 4, 5]
    dimIds.forEach((d_id) => saveDimensionGoal(goalsMap[d_id], d_id))
  }

  function hasUpdates() {
    const a = { ...generateGoalsMapFromProps() }
    const b = { ...goalsMap }
    return !isEqual(a, b)
  }

  function validateGoals() {
    const msgs = {}
    let isValid = true
    const dimIds = [0, 1, 2, 3, 4, 5]
    dimIds.forEach((d_id) => {
      const g = goalsMap[d_id]
      if (!g) return

      const isFieldIncomplete =
        String(g.value).trim() === '' ||
        String(g.year).trim() === '' ||
        String(g.quarter).trim() === ''
      const isEmpty =
        String(g.value).trim() === '' &&
        String(g.year).trim() === '' &&
        String(g.quarter).trim() === ''

      if (isFieldIncomplete && !isEmpty) {
        msgs[d_id] = 'Please fill in all inputs'
        isValid = false
      }
    })
    setErrorMessages(msgs)
    return isValid
  }

  function saveDimensionGoal(g, d_id) {
    const user = userStore.getIn(['userInfo', 'id'])
    const city = userStore.getIn(['selectedCityId'])

    const hasExistingGoal = goalStore.get('goals').find((g) => {
      return Number(g.get('dimension')) === Number(d_id)
    })
    setSaving(true)
    if (hasExistingGoal && g === null) {
      actions.deleteGoal(user, d_id.toString())
    }

    if (g === null) {
      return
    }

    actions.createGoal(
      d_id.toString(),
      parseFloat(g.value),
      g.year + '-' + g.quarter,
      city,
      user
    )
  }

  //Clears the goal, ensuring it will be deleted on the next saveGoals() call
  function clearGoal(d_id) {
    const updatedGoals = {
      ...goalsMap,
      [d_id]: null,
    }
    setGoalsMap(updatedGoals)
  }

  function renderYearOptions() {
    let arr = []
    let index
    for (index = 0; index < 10; index++) {
      arr.push(
        <MenuItem value={2018 + index} key={index}>
          {2018 + index}
        </MenuItem>
      )
    }
    return arr.map((a) => a)
  }

  function renderGoalsDialog() {
    const dimensionArr = pageStore.getIn(['allInfo', 'LatestCityDimensions'])
      ? pageStore.getIn(['allInfo', 'LatestCityDimensions']).toJS()
      : []
    return (
      <Dialog
        open={openGoals}
        onClose={() => {
          resetAll()
          setOpenGoals(false)
        }}
        classes={{ paper: 'goalsDialog' }}
      >
        <div className={'goalsDialogInnerDiv'}>
          <div className="goalItem header">
            <span>
              <FormattedMessage id={'goals.setYourGoals'} />
            </span>
            <div className="goalInput">
              <span>
                <FormattedMessage id="goals.goal" />
              </span>
              <span>
                <FormattedMessage id="goals.quarter" />
              </span>
              <span>
                <FormattedMessage id="goals.year" />
              </span>
            </div>
          </div>
          {[
            {
              id: '0',
              value: pageStore.getIn(['allInfo', 'LatestCityIq']),
            },
          ]
            .concat(dimensionArr)
            .map((d, i) => {
              const d_id = Number(d.id)
              return (
                <div className="goalItem" key={i}>
                  <InfoDonut
                    actual={Math.round(d.value)}
                    size={50}
                    textSize={20}
                    target={
                      goalsMap[d_id] ? parseFloat(goalsMap[d_id].value) : null
                    }
                    actualColor={renderColor(d_id).main}
                    targetColor={renderColor(d_id).target}
                  />
                  <span className="dimensionName">
                    {' '}
                    <FormattedMessage id={'dimensions.' + d_id} />{' '}
                  </span>
                  <div className="goalInput">
                    <Input
                      value={goalsMap[d_id] ? goalsMap[d_id].value : ''}
                      onChange={(e) => updateGoal(e, d_id, 'value')}
                      classes={{ root: 'input', input: 'centreText' }}
                    />
                    {/* <Input value={goalsMap[d_id] ? goalsMap[d_id].quarter : ''} onChange={(e) => updateGoal(e, i, 'quarter')} classes={{root: 'input', input: 'centreText'}}/> */}
                    <span style={{ marginRight: '20px' }}>
                      <Select
                        value={
                          goalsMap[d_id]
                            ? parseInt(goalsMap[d_id].quarter, 10)
                            : 0
                        }
                        onChange={(e) => updateGoal(e, i, 'quarter')}
                        classes={{ root: 'select' }}
                      >
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={3}>3</MenuItem>
                        <MenuItem value={4}>4</MenuItem>
                      </Select>
                    </span>
                    <span style={{ marginRight: '20px' }}>
                      <Select
                        value={
                          goalsMap[d_id] ? parseInt(goalsMap[d_id].year, 10) : 0
                        }
                        onChange={(e) => updateGoal(e, i, 'year')}
                        classes={{ root: 'select wide' }}
                      >
                        {renderYearOptions()}
                      </Select>
                    </span>
                    {/* <Input value={goalsMap[d_id] ? goalsMap[d_id].year : ''} onChange={(e) => updateGoal(e, i, 'year')} classes={{root: 'input', input: 'centreText'}}/> */}
                    {goalsMap[d_id] && (
                      <Clear
                        classes={{ root: 'clearIcon' }}
                        onClick={() => clearGoal(d_id)}
                      />
                    )}
                  </div>
                  {errorMessages[d_id] && (
                    <Typography color="error">{errorMessages[d_id]}</Typography>
                  )}
                </div>
              )
            })}
          {errorMessages.all && (
            <Typography color="error">{errorMessages.all}</Typography>
          )}
          <div
            className="saveBtn"
            onClick={
              saving
                ? undefined
                : () => {
                    if (!hasUpdates()) {
                      setErrorMessages({
                        all: 'No changes to save',
                      })
                    } else if (validateGoals()) {
                      saveGoals()
                    } else {
                      setSaving(false)
                    }
                  }
            }
          >
            {saving ? (
              <span>
                <FormattedMessage id="goals.saving" />
              </span>
            ) : (
              <span>
                <FormattedMessage id="goals.save" />
              </span>
            )}
          </div>
        </div>
      </Dialog>
    )
  }

  function handleSnackbarClose() {
    setOpenSnack(false)
  }

  function renderSnackBar() {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={openSnack}
        autoHideDuration={4000}
        onClose={handleSnackbarClose.bind(this)}
      >
        <div className="goalSnackBar">
          <FormattedMessage id="snackbar.goalsuccess" />
        </div>
      </Snackbar>
    )
  }

  function getGoalForDimension(d_id, type) {
    const goal = goalStore.get('goals').find((g) => {
      return g.get('dimension') === d_id
    })
    switch (type) {
      case 'goal':
        return (goal && goal.get('value')) || null
      case 'date':
        return (goal && goal.get('date').split('-').join(' Q')) || null
      default:
        break
    }
  }

  function hasScore(scoreArr) {
    const isMultiCity = pageStore.get('isMultiCity')
    if (isMultiCity) {
      return Boolean(multiCityDimensionScores)
    } else {
      return Boolean(scoreArr.size)
    }
  }
  function getLabelForDimension(dimensionId) {
    const { id } = multiCityDimensionScores[dimensionId].city

    if (!formattedCityCountryMap) return null
    if (!formattedCityCountryMap[id]) return null

    const cityName = getLocalizedCityName(formattedCityCountryMap[id], language)
    const countryName = getLocalizedCountryName(
      formattedCityCountryMap[id],
      language
    )
    return (
      <div>
        <h3 className="topScoreCity">{cityName}</h3>
        <p className="topScoreCountry">{countryName}</p>
      </div>
    )
  }
  function getActualScoreForDimension(dimensionId) {
    return Math.round(multiCityDimensionScores[dimensionId].iq)
  }
  function mayRenderDimenstionHeader() {
    if (pageStore.get('isMultiCity')) return null
    const isPrivateUser = userStore.get('role').toJS().title === 'private_user'
    const isMobile = window.innerWidth < 570

    return (
      <div className={'dimScoresHeader'}>
        <React.Fragment>
          <FormattedMessage id="dimensions.dimensionScores" />
          <Icon
            name="city-profile"
            className="infoButton"
            onClick={() => openInfoModal()}
          />
        </React.Fragment>
        <div className="toggleGoals">
          {selectedDimension ? (
            <Button onClick={() => onDimensionClick(0)}>
              <FormattedMessage id="dimensions.all" />
            </Button>
          ) : null}
          {!(selectedDimension && isMobile) && !isPrivateUser ? (
            <Button onClick={() => setOpenGoals(true)}>
              <FormattedMessage id="dimension.showGoals" />
            </Button>
          ) : null}
        </div>
      </div>
    )
  }
  function renderDonuts() {
    const isMultiCity = pageStore.get('isMultiCity')
    const isMobile = isMultiCity
      ? window.innerWidth <= 768
      : window.innerWidth < 570

    const scoreArr = pageStore
      .getIn(['allInfo', 'LatestCityDimensions'])
      .map((d) => {
        return d.get('value')
      })

    const size = isMultiCity ? 60 : isMobile ? 50 : 85
    const textSize = isMobile ? '20px' : '30px'
    const sD = selectable ? selectedDimension : ''
    return (
      <React.Fragment>
        <div className="dimensionScore" onClick={() => onDimensionClick(1)}>
          {isMultiCity && getLabelForDimension(1)}
          <div className="donut">
            <InfoDonut
              size={size}
              textSize={textSize}
              actual={
                isMultiCity
                  ? getActualScoreForDimension(1)
                  : Math.round(scoreArr.get(0))
              }
              target={getGoalForDimension(1, 'goal')}
              targetDate={getGoalForDimension(1, 'date')}
              actualColor={renderColor(1).main}
              targetColor={renderColor(1).target}
            />
          </div>
          <div
            className={
              'dimensionSubText' + (sD === 'Basic Needs' ? ' underlined' : '')
            }
          >
            <div className="topScoreTitle">
              {isMultiCity
                ? `${intl.formatMessage({
                    id: 'dashboard.multi.highest',
                  })} `
                : ''}
              <FormattedMessage id="dimensions.1" />
            </div>
          </div>
        </div>
        <div className="dimensionScore" onClick={() => onDimensionClick(2)}>
          {isMultiCity && getLabelForDimension(2)}
          <div className="donut">
            <InfoDonut
              size={size}
              textSize={textSize}
              actual={
                isMultiCity
                  ? getActualScoreForDimension(2)
                  : Math.round(scoreArr.get(1))
              }
              target={getGoalForDimension(2, 'goal')}
              targetDate={getGoalForDimension(2, 'date')}
              actualColor={renderColor(2).main}
              targetColor={renderColor(2).target}
            />
          </div>
          <div
            className={
              'dimensionSubText' +
              (sD === 'Competitiveness' ? ' underlined' : '')
            }
          >
            <div className="topScoreTitle">
              {isMultiCity
                ? `${intl.formatMessage({
                    id: 'dashboard.multi.highest',
                  })} `
                : ''}
              <FormattedMessage id="dimensions.2" />
            </div>
          </div>
        </div>
        <div className="dimensionScore" onClick={() => onDimensionClick(3)}>
          {isMultiCity && getLabelForDimension(3)}
          <div className="donut">
            <InfoDonut
              size={size}
              textSize={textSize}
              actual={
                isMultiCity
                  ? getActualScoreForDimension(3)
                  : Math.round(scoreArr.get(2))
              }
              target={getGoalForDimension(3, 'goal')}
              targetDate={getGoalForDimension(3, 'date')}
              actualColor={renderColor(3).main}
              targetColor={renderColor(3).target}
            />
          </div>
          <div
            className={
              'dimensionSubText' + (sD === 'Opportunity' ? ' underlined' : '')
            }
          >
            <div className="topScoreTitle">
              {isMultiCity
                ? `${intl.formatMessage({
                    id: 'dashboard.multi.highest',
                  })} `
                : ''}
              <FormattedMessage id="dimensions.3" />
            </div>
          </div>
        </div>
        <div className="dimensionScore" onClick={() => onDimensionClick(4)}>
          {isMultiCity && getLabelForDimension(4)}
          <div className="donut">
            <InfoDonut
              size={size}
              textSize={textSize}
              actual={
                isMultiCity
                  ? getActualScoreForDimension(4)
                  : Math.round(scoreArr.get(3))
              }
              target={getGoalForDimension(4, 'goal')}
              targetDate={getGoalForDimension(4, 'date')}
              actualColor={renderColor(4).main}
              targetColor={renderColor(4).target}
            />
          </div>
          <div
            className={
              'dimensionSubText' + (sD === 'Livability' ? ' underlined' : '')
            }
          >
            <div className="topScoreTitle">
              {isMultiCity
                ? `${intl.formatMessage({
                    id: 'dashboard.multi.highest',
                  })} `
                : ''}
              <FormattedMessage id="dimensions.4" />
            </div>
          </div>
        </div>
        <div className="dimensionScore" onClick={() => onDimensionClick(5)}>
          {isMultiCity && getLabelForDimension(5)}
          <div className="donut">
            <InfoDonut
              size={size}
              textSize={textSize}
              actual={
                isMultiCity
                  ? getActualScoreForDimension(5)
                  : Math.round(scoreArr.get(4))
              }
              target={getGoalForDimension(5, 'goal')}
              targetDate={getGoalForDimension(5, 'date')}
              actualColor={renderColor(5).main}
              targetColor={renderColor(5).target}
            />
          </div>
          <div
            className={
              'dimensionSubText' + (sD === 'Destiny' ? ' underlined' : '')
            }
          >
            <div className="topScoreTitle">
              {isMultiCity
                ? `${intl.formatMessage({
                    id: 'dashboard.multi.highest',
                  })} `
                : ''}
              <FormattedMessage id="dimensions.5" />
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
  function renderInfoDonuts() {
    const scoreArr = pageStore
      .getIn(['allInfo', 'LatestCityDimensions'])
      .map((d) => {
        return d.get('value')
      })
    const isPrivateUser = userStore.get('role').toJS().title === 'private_user'
    return (
      <React.Fragment>
        {renderInfoModal()}
        {!isPrivateUser && renderGoalsDialog()}
        {mayRenderDimenstionHeader()}
        {!hasScore(scoreArr) && (
          <div className="loadingDiv">
            <CircularProgress
              style={{ color: '#b1ff44' }}
              classes={{ root: 'spinner' }}
            />
          </div>
        )}
        {hasScore(scoreArr) && (
          <CSSTransition
            mountOnEnter
            appear
            in={true}
            timeout={0}
            classNames="fillT"
            unmountOnExit
          >
            <Grid
              container
              spacing={24}
              sx={{
                margin: '0 -12px',
                display: 'flex',
                height: 'max-content',
              }}
            >
              {renderDonuts()}
            </Grid>
          </CSSTransition>
        )}
      </React.Fragment>
    )
  }

  const isMultiCity = pageStore.get('isMultiCity')
  if (isMultiCity) {
    return renderDonuts()
  }

  return (
    <CitiIQCard
      minHeight={minHeightOverride ?? 'unset'}
      height={heightOverride ?? 'unset'}
      classes={{
        root: 'DimensionDashboard selectable',
      }}
      {...cityIqCardProps}
    >
      {renderInfoDonuts()}
      {renderSnackBar()}
    </CitiIQCard>
  )
}

function mapStateToProps(state) {
  return {
    pageStore: state.PageStore,
    goalStore: state.GoalStore,
    userStore: state.UserStore,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        createGoal,
        updateGoal,
        deleteGoal,
      },
      dispatch
    ),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(DimensionDashboard))
