import AddUserForm from './AddUserForm'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import React, { useState } from 'react'

const AddUser = (props) => {
  const [newUser, setNewUser] = useState(null)

  function renderNewUserInfo() {
    const { firstName, lastName } = newUser
    return (
      <div>
        <DialogContentText>
          Now you can add city subscriptions for {firstName} {lastName}.
        </DialogContentText>
      </div>
    )
  }

  return (
    <div>
      <DialogTitle id="form-dialog-title">
        {newUser ? `New user created!` : 'Add user'}
      </DialogTitle>
      <DialogContent>
        <br />
        {newUser && renderNewUserInfo()}
        <AddUserForm
          inline={true}
          isAdminAdd={true}
          handleUserAdded={(user) => {
            setNewUser(user)
            props.handleUserAdded()
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.onCloseDialog()} color="primary" autoFocus>
          {newUser ? 'OK' : 'Cancel'}
        </Button>
      </DialogActions>
    </div>
  )
}

export default AddUser
