import { clearConsiderationsComparisons } from '../../actions/PageActions'
import { ComparisonBarChart, CitiIQCard, InfoDonut } from '../../components'
import { renderColor } from '../../constants/Constants'
import './ConsiderationComparison.scss'
import Grid from '@material-ui/core/Grid'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import ArrowBack from '@material-ui/icons/ArrowBack'
import React from 'react'
import Helmet from 'react-helmet'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'
import { bindActionCreators } from 'redux'

function DataSummary({ actions, pageStore, intl }) {
  pageStore = pageStore.toJS()
  const { clearConsiderationsComparisons } = actions
  const [tabValue, setTabValue] = React.useState(0)

  const dimension = tabValue + 1
  let currentDimension = {}
  let snapshotRevision = ''
  if (
    Array.isArray(pageStore.allInfo.LatestCityDimensions) &&
    pageStore.allInfo.LatestCityDimensions.length
  ) {
    currentDimension = pageStore.allInfo.LatestCityDimensions.find(
      (dim) => dim.id === dimension
    )
  }
  if (!!pageStore.allInfo.LatestSnapshotRevision) {
    const [, year, quarter] =
      pageStore.allInfo.LatestSnapshotRevision.split('-')
    snapshotRevision = `Q${quarter.charAt(1)} - ${year}`
  }
  if (pageStore.isMultiCity) {
    return <Redirect to={'dashboard'} />
  }
  return (
    <div className={`DataComparisonPage`}>
      <Helmet>
        <title>
          CitiIQ -{' '}
          {intl.formatMessage({ id: 'consideration.comparison.navigation' })}
        </title>
      </Helmet>
      <div className="top-strip">
        <Link to="/dashboard" className="navigation">
          <h1>
            <ArrowBack className="nav-icon" />
            <FormattedMessage
              id="consideration.comparison.navigation"
              description="nav"
            />
          </h1>
        </Link>
      </div>

      <Grid container spacing={24} className="data-comparison-container">
        <Grid item xs={12}>
          <CitiIQCard
            height="1000px"
            className="consideration-comparison-wrapper"
          >
            <Tabs
              value={tabValue}
              onChange={(_event, value) => {
                clearConsiderationsComparisons()
                setTabValue(value)
              }}
              scrollable
              scrollButtons="off"
              indicatorColor="primary"
              textColor="primary"
              className="tabs-selector"
            >
              <Tab
                classes={{ selected: 'selected' }}
                label={
                  <FormattedMessage id="dimensions.1" description="tab-text" />
                }
              />
              <Tab
                classes={{ selected: 'selected' }}
                label={
                  <FormattedMessage id="dimensions.2" description="tab-text" />
                }
              />
              <Tab
                classes={{ selected: 'selected' }}
                className={tabValue === 2 ? 'selectedTab' : ''}
                label={
                  <FormattedMessage id="dimensions.3" description="tab-text" />
                }
              />
              <Tab
                classes={{ selected: 'selected' }}
                className={tabValue === 3 ? 'selectedTab' : ''}
                label={
                  <FormattedMessage id="dimensions.4" description="tab-text" />
                }
              />
              <Tab
                classes={{ selected: 'selected' }}
                className={tabValue === 4 ? 'selectedTab' : ''}
                label={
                  <FormattedMessage id="dimensions.5" description="tab-text" />
                }
              />
            </Tabs>
            <div className="summaryDonut">
              <span className="donut">
                <InfoDonut
                  actual={Math.round(currentDimension.value)}
                  size={50}
                  textSize={20}
                  actualColor={renderColor(dimension).main}
                  target={null}
                  targetColor={renderColor(dimension).target}
                />
              </span>
              <span className="text">
                <div className="name">{currentDimension.name}</div>
                {snapshotRevision}
              </span>
            </div>
            <ComparisonBarChart dimension={dimension} quarters={4} />
          </CitiIQCard>
        </Grid>
      </Grid>
    </div>
  )
}

function mapStateToProps(state) {
  return {
    pageStore: state.PageStore,
    userStore: state.UserStore,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        clearConsiderationsComparisons,
      },
      dispatch
    ),
  }
}

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(DataSummary)
)
