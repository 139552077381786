import { closeForceLogoutDialog } from '../../actions/ModalActions'
import { logoutUser } from '../../actions/UserActions'
import './ForceLogoutDialog.scss'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Grid from '@material-ui/core/Grid'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'

export const ForceLogoutDialog = ({ actions, history, modalStore }) => {
  const dialogOpen = modalStore.get('forceLogoutDialogOpen')

  function closeLogoutDialog() {
    actions.closeForceLogoutDialog()
    actions.logoutUser().then(() => {
      history.push('/')
    })
  }

  return (
    <Dialog open={dialogOpen} classes={{ paper: 'ForceLogoutDialog' }}>
      <Grid container spacing={8}>
        <div className="content">
          Your session has expired, please log back in to continue.
        </div>
        <div className="footer">
          <Button
            onClick={closeLogoutDialog}
            color="primary"
            classes={{ textPrimary: 'logoutButton' }}
          >
            Logout
          </Button>
        </div>
      </Grid>
    </Dialog>
  )
}

function mapStateToProps(state) {
  return {
    modalStore: state.ModalStore,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        closeForceLogoutDialog,
        logoutUser,
      },
      dispatch
    ),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ForceLogoutDialog))
