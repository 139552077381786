import {
  considerationToDimension,
  dimensionToColor,
} from '../../constants/Constants'
import { Paper, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'
import Highlighter from 'react-highlight-words'
import { FormattedMessage } from 'react-intl'
import { CSSTransition } from 'react-transition-group'

export const CustomizedMilestonesCircle = ({ cx, cy, colour, payload }) =>
  payload['milestone'] !== null ? (
    <CSSTransition
      mountOnEnter
      appear
      in={true}
      timeout={0}
      classNames="fadeT"
      unmountOnExit
    >
      <svg
        x={cx - 15}
        y={cy - 10}
        width={50}
        height={50}
        fill={colour}
        viewBox="0 0 1024 1024"
      >
        <path d="m118.24495,207.07002l0,0c0,-93.05999 77.90245,-168.50001 174.00002,-168.50001l0,0c46.14765,0 90.40523,17.75263 123.03657,49.35251c32.63133,31.59988 50.9634,74.45852 50.9634,119.1475l0,0c0,93.05996 -77.90244,168.49998 -173.99997,168.49998l0,0c-96.09756,0 -174.00002,-75.44003 -174.00002,-168.49998zm174.00002,-168.50001l0,336.99999m-174.00002,-168.49998l347.99998,0" />
      </svg>
    </CSSTransition>
  ) : (
    <svg />
  )

export const CustomizedSqaure = ({ cx, cy, colour }) => (
  <CSSTransition
    mountOnEnter
    appear
    in={true}
    timeout={0}
    classNames="fadeT"
    unmountOnExit
  >
    <svg
      x={cx - 15}
      y={cy - 10}
      width={50}
      height={50}
      fill={colour}
      viewBox="0 0 1024 1024"
    >
      <path d="m110.74985,190.695l168.06017,-164.38812l168.06017,164.38812l-168.06017,164.38812l-168.06017,-164.38812z" />
    </svg>
  </CSSTransition>
)

export const CustomizedCircle = ({ cx, cy, colour }) => (
  <CSSTransition
    mountOnEnter
    appear
    in={true}
    timeout={0}
    classNames="fadeT"
    unmountOnExit
  >
    <svg
      x={cx - 15}
      y={cy - 10}
      width={50}
      height={50}
      fill={colour}
      viewBox="0 0 1024 1024"
    >
      <path d="m118.24495,207.07002l0,0c0,-93.05999 77.90245,-168.50001 174.00002,-168.50001l0,0c46.14765,0 90.40523,17.75263 123.03657,49.35251c32.63133,31.59988 50.9634,74.45852 50.9634,119.1475l0,0c0,93.05996 -77.90244,168.49998 -173.99997,168.49998l0,0c-96.09756,0 -174.00002,-75.44003 -174.00002,-168.49998zm174.00002,-168.50001l0,336.99999m-174.00002,-168.49998l347.99998,0" />
    </svg>
  </CSSTransition>
)

export const CustomizedStar = ({ cx, cy, colour }) => (
  <CSSTransition
    mountOnEnter
    appear
    in={true}
    timeout={0}
    classNames="fadeT"
    unmountOnExit
  >
    <svg
      x={cx - 15}
      y={cy - 10}
      width={50}
      height={50}
      fill={colour}
      viewBox="0 0 1024 1024"
    >
      <path d="m87.065,148.70878l162.71868,0l50.28132,-138.65404l50.28135,138.65404l162.71865,0l-131.64193,85.69193l50.28392,138.65404l-131.64199,-85.69426l-131.64196,85.69426l50.28394,-138.65404l-131.64198,-85.69193z" />
    </svg>
  </CSSTransition>
)

export const CustomizedDiamond = ({ cx, cy, colour }) => (
  <CSSTransition
    mountOnEnter
    appear
    in={true}
    timeout={0}
    classNames="fadeT"
    unmountOnExit
  >
    <svg
      x={cx - 15}
      y={cy - 10}
      width={50}
      height={50}
      fill={colour}
      viewBox="0 0 1024 1024"
    >
      <path d="m90.98,199.9925l214.50001,-200.5l214.50001,200.5l-214.50001,200.5l-214.50001,-200.5z" />
    </svg>
  </CSSTransition>
)

export const CustomizedTriangle = ({ cx, cy, colour }) => (
  <CSSTransition
    mountOnEnter
    appear
    in={true}
    timeout={0}
    classNames="fadeT"
    unmountOnExit
  >
    <svg
      x={cx - 17}
      y={cy - 10}
      width={50}
      height={50}
      fill={colour}
      viewBox="0 0 1024 1024"
    >
      <path d="m118.41,391.51062l217.49999,-373.99999l217.49999,373.99999l-434.99998,0z" />
    </svg>
  </CSSTransition>
)

export const CustomizedXAxisTickConsiderations = ({ x, y, payload }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <FormattedMessage id={'considerations.' + payload.value}>
        {(translated_value) => (
          <text fill="white" stroke="none" textAnchor="end" fontSize={12}>
            {translated_value}{' '}
          </text>
        )}
      </FormattedMessage>
    </g>
  )
}

export const CustomizedIndicatorsToolTip = ({
  active,
  payload,
  searchKey,
  intl,
}) => {
  if (payload.length === 0) return null
  const data = payload[0].payload
  return data && active ? (
    <Paper
      style={{
        borderRadius: '8px',
        padding: '10px 20px',
        width: '300px',
      }}
    >
      <Typography variant="title">
        <Highlighter
          highlightClassName="YourHighlightClass"
          searchWords={[searchKey]}
          autoEscape={true}
          textToHighlight={data.name}
        />
        <div>({data.quarterString})</div>
      </Typography>
      <Typography variant="h1" style={{ fontWeight: 800, margin: '8px 0' }}>
        {data.value.toFixed(2)}
      </Typography>
      <Typography variant="caption">
        <Highlighter
          highlightClassName="YourHighlightClass"
          searchWords={[searchKey]}
          autoEscape={true}
          textToHighlight={data.description}
        />
      </Typography>
      <hr />
      <Typography variant="body2" align="right">
        {intl.formatMessage({ id: 'indicator.chart.affectedConsiderations' })} (
        {data.affectedConsiderations?.length ?? 0}):
      </Typography>
      {(data.affectedConsiderations ?? []).map(({ w, i, c }) => (
        <FormattedMessage key={c} id={'considerations.' + c}>
          {(translated_value) => (
            <Typography variant="caption" align="right">
              <span
                style={{
                  display: 'inline-block',
                  width: '10px',
                  height: '10px',
                  backgroundColor:
                    dimensionToColor[considerationToDimension[c].dimension]
                      .main,
                  borderRadius: '50%',
                  marginRight: '5px',
                }}
              />
              {translated_value} {`(${w}%)`}
            </Typography>
          )}
        </FormattedMessage>
      ))}
      {/* </Grid> */}
    </Paper>
  ) : null
}

export const CustomizedYAxisTickIndicators = ({ x, y, payload, offset }) => {
  return (
    <g transform={`translate(${x},${y + offset})`}>
      <text fill="white" stroke="none" textAnchor="end" fontSize={12}>
        {payload.value}
      </text>
    </g>
  )
}

export const CustomizedAreaChartToolTip = ({
  active,
  payload,
  milestones_array,
}) => {
  function renderMilestone(milestone, i) {
    return <p key={i}>{milestone.get('title')}</p>
  }

  function renderMilestones(payload, milestones) {
    let elements_array = []
    let result_array
    let count = 0
    if (payload.filter((x) => x.dataKey === 'milestone').length > 0) {
      const tooltip_date = payload[0].payload['snapshot'].split(' Q')
      const tooltip_quarter = tooltip_date[1]
      const tooltip_year = '20' + tooltip_date[0].slice(1)

      result_array = (milestones ?? []).filter(
        (x) =>
          new Date(x.get('date')).getUTCFullYear().toString() ===
            tooltip_year &&
          Math.floor(
            (new Date(x.get('date')).getUTCMonth() + 3) / 3
          ).toString() === tooltip_quarter
      )

      if (result_array.size > 0) {
        elements_array.push(<hr key={count++} />)

        for (var len = 0; len < result_array.size; len++) {
          elements_array.push(
            renderMilestone(result_array.getIn([len]), count++)
          )
        }
      }
      return elements_array
    }
  }

  function renderItem(item, itemLabelIndex) {
    return (
      <p className="label" key={item.dataKey} style={{ color: item.stroke }}>
        <FormattedMessage
          id={'dimensions.' + itemLabelIndex}
          description="tooltip-text"
        />
        : {item.value.toFixed(1)}
      </p>
    )
  }

  function renderItems(payload) {
    let items = []
    for (var len = 0; len < payload.length; len++) {
      if (payload[len].dataKey !== 'milestone') {
        items.push(renderItem(payload[len], len + 1))
      }
    }
    return items
  }

  if (active && payload) {
    return (
      <div className="custom-tooltip">
        {renderItems(payload)}

        {renderMilestones(payload, milestones_array)}
      </div>
    )
  }

  return null
}

CustomizedAreaChartToolTip.propTypes = {
  type: PropTypes.string,
  payload: PropTypes.array,
}

export const CustomizedMultiBarChartToolTip = ({ active, payload }) => {
  function renderItem(item) {
    return (
      <Typography
        variant="body2"
        className="label"
        key={item.dataKey}
        style={{ color: item.fill }}
      >
        {item.payload[item.dataKey + '_time']}: {item.value.toFixed(1)}
      </Typography>
    )
  }

  function renderItems(payload) {
    let items = []
    if (payload && payload.length > 0) {
      for (var len = 0; len < payload.length; len++) {
        items.push(renderItem(payload[len]))
      }
    }
    return items
  }
  return active ? (
    <Paper
      style={{
        borderRadius: '8px',
        padding: '10px 20px',
        width: '140px',
      }}
    >
      {renderItems(payload)}
    </Paper>
  ) : null
}

CustomizedMultiBarChartToolTip.propTypes = {
  type: PropTypes.string,
  payload: PropTypes.array,
}

export const CustomizedBarChartToolTip = ({ active, payload }) => {
  return (
    active && (
      <div className="custom-tooltip">
        {payload.map(({ dataKey, fill, value }) => {
          return (
            <p key={dataKey} className="label" style={{ color: fill }}>
              <FormattedMessage
                id={`chart.tooltip.text.${dataKey}`}
                description="tooltip-text"
              />
              {value.toFixed(1)}
            </p>
          )
        })}
      </div>
    )
  )
}

CustomizedBarChartToolTip.propTypes = {
  type: PropTypes.string,
  payload: PropTypes.array,
}
