export function parser(data, factor) {
  let lines = []
  data.forEach((s, index) => {
    let key = s.data[factor]
    lines.push({
      snapshot: s.snapshot,
      15: key.find(function (a) {
        return a.id === 15
      }).value,
      16: key.find(function (a) {
        return a.id === 16
      }).value,
      17: key.find(function (a) {
        return a.id === 17
      }).value,
      18: key.find(function (a) {
        return a.id === 18
      }).value,
      19: key.find(function (a) {
        return a.id === 19
      }).value,
    })
  })
  return lines
}

export function parserGroupBy(data, factor, need) {
  let currentYear = 2018
  let lines = []
  let map = new Map()
  data.forEach((s, index) => {
    map.set(
      s.snapshot,
      s.data[factor].find(function (a) {
        return a.id === need
      }).value
    )
  })
  let i
  for (i = 1; i < 5; i++) {
    let currentFrame = currentYear.toString() + '-00' + i.toString()
    let prevFrame = (currentYear - 1).toString() + '-00' + i.toString()
    let prev = 0
    let curr = 0
    if (map.get(currentFrame) !== undefined) {
      curr = map.get(currentFrame)
    }

    if (map.get(prevFrame) !== undefined) {
      prev = map.get(prevFrame)
    }
    lines.push({
      quarter: 'q' + i.toString(),
      curr: curr,
      prev: prev,
    })
  }
  return lines
}

export function getSorting(order, orderBy) {
  return order === 'desc'
    ? (a, b) => (b.get(orderBy) < a.get(orderBy) ? -1 : 1)
    : (a, b) => (a.get(orderBy) < b.get(orderBy) ? -1 : 1)
}
