import { setDrawerinfo } from '../../actions/ModalActions'
import { unwatchConsideration } from '../../actions/WatchListActions.js'
import { ColoredScore } from '../../components'
import {
  considerationToDimension,
  dimensionToColor,
} from '../../constants/Constants'
import './WatchListComponent.scss'
import { CircularProgress } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListSubheader from '@material-ui/core/ListSubheader'
import RemoveCircle from '@material-ui/icons/RemoveCircle'
import Visibility from '@material-ui/icons/Visibility'
import { is } from 'immutable'
import { func } from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

const WatchListComponent = ({ actions, pageStore, watchListStore }) => {
  const [editMode, setEditMode] = React.useState(false)
  const [saving, isSaving] = React.useState(false)
  const [unwatchConsiderationIds, setUnwatchConsiderationIds] = React.useState(
    []
  )
  function toggleEditMode() {
    setEditMode(!editMode)
  }

  function unwatchConsideration(id) {
    actions.unwatchConsideration(id)
  }

  function save() {
    isSaving(true)
    unwatchConsiderationIds.forEach((id) => unwatchConsideration(id))
    setUnwatchConsiderationIds([])
    toggleEditMode()
    setTimeout(() => {
      isSaving(false)
    }, 500)
  }

  function renderScoreValue(consideration_id) {
    let consideration_array = pageStore
      .getIn(['allInfo', 'LatestCityConsiderations'])
      .filter((x) => x.get('id') === consideration_id)
    return consideration_array.size
      ? Math.round(consideration_array.first().get('value'))
      : ''
  }

  const isEmpty = !watchListStore.get('watchList').size

  return (
    <React.Fragment>
      <List>
        <li>
          <ul className={'watch-list' + (!isEmpty ? ' bottom_border' : '')}>
            <ListSubheader className="sticky-header">
              <ListItemText
                className="sticky-item"
                primary={
                  <FormattedMessage
                    id="watch.list.header"
                    description="subheader"
                  />
                }
                classes={{ secondary: 'secondary', primary: 'header' }}
                secondary={
                  <FormattedMessage
                    id="watch.list.subheader"
                    description="subheader"
                  />
                }
              />

              {!editMode && !isEmpty && (
                <div className="sticky-btn-div">
                  <Button
                    className="watch-list-btn"
                    onClick={() => toggleEditMode()}
                  >
                    <FormattedMessage
                      id="watch.list.edit"
                      description="btn-text"
                    />
                  </Button>
                </div>
              )}
              {editMode && !isEmpty && (
                <div className="sticky-btn-div">
                  <Button className="watch-list-btn" onClick={() => save()}>
                    <FormattedMessage
                      id="watch.list.done"
                      description="btn-text"
                    />
                  </Button>
                </div>
              )}
            </ListSubheader>
            {isEmpty && (
              <div className="emptyWatchedList">
                <Visibility />
                <FormattedMessage id="watch.list.noWatched" />
              </div>
            )}

            {saving ? (
              <div
                style={{
                  height: 300,
                  flex: 1,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CircularProgress style={{ color: '#b1ff44' }} />
              </div>
            ) : (
              watchListStore.get('watchList').map((item) => {
                const consideration = item.get('consideration_id')
                const dim = considerationToDimension[consideration].dimension
                const color = dim ? dimensionToColor[dim].main : '#34ACE0'
                const grade = renderScoreValue(item.get('consideration_id'))
                if (unwatchConsiderationIds.includes(item.get('id'))) {
                  return null
                }
                return (
                  <ListItem
                    key={item.get('id')}
                    className="list-item"
                    onClick={() => {
                      if (!editMode) {
                        actions.setDrawerinfo({
                          consideration,
                          grade,
                        })
                      }
                    }}
                  >
                    <ListItemText
                      classes={{ primary: 'primary', secondary: 'secondary' }}
                      primary={
                        <FormattedMessage
                          id={'considerations.' + consideration}
                          description="list-name"
                        />
                      }
                      secondary={
                        <FormattedMessage
                          id={'dimensions.' + dim}
                          description="list-name"
                        />
                      }
                    />
                    {!editMode && (
                      <ColoredScore
                        backgroundColor={color}
                        fontSize={'14px'}
                        width="45px"
                        height="28px"
                        value={grade}
                      />
                    )}
                    {editMode && (
                      <ListItemIcon>
                        <RemoveCircle
                          className="remove-icon"
                          onClick={() =>
                            setUnwatchConsiderationIds([
                              ...unwatchConsiderationIds,
                              item.get('id'),
                            ])
                          }
                        />
                      </ListItemIcon>
                    )}
                  </ListItem>
                )
              })
            )}
          </ul>
        </li>
      </List>
    </React.Fragment>
  )
}

function mapStateToProps(state) {
  return {
    pageStore: state.PageStore,
    watchListStore: state.WatchListStore,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        unwatchConsideration,
        setDrawerinfo,
      },
      dispatch
    ),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WatchListComponent)
