import { GET_CITY_PROFILE, LOGOUT_USER } from '../constants/ActionTypes'
import { fromJS } from 'immutable'

const initialState = fromJS({
  currentWeather: {
    temp: '',
  },
  profile: fromJS({
    name_new: [],
    country_new: [],
  }),
  sdg: {},
  area: 0,
  population: {},
})

export default (state = initialState, action) => {
  let newState = state

  switch (action.type) {
    case GET_CITY_PROFILE:
      if (action.payload) {
        newState = state.set('profile', fromJS(action.payload.lang))
        newState = newState.set('sdg', fromJS(action.payload.sdg))
        newState = newState.set('area', fromJS(action.payload.area))
        newState = newState.set('population', fromJS(action.payload.population))
        newState = newState.set('umfEnabled', fromJS(action.payload.umfEnabled))
        newState = newState.setIn(
          ['currentWeather', 'temp'],
          fromJS(action.payload.weather)
        )
      } else {
        newState = state.set('profile', null)
        newState = newState.set('sdg', {})
        newState = newState.set('area', 0)
        newState = newState.set('population', {})
        newState = newState.setIn(['currentWeather', 'temp'], null)
      }
      return newState
    case LOGOUT_USER:
      if (action.payload.LogoutUser.success) return initialState
      return state
    default:
      return state
  }
}
